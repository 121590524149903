
import Vue from 'vue';
import Component from 'vue-class-component';
import { LpEntity } from '@/classes';
import { Prop } from 'vue-property-decorator';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import FilterEntityTypeSubtype from '@/components/EntitiesPage/FilterMenu/Submenus/FilterEntityTypeSubtype.vue';
import FilterProjectSourceTypeSubtype from '@/components/EntitiesPage/FilterMenu/Submenus/Project/FilterProjectSourceTypeSubtype.vue';
import FilterProjectStatus from '@/components/EntitiesPage/FilterMenu/Submenus/FilterProjectStatus.vue';

@Component({
	components: {
		FilterEntityTypeSubtype,
		FilterProjectSourceTypeSubtype,
		FilterProjectStatus,
	},
})
export default class FilterTypeMenu <EntityT extends LpEntity> extends Vue {
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: BaseFilterModule<EntityT>;
	@Prop(Boolean) public readonly showEntityType!: boolean;
	@Prop(Boolean) public readonly showProjectSource!: boolean;
	@Prop(Boolean) public readonly showProjectStatus!: boolean;

	public get hideProjects(): boolean {
		if (this.store.filterByAllEntityTypes) {
			return false;
		}
		return !this.store.filterByEntityTypes.includes('Project');
	}
}
