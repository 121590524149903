import * as coreHttp from "@azure/core-http";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import { FaroSubscriptionContext } from "./faroSubscriptionContext";
import {
  FaroSubscriptionOptionalParams,
  FaroSubscriptionCreateOrderOptionalParams,
  FaroSubscriptionCreateOrderResponse,
  FaroSubscriptionGetWorkspaceOrdersResponse,
  FaroSubscriptionUpdateOrderOptionalParams,
  FaroSubscriptionUserHasWorkspaceOptionalParams,
  FaroSubscriptionUserHasWorkspaceResponse,
  FaroSubscriptionUserCacheInvalidateResponse,
  FaroSubscriptionGetCustomersResponse,
  FaroSubscriptionCreateCustomerOptionalParams,
  FaroSubscriptionCreateCustomerResponse,
  FaroSubscriptionGetCustomerResponse,
  FaroSubscriptionUpdateCustomerOptionalParams,
  FaroSubscriptionUpdateCustomerResponse,
  FaroSubscriptionCreateWorkspaceOptionalParams,
  FaroSubscriptionCreateWorkspaceResponse,
  FaroSubscriptionGetWorkspacesOptionalParams,
  FaroSubscriptionGetWorkspacesResponse,  
  FaroSubscriptionGetAllWorkspacesSubscriptionsOptionalParams,
  FaroSubscriptionGetAllWorkspacesSubscriptionsResponse,
  FaroSubscriptionGetAllWorkspacesQuotaLimitsResponse,
  FaroSubscriptionGetAllWorkspacesYearlyPricingOptionalParams,
  FaroSubscriptionGetAllWorkspacesYearlyPricingResponse,
  FaroSubscriptionGetWorkspacesByUserResponse,
  FaroSubscriptionGetWorkspacesStatesResponse,
  FaroSubscriptionGetWorkspaceResponse,
  FaroSubscriptionGetWorkspacesOverviewsResponse,
  FaroSubscriptionUpdateWorkspaceOptionalParams,
  FaroSubscriptionUpdateWorkspaceResponse,
  FaroSubscriptionGetWorkspaceActivationLinkResponse,
  FaroSubscriptionActivateWorkspaceResponse,
  FaroSubscriptionDeactivateWorkspaceOptionalParams,
  FaroSubscriptionDeactivateWorkspaceResponse,
  FaroSubscriptionCancelDeactivateWorkspaceResponse,
  FaroSubscriptionReactivateWorkspaceOptionalParams,
  FaroSubscriptionReactivateWorkspaceResponse,
  FaroSubscriptionDeactivateWorkspaceHintResponse,
  FaroSubscriptionLatestSubscriptionResponse,
  FaroSubscriptionGetWorkspaceSubscriptionsOptionalParams,
  FaroSubscriptionGetWorkspaceSubscriptionsResponse,
  FaroSubscriptionGetWorkspaceFeaturesResponse,
  FaroSubscriptionGetWorkspaceQuotaLimitsResponse,
  FaroSubscriptionGetSubscriptionResponse,
  FaroSubscriptionGetSubscriptionDetailsResponse,
  FaroSubscriptionGetWorkspaceReportsOptionalParams,
  FaroSubscriptionGetWorkspaceReportsResponse,
  FaroSubscriptionValidateEmailResponse,
  FaroSubscriptionMigrateWebshareSubscriptionsResponse,
  FaroSubscriptionMigrateWebshareSubscriptionsUndoResponse
} from "./models";

export class FaroSubscription extends FaroSubscriptionContext {
  /**
   * Initializes a new instance of the FaroSubscription class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor(
    credentials: coreHttp.TokenCredential | coreHttp.ServiceClientCredentials,
    $host: string,
    options?: FaroSubscriptionOptionalParams
  ) {
    super(credentials, $host, options);
  }

  /**
   * application & database health
   * @param options The options parameters.
   */
  health(options?: coreHttp.OperationOptions): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      healthOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Application & database health, and requires a valid API key.
   * @param options The options parameters.
   */
  healthApiKey(
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      healthApiKeyOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * indicates that the server is running
   * @param options The options parameters.
   */
  healthServer(
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      healthServerOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * @param options The options parameters.
   */
  version(options?: coreHttp.OperationOptions): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      versionOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Create a new order
   * @param options The options parameters.
   */
  createOrder(
    options?: FaroSubscriptionCreateOrderOptionalParams
  ): Promise<FaroSubscriptionCreateOrderResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      createOrderOperationSpec
    ) as Promise<FaroSubscriptionCreateOrderResponse>;
  }

  /**
   * Get Orders from a workspace by worksspace id
   * @param workspaceId Unique identifier for the workspace
   * @param options The options parameters.
   */
  getWorkspaceOrders(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspaceOrdersResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      getWorkspaceOrdersOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspaceOrdersResponse>;
  }

  /**
   * Complete an order
   * @param orderId Unique identifier for the order
   * @param options The options parameters.
   */
  completeOrder(
    orderId: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { orderId, options: operationOptions },
      completeOrderOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * check if order number exists
   * @param orderId order number to validate
   * @param options The options parameters.
   */
  orderNumberExists(
    orderId: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { orderId, options: operationOptions },
      orderNumberExistsOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Update an existing order
   * @param orderId Unique identifier for the order
   * @param options The options parameters.
   */
  updateOrder(
    orderId: string,
    options?: FaroSubscriptionUpdateOrderOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { orderId, options: operationOptions },
      updateOrderOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Check if user already has a workspace
   * @param options The options parameters.
   */
  userHasWorkspace(
    options?: FaroSubscriptionUserHasWorkspaceOptionalParams
  ): Promise<FaroSubscriptionUserHasWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      userHasWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionUserHasWorkspaceResponse>;
  }

  /**
   * Invalidates the workspace permissions cache gathered from authz.
   * Those permissions are stored under the userId therefore they
   * need to be invalidate per user instead of per workspace.
   * @param userId
   * @param options The options parameters.
   */
  userCacheInvalidate(
    userId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionUserCacheInvalidateResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { userId, options: operationOptions },
      userCacheInvalidateOperationSpec
    ) as Promise<FaroSubscriptionUserCacheInvalidateResponse>;
  }

  /**
   * Get customers
   * @param options The options parameters.
   */
  getCustomers(
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetCustomersResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getCustomersOperationSpec
    ) as Promise<FaroSubscriptionGetCustomersResponse>;
  }

  /**
   * Create a customer
   * @param options The options parameters.
   */
  createCustomer(
    options?: FaroSubscriptionCreateCustomerOptionalParams
  ): Promise<FaroSubscriptionCreateCustomerResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      createCustomerOperationSpec
    ) as Promise<FaroSubscriptionCreateCustomerResponse>;
  }

  /**
   * Get a customer
   * @param customerId
   * @param options The options parameters.
   */
  getCustomer(
    customerId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetCustomerResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { customerId, options: operationOptions },
      getCustomerOperationSpec
    ) as Promise<FaroSubscriptionGetCustomerResponse>;
  }

  /**
   * Update a customer
   * @param customerId
   * @param options The options parameters.
   */
  updateCustomer(
    customerId: string,
    options?: FaroSubscriptionUpdateCustomerOptionalParams
  ): Promise<FaroSubscriptionUpdateCustomerResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { customerId, options: operationOptions },
      updateCustomerOperationSpec
    ) as Promise<FaroSubscriptionUpdateCustomerResponse>;
  }

  /**
   * Create a new workspace
   * @param options The options parameters.
   */
  createWorkspace(
    options?: FaroSubscriptionCreateWorkspaceOptionalParams
  ): Promise<FaroSubscriptionCreateWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      createWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionCreateWorkspaceResponse>;
  }

  /**
   * Get workspaces as admin
   * @param options The options parameters.
   */
  getWorkspaces(
    options?: FaroSubscriptionGetWorkspacesOptionalParams
  ): Promise<FaroSubscriptionGetWorkspacesResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getWorkspacesOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspacesResponse>;
  }

  /**
   * Get workspaces as admin,
   * but in a reduced overview variant
   * @param options The options parameters.
   */
  getWorkspacesOverviews(
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspacesOverviewsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getWorkspacesOverviewsOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspacesOverviewsResponse>;
  }  

  /**
   * Get all workspace's subscriptions
   * @param options The options parameters.
   */
  getAllWorkspacesSubscriptions(
    options?: FaroSubscriptionGetAllWorkspacesSubscriptionsOptionalParams
  ): Promise<FaroSubscriptionGetAllWorkspacesSubscriptionsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getAllWorkspacesSubscriptionsOperationSpec
    ) as Promise<FaroSubscriptionGetAllWorkspacesSubscriptionsResponse>;
  }

  /**
   * Get quota limits for all workspaces
   * @param options The options parameters.
   */
  getAllWorkspacesQuotaLimits(
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetAllWorkspacesQuotaLimitsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getAllWorkspacesQuotaLimitsOperationSpec
    ) as Promise<FaroSubscriptionGetAllWorkspacesQuotaLimitsResponse>;
  }

  /**
   * Paid workspaces will have price > 0 and valid currency.
   * Unpaid workspaces (Base or Demo) will return price = 0 and currency = XXX.
   * There are some old leftover workspaces which have no currency set. They will return price = 0 and
   * currency = XXX aswell.
   * @param options The options parameters.
   */
  getAllWorkspacesYearlyPricing(
    options?: FaroSubscriptionGetAllWorkspacesYearlyPricingOptionalParams
  ): Promise<FaroSubscriptionGetAllWorkspacesYearlyPricingResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getAllWorkspacesYearlyPricingOperationSpec
    ) as Promise<FaroSubscriptionGetAllWorkspacesYearlyPricingResponse>;
  }

  /**
   * Get workspaces that belong to the user
   * @param userId
   * @param options The options parameters.
   */
  getWorkspacesByUser(
    userId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspacesByUserResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { userId, options: operationOptions },
      getWorkspacesByUserOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspacesByUserResponse>;
  }

  /**
   * Get states of all workspaces
   * @param options The options parameters.
   */
  getworkspacesStates(
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspacesStatesResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getworkspacesStatesOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspacesStatesResponse>;
  }

  /**
   * Get a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  getWorkspace(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      getWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspaceResponse>;
  }

  /**
   * Update a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  updateWorkspace(
    workspaceId: string,
    options?: FaroSubscriptionUpdateWorkspaceOptionalParams
  ): Promise<FaroSubscriptionUpdateWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      updateWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionUpdateWorkspaceResponse>;
  }

  /**
   * Delete a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  deleteWorkspace(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      deleteWorkspaceOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Checks whether a workspace name is already taken
   * @param workspaceId workspaceId must be the name of a workspace
   * @param options The options parameters.
   */
  workspaceExists(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      workspaceExistsOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get the activation link for a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  getWorkspaceActivationLink(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspaceActivationLinkResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      getWorkspaceActivationLinkOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspaceActivationLinkResponse>;
  }

  /**
   * Activate a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  activateWorkspace(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionActivateWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      activateWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionActivateWorkspaceResponse>;
  }

  /**
   * Deactivate a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  deactivateWorkspace(
    workspaceId: string,
    options?: FaroSubscriptionDeactivateWorkspaceOptionalParams
  ): Promise<FaroSubscriptionDeactivateWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      deactivateWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionDeactivateWorkspaceResponse>;
  }

  /**
   * Cancel a deactivation in the future
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  cancelDeactivateWorkspace(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionCancelDeactivateWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      cancelDeactivateWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionCancelDeactivateWorkspaceResponse>;
  }

  /**
   * reactivate a deactivated workspace with already canceled subscription
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  reactivateWorkspace(
    workspaceId: string,
    options?: FaroSubscriptionReactivateWorkspaceOptionalParams
  ): Promise<FaroSubscriptionReactivateWorkspaceResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      reactivateWorkspaceOperationSpec
    ) as Promise<FaroSubscriptionReactivateWorkspaceResponse>;
  }

  /**
   * Hint when the currently active subscription ends.
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  deactivateWorkspaceHint(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionDeactivateWorkspaceHintResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      deactivateWorkspaceHintOperationSpec
    ) as Promise<FaroSubscriptionDeactivateWorkspaceHintResponse>;
  }

  /**
   * Show the last active subscription of a workspace.
   * For active workspaces it will return the current active subscription.
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  latestSubscription(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionLatestSubscriptionResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      latestSubscriptionOperationSpec
    ) as Promise<FaroSubscriptionLatestSubscriptionResponse>;
  }

  /**
   * Get a workspace's subscriptions
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  getWorkspaceSubscriptions(
    workspaceId: string,
    options?: FaroSubscriptionGetWorkspaceSubscriptionsOptionalParams
  ): Promise<FaroSubscriptionGetWorkspaceSubscriptionsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      getWorkspaceSubscriptionsOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspaceSubscriptionsResponse>;
  }

  /**
   * Get features associated to a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  getWorkspaceFeatures(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspaceFeaturesResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      getWorkspaceFeaturesOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspaceFeaturesResponse>;
  }

  /**
   * Get quota limits for a workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  getWorkspaceQuotaLimits(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetWorkspaceQuotaLimitsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      getWorkspaceQuotaLimitsOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspaceQuotaLimitsResponse>;
  }

  /**
   * Get a specific subscription
   * @param subscriptionId
   * @param options The options parameters.
   */
  getSubscription(
    subscriptionId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetSubscriptionResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { subscriptionId, options: operationOptions },
      getSubscriptionOperationSpec
    ) as Promise<FaroSubscriptionGetSubscriptionResponse>;
  }

  /**
   * Get Subscription details in text form
   * @param subscriptionId
   * @param options The options parameters.
   */
  getSubscriptionDetails(
    subscriptionId: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionGetSubscriptionDetailsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { subscriptionId, options: operationOptions },
      getSubscriptionDetailsOperationSpec
    ) as Promise<FaroSubscriptionGetSubscriptionDetailsResponse>;
  }

  /**
   * Get workspace reports for a given month.
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  getWorkspaceReports(
    workspaceId: string,
    options?: FaroSubscriptionGetWorkspaceReportsOptionalParams
  ): Promise<FaroSubscriptionGetWorkspaceReportsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      getWorkspaceReportsOperationSpec
    ) as Promise<FaroSubscriptionGetWorkspaceReportsResponse>;
  }

  /**
   * Notify FaroContacts and customer for pending workspace
   * @param workspaceId unique identifier for the workspace
   * @param options The options parameters.
   */
  pendingWorkspaceNotification(
    workspaceId: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { workspaceId, options: operationOptions },
      pendingWorkspaceNotificationOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Validate an email address.
   * @param email email that is to be validated
   * @param options The options parameters.
   */
  validateEmail(
    email: string,
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionValidateEmailResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { email, options: operationOptions },
      validateEmailOperationSpec
    ) as Promise<FaroSubscriptionValidateEmailResponse>;
  }

  /**
   * Initiate webshare migration
   * @param options The options parameters.
   */
  migrateWebshareSubscriptions(
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionMigrateWebshareSubscriptionsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      migrateWebshareSubscriptionsOperationSpec
    ) as Promise<FaroSubscriptionMigrateWebshareSubscriptionsResponse>;
  }

  /**
   * Undo webshare migration
   * @param options The options parameters.
   */
  migrateWebshareSubscriptionsUndo(
    options?: coreHttp.OperationOptions
  ): Promise<FaroSubscriptionMigrateWebshareSubscriptionsUndoResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      migrateWebshareSubscriptionsUndoOperationSpec
    ) as Promise<FaroSubscriptionMigrateWebshareSubscriptionsUndoResponse>;
  }
}
// Operation Specifications

const serializer = new coreHttp.Serializer(Mappers, /* isXml */ false);

const healthOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/health",
  httpMethod: "GET",
  responses: { 200: {}, 500: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const healthApiKeyOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/health/apikey",
  httpMethod: "GET",
  responses: { 200: {}, 401: {}, 500: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const healthServerOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/health/server",
  httpMethod: "GET",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const versionOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/version",
  httpMethod: "GET",
  responses: { 200: {}, 503: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const createOrderOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/orders",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.OrderCreatedInfo
    },
    400: {
      bodyMapper: { type: { name: "String" } }
    },
    500: {}
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getWorkspaceOrdersOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/orders/{workspaceId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "Order" } }
        }
      }
    },
    404: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const completeOrderOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/order/{orderId}",
  httpMethod: "PATCH",
  responses: { 200: {}, 500: {} },
  urlParameters: [Parameters.$host, Parameters.orderId],
  serializer
};
const orderNumberExistsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/order/{orderId}",
  httpMethod: "HEAD",
  responses: { 200: {}, 204: {}, 500: {} },
  urlParameters: [Parameters.$host, Parameters.orderId],
  serializer
};
const updateOrderOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/order/{orderId}/update",
  httpMethod: "PATCH",
  responses: { 200: {}, 404: {}, 500: {} },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host, Parameters.orderId],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer
};
const userHasWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/user-workspace",
  httpMethod: "GET",
  responses: {
    200: {},
    204: {},
    206: {},
    400: {},
    500: {
      bodyMapper: { type: { name: "String" } }
    }
  },
  queryParameters: [Parameters.email],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const userCacheInvalidateOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/user/{userId}/cache/invalidate",
  httpMethod: "GET",
  responses: {
    200: {},
    401: {},
    403: {},
    500: {
      bodyMapper: { type: { name: "String" } }
    }
  },
  urlParameters: [Parameters.$host, Parameters.userId],
  headerParameters: [Parameters.accept1],
  serializer
};
const getCustomersOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/customers",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "Customer" } }
        }
      }
    },
    500: {}
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const createCustomerOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/customer",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.Customer
    },
    400: {
      bodyMapper: { type: { name: "String" } }
    },
    500: {}
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getCustomerOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/customer/{customerId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Customer
    },
    400: {},
    404: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.customerId],
  headerParameters: [Parameters.accept1],
  serializer
};
const updateCustomerOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/customer/{customerId}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.Customer
    },
    400: {},
    404: {},
    500: {}
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host, Parameters.customerId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const createWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.Workspace
    },
    400: {},
    403: {},
    409: {},
    500: {}
  },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getWorkspacesOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspaces",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "AdminWorkspace" } }
        }
      }
    },
    500: {}
  },
  queryParameters: [Parameters.withoutOwner],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const getWorkspacesOverviewsOperationSpec: coreHttp.OperationSpec = {
	path: "/subscription/v1/workspaces/overviews",
	httpMethod: "GET",
	responses: {
		200: {
			bodyMapper: {
				type: {
					name: "Sequence",
					element: { type: { name: "Composite", className: "WorkspaceOverview" } }
				}
			}
		},
		500: {}
	},
	queryParameters: [Parameters.withoutOwner],
	urlParameters: [Parameters.$host],
	headerParameters: [Parameters.accept1],
	serializer
};
const getAllWorkspacesSubscriptionsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspaces/subscriptions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "WorkspaceSubscriptions" }
          }
        }
      }
    },
    400: {},
    500: {}
  },
  queryParameters: [Parameters.fromParam, Parameters.to],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const getAllWorkspacesQuotaLimitsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspaces/quota/limits",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "WorkspaceQuotaLimits" }
          }
        }
      }
    },
    400: {},
    401: {},
    403: {},
    500: {}
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const getAllWorkspacesYearlyPricingOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspaces/pricing",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "WorkspacePricing" }
          }
        }
      }
    },
    400: {},
    401: {},
    403: {},
    500: {}
  },
  queryParameters: [Parameters.year],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const getWorkspacesByUserOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspaces/byuserid/{userId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "Workspace" } }
        }
      }
    },
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.userId],
  headerParameters: [Parameters.accept1],
  serializer
};
const getworkspacesStatesOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspaces/state",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "WorkspaceState" } }
        }
      }
    },
    401: {},
    500: {}
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const getWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Workspace
    },
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const updateWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.Workspace
    },
    400: {},
    500: {},
    501: {}
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deleteWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}",
  httpMethod: "DELETE",
  responses: { 200: {}, 500: {} },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  serializer
};
const workspaceExistsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}",
  httpMethod: "HEAD",
  responses: { 200: {}, 400: {}, 404: {}, 500: {} },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  serializer
};
const getWorkspaceActivationLinkOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/activation-link",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: { type: { name: "String" } }
    },
    401: {},
    403: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept2],
  serializer
};
const activateWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/activate",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.Workspace
    },
    401: {},
    403: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const deactivateWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/deactivate",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.Workspace
    },
    400: {},
    401: {},
    403: {},
    404: {},
    500: {}
  },
  requestBody: Parameters.body6,
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const cancelDeactivateWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/deactivate",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.Workspace
    },
    400: {},
    401: {},
    403: {},
    404: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const reactivateWorkspaceOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/reactivate",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.Workspace
    },
    400: {},
    401: {},
    403: {},
    404: {},
    500: {}
  },
  requestBody: Parameters.body7,
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deactivateWorkspaceHintOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/deactivate/hint",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.DeactivateHint
    },
    401: {},
    403: {},
    404: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const latestSubscriptionOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/subscription/latest",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Subscription
    },
    401: {},
    403: {},
    404: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const getWorkspaceSubscriptionsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/subscriptions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "Subscription" } }
        }
      }
    },
    400: {},
    500: {}
  },
  queryParameters: [Parameters.fromParam, Parameters.to],
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const getWorkspaceFeaturesOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/features",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "String" } } }
      }
    },
    404: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.accept1],
  serializer
};
const getWorkspaceQuotaLimitsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspace_id}/quota/limits",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.QuotaLimits
    },
    400: {},
    401: {},
    403: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.workspaceId1],
  headerParameters: [Parameters.accept1],
  serializer
};
const getSubscriptionOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/subscription/{subscriptionId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Subscription
    },
    404: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.subscriptionId],
  headerParameters: [Parameters.accept1],
  serializer
};
const getSubscriptionDetailsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/subscription/{subscriptionId}/details",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: { type: { name: "String" } }
    },
    400: {},
    500: {}
  },
  urlParameters: [Parameters.$host, Parameters.subscriptionId],
  headerParameters: [Parameters.accept1],
  serializer
};
const getWorkspaceReportsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/reports",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "Report" } }
        }
      }
    },
    500: {}
  },
  requestBody: Parameters.body8,
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const pendingWorkspaceNotificationOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/workspace/{workspaceId}/notify",
  httpMethod: "POST",
  responses: { 200: {}, 400: {}, 500: {} },
  urlParameters: [Parameters.$host, Parameters.workspaceId],
  serializer
};
const validateEmailOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/validate/email",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ValidationResult
    },
    500: {}
  },
  queryParameters: [Parameters.email1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const migrateWebshareSubscriptionsOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/migration/webshare",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.Text
    },
    500: {}
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
const migrateWebshareSubscriptionsUndoOperationSpec: coreHttp.OperationSpec = {
  path: "/subscription/v1/migration/webshare/undo",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.Text
    },
    500: {}
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept1],
  serializer
};
