var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"meshBackgroundImageBackground",class:[
		_vm.imageSize,
		{
			'meshBackgroundImageBackground--off': _vm.hideMeshOnSuccessLoad && !_vm.errorLoading,
			'thumbnail-lg-width meshBackgroundImageBackground-lg': _vm.$vuetify.breakpoint.lgAndUp && !_vm.listView,
			'thumbnail-md-width meshBackgroundImageBackground-md': _vm.$vuetify.breakpoint.md && !_vm.listView,
			'thumbnail-sm-width meshBackgroundImageBackground-sm': _vm.$vuetify.breakpoint.sm && !_vm.listView,
			'thumbnail-xs-width meshBackgroundImageBackground-xs': _vm.$vuetify.breakpoint.xs && !_vm.listView
		}
	]},[_c('div',{staticClass:"meshBackgroundImageWrapper"},[_c('img',{staticClass:"meshBackgroundImage",attrs:{"src":_vm.internalSrc},on:{"error":_vm.onError}}),(_vm.hintMsg)?_c('clickable-label',{staticClass:"hintMsgPos",attrs:{"hintMsg":_vm.hintMsg},model:{value:(_vm.onOpenMenu),callback:function ($$v) {_vm.onOpenMenu=$$v},expression:"onOpenMenu"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }