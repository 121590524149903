
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FaroTab } from '@/utils/types';

@Component
export default class FaroTabs extends Vue {
	@Prop(Array) public readonly tabs!: FaroTab[];
	@Prop(String) public readonly value!: string;

	public get openedTab(): string {
		return this.value;
	}

	public get tabModel(): string {
		return this.value;
	}

	public set tabModel(value: string) {
		this.$emit('input', value);
	}
}
