
import Vue from 'vue';
import Component from 'vue-class-component';
import { LpEntity } from '@/classes';
import { Prop } from 'vue-property-decorator';
import { BaseFilterModule, EntityType } from '@/store/modules/BaseFilterModule';

@Component
export default class FilterEntityTypeSubtype <EntityT extends LpEntity> extends Vue {
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: BaseFilterModule<EntityT>;

	public get filterByEntityTypesOptions() {
		return this.store.filterByEntityTypesOptions;
	}

	public get filterByAllEntityTypes(): boolean {
		return this.store.filterByAllEntityTypes;
	}

	public set filterByAllEntityTypes(filterByAllEntityTypes: boolean) {
		if (!filterByAllEntityTypes) {
			this.store.setFilterByEntityTypes([]);
		}
		this.store.setFilterByAllEntityTypes(filterByAllEntityTypes);
	}

	public get filterByEntityTypes() {
		if (this.filterByAllEntityTypes) {
			return this.store.filterByEntityTypesOptions.map((filterByEntityTypesOption) => filterByEntityTypesOption.value);
		}
		return this.store.filterByEntityTypes;
	}

	public set filterByEntityTypes(filterByEntityTypes: EntityType[]) {
		if (filterByEntityTypes.length < this.store.filterByEntityTypesOptions.length) {
			this.filterByAllEntityTypes = false;
		}
		if (filterByEntityTypes.length === this.filterByEntityTypesOptions.length) {
			this.filterByAllEntityTypes = true;
		}
		this.store.setFilterByEntityTypes(filterByEntityTypes);
	}
}
