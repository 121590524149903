import { Application } from '@/classes/Application';
import {  Module, RegisterOptions } from 'vuex-class-modules';
import { PageModule } from '@/store/modules/PageModule';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import { ApplicationService } from '@/store/services/ApplicationService';
import { ApplicationAny } from '@/classes/ApplicationAny';

@Module
export class ApplicationModule extends BaseFilterModule<Application> {
	// ###################################### Properties ######################################

	// ###### Public ######

	// ###### Protected ######

	protected readonly service = new ApplicationService({});

	// ###### Private ######


	// ###################################### Getters ######################################

	// ###### Public ######

	public get projectsForFilterList() {
		// Since there are no projects in the applications page, just return an empty array.
		return [];
	}

	public get filterByProjectSourceOptions() {
		// Since there are no project sources in the applications page, just return an empty array.
		return [];
	}
	public get filterByProjectStatusOptions() {
		// Since there are no project statuses in the applications page, just return an empty array.
		return [];
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions) {
		// Using "ApplicationAny" here allows the module to construct different types of Applications.
		super(pages, options, ApplicationAny);
	}


	// ###################################### Actions ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######


	// ###################################### Mutations ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######


	// ###################################### Helper Methods ######################################

	// ###### Public ######

	// ###### Protected ######

	/**
	 * Filters a list of applications by keeping only the ones that have some attribute,
	 * that matches some search text.
	 * @param applications The original application list.
	 * @param searchTxt The search text.
	 * @returns A new filtered application list.
	 */
	protected filterByTextItems(applications: Application[], searchTxt: string): Application[] {
		searchTxt = searchTxt.toLowerCase();
		return applications.filter((application) =>
			application.Name.toLowerCase().includes(searchTxt) ||
			application.Description.toLowerCase().includes(searchTxt),
		);
	}

	// ###### Private ######
}
