import { IMFATicketInfo, IUserSettings } from '@/definitions/interfaces';
import { User } from '@/classes/authz/User';
import { BASE_API_URL, config } from '@/config';
import { BaseService, ClientOptions } from '@/store/services/BaseService';

/**
 * This service is in charge of CRUD operations to manipulate the Sphere Users in the landing page backend.
 */
export class UserService extends BaseService<User> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'user',
			apiEndpoint: config.apiEndpoint,
			baseApiUrl: BASE_API_URL,
		}, clientConfig);
	}

	public async getSingle<ResponseT = User, QueryT extends object = never>(uuid: string, query?: QueryT): Promise<ResponseT> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<ResponseT = User, QueryT extends object = never>(query?: QueryT): Promise<ResponseT[]> {
		throw new Error('Function not yet implemented');
	}

	public async create<ResponseT = User, QueryT extends object = never>(body?: User, query?: QueryT): Promise<ResponseT> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<ResponseT = User, QueryT extends object = never>(body?: User, query?: QueryT): Promise<ResponseT> {
		throw new Error('Function not yet implemented');
	}

	public async updateMulti<ResponseT = User, QueryT extends object = never>(body?: User[], query?: QueryT): Promise<ResponseT[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		const url = this.makeUrl() + '/' + uuid + '/unassign-workspace';
		return await this.del(url);
	}

	/**
	 * This service sends the request to the backend and fetches the user's settings.
	 */
	public async getUserSettings(): Promise<IUserSettings> {
		return await this.get(`${config.apiEndpoint}/home/v1/user/settings`);
	}

	/**
	 * This service sends the request to the backend to change the user's settings.
	 */
	public async changeUserSettings(userSettings: Partial<IUserSettings>): Promise<IUserSettings> {
		return await this.put(`${config.apiEndpoint}/home/v1/user/settings`, userSettings);
	}

	/**
	 * This service sends the request to the backend to send an MFA enrollment ticket to the user.
	 */
	public async enrollMFA(): Promise<IMFATicketInfo> {
		const url = this.makeUrl() + '/mfa-enrollment';
		return await this.post(url);
	}
	/**
	 * This service sends the request to the backend to delete the mfa enrollment(s) of user
	 */
	public async disableMFA(): Promise<{ Success: true }> {
		const url = this.makeUrl() +  '/mfa-enrollment';
		return await this.del(url);
	}

	/**
	 * This service sends the request to the backend to check the mfa enrollment status
	 */
	public async checkUserHasMFAEnabled(): Promise<{ MFAEnabled: boolean }> {
		const url = this.makeUrl() + '/mfa-status';
		return await this.get(url);
	}
}
