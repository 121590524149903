import Vue from 'vue';
import { HttpClient, RequestOptions } from '@faroconnect/clientbase';
import { $assert, WebUtils } from '@faroconnect/utils';
import { validateString } from '@/utils/validate';
import { handleAuthenticationError, redirectAfterAuthenticationError } from '@/utils/errorhandler';
import { AxiosError } from 'axios';

export interface ClientOptions {
	server?: string | undefined;
	apiId?: string | undefined;
	apiKey?: string | undefined;
	subdomain?: string | undefined;
	webshareServer?: string | undefined;
}

export interface ApiConfig {
	apiRoute?: string;
	apiEndpoint?: string;
	baseApiUrl?: string;
}

/**
 * Base service for CRUD operations on any data.
 */
export abstract class BaseServiceAny {
	/**
	 * Returns the custom headers to add to the request, in particular the mandatory
	 *   Authorization: Bearer ...
	 * header for user authentication.
	 * If the user has been logged out due to inactivity, the Auth0 log-in screen is shown and the user is
	 * then redirected to the requested page.
	 */
	public static async getCustomHeaders(): Promise<{ Authorization?: string }> {
		$assert.Assert(Vue.prototype);
		if (!Vue.prototype) {
			return {};
		}
		// $assert.Assert(Vue.prototype.$auth); // This assertion currently breaks unit tests.
		if (!Vue.prototype.$auth) {
			return {};
		}

		const userToken = await BaseServiceAny.getTokenSilentlyWithRedirect();
		return { Authorization: `Bearer ${userToken}` };
	}

	/**
	 * Returns the access token. If the token is invalid or missing, a new one is retrieved.
	 * If the user has been logged out due to inactivity, the Auth0 log-in screen is shown and the user is
	 * then redirected to the requested page.
	 * @author OK
	 */
	public static async getTokenSilentlyWithRedirect(): Promise<string | undefined> {
		$assert.Assert(Vue.prototype);

		try {
			const $tsStore: $tsStore = Vue.prototype.$tsStore;
			return await $tsStore.users.getTokenSilently();
		} catch (error: any) {
			if (error.error === 'login_required' || error.message === 'Login required') {
				return redirectAfterAuthenticationError();
			} else {
				throw error;
			}
		}
	}
	protected client: HttpClient;
	/**
	 * Api endpoint for the current service.
	 * E.g. for the project service, to call 'http://localhost:4803/home/v1/project',
	 * 'http://localhost:4803' is the apiEndpoint.
	 */
	protected apiEndpoint?: string;
	/**
	 * Base api url for the current service.
	 * E.g. for the project service, to call 'http://localhost:4803/home/v1/project',
	 * '/home/v1' is the baseApiUrl.
	 */
	protected baseApiUrl?: string;
	/**
	 * Api route endpoint for the current service.
	 * E.g. for the project service, to call 'http://localhost:4803/home/v1/project',
	 * 'project' is the apiRoute.
	 */
	protected apiRoute?: string;

	public constructor(apiConfig: ApiConfig, clientConfig: ClientOptions) {
		if (apiConfig.apiEndpoint) {
			$assert.Assert(!apiConfig.apiEndpoint.startsWith('/'), `apiEndpoint should not start with '/'. Found ${apiConfig.apiEndpoint}`);
			$assert.Assert(!apiConfig.apiEndpoint.endsWith('/'), `apiEndpoint should not end with '/'. Found ${apiConfig.apiEndpoint}`);
		}
		if (apiConfig.baseApiUrl) {
			$assert.Assert(apiConfig.baseApiUrl.startsWith('/'), `baseApiUrl should start with '/'. Found ${apiConfig.baseApiUrl}`);
			$assert.Assert(!apiConfig.baseApiUrl.endsWith('/'), `baseApiUrl should not end with '/'. Found ${apiConfig.baseApiUrl}`);
		}
		if (apiConfig.apiRoute) {
			$assert.Assert(!apiConfig.apiRoute.startsWith('/'), `apiRoute should not start with '/'. Found ${apiConfig.apiRoute}`);
			$assert.Assert(!apiConfig.apiRoute.endsWith('/'), `apiRoute should not end with '/'. Found ${apiConfig.apiRoute}`);
		}

		this.apiRoute = apiConfig.apiRoute;
		this.apiEndpoint = apiConfig.apiEndpoint;
		this.baseApiUrl = apiConfig.baseApiUrl;

		this.client = new HttpClient(
			clientConfig.server,
			clientConfig.apiId,
			clientConfig.apiKey,
			clientConfig.subdomain,
			clientConfig.webshareServer,
		);
		this.client.onResponse({
			onRejected: (error: AxiosError) => {
				return handleAuthenticationError(error);
			},
		});
	}

	/**
	 * Makes the url for a backend route.
	 * @param endpoint Endpoint of the url.
	 * 				   E.g. to call the route 'http://localhost:4803/home/v1/project/:uuid', the endpoint is 'uuid' and 'project' is the apiRoute.
	 * @param query Optional query to include in the url.
	 * 				E.g. to call the route 'http://localhost:4803/home/v1/project?foo=bar&hi=all', the query is { foo: 'bar', hi: 'all' }.
	 * @returns The url to be called. E.g. 'http://localhost:4803/home/v1/project?foo=bar&hi=all'.
	 */
	protected makeUrl<QueryT extends object>(endpoint?: string, query?: QueryT): string {
		const apiEndpoint = validateString(this.apiEndpoint);
		const baseApiUrl = validateString(this.baseApiUrl);

		let url = `${apiEndpoint}${baseApiUrl}`;
		if (this.apiRoute) {
			const apiRoute = validateString(this.apiRoute);
			url += `/${apiRoute}`;
		}
		if (endpoint) {
			url += `/${endpoint}`;
		}
		if (query) {
			url += WebUtils.getParamString(query) ?? '';
		}
		return url;
	}

	// Unused:
	// protected async getUserUuid(headers: { Authorization?: string }) {
	// 	if (!store.users.user?.UUID) {
	// 		const url = config.authzApiEndpoint + BASE_AUTHZ_API_URL + '/user/';
	// 		const userInfo: AuthzInterface.IUser = await this.client.get(url, { customHeaders: headers });
	// 		const user = User.fromResponse(userInfo);
	// 		store.users.setUser(user);
	// 		await store.users.updateLanguage(user);
	// 		return userInfo.UUID;
	// 	} else {
	// 		return store.users.user.UUID;
	// 	}
	// }

	protected async get<ResponseT>(url: string, params?: any, headers?: any): Promise<ResponseT> {
		let customHeaders = await BaseServiceAny.getCustomHeaders();
		customHeaders = {...customHeaders, ...headers};
		return this.client.get(url, { customHeaders, params });
	}

	protected async post<ResponseT>(url: string, data?: any, options?: RequestOptions): Promise<ResponseT> {
		const customHeaders = options?.headers ? options.headers : await BaseServiceAny.getCustomHeaders();
		return this.client.post(url, { customHeaders, data });
	}

	protected async put<ResponseT>(url: string, data?: any): Promise<ResponseT> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		return this.client.put(url, { customHeaders, data });
	}

	protected async del<ResponseT>(url: string): Promise<ResponseT> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		return this.client.del(url, { customHeaders });
	}
}
