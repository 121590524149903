
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HintMsg } from '@/utils/types';

@Component
export default class ClickableLabel extends Vue {
	@Prop(Object) public readonly hintMsg!: HintMsg | null;
	@Prop({ type: String, default: 'start' }) public readonly location!: 'start' | 'end';
	@Prop(Boolean) public readonly value!: boolean;

	// Menu Data
	public get onOpenMenu(): boolean {
		return this.value;
	}

	public set onOpenMenu(onOpenMenu: boolean) {
		this.$emit('input', onOpenMenu);
	}

	public get hintMsgClass(): string {
		if (!this.hintMsg || !this.hintMsg.color) {
			return '';
		}

		switch (this.hintMsg.color) {
			case 'error':
				return `hintMsg-${this.location} hintMsgError`;
			case 'warning':
				return `hintMsg-${this.location} hintMsgWarning`;
			case 'success':
				return `hintMsg-${this.location} hintMsgSuccess`;
			case 'info':
			default:
				return `hintMsg-${this.location} hintMsgInfo`;
		}
	}

	/**
	 * Reacts to changes when there is a click inside a menu.
	 * In this case, it should close the menu since an action was already executed.
	 */
	public innerClick() {
		this.$emit('inner-click');
		this.onOpenMenu = false;
	}
}
