import Vue, { VueConstructor } from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import ApplicationsPage from '@/pages/ApplicationsPage.vue';
import ChangeEmailPage from '@/pages/ChangeEmailPage.vue';
import ProjectsPage from '@/pages/ProjectsPage.vue';
import ProjectsStatusPage from '@/pages/ProjectsStatusesPage.vue';
import ProjectInfoPage from '@/pages/ProjectInfoPage.vue';
import WorkspaceInfoPage from '@/pages/WorkspaceInfoPage.vue';
import SelectWorkspacePage from '@/pages/SelectWorkspacePage.vue';
import SettingsPage from '@/pages/SettingsPage.vue';
import SignupTask from '@/components/Tasks/SignupTask.vue';
import ComplianceCheckTask from '@/components/Tasks/ComplianceCheckTask.vue';
import PendingEmailConfirmationTask from '@/components/Tasks/PendingEmailConfirmationTask.vue';
import NotFoundTask from '@/components/Tasks/NotFoundTask.vue';
import LoginFailedTask from '@/components/Tasks/LoginFailedTask.vue';
import InviteResult from '@/pages/InviteResultPage.vue';
import LegalNoticesPage from '@/pages/LegalNoticesPage.vue';
import WorkspaceDashboardPage from '@/pages/WorkspaceDashboardPage.vue';
import MigrationDashboardPage from '@/pages/MigrationDashboardPage.vue';
import MigrationStatsPage from '@/pages/MigrationStatsPage.vue';
import MigrationPage from '@/pages/MigrationPage.vue';
import { authGuard } from '@faroconnect/auth0-frontend';
import { BASE_URL } from '@/config';
import { RouteName } from '@/router/routes';

Vue.use(VueRouter);

export interface RouteConfigBase extends Omit<RouteConfig, 'components' | 'props'> {
	name: RouteName;
	component: VueConstructor<Vue>,
}

export interface RouteConfigRedirect extends Omit<RouteConfigBase, 'name' | 'component'> {
	redirect: string;
}

const userAgent = navigator.userAgent.toLowerCase();
const isSearchEngineUserAgent = userAgent.includes('googlebot') || userAgent.includes('bingbot') || userAgent.includes('slurp') ||
		userAgent.includes('duckduckbot') || userAgent.includes('baiduspider') || userAgent.includes('yandexbot');

const routes: Array<RouteConfigBase | RouteConfigRedirect> = [
	{
		path: '/',
		name: 'HomePage',
		component: HomePage,
		beforeEnter: isSearchEngineUserAgent ? undefined : authGuard,
	},
	{
		// The WebShare page was removed, so it cannot be embedded any more.
		// Keeping this URL as a redirect, in case that someone has bookmarked it.
		path: '/workspacedashboard/:workspace/webshare',
		name: 'WebshareDashboardPage',
		redirect: '/workspacedashboard/:workspace',
		beforeEnter: authGuard,
	},
	{
		path: '/workspacedashboard/:workspace',
		name: 'WorkspaceDashboardPage',
		component: WorkspaceDashboardPage,
		beforeEnter: authGuard,
	},
	{
		path: '/migrationdashboard',
		name: 'MigrationDashboardPage',
		component: MigrationDashboardPage,
		beforeEnter: authGuard,
	},
	{
		path: '/migrationstats',
		name: 'MigrationStatsPage',
		component: MigrationStatsPage,
		beforeEnter: authGuard,
	},
	{
		path: '/migration/:workspace/:migration',
		name: 'MigrationPage',
		component: MigrationPage,
		beforeEnter: authGuard,
	},
	{
		path: '/applications',
		name: 'ApplicationsPage',
		component: ApplicationsPage,
		beforeEnter: authGuard,
	},
	{
		path: '/projects/:workspace',
		name: 'ProjectsPage',
		component: ProjectsPage,
		beforeEnter: authGuard,
	},
	{
		// Redirect the old /projects route to the HomePage which gets the active workspace UUID and then redirects
		// to the ProjectsPage.
		path: '/projects',
		redirect: '/',
	},
	{
		path: '/projects/:workspace/status',
		name: 'ProjectsStatusPage',
		component: ProjectsStatusPage,
		beforeEnter: authGuard,
	},
	{
		// The project info page needs the workspace UUID so that deep links to projects that aren't in the currently
		// active workspace work.
		path: '/projects/:workspace/:project/info',
		name: 'ProjectInfoPage',
		component: ProjectInfoPage,
		beforeEnter: authGuard,
	},
	{
		path: '/workspaces/:includeActive?',
		name: 'SelectWorkspacePage',
		component: SelectWorkspacePage,
		beforeEnter: authGuard,
	},
	{
		path: '/workspaceinfo/:workspace',
		name: 'WorkspaceInfoPage',
		component: WorkspaceInfoPage,
		beforeEnter: authGuard,
	},
	{
		// This page should be accessible just by link, without authentication.
		path: '/workspaces/:workspace/invite/:user/:token',
		name: 'InviteResultPage',
		component: InviteResult,
	},
	{
		path: '/change-email/:token',
		name: 'ChangeEmailPage',
		component: ChangeEmailPage,
	},
	{
		path: '/settings',
		name: 'SettingsPage',
		component: SettingsPage,
		beforeEnter: authGuard,
	},
	{
		path: '/signup',
		name: 'SignupTask',
		component: SignupTask,
		// We do not need authGuard because the signup task uses a token in the url to authenticate the request.
	},
	{
		path: '/pending-confirmation',
		name: 'PendingEmailConfirmationPage',
		component: PendingEmailConfirmationTask,
	},
	{
		path: '/pending-validation',
		name: 'PendingValidationPage',
		component: ComplianceCheckTask,
	},
	{
		path: '/failed-validation',
		name: 'FailedValidationPage',
		component: ComplianceCheckTask,
	},
	{
		path: '/login-failed',
		name: 'LoginFailedPage',
		component: LoginFailedTask,
	},
	{
		path: '/legal',
		name: 'LegalNoticesPage',
		component: LegalNoticesPage,
	},
	{
		path: '/legal/terms-of-service',
		name: 'TermsOfServicePage',
		component: LegalNoticesPage,
	},
	{
		path: '/legal/privacy-policy',
		name: 'PrivacyPolicyPage',
		component: LegalNoticesPage,
	},
	{
		path: '/legal/data-processing-agreement',
		name: 'DataProcessingAgreementPage',
		component: LegalNoticesPage,
	},
	{
		path: '/legal/cookie-policy',
		name: 'CookiePolicyPage',
		component: LegalNoticesPage,
	},
	{
		path: '/not-found',
		name: 'NotFoundPage',
		component: NotFoundTask,
	},
	{
		path: '*',
		redirect: '/not-found',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: BASE_URL,
	routes,
	scrollBehavior: (to) => {
		// Auto-scroll behavior is required only for the legal documents left nav drawer links.
		// Ignore url changes that include the param "legalDocsSavePosition". Those changes are triggered
		// by the user scrolling through the legal doc and they should not trigger auto-scroll.
		const enableAutoScroll: boolean = !!to.hash && to.path.startsWith('/legal/') && to.params.legalDocsSavePosition !== 'true';
		if (enableAutoScroll) {
			return {
				selector: to.hash,
				behavior: 'smooth',
				offset: {
					x: 0,
					// AppBar height (60px) + gap (20px). This makes subtitle text fully visible.
					y: 80,
				},
			};
		}
	},
});

router.beforeEach((to, from, next) => {
	if (to.query.sso_connection) {
		// Remove the query parameter 'sso_connection'
		delete to.query.sso_connection;
		next({ path: to.path, query: to.query });
	} else {
		next();
	}
});

export default router;
