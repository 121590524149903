
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class LegalNoticesPage extends Vue {
	/**
	 * HTML content of the Terms of Service section.
	 */
	public termsOfService: string = '';
	/**
	 * HTML content of the Privacy Policy section.
	 */
	public privacyPolicy: string = '';
	/**
	 * HTML content of the Cookie Policy section.
	 */
	public cookiePolicy: string = '';
	/**
	 * HTML content of the Data Processing Agreement section.
	 */
	public dataProcessingAgreement: string = '';
	/**
	 * Flag if the Terms of Service section should be expanded on page load.
	 */
	public showToS: boolean = false;
	/**
	 * Flag if the Privacy Policy section should be expanded on page load.
	 */
	public showPP: boolean = false;
	/**
	 * Flag if the Cookie Policy section should be expanded on page load.
	 */
	public showCP: boolean = false;
	/**
	 * Flag if the Data Processing Agreement section should be expanded on page load.
	 */
	public showDPA: boolean = false;

	public async created() {
		this.termsOfService = (require('html-loader!@/assets/legal-docs/TermsOfService.v3.html')).default;
		this.privacyPolicy = (require('html-loader!@/assets/legal-docs/PrivacyPolicy.v1.html')).default;
		this.cookiePolicy = (require('html-loader!@/assets/legal-docs/CookiePolicy.v1.html')).default;
		this.dataProcessingAgreement = (require('html-loader!@/assets/legal-docs/DataProcessingAgreement.v3.html')).default;

		// If the URL is for one of the sub pages, open the corresponding section.
		const route = this.$route.name;
		if (route === 'TermsOfServicePage') {
			this.showToS = true;
		} else if (route === 'PrivacyPolicyPage') {
			this.showPP = true;
		} else if (route === 'CookiePolicyPage') {
			this.showCP = true;
		} else if (route === 'DataProcessingAgreementPage') {
			this.showDPA = true;
		}
	}
}
