
import { config } from '@/config';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { WkDashboardBackMsg, WkDashboardMsg } from '@/definitions-frontend/iframe';
import { AuthzType } from '@faroconnect/authz-client';
import { $assert } from '@faroconnect/utils';

@Component
export default class WorkspaceDashboardPage extends Vue {
	public readonly workspaceDashboardUI: string = config.workspaceDashboardUI;

	public get workspaceUuid(): string {
		return this.$route.params?.workspace ?? '';
	}

	public get workspaceDashboardUIWithWorkspace() {
		let url = this.workspaceDashboardUI;
		if (!url.endsWith('/')) {
			url += '/';
		}
		url += this.workspaceUuid;
		return url;
	}

	public get auth0Token(): string | null {
		return this.$tsStore.users.auth0Token;
	}

	public get iFrameWidth(): string {
		const offset = this.$tsStore.pages.leftNavigationDrawerWidth;
		return `calc(100vw - ${offset}px)`;
	}

	public get iFrameHeight(): string {
		const offset = this.$tsStore.pages.appbarHeightCurrent;
		return `calc(100vh - ${offset}px)`;
	}

	public get workspacePermissions(): AuthzType.WorkspacePermissionName[] {
		return this.$tsStore.users.workspacePermissions[this.workspaceUuid] ?? [];
	}

	public get projectPermissions(): { [key: string ]: AuthzType.ProjectPermissionName[] | undefined } {
		return this.$tsStore.users.projectPermissions[this.workspaceUuid] ?? {};
	}

	public postMessage(data: WkDashboardMsg) {
		const workspaceDashboardIFrame = document.getElementById('workspaceDashboardIFrame') as HTMLIFrameElement;
		const contentWindow = workspaceDashboardIFrame?.contentWindow;
		// On localhost, LP and the IFrame run on different ports, so the IFrame's origin is not accessible and must be specified as '*'.
		// When deployed, we can use the expected origin, since it's the same host as LP.
		const targetOrigin = this.workspaceDashboardUI.startsWith('http://localhost') ? '*' : this.workspaceDashboardUI;
		contentWindow?.postMessage(data, targetOrigin);
	}

	public onError() {
		this.$faroLoading.stop();
	}

	public async postAuthMessage(newToken?: string | undefined | null) {
		if (!newToken) {
			newToken = await this.$tsStore.users.getTokenSilently();
		}

		this.postMessage({
			name: 'auth',
			data: {
				token: newToken ?? null,
				workpacePermissions: this.workspacePermissions,
				projectPermissions: this.projectPermissions,
				user: this.$tsStore.users.user,
			},
		});
	}

	public async onLoaded() {
		// This one is needed redundantly to created() to make hot reloading work correctly on localhost.
		// Avoid double scrollbar on <html> element, which is redundant to scrollbar in <iframe>.
		document.documentElement.style.overflowY = 'hidden';

		this.$faroLoading.stop();

		this.postMessage({ name: 'init' });
		await this.postAuthMessage();
	}

	public created() {
		// Avoid double scrollbar on <html> element, which is redundant to scrollbar in <iframe>.
		document.documentElement.style.overflowY = 'hidden';

		this.$faroLoading.start();
		window.onmessage = async (e: MessageEvent<WkDashboardBackMsg>) => {
			const data: WkDashboardBackMsg = e.data;
			// The WebPack dev server sends messages with e.g. e.data === 'webpackHotUpdatebb82845234754671' or e.data.type === 'webpackProgress'.
			// We don't want to assert on such messages.
			$assert.Assert(!data.name || data.name === 'get-token', 'Unexpected message received: ' + data.name + ' | ' + JSON.stringify(data));

			switch (data.name) {
				// This message is only sent when the Dashboard detects that its token has expired.
				case 'get-token': {
					await this.postAuthMessage();
					break;
				}
			}
		};
	}

	public destroyed() {
		// Reset scrollbar on <html> element for other pages.
		// Keep in sync with global.less - if 'overlay' is removed there, we can use '' here.
		document.documentElement.style.overflowY = 'overlay';
	}

	@Watch('auth0Token')
	public async onAuth0TokenChanged(newAuth0Token: string | null | undefined) {
		await this.postAuthMessage(newAuth0Token);
	}

	@Watch('workspacePermissions')
	public async onWorkspacePermissions() {
		await this.postAuthMessage();
	}

	@Watch('projectPermissions')
	public async onProjectPermissions() {
		await this.postAuthMessage();
	}
}
