
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Project } from '@/classes/authz/Project';
import { ProjectBaseModule } from '@/store/modules/authz/ProjectBaseModule';
import { ProjectStatusModule } from '@/store/modules/projz/ProjectStatusModule';

@Component
export default class FilterProjectStatus extends Vue {
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: ProjectBaseModule<Project> | ProjectStatusModule;

	public get showFilterProjectStatus() {
		return this.filterByProjectStatusOptions.length > 0;
	}

	public get filterByProjectStatusOptions() {
		return this.store.filterByProjectStatusOptions;
	}

	public get filterByAllProjectStatuses() {
		return this.store.filterByAllProjectStatuses;
	}

	public set filterByAllProjectStatuses(filterByAllProjectStatuses: boolean) {
		if (!filterByAllProjectStatuses) {
			this.store.setFilterByProjectStatusIds([]);
		}
		this.store.setFilterByAllProjectStatuses(filterByAllProjectStatuses);
	}

	public get filterByProjectStatusIds() {
		if (this.filterByAllProjectStatuses) {
			return this.store.projectStatusIdList;
		}
		return this.store.filterByProjectStatusIds;
	}

	public set filterByProjectStatusIds(filterByProjectStatusIds: string[]) {
		if (filterByProjectStatusIds.length < this.store.projectStatusIdList.length) {
			this.filterByAllProjectStatuses = false;
		}
		if (filterByProjectStatusIds.length === this.filterByProjectStatusOptions.length) {
			this.filterByAllProjectStatuses = true;
		}
		this.store.setFilterByProjectStatusIds(filterByProjectStatusIds);
	}
}
