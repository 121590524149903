
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ImageSize } from '@/utils/types';
import { HintMsg } from '@/utils/types';
import ClickableLabel from '@/components/Embeddable/ClickableLabel.vue';

@Component({
	components: {
		ClickableLabel,
	},
})
export default class MeshBackgroundImage extends Vue {
	@Prop(Boolean) public readonly listView!: boolean;
	@Prop(String) public readonly src!: string;
	@Prop(String) public readonly imageSize!: ImageSize;
	@Prop(String) public readonly defaultSrc!: string;
	// Flag to hides the mesh background if it succeeds to load the original image.
	@Prop(Boolean) public readonly hideMeshOnSuccessLoad!: boolean;
	@Prop(Object) public readonly hintMsg!: HintMsg | null;
	@Prop(Boolean) public readonly value!: boolean;

	public internalSrc: string = this.src;
	public errorLoading: boolean = false;

	public onError() {
		this.internalSrc = this.defaultSrc;
		this.errorLoading = true;
	}

	// Menu Data
	public get onOpenMenu(): boolean {
		return this.value;
	}

	public set onOpenMenu(onOpenMenu: boolean) {
		this.$emit('input', onOpenMenu);
	}
}
