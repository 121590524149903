
import Vue from 'vue';
import Component from 'vue-class-component';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';
import UserSettings from '@/components/SettingsPage/UserSettings.vue';
import EnterpriseSsoConfig from '@/components/SettingsPage/EnterpriseSsoConfig.vue';
import { HttpClient } from '@faroconnect/clientbase';
import { config } from '@/config';
import { BaseServiceAny } from '@/store/services/BaseServiceAny';

@Component({
	components: {
		TopPageBase,
		UserSettings,
		EnterpriseSsoConfig,
	},
})
export default class SettingsPage extends Vue {
	public error = false;
	public loading = false;
	public canConfigureSso: boolean | null = null; // null means "still unknown"

	/**
	 * Determines (using the SSO Config API) if the "Enterprise SSO Connections" tab should be visible.
	 *
	 * Checks if for at least one workspace:
	 *   - the current user has the "manage-users" permission, and
	 *   - the workspace has the "sso" feature.
	 * In this case, the user can access the "Enterprise SSO Connections" tab.
	 */
	public async checkCanConfigureSso(): Promise<void> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		const client = new HttpClient();
		const url = `${config.ssoConfigApi}/sso-config/v1/connections/user-access`;
		try {
			await client.get(url, { customHeaders });
			// If the API returns 200, the user has access.
			this.canConfigureSso = true;
		} catch (e) {
			// If the API returns an error (403), the user doesn't have access.
			this.canConfigureSso = false;
		}
	}

	public async mounted() {
		await this.checkCanConfigureSso();
		this.$tsStore.pages.setFinishedPageLoading(true);
		if (this.$tsStore.pages.finishedMainLoading) {
			this.$faroLoading.stop();
		}
	}
}
