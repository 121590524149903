import { AxiosError, AxiosResponse } from 'axios';
import Vue from 'vue';
import { VueAuth } from '@faroconnect/auth0-frontend';
import { faroLocalization } from '@faroconnect/baseui';
import {
	$assert,
	AccessDeniedError,
	AuthenticationError,
	BadRequestError,
	ConflictError,
	InternalError,
	NotFoundError,
	PaymentRequiredError,
} from '@faroconnect/utils';
import { BASE_URL, config } from '@/config';

type errorName =
	'BadRequestError' |
	'AuthenticationError' |
	'PaymentRequiredError' |
	'AccessDeniedError' |
	'NotFoundError' |
	'ConflictError' |
	'InternalError' |
	'ConnectionError';

/**
 * Stores whether the checks to the timeout session should be enabled.
 * For the first minute that the page has been loaded makes no sense to check for it
 * since the token should not have expired.
 */
let sessionTimeoutCheckEnabled = false;

setTimeout(() => {
	sessionTimeoutCheckEnabled = true;
}, 60 * 1000);

function translate(stringId: string): string {
	return faroLocalization.i18n.tc(stringId);
}

/**
 * Gets the message to show to the user based on an error.
 * @param error The error to be evaluated.
 * @param overrideTranslation Optional Object with some error translations that can be used to override the error message.
 * 							  E.g. if you provide { BadRequestError: 'foo' } and the name of the provided error is BadRequestError,
 * 							  this function will return 'foo'. If you omit this parameter, it would return the translation of "UI_HTTP_400"
 * @returns A translated string based on the provided error.
 */
export function getErrorMessage(error: any, overrideTranslation?: Partial<{ [key in errorName]: string | undefined }>) {
	if (!error) {
		return translate('UI_HTTP_UNKNOWN');
	} else if (error instanceof BadRequestError || error.name === 'BadRequestError' || error.status === 400 || error.status === 405) {
		return overrideTranslation?.BadRequestError ?? translate('UI_HTTP_400');
	} else if (error instanceof AuthenticationError || error.name === 'AuthenticationError' || error.status === 401) {
		return overrideTranslation?.AuthenticationError ?? translate('UI_HTTP_401');
	} else if (error instanceof PaymentRequiredError || error.name === 'PaymentRequiredError' || error.status === 402) {
		return overrideTranslation?.PaymentRequiredError ?? translate('UI_HTTP_402');
	} else if (error instanceof AccessDeniedError || error.name === 'AccessDeniedError'  || error.status === 403) {
		return overrideTranslation?.AccessDeniedError ?? translate('UI_HTTP_403');
	} else if (error instanceof NotFoundError || error.name === 'NotFoundError' || error.status === 404) {
		return overrideTranslation?.NotFoundError ?? translate('UI_HTTP_404');
	} else if (error instanceof ConflictError || error.name === 'ConflictError' || error.status === 409) {
		return overrideTranslation?.ConflictError ?? translate('UI_HTTP_409');
	} else if (error instanceof InternalError || error.name === 'InternalError' || error.status === 500) {
		return overrideTranslation?.InternalError ?? translate('UI_HTTP_500');
	} else if (error.name === 'ConnectionError' || error.name === 'ServiceUnavailableError' ||
		error.status === 0 || error.status === 502 || error.status === 503 || error.status === 504
	) {
		let text = overrideTranslation?.ConnectionError ?? translate('UI_HTTP_503');
		if (config.env === 'local') {
			text += '. Please make sure that you are running the backend.';
		}
		return text;
	} else {
		return translate('UI_HTTP_UNKNOWN');
	}
}

/**
 * Interceptor for axios error responses. See https://axios-http.com/docs/interceptors
 */
export function handleAuthenticationError(error: AxiosError | AxiosResponse) {
	// Based on HttpClient.setStatus.
	const axiosError: AxiosError = error as AxiosError;
	const axiosResponse: AxiosResponse = error as AxiosResponse;
	const status = axiosResponse.status || axiosError.response?.status;
	const data = (axiosError.response?.data || axiosResponse.data) as any;
	const $tsStore: $tsStore = Vue.prototype.$tsStore;

	if (status === 404 && data?.objectType === 'User' && data?.object?.Email &&
		(error.config?.url?.includes('/authz/v1/user') || error.config?.url?.includes('/authz/v1/workspace'))
	) {
		// After email change: We might have a token with the old email address.
		// This code might need adaptions if the UI ever requests other users (except current user) by email.
		return $tsStore.users.getTokenSilently({ ignoreCache: true }).then((token) => {
			// If we could still get a new token from Auth0, then the problem might be something else.
			// Hopefully, future API calls will succeed with the new token.
			// This also avoids a redirect loop if the current Auth0 DEV user doesn't exist in AuthZ localhost.
			return token ? Promise.reject(error) : redirectAfterAuthenticationError();
		}, () => {
			return redirectAfterAuthenticationError();
		});
	} else if (sessionTimeoutCheckEnabled && status === 401) {
		// The authentication error usually already happens when getTokenSilently is called.
		const msg1: string | undefined = axiosError.message;
		const msg2: string | undefined = (axiosError.response as AxiosResponse)?.data?.message;
		const msg3: string | undefined = axiosResponse?.data?.message;
		const isJwtExpired = [msg1, msg2, msg3].some((msg) => msg?.includes('jwt expired'));
		// Since some applications could throw a 401 without necessarily meaning that the token expired,
		// we need a stronger check that the error was thrown because the token expired, otherwise
		// it could lead to a redirection loop.
		if (isJwtExpired) {
			// This code seems unreachable, because our AuthenticationError seems to never include 'jwt expired'.
			return redirectAfterAuthenticationError();
		}
	}
	return Promise.reject(error);
}

/**
 * If the user has been logged out due to inactivity, show the Auth0 log-in screen and then redirect
 * him to the requested page.
 * @author OK
 * @param forceLogin Force a login, even if the Auth0 session is still valid.
 * @returns A never-resolving promise so that neither response nor error handling is triggered.
 */
export function redirectAfterAuthenticationError(forceLogin = false): Promise<any> {
	Vue.prototype.$faroLoading.start();
	// Cut off the leading "/home" (5 chars) from a path like "/home/workspaces".
	$assert.Assert(BASE_URL.length === 5);
	const path = window.location.pathname.substring(BASE_URL.length);
	const prompt = forceLogin ? 'login' : undefined;

	void((Vue.prototype.$auth as VueAuth).loginWithRedirectAndPath(path, undefined, undefined, { prompt }));
	return new Promise(() => {});
}
