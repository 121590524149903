import { BadRequestError, UuidUtils, InternationalizationUtils, $assert } from '@faroconnect/utils';
import * as UserUtils from '@/utils/userutils';
import * as UserValidation from '@/utils/uservalidation';

export function validateString<T extends string>(str: T | any, attrName?: string): T {
	if (typeof str !== 'string') {
		throw new BadRequestError('string', str, attrName);
	}
	return str as T;
}

/**
 * Casts a value to be a valid string and returns an empty string if undefined was provided.
 * The difference with validateString is that an error won't be thrown, useful in situations
 * when we should just log that the variable is not a string but we should not interrupt the
 * page, since it might partually work.
 * @param str The string to be casted.
 * @param attrName The name of the attribute to be printed in the console.
 * @returns The casted value as a string.
 */
export function castString<T extends string>(str: T | undefined, attrName: string): T {
	$assert.String(str as string, `Expected the property ${attrName} to be a string`);
	return str ?? '' as T;
}

export function validateUuid<T extends string>(uuid: T | any, attrName?: string): T {
	const str = validateString(uuid);
	if (!UuidUtils.isRfcUuid(str)) {
		throw new BadRequestError('UUID', str, attrName);
	}
	return str as T;
}


export function validateStringIfDefined<T extends string>(str: T | any, attrName?: string): T | undefined {
	if (str === undefined) {
		return undefined;
	}
	return validateString(str, attrName) as T;
}

/**
 * Validates that the provided value belongs to an enum.
 * @param value The value to be evaluated.
 * @param Enum The enum that the value should belong to.
 * @param attrName Optional attribute name to include in the bad request error.
 * @returns If it passes the validation, returns the provided value.
 */
export function validateEnum<ValueT extends string, EnumT extends { [key in ValueT]: key }>(
	value: ValueT | undefined,
	Enum: EnumT,
	attrName?: string,
): ValueT {
	const val = value as ValueT;
	validateString(val, attrName);
	if (!Object.values(Enum).includes(val)) {
		throw new BadRequestError(Object.values(Enum).join(', '), val, attrName);
	}
	return val;
}

/**
 * Validates the provided phone number according to the provided phone country code.
 * @param value Phone number to be evaluated.
 * @param phoneCountryCode Phone country code.
 * @returns The provided value if it passes the validation.
 */
export function validatePhoneNumber(value: string, phoneCountryCode: InternationalizationUtils.CountryCode): string {
	const preSanitizedPhoneNumber = UserUtils.removeUnnecessarySpaces(value);
	const phoneNumberRuleResult = UserValidation.phoneNumberRule(preSanitizedPhoneNumber, phoneCountryCode);
	if (phoneNumberRuleResult.result !== true) {
		throw new BadRequestError('Phone', value, 'PhoneNumber');
	}
	return phoneNumberRuleResult.phoneNumber;
}

export function validateWorkspaceName(name: string | undefined, isFaroUser: boolean): boolean {
	if (!isFaroUser && name && name.includes('faro')) {
		return false;
	} else {
		return !!name && /^[a-z0-9][a-z0-9-]+[a-z0-9]$/.test(name) && (name.length >= 3 && name.length <= 32);
	}
}

export function validateWorkspaceDescription(description?: string): boolean {
	return (description ?? '').length <= 180;
}

/**
 * Returns the string value of a query parameter.
 * In case the string is null or undefined, an empty string will be returned.
 */
export function queryToString<T extends string>(val: string | null | Array<(string | null)>): T {
	if (typeof val === 'string') {
		// null could be stringified by the browser.
		if (val === 'null' || val === 'undefined') {
			return '' as T;
		}
		return val as T;
	}
	return '' as T;
}

/**
 * Returns the string value of a query parameter.
 * In case the string is null or undefined, an empty string will be returned.
 */
export function queryToBool(val: string | null | boolean | Array<(string | null)>): boolean {
	if (val === 'true' || val === true) {
		return true;
	}
	return false;
}
