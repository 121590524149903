
import Component from 'vue-class-component';
import ItemsViewBaseMixin from '@/mixins/ItemsViewBaseMixin';
import { LpEntity } from '@/classes';
import { Prop } from 'vue-property-decorator';
import { FaroTab } from '@/utils/types';
import FaroTabs from '@/components/Embeddable/FaroTabs.vue';

@Component({
	components: {
		FaroTabs,
	},
})
export default class FilterMenuBase<EntityT extends LpEntity> extends ItemsViewBaseMixin<EntityT> {
	@Prop(String) public readonly value!: string;
	@Prop(Boolean) public readonly show!: boolean;
	@Prop(Array) public readonly tabs!: FaroTab[];
	@Prop(String) public readonly height!: string;

	public get menuVisible(): boolean {
		return this.show;
	}

	public set menuVisible(show: boolean) {
		this.$emit('show', show);
	}

	public get openedTab(): string {
		return this.value;
	}

	public get tabValues(): string[] {
		return this.tabs.map((tab) => tab.value);
	}

	public get tabModel(): string {
		return this.value;
	}

	public set tabModel(value: string) {
		this.$emit('input', value);
	}

	public onClear() {
		this.$emit('clear');
	}

	public onApply() {
		this.$emit('apply');
	}
}
