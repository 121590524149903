
import Vue from 'vue';
import Component from 'vue-class-component';
import { AuthzBaseInterface, AuthzConstant } from '@faroconnect/authz-client';
import { $assert, UuidUtils } from '@faroconnect/utils';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';
import { Project } from '@/classes/authz/Project';
import { Workspace } from '@/classes/authz/Workspace';
import { VBreadcrumbItem } from '@/definitions-frontend/globals';
import { ProjectPageUtility } from '@/shared/PageUtilities';
import { ItemButtons } from '@/utils/types';
import { User } from '@/classes/authz/User';
import { config } from '@/config';
import { UtilsProjectService } from '@/store/services/UtilsProjectService';

@Component({
	components: {
		TopPageBase,
	},
})
export default class ProjectInfoPage extends Vue {
	// Validation methods
	protected readonly validateName = (v: string): boolean | string => {
		const sv = v.trim();
		if (sv === '') {
			return this.$tc('LP_PROJECT_NAME_REQUIRED');
		} else if (sv.length >= 1 && sv.length <= 60) {
			return true;
		} else {
			return this.$tc('LP_PROJECT_NAME_LENGTH');
		}
	};

	protected readonly validateDescription = (v: string|null|undefined): boolean | string => {
		return ((v + '').trim().length < 255) || this.$tc('LP_PROJECT_DESCRIPTION_LENGTH');
	};

	public error = false;
	public loading = false;
	public project : Project | null = null; //null means "still unknown".
	public projectInviter: AuthzBaseInterface.InviterI | null = null; // null means "still unknown".
	public projectInviterUser: User | null = null; // null means "still unknown".
	public projectInviterAuthZUrl: string | null = null;
	public isEditingProjectName: boolean = false;
	public isEditingProjectDescription: boolean = false;
	public currentProjectName: string = '';
	public currentProjectDescription: string = '';
	public supportedProjectTypes: string[] = ['WebShare'];
	public projectUtility : ProjectPageUtility | undefined;
	public itemButtons: ItemButtons<Project> = [];
	//Had to manually load the following fields to suprress vue consoile warnings
	public projectType:string = '';
	public projectIcon: string | null = null;
	public projectDefaultImgUrl: string = '';
	public isDemo: boolean = false;
	public projectCreator: User | null = null;
	public projectCreatorAuthZUrl: string | null = null;
	/**
	 * The project image URL, set in setProjectImageUrl.
	 */
	public imgUrl: string = '';
	/**
	 * The URL of an image may stay the same when the image gets changed, so we need to trigger the reload
	 * of the UI element another way.
	 */
	public reloadRandom: string = '';

	// ---------------------------------------------------------------------------

	public get breadcrumbItems(): VBreadcrumbItem[] {
		const workspaceUuid = this.activeWorkspace?.UUID || '';

		return [
			{
				text: this.$tc('UI_PROJECTS'),
				to: { name: 'ProjectsPage', params: { workspace: workspaceUuid } },
			},
			{
				text: this.project?.Name ?? '...', // Use placeholder while page is loading.
				disabled: true,
			},
		];
	}

	public get user(): User | null {
		return this.$tsStore.users.user ?? null;
	}

	public get hasEditProjectPermissions() {
		// Currently, ProjectModule.updateSingle() only allows to update WebShare projects.
		if (!this.project || this.project.Type !== AuthzConstant.PROJECT_TYPE_WEBSHARE) {
			return false;
		}
		return this.$tsStore.users.projectAccessControl.editProject(this.project.Workspace, this.project.UUID).hasPermission;
	}

	/**
	 * Returns true if the project has the necessary attributes set to be able to change the project image,
	 * and the user has the required project permission.
	 * @author OK
	 */
	public get canEditImage(): boolean {
		if (!this.project) {
			return false;
		}
		// We can't call the WebShare route without these attributes:
		if (!this.project.DomainUrl) {
			return false;
		}
		// Not set at least for some old projects on the DEV server.
		if (this.project.Type === 'WebShare' && !this.project.UrlId) {
			return false;
		}
		return this.$tsStore.users.projectAccessControl.editProject(this.project.Workspace, this.project.UUID).hasPermission ||
			this.$tsStore.users.projectAccessControl.editData(this.project.Workspace, this.project.UUID).hasPermission ||
			this.$tsStore.users.projectAccessControl.syncProject(this.project.Workspace, this.project.UUID).hasPermission;
	}

	public get activeWorkspace(): Workspace | null {
		return this.$tsStore.workspaces.activeWorkspace;
	}

	protected formatDate(dateStr?: string | null): string {
		if (!dateStr) {
			return '';
		}

		const userLang = this.user?.Language || 'en_US';
		return (new Date(dateStr)).toLocaleDateString(
			userLang.replace('_', '-'),
			{
				year: 'numeric',
				month: 'short',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				second: undefined,
			},
		);
	}

	// ---------------------------------------------------------------------------

	public get creationDate() {
		return this.formatDate(this.project?.CreationDate);
	}

	public get updateDate() {
		return this.formatDate(this.project?.propertiesOrContentUpdateDate);
	}

	public async updateProjectName() {
		if (this.project) {
			const resultValidate = this.validateName(this.currentProjectName);

			if (resultValidate !== true) {
				// Set a UUID as traceId so that we know where the error was thrown since there's no request.
				this.$faroComponents.$emit('show-error', { error: { traceId: '0774ab79-453b-4089-881b-f9b6d2757dfe' },
					title: 'LP_INVALID_INPUT', message: resultValidate });
				return;
			}

			this.$tsStore.projects.updateSingle({project: this.project, data: {DisplayName: this.currentProjectName.trim()}}).
				then(
					() => {},
					(reason) => {
						// updateSingle will already emit the errors, so we dont need to do it here.
						this.currentProjectName = this.project!.Name;
					},
				);
			this.isEditingProjectName = false;
		}
	}

	public cancelProjectNameUpdate() {
		if (!this.project) {return;}
		this.isEditingProjectName = false;
		this.currentProjectName = this.project.Name;
	}

	public startProjectNameUpdate() {
		this.isEditingProjectName = true;
	}

	public async updateProjectDescription(name: string) {
		if (this.project) {
			const resultValidate = this.validateDescription(this.currentProjectDescription);

			if (resultValidate !== true) {
				// Set a UUID as traceId so that we know where the error was thrown since there's no request.
				this.$faroComponents.$emit('show-error', { error: { traceId: '68069490-00d8-4f24-8086-14339fdbcf8d' },
					title: 'LP_INVALID_INPUT', message: resultValidate });
				return;
			}

			this.$tsStore.projects.updateSingle({project: this.project, data: {Description: this.currentProjectDescription.trim()}}).
				then(
					() => {},
					(reason) => {
						// updateSingle will already emit the errors, so we dont need to do it here.
						this.currentProjectDescription = this.project!.Description;
					},
				);
		}
		this.isEditingProjectDescription = false;
	}

	public async cancelProjectDescriptionUpdate() {
		if (!this.project) {return;}
		this.isEditingProjectDescription = false;
		this.currentProjectDescription = this.project.Description;
	}

	public startProjectDescriptionUpdate() {
		this.isEditingProjectDescription = true;
	}

	/*
		Helper function for ProjectPageUtilityClass
	*/
	public async xOpenEntity(project: Project) {
		if (this.projectUtility) {this.projectUtility.openPageUtil(project);}
	}


	/*
		Get action buttons. Since this is a single entity page, I did not inherit the base entity page,
		but using the ProjectPageUtility would be cleaner than getting all icons manually.
	*/
	public setActionButtons() {
		if (!this.projectUtility || !this.project) {return null;}
		let buttons =  this.projectUtility.buttons();
		buttons.forEach( (btn) => {
			//Since this is a project info page, we are removing the Info Button.
			if (('caption' in btn) && btn.caption && btn.caption === this.$tc('UI_INFO')) {
				btn.hide = true;
			} else if (('getHidden' in btn) && btn.getHidden && this.project) {
				btn.hide = btn.getHidden(this.project);
			}
			if (('click' in btn) && this.project) {
				btn.click = btn.click.bind(btn, this.project);
			}
		});
		this.itemButtons = buttons.filter((btn) => !btn.hide);
	}

	public openProject() {
		if (!this.projectUtility || !this.project) {return;}
		this.projectUtility.openPageUtil(this.project);
	}

	/**
	 * Retrieves information about the project.
	 */
	public async getProjectInfo(): Promise<boolean> {
		this.project = this.$tsStore.projects.getProjectByUuid(this.$route.params.project);
		if (!this.project) {
			return false;
		}
		this.projectUtility = new ProjectPageUtility(this);
		this.currentProjectDescription = this.project.Description;
		this.currentProjectName = this.project.Name;
		this.projectType = this.project.Type;
		this.projectIcon = this.project.typeIcon;
		this.projectDefaultImgUrl = this.project.DefaultImgUrl;
		this.isDemo = this.project.IsDemo;
		if (this.project.Inviter?.Email && !User.isEmailOfDeletedUser(this.project.Inviter.Email)) {
			// This field needs to be loaded earlier, since changes to the project info will invalidate the Inviter field.
			this.projectInviter = this.project.Inviter;
		}
		try {
			await this.setProjectImageUrl();
		} catch (error: any) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_PROJECT_IMAGE' });
		}

		return true;
	}

	public async getWebshareDetails() {
		if (!this.project) {
			return;
		}
		await this.$tsStore.projects.getWebshareDetails(this.project);
	}

	/** Get user details of project creator. */
	public async getProjectCreator() {
		if (!this.project?.Creator || !this.$tsStore.users.workspacePermissions[this.project.Workspace]?.includes('view-users')) {
			return;
		}

		try {
			this.projectCreator = await this.$tsStore.users.getUser({
				workspaceUuid: this.project.Workspace,
				uuid: this.project.Creator,
			});
			if (this.$tsStore.users.workspaceAccesses[this.project.Workspace]?.openAuthz.hasPermission) {
				this.projectCreatorAuthZUrl = config.authzUI + `/${this.project.Workspace}/user/${this.projectCreator.UUID}`;
			}
		} catch (e) {
			// Unexpected, but not important, so we don't show the error in the UI.
			console.error('Error getting creator:', e);
			// adjust message if need to show 'error' to user
			// faroComponents.$emit('show-error', { message: 'LP_ERR_UPDATE_LAST_VISITED' });
		}
	}

	/** Get user details of project inviter. */
	public async getProjectInviter() {
		if (!this.projectInviter?.Uuid || !this.project?.Workspace ||
			!this.$tsStore.users.workspacePermissions[this.project.Workspace]?.includes('view-users')
		) {
			return;
		}

		try {
			this.projectInviterUser = await this.$tsStore.users.getUser({
				workspaceUuid: this.project.Workspace,
				uuid: this.projectInviter.Uuid,
			});

			if (this.$tsStore.users.workspaceAccesses[this.project.Workspace]?.openAuthz.hasPermission) {
				this.projectInviterAuthZUrl = config.authzUI + `/${this.project.Workspace}/user/${this.projectInviter.Uuid}`;
			}
		} catch (e: any) {
			// The inviter could e.g. already have left the workspace.
			console.error('Error getting inviter details:', e);
		}
	}

	/**
	 * Sets the project image URL to use for the lp-img element.
	 * @author OK
	 */
	protected async setProjectImageUrl(): Promise<void> {
		$assert.Object(this.project);
		if (!this.project) {
			return;
		}

		this.imgUrl = this.project.ImgUrl;
		this.reloadRandom = UuidUtils.generateUuid();
	}

	/**
	 * Handler for a click on the project image to exchange it with the selected image file in the file
	 * dialog.
	 * @author OK
	 * @param file The file provided by the browser's file dialog.
	 */
	public async onChangeImage(file: File) {
		if (!this.canEditImage) {
			return;
		}
		if (!file || !this.project) {
			$assert.Assert(false);
			return;
		}
		const project = this.project;

		if (!file.name || file.name.length === 0) {
			// Set a UUID as traceId so that we know where the error was thrown since there's no request.
			this.$faroComponents.$emit('show-error', { error: { traceId: 'a82e2639-c0d9-469b-a512-07fd1a65e463' },
				title: 'LP_INVALID_INPUT', message: 'LP_ERR_FILE' });
			return;
		}

		if (!(file.type === 'image/bmp' || file.type === 'image/gif' || file.type === 'image/jpeg' ||
			file.type === 'image/jpg' || file.type === 'image/png')) {
			// Set a UUID as traceId so that we know where the error was thrown since there's no request.
			this.$faroComponents.$emit('show-error', { error: { traceId: '907155ed-c044-40ac-af5a-1ba6392e2482' },
				title: 'LP_INVALID_INPUT', message: 'LP_ERR_FILE_TYPE', value: 'BMP, GIF, JPG, PNG' });
			return;
		}

		if (!file.size || 10485760 < file.size) {
			// Set a UUID as traceId so that we know where the error was thrown since there's no request.
			this.$faroComponents.$emit('show-error', { error: { traceId: '96b90915-e99d-4d96-b6ca-292088718e2d' },
				title: 'LP_INVALID_INPUT', message: 'LP_ERR_FILE_SIZE', value: '10 ' + this.$tc('UI_MB') });
			return;
		}

		const reader = new FileReader();

		reader.onload = async (event) => {
			try {
				// Example of the data URI scheme contained in reader.result:
				// "data:image/png;base64,{{base64EncodedImage}}"
				// --> For the upload we need the last part with the base64 encoded image data.
				const dataArray = (reader.result as string).split(',');
				const data = dataArray[dataArray.length - 1];

				const utilsProjectService = new UtilsProjectService({});
				await utilsProjectService.updateProjectImage(project, file.type, data);
				await this.setProjectImageUrl();
			} catch (error: any) {
				this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_UPDATE_PROJECT_IMAGE' });
			}

			this.$faroLoading.stop();
		};

		this.$faroLoading.start();
		reader.readAsDataURL(file);
	}

	public async mounted() {
		this.$faroLoading.start();
		this.loading = true;
		let projectExists = await this.getProjectInfo();
		if (!this.$tsStore.workspaces.isDemoActive && !projectExists) {
			// It's possible that the project exists but it's currently not loaded in the store, so we getAll projects
			// again.
			await this.$tsStore.projects.getAll();
			projectExists = await this.getProjectInfo();
			if (!projectExists) {
				this.$router.push({name: 'NotFoundPage'});
			}
		}

		if (!this.isDemo) {
			await Promise.all([
				this.getWebshareDetails(),
				this.getProjectCreator(),
				this.getProjectInviter(),
			]);
			this.$faroComponents.$on('project-delete-success', (projectUUID: string) => {
				if (projectUUID === this.project!.UUID) {
					const workspaceUuid = this.activeWorkspace?.UUID || '';
					this.$router.push({ name: 'ProjectsPage', params: { workspace: workspaceUuid } });
				}
			});
		}
		this.setActionButtons();

		this.$tsStore.pages.setFinishedPageLoading(true);
		if (this.$tsStore.pages.finishedMainLoading) {
			this.$faroLoading.stop();
		}
		this.loading = false;
	}
}
