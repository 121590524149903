import { Application } from '@/classes/Application';
import { IApplication } from '@/definitions/interfaces';
import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { config, dynamicConfig } from '@/config';
import { isFeatureEnabledByAuth0Token } from '@/utils/permissions';
import { faroLocalization } from '@faroconnect/baseui';
import { castString } from '@/utils/validate';
import { ApplicationMobile } from '@/classes/ApplicationMobile';

/**
 * This service is in charge of CRUD operations to manipulate the Sphere applications.
 */
export class ApplicationService extends BaseService<Application> {
	/**
	 * The translation files are not yet parsed when the constructor is called, so this must be set in the
	 * getAll method.
	 */
	private mockedApplications?: Application[];

	public constructor(clientConfig: ClientOptions) {
		super({}, clientConfig);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<IApplication> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<IApplication[]> {
		if (this.mockedApplications) {
			return this.mockedApplications;
		}

		this.mockedApplications = [
			Application.forRequest({
				Name: 'FARO HoloBuilder™',
				CyId: 'faro-holobuilder',
				ImgUrl: require('@/assets/FARO_HoloBuilder.svg'),
				MeshBackground: true,
				Url: config.holobuilder,
			}),
			ApplicationMobile.forRequest({
				Name: 'FARO Stream',
				CyId: 'faro-stream',
				ImgUrl: require('@/assets/stream.svg'),
				Description: faroLocalization.i18n.tc('LP_FARO_STREAM_DESC'),
				Url: '',
				IOSUrl: castString(dynamicConfig?.url?.streamDownloadIOS, 'url.streamDownloadIOS'),
				IOSImgUrl: dynamicConfig.img.streamQrIOS,
				AndroidUrl: castString(dynamicConfig?.url?.streamDownloadPlayStore, 'url.streamDownloadPlayStore'),
				AndroidImgUrl: dynamicConfig.img.streamQrPlayStore,
				MeshBackground: true,
			}),
		];

		if (await isFeatureEnabledByAuth0Token('slabSmart')) {
			const app = ApplicationMobile.forRequest({
				Name: 'FARO Flatness Check',
				CyId: 'faro-slabsmart',
				ImgUrl: require('@/assets/CPT4895_Graphic_App_SlabSmart_CPT-icon.svg'),
				Description: faroLocalization.i18n.tc('LP_FARO_SLAB_SMART_DESC'),
				Url: '',
				IOSUrl: castString(dynamicConfig?.url?.slabSmartDownloadIOS, 'url.slabSmartDownloadIOS'),
				IOSImgUrl: dynamicConfig.img.slabSmartQr,
				MeshBackground: true,
			});
			this.mockedApplications.push(app);
		}

		return this.mockedApplications;
	}

	public async create<QueryT extends object>(body?: Application, query?: QueryT): Promise<IApplication> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(body?: Application, query?: QueryT): Promise<IApplication> {
		throw new Error('Function not yet implemented');
	}

	public async updateMulti<QueryT extends object>(body?: Application[], query?: QueryT): Promise<IApplication[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}
}
