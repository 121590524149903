
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { $assert, UuidUtils } from '@faroconnect/utils';
import { LpEntity } from '@/classes';
import ItemsViewBaseMixin from '@/mixins/ItemsViewBaseMixin';
import { ImageSize } from '@/utils/types';
import { Header } from '@/utils/listview';
import { Workspace } from '@/classes/authz/Workspace';
import { Project } from '@/classes/authz/Project';
import DemoWorkspaceOrProjectLabel from '@/components/Embeddable/DemoWorkspaceOrProjectLabel.vue';
import { ApplicationWebShare } from '@/classes/ApplicationWebShare';

@Component({
	components: {
		DemoWorkspaceOrProjectLabel,
	},
})
export default class PageListView<EntityT extends LpEntity> extends ItemsViewBaseMixin<EntityT> {
	// Headers in the table
	@Prop(Array) public readonly headers!: Array<Header<EntityT>>;
	// For more props see ItemsViewBaseMixin

	public openedMenuUuid: string | null = null;
	/**
	 * The URL of an image may stay the same when the image gets changed, so we need to trigger the reload
	 * of the UI element another way.
	 */
	public reloadRandoms: { [key: string]: string } = {};

	// ---------------------------------------------------------------------------

	public get imageSize(): ImageSize {
		return this.$vuetify.breakpoint.mdAndDown ? 'list-sm' : 'list-lg';
	}

	public get sortBySelected(): keyof EntityT | 'Actions' | 'data-table-expand' {
		return this.store.SortBy.value;
	}

	public set sortBySelected(sortBySelected: keyof EntityT | 'Actions' | 'data-table-expand') {
		const sortBy = this.sortItems.find((item) => item.value === sortBySelected);
		$assert.Object(sortBy, 'Could not find element to sort by, please make sure to add it to xSortItems');
		if (sortBy) {
			this.store.setSortBy(sortBy);
		}
	}

	public get sortDescSelected() {
		return this.store.SortDesc;
	}

	public set sortDescSelected(sortDescSelected: boolean) {
		this.store.setSortDesc(sortDescSelected);
	}

	// ---------------------------------------------------------------------------

	public setOpenedMenu(value: boolean, item: EntityT) {
		this.openedMenuUuid = value ? item.UUID : null;
	}

	public getEntityHref(item: EntityT): string | undefined {
		if (this.xIsEntityDisabled(item)) {
			return undefined;
		}
		let url = undefined;
		if (item instanceof Workspace && !item.isPendingUserInvitation) {
			const user = this.$tsStore.users.user ?? null;
			url = ApplicationWebShare.makeSsoUrl(item.webShareUrl as string, user);
		}
		if (item instanceof Project && item.FullUrl) {
			url = item.FullUrl;
		}
		return url;
	}

	public async onImageClick(item: EntityT) {
		if (item instanceof Workspace && item.isPendingUserInvitation) {
			this.setOpenedMenu(true, item);
		} else {
			this.xOnEntityClicked(item);
		}
	}

	public onEntityClicked(item: EntityT) {
		this.$emit('click-entity', item);
	}

	public customSort() {
		return this.entitiesOnPage ?? [];
	}

	public onClickHeader(header: Header<EntityT>) {
		if (header.sortable === false) {
			return;
		}
		if (this.sortBySelected === header.value) {
			this.sortDescSelected = !this.sortDescSelected;
		} else {
			this.sortDescSelected = false;
			this.sortBySelected = header.value;
		}
	}

	/**
	 * Reloads the image URL used by the lp-img element.
	 * @author OK
	 */
	public async reloadImageUrl(item: EntityT): Promise<void> {
		try {
			Vue.set(this.reloadRandoms, item.UUID, UuidUtils.generateUuid());
		} catch (error: any) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_PROJECT_IMAGE' });
		}
	}

	/**
	 * Event handler for the mounting of the LpImg components.
	 */
	public async onMountedLpImg(item: EntityT): Promise<void> {
		this.reloadImageUrl(item);
	}
}
