
import Vue from 'vue';
import Component from 'vue-class-component';
import { $assert, UuidUtils } from '@faroconnect/utils';
import { HttpError } from '@faroconnect/clientbase';
import { BASE_URL } from '@/config';
import { setLanguageLikeBrowser } from '@/utils/browser';
import { getErrorMessage } from '@/utils/errorhandler';

interface ChangeEmailError {
	isActive: boolean;
	message: string;
	showTryAgain: boolean;
}

@Component
export default class ChangeEmailPage extends Vue {
	public readonly DEFAULT_TIMER_SECONDS = 10;
	public readonly lpUrl = window.location.origin;
	public isRedirectMode: boolean = false;
	public redirectTime: number = 0;
	public isReady: boolean = false;
	public error: ChangeEmailError = {
		isActive: false,
		showTryAgain: false,
		message: '',
	};
	public successMessage: boolean = false;

	/**
	 * Copied from InviteResultPage.vue.
	 */
	public startRedirectTimer(payload: { callback: () => void, seconds?: number }) {
		this.isRedirectMode = true;
		this.redirectTime = payload.seconds ? payload.seconds : this.DEFAULT_TIMER_SECONDS;
		const interval = setInterval(() => {
			if (this.redirectTime === 1) {
				clearInterval(interval);
				return payload.callback();
			}
			this.redirectTime -= 1;
		}, 1000);
	}

	/**
	 * Copied from InviteResultPage.vue.
	 */
	public redirectToLoginStartTimer() {
		const that = this;
		this.startRedirectTimer({
			callback() {
				that.redirectToLogin();
			},
		});
	}

	public async redirectToLogin() {
		// The current token has the old email address, and will lead to errors because e.g. AuthZ cannot find the user.
		// Log out to ensure getting a fresh token.
		$assert.Assert(!this.error.message, 'This should be the success case');
		this.$auth.logout({returnTo: window.location.origin + BASE_URL});
		// Tried this instead, to keep the user logged in, but the Auth0 session is no longer valid.
		// await this.$tsStore.users.getTokenSilently({ ignoreCache: true });
		// location.pathname = BASE_URL; // reload the page
	}

	public async created() {
		// Validate basic inputs.
		const tokenUuid: string = this.$route.params.token;
		if (!UuidUtils.isSimpleUuid(tokenUuid)) {
			this.error.isActive = true;
			this.error.message = this.$tc('LP_ERR_EMAIL_URL_INVALID');
			this.isReady = true;
			return;
		}

		try {
			// Call AuthZ to finish the process to change the user's email address.
			// throws HttpError
			await this.$tsStore.users.finishEmailChange(tokenUuid);
			this.successMessage = true;
			this.redirectToLoginStartTimer();
		} catch (error) {
			console.error(error);
			// Provide distinct error messages for the two most common error types:
			//  * BadRequestError with actual = "Expired token" for an expired token.
			//  * NotFoundError with objectType = "EmailToken" for an already used token (or otherwise non-existing token).
			// Both errors are represented as HttpError, so e.g. "e instanceof BadRequestError" always returns false.
			const e = error as HttpError;
			if ((e.name === 'BadRequestError' || e.status === 400) && e.responseBody?.actual === 'Expired token') {
				this.error.message = this.$tc('LP_ERR_EMAIL_TOKEN_EXPIRED');
			} else if ((e.name === 'NotFoundError' || e.status === 404) && e.responseBody?.objectType === 'EmailToken') {
				this.error.message = this.$tc('LP_ERR_EMAIL_TOKEN_USED');
			} else {
				this.error.message = getErrorMessage(error);
				this.error.showTryAgain = true;
			}
			this.error.isActive = true;
		} finally {
			this.isReady = true;
		}
	}

	public mounted() {
		// If the user is already on the store the language was already set to match the user's language.
		if (!this.$tsStore.users.user) {
			setLanguageLikeBrowser();
		}
	}
}

