import { AuthzType } from '@faroconnect/authz-client';
import { DataTableHeader } from 'vuetify';
import { config } from '@/config';

export const workspaceRegionNames: { [key in AuthzType.WebshareRegion]: string } = {
	eu: 'EU',
	us: 'US',
};

export const workspaceRegionCypressId: { [key in AuthzType.WebshareRegion]: string } = {
	eu: 'wsreu_item',
	us: 'wsrus_item',
};

export interface DataTableHeaderWithCypressLocator extends DataTableHeader {
	/**
	 * Cypress test locator value
	 */
	dataCyId?: string;
}

export const americaCountries: string[] = [
	'GY', 'GF', 'EC', 'VE', 'BO', 'MX', 'BZ', 'SR', 'CR', 'BM',
	'CO', 'GL', 'PM', 'AR', 'UM', 'PY', 'UY', 'HN', 'BR', 'CA',
	'PE', 'GT', 'SV', 'NI', 'FK', 'PA', 'US', 'CL',
];

/**
 * Gets available workspace regions "text" and "value" properties.
 */
export function availableRegions(): DataTableHeaderWithCypressLocator[] {
	const availableRegions: AuthzType.WebshareRegion[] = [...config.webShareRegions];
	return Object.values(availableRegions).map((region) => ({
		text: workspaceRegionNames[region as keyof typeof workspaceRegionNames],
		value: region,
		dataCyId: workspaceRegionCypressId[region as keyof typeof workspaceRegionCypressId],
	})).sort((a, b) => a.text.localeCompare(b.text));
}

// Choose Region based on user's country
export function isUSorEU(country: string): string {
	return americaCountries.includes(country) ? 'us' : 'eu';
}
