
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class LpDialog extends Vue {
	@Prop(String) public readonly text!: string;
	@Prop(String) public readonly title!: string;
	/* EX: image prop value ex: require('@/assets/mfa-notification.svg') */
	@Prop(String) public readonly image!: string;
	/* EX: image prop value ex: require('@/assets/help-active-workspace-2.png') */
	@Prop(String) public readonly image2!: string;
	/* EX: image prop value ex: require('@/assets/help-active-workspace-2-tn.jpg') */
	@Prop(String) public readonly image2tn!: string;
	/* EX: icon prop value ex: '$vuetify.icons.36_active_generic-help' */
	@Prop(String) public readonly icon!: string;
	@Prop(String) public readonly okBtnText!: string;
	@Prop(String) public readonly cancelBtnText?: string;
	/* persistence allow us to decide if the click outside the dialog will make the dialog disapear */
	@Prop(Boolean) public readonly persistent!: boolean;
	/* Custom Identifier for us to run UI tests properly */
	@Prop(String) public readonly dataCy!: string;
	@Prop(Boolean) public readonly rawTitleHtml!: boolean;
	@Prop(String) public readonly imgWidth!: string;

	public active: boolean = true;
}
