import { Service } from '@/classes/Service';
import { ServiceAcademy } from '@/classes/ServiceAcademy';
import { ServiceFaroNow } from '@/classes/ServiceFaroNow';
import { IService } from '@/definitions/interfaces';
import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { dynamicConfig } from '@/config';
import { isFeatureEnabledByAuth0PermissionsSync } from '@/utils/permissions';

/**
 * This service is in charge of CRUD operations to manipulate the Sphere Services.
 */
export class ServiceService extends BaseService<Service> {
	/**
	 * The translation files are not yet parsed when the constructor is called, so this must be set in the
	 * getAll method.
	 */
	private mockedServices?: Service[];

	public constructor(clientConfig: ClientOptions) {
		super({}, clientConfig);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<IService> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<IService[]> {
		if (this.mockedServices) {
			return this.mockedServices;
		}

		this.mockedServices = [
			Service.forRequest({
				Name: 'FARO Knowledge Base',
				StringId: 'LP_TECH_SUPPORT',
				ImgUrl: require('@/assets/FARO_Knowledge_Base.svg'),
				GradientBackground: true,
				Url: 'https://knowledge.faro.com/Software/FARO_Sphere/Sphere',
			}),
			ServiceFaroNow.forRequest({
				Name: 'FARO Support',
				StringId: 'LP_SUBMIT_CASE',
				ImgUrl: require('@/assets/FARO_Support.svg'),
				GradientBackground: true,
				// The url is just the fallback one. It will be calculataed depending on the user's language.
				Url: 'https://knowledge.faro.com/Submit_Case',
			}),
		];

		// The order does not matter, since the page sorts the tiles anyways.
		const isfeatureEnabled: boolean = isFeatureEnabledByAuth0PermissionsSync('faroAcademyService');
		if (isfeatureEnabled && dynamicConfig.url.academyUrlCustomers) {
			this.mockedServices.push(ServiceAcademy.forRequest({
				Name: 'Access Training',
				StringId: 'LP_ACCESS_TRAINING',
				ImgUrl: require('@/assets/FARO_Academy.svg'),
				GradientBackground: true,
				Url: dynamicConfig.url.academyUrlCustomers, // See `get LocalizedUrl()`.
			}));
		}
		if (isfeatureEnabled && dynamicConfig.url.academyDevUrlCustomers) {
			this.mockedServices.push(ServiceAcademy.forRequest({
				Name: 'Access Training (DEV)', // Keep in sync with LP_ACCESS_TRAINING. No need to translate.
				ImgUrl: require('@/assets/FARO_Academy_grey.svg'),
				GradientBackground: true,
				Url: dynamicConfig.url.academyDevUrlCustomers, // See `get LocalizedUrl()`.
			}));
		}

		return this.mockedServices;
	}

	public async create<QueryT extends object>(body?: Service, query?: QueryT): Promise<IService> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(body?: Service, query?: QueryT): Promise<IService> {
		throw new Error('Function not yet implemented');
	}

	public async updateMulti<QueryT extends object>(body?: Service[], query?: QueryT): Promise<IService[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}
}
