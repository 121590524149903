import { faroLocalization } from '@faroconnect/baseui';
import { Route } from 'vue-router';
import { RouteName } from '@/router/routes';

export interface SearchItem {
	totalLength?: number;
	filteredLength?: number;
	placeholder?: string;
	show: boolean;
}

export function getSearchBarItems($route: Route, $tsStore: $tsStore): SearchItem {
	const routeName: RouteName = $route.name as RouteName;
	switch (routeName) {
		case 'ApplicationsPage':
			return {
				totalLength: $tsStore.applications.itemsList.length,
				filteredLength: $tsStore.applications.filteredItems.length,
				placeholder: faroLocalization.i18n.tc('LP_SEARCH_APPLICATIONS'),
				show: true,
			};
		case 'ProjectsPage':
			return {
				totalLength: $tsStore.projects.itemsList.length,
				filteredLength: $tsStore.projects.filteredItems.length,
				placeholder: faroLocalization.i18n.tc('LP_SEARCH_PROJECTS'),
				show: true,
			};
		case 'ProjectsStatusPage':
			return {
				totalLength: $tsStore.projectStatuses.itemsList.length,
				filteredLength: $tsStore.projectStatuses.filteredItems.length,
				placeholder: faroLocalization.i18n.tc('LP_SEARCH_PROJECTS_STATUS'),
				show: true,
			};
		case 'SelectWorkspacePage':
			// Special case because we do not search all workspaces
			return {
				placeholder: faroLocalization.i18n.tc('LP_SEARCH_WORKSPACES'),
				show: true,
			};
		case 'MigrationDashboardPage':
		case 'MigrationStatsPage':
			return {
				placeholder: faroLocalization.i18n.tc('UI_SEARCH'),
				show: true,
			};
		case 'HomePage':
		case 'ProjectInfoPage':
		case 'WorkspaceInfoPage':
		case 'WorkspaceDashboardPage':
		case 'InviteResultPage':
		case 'ChangeEmailPage':
		case 'SettingsPage':
		case 'SignupTask':
		case 'PendingEmailConfirmationPage':
		case 'PendingValidationPage':
		case 'FailedValidationPage':
		case 'LegalNoticesPage':
		case 'TermsOfServicePage':
		case 'PrivacyPolicyPage':
		case 'DataProcessingAgreementPage':
		case 'CookiePolicyPage':
		case 'MigrationPage':
		case 'LoginFailedPage':
		case 'NotFoundPage':
			return {
				show: false,
			};
		default:
			console.error('Provided unknown route name: ' + routeName);
			return {
				show: false,
			};
	}
}
