
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UuidUtils } from '@faroconnect/utils';
import { FaroComponentButton, FaroIconButtonI } from '@faroconnect/baseui';
import { HintMsg } from '@/utils/types';
import { LpEntity } from '@/classes';
import MeshBackgroundImage from '@/components/Embeddable/MeshBackgroundImage.vue';
import GradientBackgroundImage from '@/components/Embeddable/GradientBackgroundImage.vue';
import DemoWorkspaceOrProjectLabel from '@/components/Embeddable/DemoWorkspaceOrProjectLabel.vue';
import { Workspace } from '@/classes/authz/Workspace';
import { Project } from '@/classes/authz/Project';
import { Application } from '@/classes/Application';
import { ApplicationWebShare } from '@/classes/ApplicationWebShare';

@Component({
	components: {
		MeshBackgroundImage,
		GradientBackgroundImage,
		DemoWorkspaceOrProjectLabel,
	},
})
export default class EntityCard extends Vue {
	@Prop(Object) public readonly entity!: LpEntity;
	@Prop(Number) public readonly index!: number;
	@Prop(Number) public readonly X_DESCRIPTION_PREVIEW_LENGTH!: number;
	@Prop(Number) public readonly X_TITLE_PREVIEW_LENGTH!: number;
	@Prop(String) public readonly imageSize!: string;
	@Prop(Function) public readonly xGetHintMsg!: (entity: LpEntity) => HintMsg | null;
	@Prop(Function) public readonly xGetItemButtons!: (entity: LpEntity) => Array<FaroIconButtonI | FaroComponentButton>;
	@Prop(Function) public readonly xOnEntityClicked!: (entity: LpEntity) => void;
	@Prop(Function) public readonly xIsEntityClickable!: (entity: LpEntity) => boolean;
	@Prop(Function) public readonly xIsEntityDisabled!: (entity: LpEntity) => boolean;
	@Prop(Function) public readonly xisEntityLoading!: (entity: LpEntity) => boolean;
	@Prop(Function) public readonly xUseMeshBackground!: (entity: LpEntity) => boolean;
	@Prop(Function) public readonly xUseGradientBackground!: (entity: LpEntity) => boolean;
	@Prop(Array) public readonly itemButtons!: FaroIconButtonI[];
	@Prop({type: Boolean, default: false}) public readonly hideSecondaryInfo!: boolean;

	// Menu Data
	public onOpenMenu: boolean = false;
	/**
	 * The URL of an image may stay the same when the image gets changed, so we need to trigger the reload
	 * of the UI element another way.
	 */
	public reloadRandom: string = '';

	// ---------------------------------------------------------------------------

	public get isEntityDisabled(): boolean {
		return this.xIsEntityDisabled(this.entity);
	}

	/**
	 * Disables hover effect on workspace card if workspace is not created.
	 */
	public get isHoverable() {
		if (this.entity instanceof Workspace) {
			return !!this.workspaceCreated;
		}
		if (this.entity instanceof Application) {
			return !this.entity.ComingSoon;
		}
		return true;
	}

	/**
	 * The project status icon is hidden for successfully completed projects.
	 * @author OK
	 */
	public get isProjectStatusVisible(): boolean {
		return this.entity instanceof Project && !!this.entity.projectStatus &&
			(this.entity.projectStatus.Progress !== null || !this.entity.isStatusSuccessful);
	}

	public get workspaceCreated() {
		if (!(this.entity instanceof Workspace)) {
			return undefined;
		}
		return this.entity.DomainCreationStatus === 'created';
	}

	public get entityHref(): string | null {
		let url = null;
		if (this.entity instanceof Workspace && this.entity.webShareUrl) {
			const user = this.$tsStore.users.user ?? null;
			url = ApplicationWebShare.makeSsoUrl(this.entity.webShareUrl, user);
		} else if (this.entity instanceof Project && this.entity.FullUrl) {
			url = this.entity.FullUrl;
		}
		return url;
	}

	// ---------------------------------------------------------------------------

	public onClickEntityNoMenu($event: Event) {
		// We don't open the menu when the user clicks on the title or description, since these overlap with the menu,
		// and so the user could accidentally accept or decline.
		if (this.entity instanceof Workspace && this.entity.isPendingUserInvitation) {
			return;
		}

		this.onClickEntity($event);
	}

	public onClickEntity($event: Event) {
		// ignore <a> default behaviour
		// kepp the current behaviour regarding updates on the LastVisitDate
		$event.preventDefault();
		$event.stopPropagation();

		// A pending user invitation is not "clickable", but still we should open the menu when the user clicks on the workspace logo.
		if (this.entity instanceof Workspace && this.entity.isPendingUserInvitation) {
			this.openMenu();
			return;
		}
		if (!this.xIsEntityClickable(this.entity) || this.isEntityDisabled) {
			return;
		}

		this.xOnEntityClicked(this.entity);
	}

	public openMenu() {
		this.onOpenMenu = true;
	}

	/**
	 * Reloads the image URL used by the lp-img element.
	 * @author OK
	 */
	protected async reloadImageUrl(): Promise<void> {
		try {
			this.reloadRandom = UuidUtils.generateUuid();
		} catch (error: any) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_PROJECT_IMAGE' });
		}
	}

	public async mounted() {
		this.reloadImageUrl();
	}
}
