import Vue from 'vue';
import Component from 'vue-class-component';
import FilterMenuBase from '@/components/EntitiesPage/FilterMenu/FilterMenuBase.vue';
import { FaroTab } from '@/utils/types';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import { LpEntity } from '@/classes';

@Component({
	components: {
		FilterMenuBase,
	},
})
export class FilterMenuBaseMixin<EntityT extends LpEntity> extends Vue {
	public xMenuVisible: boolean = false;

	public get xTabs(): FaroTab[] {
		throw new Error('This getter should be overriden');
	}

	public get xStore(): BaseFilterModule<EntityT> {
		throw new Error('This getter should be overriden');
	}

	public xCloseMenu() {
		this.xMenuVisible = false;
	}

	public xOnShow(show: boolean) {
		this.xMenuVisible = show;
	}

	public xOnClear() {
		this.xStore.resetFilter();
		this.xCloseMenu();
	}

	public xOnApply() {
		this.xCloseMenu();
	}
}
