
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BackgroundImage extends Vue {
	@Prop(String) public readonly title!: string;
	@Prop(String) public readonly text!: string;
}
