import { Application } from '@/classes/Application';
import { IApplicationMobile, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';

export class ApplicationMobile extends Application implements IApplicationMobile {
	public static forRequest(json: Partial<IApplicationMobile> & IGradientBackground & IMeshBackground) {
		return new ApplicationMobile(json);
	}

	public Class = 'ApplicationMobile';
	public IOSUrl?: string;
	public IOSImgUrl?: string;
	public AndroidUrl?: string;
	public AndroidImgUrl?: string;

	public constructor(json: Partial<IApplicationMobile> & IGradientBackground & IMeshBackground) {
		super(json);
		this.IOSUrl = json.IOSUrl;
		this.IOSImgUrl = json.IOSImgUrl;
		this.AndroidUrl = json.AndroidUrl;
		this.AndroidImgUrl = json.AndroidImgUrl;
	}
}
