import { IApplication, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';
import { Application } from '@/classes/Application';
import { User } from '@/classes/authz/User';

export class ApplicationWebShare extends Application {
	// Boilerplate
	public static forRequest(json: Partial<IApplication> & IGradientBackground & IMeshBackground) {
		return new ApplicationWebShare(json);
	}

	/**
	 * Adapt a WebShare frontend URL to ensure that WebShare has the same user logged in as Sphere.
	 * The WebShare frontend uses session-based authentication, so it could be a different user
	 * (e.g. another email address belonging to same person).
	 * If the email does not match, WebShare will force an SSO login with Auth0.
	 * @param url Base URL
	 * @param user Currently logged in user.
	 * @returns Adapted URL
	 */
	public static makeSsoUrl(url: string, user: User | null): string {
		const delim = url.includes('?') ? '&' : '?';
		// Ensure that WebShare has the same user logged in as Sphere.
		// The WebShare frontend uses session-based authentication, so it could be a different user
		// (e.g. another email address belonging to same person).
		url += delim + 'sso=' + encodeURIComponent(user ? user.Email : 'force');
		// When opening the WebShare UI, don't use the Vue Dev Server proxy URL, since it would show Landing Page.
		if (url.startsWith('http://localhost:4804')) {
			url = url.replace(':4804', ''); // Replace the first occurrence.
		}
		return url;
	}

	/**
	 * Build a WebShare frontend URL to do SSO with Sphere, and afterwards redirect to DataHub.
	 * TODO(FC-4431): Better integrate Auth0 into the DataHub UI instead.
	 * @param domainUrl Base URL, e.g. "https://volvo.webshare-america.net"
	 * @param dataHubPath DataHub UI path to open, e.g. "/datahub/#/folder/4c3b19bc-4bbf-4d36-a024-848a5231e653".
	 * @param user Currently logged in user.
	 * @returns Adapted URL
	 */
	// public static makeDataHubSsoUrl(domainUrl: string, dataHubPath: string, user: User | null): string {
	// 	let url = domainUrl + '/?sso=' + encodeURIComponent(user ? user.Email : 'force') + '&sso_target=' + encodeURIComponent(dataHubPath);
	// 	// When opening the DataHub UI, don't use the Vue Dev Server proxy URL, since it would show Landing Page.
	// 	if (url.startsWith('http://localhost:4804')) {
	// 		url = url.replace(':4804', ''); // Replace the first occurrence.
	// 	}
	// 	return url;
	// }

	public readonly isApplicationWebShare: boolean = true;

	public Class: string = 'ApplicationWebShare';

	public get LocalizedUrl(): string {
		return ApplicationWebShare.makeSsoUrl(this.Url, this.user);
	}
}
