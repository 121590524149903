import { faroLocalization } from '@faroconnect/baseui';
import { DataTableHeader, Framework } from 'vuetify';
import { LpEntity } from '@/classes';
import { Project } from '@/classes/authz/Project';
import { IVueI18n} from 'vue-i18n';
import { ProjectStatus } from '@/classes/projz/ProjectStatus';

export interface Header<EntityT extends LpEntity> extends DataTableHeader<EntityT> {
	value: keyof LpEntity | 'Actions' | 'data-table-expand' | Exclude<keyof EntityT, number | symbol>;
}

abstract class BaseHeader {
	protected $tc = faroLocalization.i18n.tc.bind(faroLocalization.i18n);
	constructor(protected $vuetify: Framework, $tc: IVueI18n['tc']) {}
}

export class EntityHeader<EntityT extends LpEntity> extends BaseHeader {
	public get imgUrl(): Header<EntityT> {
		return {
			text: '',
			value: 'ImgUrl',
			sortable: false,
			width: this.$vuetify.breakpoint.lgAndUp ? '140px' : '90px',
		};
	}

	public get name(): Header<EntityT> {
		return {
			text: this.$tc('UI_NAME'),
			value: 'Name',
			width: '250px',
		};
	}

	public get description(): Header<EntityT> {
		return {
			text: this.$tc('UI_DESCRIPTION'),
			value: 'Description',
			width: '300px',
		};
	}

	public get creationDate(): Header<EntityT> {
		return {
			text: this.$tc('UI_CREATED'),
			value: 'prettyCreationDate',
			width: '150px',
		};
	}

	public get updateDate(): Header<EntityT> {
		return {
			text: this.$tc('UI_UPDATED'),
			value: 'prettyUpdateDate',
			width: '150px',
		};
	}

	public get actions(): Header<EntityT> {
		return {
			text: '',
			value: 'Actions',
			sortable: false,
			width: '50px',
		};
	}
}

export class ProjectHeader extends BaseHeader {
	public get type(): Header<Project> {
		return {
			text: this.$tc('UI_TYPE'),
			value: 'Type',
			width: '100px',
		};
	}

	public get status(): Header<Project> {
		return {
			text: this.$tc('UI_STATUS'),
			value: 'projectStatusStatusId',
			width: '100px',
		};
	}
}

export class ProjectStatusHeader extends BaseHeader {
	public get projectName(): Header<ProjectStatus> {
		return {
			text: this.$tc('UI_PROJECT'),
			value: 'projectName',
			width: '350px',
		};
	}

	public get statusName(): Header<ProjectStatus> {
		return {
			text: this.$tc('UI_STATUS'),
			value: 'displayText',
			width: '300px',
		};
	}
}
