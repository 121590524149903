
import Vue from 'vue';
import {config} from '../../config';

/**
 * Embeds the Enterprise SSO Config UI as an IFrame.
 * @author FM, MH
 */
export default Vue.extend({
	data() {
		return {
			ssoConfigFront: config.ssoConfigFront,
		};
	},
});
