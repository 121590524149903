import Vue from 'vue';
import { Project } from '@/classes/authz/Project';
import { Workspace } from '@/classes/authz/Workspace';
import { ShareWorkspaceTaskInitData } from '@faroconnect/baseui/public/export/ShareWorkspaceTask/ShareWorkspaceTask';
import CancelPaidSubscriptionDialog from '@/components/Dialog/CancelPaidSubscriptionDialog.vue';
import ShareWorkspaceTask from '@/components/Tasks/ShareWorkspaceTask.vue';
import ShareProjectTask from '@/components/Tasks/ShareProjectTask.vue';
import { ShareProjectTaskInitData } from '@/components/Tasks/ShareProjectTask';
import { ItemButtons } from '@/utils/types';
import {
	acceptInvitation,
	hasType,
	missingAuthzAcceptation,
	rejectInvitation,
	leaveWorkspace,
} from './commonUtils';
import PageBaseMixin from '@/mixins/PageBaseMixin';
import { AuthzConstant } from '@faroconnect/authz-client';
import { ApplicationWebShare } from '@/classes/ApplicationWebShare';
import { encodeParamForWebShare } from '@/utils/route';
import { CancelPaidSubscriptionDialogInitData } from '@/utils/subscriptionutils';
import { getErrorMessage } from '@/utils/errorhandler';


export class ProjectPageUtility {
	constructor(public vue: PageBaseMixin<Project> | Vue) { }

	public buttons(): ItemButtons<Project> {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return [
			{
				icon: '$vuetify.icons.36_action-info',
				caption: this.vue.$tc('UI_INFO'),
				dataCy: 'view-prj-btn',
				click: (item: Project) => {
					const workspaceUuid = $tsStore.workspaces.activeWorkspace?.UUID || '';
					this.vue.$router.push({ name: 'ProjectInfoPage', params: { workspace: workspaceUuid, project: item.UUID }});
				},
				getHidden: (item: Project) => !hasType('Project', item),
			},
			{
				icon: '$vuetify.icons.36_device-commands-new',
				caption: this.vue.$tc('LP_CREATE_WEBSHARE_PROJECT'),
				click: (item: Project) => {
					this.startWebShareProjectCreation(item);
				},
				getHidden: (item: Project) => !hasType('Project', item) || item.IsDemo || item.Type === AuthzConstant.PROJECT_TYPE_WEBSHARE ||
					(!$tsStore.users.getWorkspaceAccessControl(item.Workspace).createProject.hasPermission),
			},
			{
				icon: '$vuetify.icons.36_file-share',
				caption: this.vue.$tc('LP_SHARE_PROJECT'),
				getHidden: (item: Project) => !hasType('Project', item) || item.IsDemo ||
					// We also hide the item if the user doesn't have access to it
					!$tsStore.users.projectAccessControl.shareProject(item.Workspace, item.UUID).hasPermission ||
					!$tsStore.users.getWorkspaceAccessControl(item.Workspace).assignProjectRole.hasPermission ||
					!$tsStore.users.getWorkspaceAccessControl(item.Workspace).readUser.hasPermission ||
					!$tsStore.users.getWorkspaceAccessControl(item.Workspace).readProjectRole.hasPermission ||
					!$tsStore.users.getWorkspaceAccessControl(item.Workspace).readGroup.hasPermission,
				click: (item: Project) => {
					this.vue.$faroTaskService.showFullscreenTask<ShareProjectTaskInitData>(ShareProjectTask, { project: item });
				},
			},
			{
				icon: '$vuetify.icons.36_error_generic-delete',
				caption: this.vue.$tc('UI_DELETE'),
				textClass: 'ui_fontRed',
				getHidden: (item: Project) => !hasType('Project', item) || item.IsDemo ||
					!$tsStore.users.getWorkspaceAccessControl(item.Workspace).deleteProject.hasPermission,
				click: (item: Project) => {
					this.showDeleteConfirmDialog(item);
				},
			},
		];
	}

	/**
	 * Opens the "Import SCENE project" task in the WebShare UI that starts the WebShare project generator.
	 * @author OK
	 */
	protected openWSGeneratorPage(project: Project): void {
		// https://faro-eu.websharecloud.net/?v=pl&t=p:default,c:datahub,t:wsgenerationlp,h:f,m:t,pr:f&pl=dhpu:2382c688-4fb1-492e-8b8a-c3e493687afc,dhpn:Vaults
		const nameEncoded = encodeParamForWebShare(project.Name);
		let url = `${project.DomainUrl}/?v=pl&t=p:default,c:datahub,t:wsgenerationlp,h:f,m:t,pr:f&pl=dhpu:${project.UUID},dhpn:${nameEncoded}`;
		url = ApplicationWebShare.makeSsoUrl(url, project.user);
		window.location.href = url;
	}

	/**
	 * Calls WebShare to put a job on the queue for the SCENE generator to create a SCENE project in the
	 * DataHub from the provided Stream project.
	 * @author OK
	 * @param project Project instance.
	 */
	protected startSceneGenerator(project: Project): Promise<void> {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.projects.startSceneGenerator(project);
	}

	public startWebShareProjectCreation(project: Project) {
		if (project.Type === AuthzConstant.PROJECT_TYPE_SCENE) {
			this.openWSGeneratorPage(project);
		} else {
			// This function will already emit the necessaray error/success snackbars.
			this.startSceneGenerator(project);
		}
	}

	public showDeleteConfirmDialog(project: Project) {
		this.vue.$faroComponents.$emit('show-delete-project-dialog', project);
	}

	public openPageUtil(project: Project) {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const openTask: boolean = project.Type === AuthzConstant.PROJECT_TYPE_SCENE || project.Type === AuthzConstant.PROJECT_TYPE_STREAM;
		if (!project.IsDemo && openTask) {
			const workspaceUuid = $tsStore.workspaces.activeWorkspace?.UUID || '';
			this.vue.$router.push({ name: 'ProjectInfoPage', params: { workspace: workspaceUuid, project: project.UUID }});
		} else {
			$tsStore.projects.openPage(project);
		}
	}
}

export class WorkspacePageUtility {
	constructor(public vue: PageBaseMixin<Workspace>) { }

	public buttons(): ItemButtons<Workspace> {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return [
			{
				icon: '$vuetify.icons.36_file-share',
				caption: this.vue.$tc('LP_INVITE_USERS'),
				getHidden: (item: Workspace) => !hasType('Workspace', item) || item.ReadOnly || item.IsDemo || missingAuthzAcceptation(item) ||
					(!$tsStore.users.getWorkspaceAccessControl(item.UUID).inviteUser.hasPermission ||
					!$tsStore.users.getWorkspaceAccessControl(item.UUID).readProjectRole.hasPermission ||
					!$tsStore.users.getWorkspaceAccessControl(item.UUID).readGroup.hasPermission),
				click: (item: Workspace) => {
					this.vue.$faroTaskService.showFullscreenTask<ShareWorkspaceTaskInitData>(ShareWorkspaceTask, {
						workspaceUuid: item.UUID,
					});
				},
			},
			{
				icon: '$vuetify.icons.36_success_generic-check',
				caption: this.vue.$tc('LP_ACCEPT_INVITATION'),
				textClass: 'ui_fontGreen',
				getHidden: (item: Workspace) => !hasType('Workspace', item) || item.ReadOnly || item.IsDemo || !missingAuthzAcceptation(item),
				click: (item: Workspace) => acceptInvitation(item, this.vue),
			},
			{
				icon: '$vuetify.icons.36_error_generic-close',
				caption: this.vue.$tc('LP_REJECT_INVITATION'),
				textClass: 'ui_fontRed',
				getHidden: (item: Workspace) => !hasType('Workspace', item) || item.ReadOnly || item.IsDemo || !missingAuthzAcceptation(item),
				click: (item: Workspace) => rejectInvitation(item, this.vue),
			},
			{
				icon: '$vuetify.icons.36_error_generic-logout',
				caption: this.vue.$tc('LP_LEAVE_WORKSPACE'),
				textClass: 'ui_fontRed',
				getHidden: (item: Workspace) => !hasType('Workspace', item) || item.ReadOnly || item.IsDemo ||
					missingAuthzAcceptation(item) || item.Owner === $tsStore.users.user?.UUID,
				click: (item: Workspace) => {
					this.showLeaveConfirmDialog(item);
				},
			},
			{
				icon: '$vuetify.icons.36_error_action-error',
				caption: this.vue.$tc('LP_CANCEL_SUB'),
				textClass: 'ui_fontRed',
				getHidden: (item: Workspace) => !hasType('Workspace', item) || item.ReadOnly || item.IsDemo ||
					missingAuthzAcceptation(item) || item.Owner !== $tsStore.users.user?.UUID,
				click: (item: Workspace) => {
					this.showCancelSubscriptionConfirmDialog(item);
				},
			},
			// TODO we don't yet support deleting a workspace
			// {
			// 	icon: '$vuetify.icons.36_error_generic-delete',
			// 	caption: this.$tc('UI_DELETE'),
			// 	getHidden: this.missingAuthzAcceptation,
			// 	textClass: 'ui_fontRed',
			// 	click: (item: Workspace) => {
			// 		this.onRemove(item);
			// 	},
			// },
		];
	}

	public showLeaveConfirmDialog(workspace: Workspace) {
		this.vue.$faroNotify.showConfirmationDialog({
			title: this.vue.$tc('LP_LEAVE_WORKSPACE'),
			text: this.vue.$tc('LP_CONFIRM_LEAVE_WORKSPACE'),
			group: 'error',
			persistent: false,
			leftButton: {
				textId: 'UI_CANCEL',
			},
			rightButton: {
				color: 'error',
				text: this.vue.$tc('LP_CONFIRM'),
				click: () => leaveWorkspace(workspace, this.vue),
			},
		});
	}

	/**
	 * Handler for a click on the Cancel Subscription menu entry.
	 * Shows a modal dialog with a description text for paid subscriptions.
	 * Opens a modal confirmation dialog for free subscriptions.
	 * @author OK
	 */
	public async showCancelSubscriptionConfirmDialog(workspace: Workspace): Promise<void> {
		if (workspace.CommerciallyRelevant) {
			this.vue.$faroTaskService.showFullscreenTask<CancelPaidSubscriptionDialogInitData>(
				CancelPaidSubscriptionDialog, { erpId: workspace.ErpId! });
		} else {
			this.vue.$faroNotify.showConfirmationDialog({
				title: this.vue.$tc('LP_CANCEL_SUB'),
				text: this.vue.$tc('LP_CANCEL_SUB_CONFIRM'),
				group: 'error',
				persistent: false, // A click outside closes the dialog as well.
				leftButton: {
					textId: 'UI_NO',
				},
				rightButton: {
					color: 'error',
					text: this.vue.$tc('UI_YES'),
					click: () => this.onCancelFreeSubscription(workspace),
				},
			});
		}
	}

	/**
	 * Triggered when the user confirmed the cancellation of the free subscription.
	 * Calls the subscription service to deactivate the workspace.
	 * @author OK
	 */
	protected async onCancelFreeSubscription(workspace: Workspace): Promise<void> {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		try {
			await $tsStore.workspaces.deactivateWorkspace(workspace.UUID);
			this.vue.$faroNotify.showSnackbar('success', this.vue.$tc('UI_SUCCESS'), this.vue.$tc('LP_CANCEL_SUB_SUCCESS'));
			this.vue.$router.push({ name: 'SelectWorkspacePage' });
		} catch (error) {
			this.vue.$faroComponents.$emit('show-error', { error, message: getErrorMessage(error) });
		}
	}
}
