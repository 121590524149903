import { IApplication, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';
import { Application } from '@/classes/Application';
import { ApplicationWebShare } from './ApplicationWebShare';
import { ApplicationMobile } from './ApplicationMobile';

/**
 * Factory class that enables BaseModule<Application> to handle different kinds of Applications.
 */
export class ApplicationAny extends Application {
	public static fromResponse(json: IApplication) {
		if (json.Class === 'ApplicationWebShare') {
			return new ApplicationWebShare(json);
		} else if (json.Class === 'ApplicationMobile') {
			return new ApplicationMobile(json);
		} else if (json.Class === 'Application') {
			return new Application(json);
		} else {
			throw new Error('[DEV] Reached exhaustive handling. ' +
				'Do not forget to update fromResponse() in ApplicationAny after creating a new Application class.');
		}
	}

	public static forRequest(json: Partial<IApplication> & IGradientBackground & IMeshBackground) {
		if (json.Class === 'ApplicationWebShare') {
			return new ApplicationWebShare(json);
		} else if (json.Class === 'ApplicationMobile') {
			return new ApplicationMobile(json);
		} else if (json.Class === 'Application') {
			return new Application(json);
		} else {
			throw new Error('[DEV] Reached exhaustive handling. ' +
				'Do not forget to update forRequest() in ApplicationAny after creating a new Application class.');
		}
	}

	public readonly isApplicationAny: boolean = true;
}
