import {FaroSubscription} from '@/subscription/generated/client';
import {HttpOperationResponse, RequestPrepareOptions, WebResourceLike} from '@azure/core-http';

export class SubscriptionService extends FaroSubscription {
	public sendRequest(options: RequestPrepareOptions | WebResourceLike): Promise<HttpOperationResponse> {
		return super.sendRequest(options).then((resp) => {
			if (resp.status >= 200 && resp.status < 300) {
				return Promise.resolve(resp);
			}
			return Promise.reject(resp);
		});
	}
}
