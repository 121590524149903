import { IService, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';
import { Service } from '@/classes/Service';
import { ServiceAcademy } from './ServiceAcademy';
import { ServiceFaroNow } from './ServiceFaroNow';

/**
 * Factory class that enables BaseModule<Service> to handle different kinds of Services.
 */
export class ServiceAny extends Service {
	public static fromResponse(json: IService) {
		if (json.Class === 'ServiceAcademy') {
			return new ServiceAcademy(json);
		} else if (json.Class === 'ServiceFaroNow') {
			return new ServiceFaroNow(json);
		} else if (json.Class === 'Service') {
			return new Service(json);
		} else {
			throw new Error('[DEV] Reached exhaustive handling. ' +
				'Do not forget to update fromResponse() in ServiceAny after creating a new Service class.');
		}
	}

	public static forRequest(json: Partial<IService> & IGradientBackground & IMeshBackground) {
		if (json.Class === 'ServiceAcademy') {
			return new ServiceAcademy(json);
		} else if (json.Class === 'ServiceFaroNow') {
			return new ServiceFaroNow(json);
		} else if (json.Class === 'Service') {
			return new Service(json);
		} else {
			throw new Error('[DEV] Reached exhaustive handling. ' +
				'Do not forget to update forRequest() in ServiceAny after creating a new Service class.');
		}
	}

	public readonly isServiceAny: boolean = true;
}
