
import Vue, { VueConstructor } from 'vue';
import Component from 'vue-class-component';
import ProjectsPageBaseMixin from '@/mixins/ProjectsPageBaseMixin';
import { Project } from '@/classes/authz/Project';
import { Workspace } from '@/classes/authz/Workspace';
import { EntitiesFetchResult, ItemButtons } from '@/utils/types';
import { Prop } from 'vue-property-decorator';
import ProjectFilterMenu from '@/components/EntitiesPage/FilterMenu/ProjectFilterMenu.vue';
import { ProjectPageUtility } from '@/shared/PageUtilities';
import { Header, ProjectHeader } from '@/utils/listview';

@Component
export default class ProjectsPage extends ProjectsPageBaseMixin {
	@Prop(Object) public readonly projectsFetchResult!: EntitiesFetchResult;

	public projectHeader = new ProjectHeader(this.$vuetify, this.$tc);

	// ######################## Inherited Getters ####################

	public get xGetAllErrString(): string {
		return this.$tc('LP_ERR_GET_PROJECTS');
	}

	public get xItems() {
		return this.$tsStore.projects.itemsList;
	}

	public get xFilteredItems() {
		return this.$tsStore.projects.filteredItems;
	}

	public get xPageName(): string {
		return 'ProjectsPage';
	}

	public get xEntitiesOnPage() {
		return this.$tsStore.projects.entitiesOnPage;
	}

	public get xItemButtons(): ItemButtons<Project> {
		const sharedButtons = new ProjectPageUtility(this);
		return sharedButtons.buttons();
	}

	public get xFilterMenu(): VueConstructor<Vue> {
		return ProjectFilterMenu;
	}

	public get xHeaders(): Array<Header<Project>> {
		return [
			this.xEntityHeader.imgUrl,
			this.xEntityHeader.name,
			this.xEntityHeader.description,
			this.xEntityHeader.creationDate,
			this.xEntityHeader.updateDate,
			this.projectHeader.type,
			this.projectHeader.status,
			this.xEntityHeader.actions,
		];
	}

	// ######################## Class Getters ####################

	public get hasProjectsFetchResultError(): boolean {
		return typeof this.projectsFetchResult.errorMsg === 'string';
	}

	public get pageLoading(): boolean {
		if (this.hasProjectsFetchResultError) {
			return false;
		}
		return this.projectsFetchResult.loading || this.xLoading;
	}

	public get pageError(): boolean {
		if (this.hasProjectsFetchResultError) {
			return true;
		}
		return this.xError;
	}

	public get pageErrorTitle(): string | null {
		if (this.hasProjectsFetchResultError) {
			return this.xGetAllErrString;
		}
		return this.xErrorTitle;
	}

	public get pageErrorMsg(): string | null {
		return this.projectsFetchResult.errorMsg || this.xErrorMsg;
	}

	public get hasProjectStatus(): boolean {
		return !this.$tsStore.workspaces.isDemoActive && this.$tsStore.projectStatuses.itemsList.length > 0;
	}

	public get hasRunningProjects(): boolean {
		return this.$tsStore.projectStatuses.hasRunningProjects;
	}

	public get failedConnectionProjectService(): boolean {
		return this.$tsStore.projectStatuses.failedConnection;
	}

	public get activeWorkspace(): Workspace | null {
		return this.$tsStore.workspaces.activeWorkspace;
	}

	// ######################## Inherited Methods ####################

	/**
	 * Opens the Project Info Panel or project URL depending on its type
	 * Check openPageUtil
	 */
	public async xOpenEntity(project: Project) {
		const projectPageUtils = new ProjectPageUtility(this);
		projectPageUtils.openPageUtil(project);
	}

	public async xAddNewTile(): Promise<void> {
		// TODO code to add a new tile
		this.$faroNotify.showSnackbar('info', undefined, 'Functionality not yet implemented');
	}

	// ######################## Class Methods ####################

	public openProjectStatusesPage() {
		const workspaceUuid = this.activeWorkspace?.UUID || '';
		this.$router.push({ name: 'ProjectsStatusPage', params: { workspace: workspaceUuid } });
	}

	public showProjectStatusError() {
		this.$faroComponents.$emit('show-error', { message: 'LP_ERR_FETCH_PROJECT_STATUS' });
	}

	/**
	 * Override inherited xInitialize method so that it also adds the Faro demo project to the store.
	 */
	public async xInitialize() {
		this.xLoading = true;
		try {
			this.$faroLoading.start();
			if (!this.$tsStore.workspaces.isDemoActive) {
				await this.xStore.getAll();
			}
		} catch (error) {
			this.handleError(error);
		} finally {
			this.$tsStore.pages.setFinishedPageLoading(true);
			if (this.$tsStore.pages.finishedMainLoading) {
				this.$faroLoading.stop();
			}
			this.xLoading = false;
		}
	}

	public async mounted() {
		const initialized = await this.xInitializeAfterFirstPage();
		if (initialized) {
			this.$emit('reset-projects-fetch-result');
		}
		this.xStore.updateFiltersState();
	}
}
