import { faroLocalization } from '@faroconnect/baseui';
import { IApplication, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';
import { validateString } from '@/utils/validate';
import { BaseEntity } from '@/classes/BaseEntity';
import Vue from 'vue';
import { User } from './authz/User';

const defaultWorkspaceImage = require('@/assets/defaultProjectImage.jpg');

export class Application extends BaseEntity implements IApplication, IGradientBackground, IMeshBackground {
	public static readonly constructorName = Application.name;

	public static fromResponse(json: IApplication) {
		return new Application(json);
	}

	public static forRequest(json: Partial<IApplication> & IGradientBackground & IMeshBackground) {
		return new Application(json);
	}

	public readonly isApplication: boolean = true;

	public Class: string = 'Application';
	public Url: string;
	public ImgUrl: string;
	public DefaultImgUrl: string = defaultWorkspaceImage;
	public GradientBackground: boolean;
	public MeshBackground: boolean;
	/**
	 * Some applications use a title that needs to be translated, like "Access Training", instead of a product name.
	 */
	public StringId?: string;
	/**
	 * If set to true, the application will be disabled, and a flag will be added,
	 * to show the user that the application will be available soon.
	 */
	public ComingSoon: boolean;

	protected constructor(json: Partial<IApplication> & IGradientBackground & IMeshBackground) {
		super(json);
		this.ImgUrl = json.ImgUrl ?? defaultWorkspaceImage;
		this.Url = validateString(json.Url);
		this.GradientBackground = json.GradientBackground ?? false;
		this.MeshBackground = json.MeshBackground ?? false;
		this.StringId = json.StringId ?? undefined;
		this.ComingSoon = json.ComingSoon ?? false;
	}

	/**
	 * Returns the current logged in user.
	 */
	public get user(): User | null {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.users.user ?? null;
	}

	/**
	 * This method could be overriden to modify the application's url based on the user's language or any other settings.
	 */
	public get LocalizedUrl(): string {
		return this.Url;
	}

	/**
	 * Returns the translated string or, if not available, the name as fallback.
	 * Used by the application's list view in ApplicationsPage -> xHeaders.
	 * @author OK
	 */
	public get translatedStringIdOrName(): string {
		return this.StringId ? faroLocalization.i18n.tc(this.StringId) : this.Name;
	}
}
