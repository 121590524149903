
import Component from 'vue-class-component';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';
import { Workspace } from '@/classes/authz/Workspace';
import { User } from '@/classes/authz/User';
import { isFeatureEnabledByAuth0PermissionsSync } from '@/utils/permissions';
import { validateWorkspaceName, validateWorkspaceDescription } from '@/utils/validate';
import { $assert } from '@faroconnect/utils';
import { ApplicationWebShare } from '@/classes/ApplicationWebShare';
import { ItemButtons } from '@/utils/types';
import { WorkspacePageUtility } from '@/shared/PageUtilities';
import PageBaseMixin from '@/mixins/PageBaseMixin';


@Component({
	components: {
		TopPageBase,
	},
})
export default class WorkspaceInfoPage extends PageBaseMixin<Workspace> {
	// ##### Validation Methods ##### //

	/**
	 * Validates the entered workspace name.
	 * @author OK
	 * @param name Entered name.
	 */
	protected readonly validateName = (name: string): boolean | string => {
		const n = name.trim();
		const isFaroUser = !!this.$tsStore.users.isStrictFaroUser; // "Strict" to be in sync with SubSvc backend logic.

		if (n === '') {
			return this.$tc('LP_REQUIRED_NAME');
		} else if (validateWorkspaceName(n, isFaroUser)) {
			return true;
		} else if (n.startsWith('-') || n.endsWith('-')) {
			return this.$tc('LP_NAME_INVALID_HYPHEN');
		} else if (!isFaroUser && n.includes('faro')) {
			return this.$tc('LP_NAME_INVALID_FARO');
		} else {
			return this.$tc('LP_NAME_INVALID');
		}
	};

	/**
	 * Validates the entered workspace description.
	 * @author OK
	 * @param desc Entered description.
	 */
	protected readonly validateDescription = (desc: string): boolean | string => {
		return validateWorkspaceDescription(desc) || this.$tc('LP_DESC_LEN_INVALID');
	};

	// ---------------------------------------------------------------------------

	// ##### Members ##### //

	public error: boolean = false;
	public loading: boolean = false;
	public itemButtons: ItemButtons<Workspace> = [];
	public workspace : Workspace | null = null; // null means "still unknown".
	public newWorkspaceOwnerUuid: string = '';
	public isEditingName: boolean = false;
	public isEditingDescription: boolean = false;
	public isEditingOwner: boolean = false;
	public isFetchingAdmins = false;
	public currentName: string = '';
	public currentDescription: string = '';

	/**
	 * User object for the workspace owner. Is null if the user doesn't have the view-users permission, in which case
	 * the inviter's email will be shown as contact person instead.
	 */
	public owner: User | null = null;

	/**
	 * List of all users that have the Workspace Admin workspace role and can be selected as new workspace owner.
	 */
	public workspaceAdmins: User[] = [];

	/**
	 * Workspace role names.
	 */
	public roleNames: string[] | null = null;

	// ---------------------------------------------------------------------------

	// ##### Getters ##### //

	/**
	 * Returns true if the user is allowed to edit the workspace name.
	 * @author OK
	 */
	public get hasEditNamePermission(): boolean {
		if (!this.workspace || this.workspace.IsDemo) {
			return false;
		}
		return isFeatureEnabledByAuth0PermissionsSync('updateWorkspaceName') &&
			this.$tsStore.users.getWorkspaceAccessControl(this.workspace.UUID).updateWorkspace.hasPermission;
	}

	/**
	 * Returns true if the user is allowed to edit the workspace description.
	 * @author OK
	 */
	public get hasEditDescriptionPermission(): boolean {
		if (!this.workspace || this.workspace.IsDemo) {
			return false;
		}
		return this.$tsStore.users.getWorkspaceAccessControl(this.workspace.UUID).updateWorkspace.hasPermission;
	}

	public get user() {
		return this.$tsStore.users.user ?? null;
	}

	public get creationDate(): string {
		return this.formatDate(this.workspace?.CreationDate);
	}

	public get canEditWorkspaceOwner(): boolean {
		if (!this.workspace) {
			return false;
		}
		return this.$tsStore.users.getWorkspaceAccessControl(this.workspace.UUID).updateOwner.hasPermission;
	}

	public get isContactOwner(): boolean {
		return !!this.owner;
	}

	/**
	 * Returns the text to display for the contact person.
	 * @author OK
	 */
	public get contactText(): string {
		if (this.workspace?.IsDemo) {
			return '';
		} else if (this.owner) {
			return this.owner.FullName;
		} else if (this.workspace?.Inviter) {
			return this.workspace.Inviter.Email;
		} else {
			return '';
		}
	}

	/**
	 * Returns the uuid of the current workspace owner
	 * @author BE
	 */
	public get currentOwnerUuid(): string {
		if (this.workspace?.IsDemo) {
			return '';
		} if (this.owner) {
			return this.owner.UUID;
		} else if (this.workspace?.Inviter) {
			// See also `isContactOwner` - we show 'LP_SHARED_BY' in this case.
			return this.workspace.Inviter.Uuid;
		} else {
			return '';
		}
	}

	/**
	 * Sets the uuid of the new workspace owner, selected by the user in the dropdown.
	 * In updateOwner(), this uuid is used to update the workspace owner.
	 */
	public set currentOwnerUuid(uuid: string) {
		this.newWorkspaceOwnerUuid = uuid;
	}

	/**
	 * Returns the email link to use for the contact person.
	 * @author OK
	 */
	public get contactEmail(): string {
		if (this.workspace?.IsDemo) {
			return '';
		} else if (this.owner) {
			return this.owner.Email;
		} else if (this.workspace?.Inviter) {
			return this.workspace.Inviter.Email;
		} else {
			return '';
		}
	}

	/**
	 * Returns the company to use for the contact person.
	 * @author OK
	 */
	public get contactCompany(): string {
		if (this.workspace?.IsDemo) {
			return '';
		} else if (this.owner) {
			return this.owner.Company;
		} else {
			return '';
		}
	}

	/**
	 * Returns true if the user can access at least 2 workspaces, which should usually be true because of the demo
	 * workspace.
	 * @author OK
	 */
	public get hasOtherWorkspaces(): boolean {
		return 1 < this.$tsStore.workspaces.itemsList.length;
	}

	// ---------------------------------------------------------------------------

	// ##### Update Name ##### //

	public startNameUpdate(): void {
		if (!this.workspace) {
			return;
		}
		this.isEditingName = true;
	}

	public async updateName(): Promise<void> {
		// Don't show an error if the name is the same as before.
		const currentNameTrimmed = this.currentName?.trim() || '';
		if (!this.workspace || currentNameTrimmed === this.workspace.Name) {
			this.cancelNameUpdate();
			return;
		}

		const resultValidate = this.validateName(currentNameTrimmed);
		if (resultValidate !== true) {
			// Set a UUID as traceId so that we know where the error was thrown since there's no request.
			this.$faroComponents.$emit('show-error', { error: { traceId: '97e1c890-11dd-4c51-a537-c6db824faa92' },
				title: 'LP_INVALID_INPUT', message: resultValidate });
			return;
		}

		this.$tsStore.workspaces.updateSingle2({ uuid: this.workspace.UUID, data: { Name: currentNameTrimmed }}).then(
			() => {},
			(reason) => {
				// updateSingle will already emit the errors, so we dont need to do it here.
				this.currentName = this.workspace!.Name;
			},
		);

		this.isEditingName = false;
	}

	public cancelNameUpdate(): void {
		if (!this.workspace) {
			return;
		}
		this.isEditingName = false;
		this.currentName = this.workspace.Name;
	}

	// ##### Update Description ##### //

	public startDescriptionUpdate(): void {
		if (!this.workspace) {
			return;
		}
		this.isEditingDescription = true;
	}

	public async startOwnerUpdate(): Promise<void> {
		if (!this.workspace) {
			return;
		}
		this.isEditingOwner = true;
		this.isFetchingAdmins = true;
		try {
			await this.getWorkspaceAdmins();
		} catch (error: any) {
			this.isEditingOwner = false;
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_FETCH_USERS_BELONG_WORKSPACE' });
		} finally {
			this.isFetchingAdmins = false;
		}
	}

	public async updateOwner(): Promise<void> {
		if (!this.workspace) {
			return;
		}
		if (!this.newWorkspaceOwnerUuid) {
			return;
		}

		let newOwner = null;
		try {
			// throws HttpError
			newOwner = await this.$tsStore.users.getUserWithWorkspaceRoles({
				workspaceUuid: this.workspace.UUID,
				uuid: this.newWorkspaceOwnerUuid,
			});
			if (newOwner.WorkspaceRoles || newOwner.WorkspaceRolesFromGroups?.WorkspaceRoles) {
				const isAdminDirect = newOwner.WorkspaceRoles?.some((role) => role.Id === 'workspace-admin');
				const isAdminViaGroup = newOwner.WorkspaceRolesFromGroups?.WorkspaceRoles?.some((role) => role.Id === 'workspace-admin');
				if (!isAdminDirect && !isAdminViaGroup) {
					this.$faroComponents.$emit('show-error', { error: { traceId: '6bcbd5af-5d31-4882-9eb3-fbe68357f9e6' },
						message: 'LP_ERR_OWNER_NOT_ADMIN' });
					return;
				}
			} else {
				this.$faroComponents.$emit('show-error', { error: { traceId: '903dbdf6-df89-4cb5-a164-bd81ee0c96e7' },
					message: 'LP_ERR_OWNER_NOT_ADMIN' });
				return;
			}
		} catch (error: any) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_USER_NOT_IN_WORKSPACE' });
			return;
		}

		try {
			// throws HttpError
			await this.$tsStore.workspaces.updateOwner(this.workspace.UUID, newOwner.UUID);
		} catch (error: any) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_UPDATING_OWNER' });
			return;
		}

		this.owner = await this.$tsStore.users.getUser({ workspaceUuid: this.workspace.UUID, uuid: newOwner.UUID });
		this.isEditingOwner = false;
		this.newWorkspaceOwnerUuid = '';
		// The action menu items need to be updated since the current user may have been set or unset as the owner.
		this.setActionButtons();
	}

	public async updateDescription(): Promise<void> {
		if (!this.workspace) {
			return;
		}

		const resultValidate = this.validateDescription(this.currentDescription);
		if (resultValidate !== true) {
			// Set a UUID as traceId so that we know where the error was thrown since there's no request.
			this.$faroComponents.$emit('show-error', { error: { traceId: '0c1bbf70-7ab3-4fa6-9d2d-6bb9dcb50cba' },
				title: 'LP_INVALID_INPUT', message: resultValidate });
			return;
		}

		this.$tsStore.workspaces.updateSingle2({ uuid: this.workspace.UUID, data: { Description: this.currentDescription.trim()}}).then(
			() => {},
			(reason) => {
				// updateSingle will already emit the errors, so we dont need to do it here.
				this.currentDescription = this.workspace!.Description;
			},
		);

		this.isEditingDescription = false;
	}

	public cancelDescriptionUpdate(): void {
		if (!this.workspace) {
			return;
		}
		this.isEditingDescription = false;
		this.currentDescription = this.workspace.Description;
	}

	public cancelOwnerUpdate(): void {
		if (!this.workspace) {
			return;
		}
		this.isEditingOwner = false;
		this.newWorkspaceOwnerUuid = '';
	}

	// ##### Other actions ##### //

	/**
	 * Handler for the Switch workspace button.
	 * @author OK
	 */
	public onSwitchWorkspace(): void {
		this.$router.push({ name: 'SelectWorkspacePage' });
	}

	/**
	 * Handler for the Open workspace in WebShare button.
	 * @author OK
	 */
	public onOpenWorkspace(): void {
		if (!this.workspace) {
			return;
		}

		$assert.Assert(this.workspace.webShareUrl, `webShareUrl isn't set for ${this.workspace.UUID}!`);
		if (this.workspace.webShareUrl) {
			this.$tsStore.workspaces.updateLastVisited(this.workspace.UUID);
			const url = ApplicationWebShare.makeSsoUrl(this.workspace.webShareUrl, this.user);
			window.location.href = url;
		} else {
			this.$faroComponents.$emit('show-error', { message: 'LP_ERR_SHOW_WORKSPACE' });
		}
	}

	// ##### Helper functions ##### //

	protected formatDate(dateStr?: string | null): string {
		if (!dateStr) {
			return '';
		}

		const language = this.user?.Language || 'en_US';
		return (new Date(dateStr)).toLocaleDateString(
			language.replace('_', '-'),
			{
				year: 'numeric',
				month: 'short',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				second: undefined,
			},
		);
	}

	// ##### Lifecycle ##### //

	/**
	 * Retrieves information about the workspace.
	 * @author OK
	 * @returns False if there's no active workspace. True otherwise.
	 */
	public async getWorkspaceInfo(): Promise<boolean> {
		this.workspace = this.$tsStore.workspaces.activeWorkspace;
		if (!this.workspace) {
			return false;
		}

		this.currentDescription = this.workspace.Description;
		this.currentName = this.workspace.Name;

		if (!this.workspace.IsDemo) {
			$assert.Assert(this.user);
			if (this.user) {
				const options = { workspaceUuid: this.workspace.UUID, userUuid: this.user.UUID };
				try {
					// throws HttpError
					this.roleNames = await this.$tsStore.users.getWorkspaceRoleNames(options);
				} catch (error: any) {
					this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_WORKSPACE_ROLES' });
				}
			}

			$assert.Assert(this.workspace.Owner);
			if (this.workspace.Owner && this.$tsStore.users.getWorkspaceAccessControl(this.workspace.UUID).readUser.hasPermission) {
				try {
					// throws HttpError
					this.owner = await this.$tsStore.users.getUser({ workspaceUuid: this.workspace.UUID, uuid: this.workspace.Owner });
					// Prepopulate workspaceAdmins with the owner
					this.workspaceAdmins = [this.owner];
				} catch (error: any) {
					// If NotFoundError, don't show any error message to the user.
					if (error.status !== 404) {
						this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_USERS' });
					}
				}
			}
		}

		return true;
	}

	/**
	 * Gets all users that have the Workspace Admin workspace role.
	 * @author BE
	 * @author OK (original in AuthZ)
	 */
	protected async getWorkspaceAdmins() {
		const workspaceUuid = this.$tsStore.workspaces.activeWorkspace?.UUID;
		if (!workspaceUuid) {
			return [];
		}

		// Get all users with workspace roles
		const query = {
			workspacerolenames: true,
			workspacerolenamesfromgroups: true,
		};
		const allUsers = await this.$tsStore.users.getAllFromWorkspace({workspaceUuid: workspaceUuid, query});

		this.workspaceAdmins = allUsers.filter((user: User) => {
			// Disallow pending users (and in theory also deleted users, but we don't set user.State = 'deleted' yet).
			if (user.State !== 'active') {
				return false;
			}
			// Filter users with the WorkspaceAdmin role
			if (user.WorkspaceRoleNames.includes('Workspace Admin')) {
				return true;
			}
			// Filter users with the WorkspaceAdmin role via group
			for (const groupUuid in user.WorkspaceRoleNamesFromGroups) {
				if (user.WorkspaceRoleNamesFromGroups[groupUuid].includes('Workspace Admin')) {
					return true;
				}
			}
			return false;
		}).sort((a: User, b: User) => {
			// sort() is in-place, but that's fine here because filter() has already created a copy.
			return a.NameAndEmail.localeCompare(b.NameAndEmail);
		});
	}

	/**
	 * Sets the action buttons for the three dots menu.
	 * @author OK
	 */
	public setActionButtons(): void {
		if (!this.workspace || this.workspace.IsDemo) {
			return;
		}

		const utility = new WorkspacePageUtility(this);
		const buttons = utility.buttons().filter((btn) => !btn.hide);
		buttons.forEach((btn) => {
			if (('getHidden' in btn) && btn.getHidden && this.workspace) {
				btn.hide = btn.getHidden(this.workspace);
			}
			if (('click' in btn) && this.workspace) {
				btn.click = btn.click.bind(btn, this.workspace);
			}
		});
		this.itemButtons = buttons;
	}

	protected async mounted() {
		this.$faroLoading.start();
		this.loading = true;
		const workspaceExists = await this.getWorkspaceInfo();
		if (!workspaceExists) {
			this.$router.push({name: 'NotFoundPage'});
		}

		this.setActionButtons();

		this.$tsStore.pages.setFinishedPageLoading(true);
		if (this.$tsStore.pages.finishedMainLoading) {
			this.$faroLoading.stop();
		}
		this.loading = false;
	}
}
