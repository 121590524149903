import { AuthzInterface, AuthzBaseInterface, AuthzType } from '@faroconnect/authz-client';
import { InternationalizationUtils } from '@faroconnect/utils';
import { BaseEntity } from '@/classes/BaseEntity';

type UserClass = 'User';

interface TempUser {
	/**
	 * If the value is set to true it means that the user was created only on the client side
	 * and the server does not know about it because it will be created/invited.
	 */
	isTempUser: boolean;
}

export const defaultUserImage = require('@/assets/account-circle-48.png');

export class User extends BaseEntity implements AuthzInterface.IUser, TempUser {
	public static readonly constructorName = User.name;

	public static fromResponse(json: AuthzInterface.IUser) {
		return new User(json);
	}

	public static forRequest(json: Partial<AuthzInterface.IUser> & Partial<TempUser>) {
		return new User(json);
	}

	/**
	 * See AuthZ.
	 * @param name Name with any case, e.g. 'john' or 'jOHN'.
	 * @returns Name as written usually, e.g. 'John'.
	 */
	public static nameCase(name: string): string {
		const first = name.substring(0, 1);
		const rest = name.substring(1);
		return first.toLocaleUpperCase() + rest.toLocaleLowerCase();
	}

	/**
	 * See AuthZ.
	 * @param email
	 * @returns [firstname, middlename, lastname]
	 */
	public static getNameFromEmail(email: string): string[] {
		const parts = email.split('@');
		// Keep only non-empty parts, to handle some semi-invalid email addresses (leading/trailing split characters).
		const nameParts = (parts[0] || '').split(/[._+-]+/).filter((part) => !!part);

		let firstname = '';
		let middlename = '';
		let lastname = '';

		if (nameParts.length <= 1) {
			// ['John', '', '']
			firstname = nameParts[0] ?? '';
		} else if (nameParts.length === 2) {
			// ['John', '', 'Doe']
			firstname = nameParts[0];
			lastname = nameParts[1];
		} else {
			// ['John', 'Middle X Yz', 'Doe']
			firstname = nameParts[0];
			middlename = nameParts.slice(1, nameParts.length - 1).map(m => User.nameCase(m)).join(' ');
			lastname = nameParts[nameParts.length - 1];
		}

		return [User.nameCase(firstname), middlename, User.nameCase(lastname)];
	}

	public static getNameStringFromEmail(email: string): string {
		return  User.getNameFromEmail(email).filter(el => !!el).join(' ');
	}

	public static isEmailOfDeletedUser(email: string): boolean {
		return !!email.match(/^deleted-[a-f0-9-]{36}@example.com$/);
	}

	public readonly isUser: boolean = true;

	public Class: UserClass = 'User';

	public ImgUrl: string = defaultUserImage;
	public DefaultImgUrl: string = defaultUserImage;

	// User properties. DO NOT USE DEFAULT VALUES, or you might miss the assignment in the constructor
	public FirstName: string;
	public MiddleName: string;
	public LastName: string;
	public Email: string;
	public Company: string;
	public Language: InternationalizationUtils.Language;
	public LengthUnit: InternationalizationUtils.LengthUnit;
	public AreaUnit: InternationalizationUtils.AreaUnit;
	public AngleUnit: InternationalizationUtils.AngleUnit;
	public Country: InternationalizationUtils.CountryCode;
	public City: string;
	public Timezone: InternationalizationUtils.Timezone;
	public DateFormat: InternationalizationUtils.DateFormat;
	public TimeFormat: InternationalizationUtils.TimeFormat;
	public ExperienceProgram: boolean;
	public EmailNotifications: boolean;
	public PageNotifications: boolean;
	public GroupUuids: string[];
	public GroupNames: string[];
	public WorkspaceRoleUuids: string[];
	public WorkspaceRoleNames: string[];
	public WorkspaceRoleNamesFromGroups: { [key: string]: string[] };
	public WorkspaceRoles: AuthzInterface.IWorkspaceRole[];
	public WorkspaceRolesFromGroups: AuthzBaseInterface.WorkspaceRolesFromGroupsI<AuthzInterface.IWorkspaceRole, AuthzInterface.IGroup>;
	public ProjectRoles: AuthzBaseInterface.ProjectRolesMapForUserOrGroupI;
	public StateOrProvince: string;
	public PostalCode: string;
	public Industry: AuthzType.Industry | null;
	public PrimaryApplicationInterest: AuthzType.ApplicationInterest | null;
	public PhoneCountryCode: InternationalizationUtils.CountryCode;
	public PhoneNumber: string | null;
	public ComplianceCheck?: AuthzBaseInterface.ComplianceCheckI;
	public isTempUser: boolean;
	public State: 'active' | 'pending' | 'deleted';
	public SphereXgInfoViewed: boolean;
	public SphereXgInfoClickedLink: boolean;
	public QA: boolean;

	protected constructor(obj: Partial<AuthzInterface.IUser> & Partial<TempUser> ) {
		super({...obj});
		this.FirstName = obj.FirstName ?? '';
		this.MiddleName = obj.MiddleName ?? '';
		this.LastName = obj.LastName ?? '';
		this.Email = obj.Email ?? '';
		this.Company = obj.Company ?? '';
		this.Language = obj.Language ?? InternationalizationUtils.languages.en_US;
		this.LengthUnit = obj.LengthUnit ?? InternationalizationUtils.lengthUnits.m;
		this.AreaUnit = obj.AreaUnit ?? InternationalizationUtils.areaUnits.m;
		this.AngleUnit = obj.AngleUnit ?? InternationalizationUtils.angleUnits.deg;
		this.Country = obj.Country ?? InternationalizationUtils.countryCodes.DE;
		this.City = obj.City ?? '';
		this.PostalCode = obj.PostalCode ?? '';
		this.Timezone = obj.Timezone ?? InternationalizationUtils.timezones['UTC|+01:00|WEST|AmsterdamBerlinBernRomeStockholmVienna'].Id;
		this.DateFormat = obj.DateFormat ?? InternationalizationUtils.dateFormats['DD/MM/YYYY'];
		this.TimeFormat = obj.TimeFormat ?? InternationalizationUtils.timeFormats['HH:MM:SS'];
		this.ExperienceProgram = obj.ExperienceProgram ?? false;
		this.EmailNotifications = obj.EmailNotifications ?? false;
		this.PageNotifications = obj.PageNotifications ?? false;
		this.GroupUuids = obj.GroupUuids ?? [];
		this.GroupNames = obj.GroupNames ?? [];
		this.WorkspaceRoleUuids	= obj.WorkspaceRoleUuids ?? [];
		this.WorkspaceRoleNames = obj.WorkspaceRoleNames ?? [];
		this.WorkspaceRolesFromGroups = obj.WorkspaceRolesFromGroups ?? {};
		this.WorkspaceRoles = obj.WorkspaceRoles ?? [];
		this.WorkspaceRoleNamesFromGroups = obj.WorkspaceRoleNamesFromGroups ?? {};
		this.ProjectRoles = obj.ProjectRoles ?? { ProjectsMap: {}, CollectionsMap: {} };
		this.StateOrProvince = obj.StateOrProvince ?? '';
		this.PostalCode = obj.PostalCode ?? '';
		this.Industry = obj.Industry ?? null;
		this.PrimaryApplicationInterest = obj.PrimaryApplicationInterest ?? null;
		this.PhoneCountryCode = obj.PhoneCountryCode ?? 'DE';
		this.PhoneNumber = obj.PhoneNumber ?? null;
		this.ComplianceCheck = obj.ComplianceCheck;
		this.isTempUser = obj.isTempUser ?? false;
		this.State = obj.State ?? 'active';
		// If the attribute is missing, we probably forgot to deploy AuthZ. Falling back to `true` is safer, so we don't show the dialog every time.
		this.SphereXgInfoViewed = obj.SphereXgInfoViewed ?? true;
		this.SphereXgInfoClickedLink = obj.SphereXgInfoClickedLink ?? false;
		this.QA = obj.QA ?? false;

		// Fill BaseEntity Properties
		this.Name = this.FullName;
		this.Description = '';
	}

	/**
	 * Returns the initial of the middle name in locale upper case.
	 */
	public get MiddleNameInitial(): string {
		return this.MiddleName ? this.MiddleName[0].toLocaleUpperCase() : '';
	}

	/**
	 * Gets the first name plus the initial of the middle name (if available) in one string.
	 * It includes the first name plus the first character of the middle name.
	 */
	public get FirstAndMiddleNameInitial(): string {
		if (this.MiddleName) {
			const sep = this.FirstName ? ' ' : '';
			return this.FirstName + sep + this.MiddleNameInitial;
		} else {
			return this.FirstName;
		}
	}

	/**
	 * Gets the first and middle name in one string.
	 */
	public get FirstAndMiddleName(): string {
		if (this.MiddleName) {
			const sep = this.FirstName ? ' ' : '';
			return this.FirstName + sep + this.MiddleName;
		} else {
			return this.FirstName;
		}
	}

	/**
	 * Gets the first and last name in one string.
	 */
	public get FirstAndLastName(): string {
		if (this.LastName) {
			const sep = this.FirstName ? ' ' : '';
			return this.FirstName + sep + this.LastName;
		} else {
			return this.FirstName;
		}
	}

	/**
	 * Gets the full name of the user, including first, the initial of the middle and last name.
	 */
	public get FullNameShort(): string {
		const sep = this.FirstAndMiddleNameInitial && this.LastName ? ' ' : '';
		return this.FirstAndMiddleNameInitial + sep + this.LastName;
	}

	/**
	 * Gets the full name of the user and his email.
	 */
	public get NameAndEmail(): string {
		return this.FullNameShort ? (this.FullNameShort + ' <' + this.Email + '>') : this.Email;
	}

	/**
	 * Gets the full name of the user, including first, middle and last name.
	 */
	public get FullName(): string {
		const sep = this.FirstAndMiddleName && this.LastName ? ' ' : '';
		return this.FirstAndMiddleName + sep + this.LastName;
	}
}
