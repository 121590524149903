import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { BASE_AUTHZ_API_URL, config } from '@/config';
import { Project, IProject } from '@/classes/authz/Project';
import { ApiResponse } from '@/store/types';
import { ConflictError } from '@faroconnect/utils';
import { faroLocalization } from '@faroconnect/baseui';
import { getErrorMessage } from '@/utils/errorhandler';
import { HttpError } from '@faroconnect/clientbase';
import { getInviteUserProjectConflictErrorHandled } from '@/utils/projecterrors';

export interface AssignProjectRolesUsersAndGroupsQuery {
	userUuids: string[];
	groupUuids: string[];
	projectRolesUuids: string[];
	projectUuid: string;
}

export interface GetAllProjectsQuery {
	withinviters: boolean;
	withsharedate: boolean;
}

/**
 * This service is in charge of CRUD operations to manipulate the Sphere projects in the Authz backend.
 */
export class ProjectService extends BaseService<Project, IProject> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'project',
			apiEndpoint: config.authzApiEndpoint,
			baseApiUrl: BASE_AUTHZ_API_URL,
		}, clientConfig);
	}

	/**
	 * Get's single project using workspace uuid & uuid
	 * @param workspaceUuid project workspace uuid
	 * @param uuid project uuid
	 * @author Shoaib Feda
	 */
	public async getSingleWithWorkspaceUuid<QueryT extends object>(workspaceUuid: string, uuid: string, query?: QueryT): Promise<IProject> {
		return await this.get(this.makeUrl(`${workspaceUuid}\\${uuid}`));
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<IProject> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<IProject[]> {
		const projects: IProject[] = await this.get(this.makeUrl(undefined, query));
		return projects;
	}

	public async create<QueryT extends object>(body?: Project, query?: QueryT): Promise<IProject> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(body?: Partial<IProject>, query?: QueryT): Promise<IProject> {
		const url = this.makeUrl('', query);
		return this.put(url, body);
	}

	public async updateMulti<QueryT extends object>(body?: Project[], query?: QueryT): Promise<IProject[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	public async assignProjectRolesUsersAndGroups(
		workspaceUuid: string,
		query: AssignProjectRolesUsersAndGroupsQuery,
	): Promise<ApiResponse<{Success: boolean}>>  {
		const body = {
			UserUuids: query.userUuids,
			GroupUuids: query.groupUuids,
			ProjectRoleUuids: query.projectRolesUuids,
			CollectionOrProjectUuid: query.projectUuid,
			Target: 'Project',
		};
		try {
			const response = await this.put(this.makeUrl(`${workspaceUuid}/invite/user-and-groups`), body);
			return {
				Success: true,
				Data: response as { Success: boolean },
			};
		} catch (error) {
			let title: string | undefined;
			let messages: string | undefined | string[];

			// It seems that the error could be also packed as an HttpError.
			const httpError: HttpError = error as HttpError;

			if (httpError.name === 'ConflictError') {
				const conflictError: ConflictError = httpError.responseBody;
				({ title, messages } = getInviteUserProjectConflictErrorHandled(conflictError));
				if (title && !messages) {
					messages = ' '; // Don't show "unknown error" if we know what the error is.
				}
			}

			if (!title) {
				title = faroLocalization.i18n.tc('LP_ERR_INVITE_USERS_PROJECT');
				messages = getErrorMessage(error);
			}
			return {
				Success: false,
				Data: {
					ErrorTitle: title,
					ErrorMessages: messages,
				},
				traceId: httpError.traceId,
			};
		}
	}
}
