import Vue from 'vue';
import { Action, Module, Mutation, RegisterOptions } from 'vuex-class-modules';
import { AuthzType } from '@faroconnect/authz-client';
import { IMigration, Migration, MigrationStubI, ProjectExtended } from '@/classes/migrator/Migration';
import { MigratorService } from '../services/MigratorService';
import { BaseModule } from './BaseModule';
import { PageModule } from './PageModule';
import { StatsAggregatedMap, WorkspaceStats } from '@/classes/migrator/WorkspaceStats';
import { config } from '@/config';

type WebshareRegion = AuthzType.WebshareRegion;

/**
 * Store for the Migration objects from the Sphere Migrator service.
 */
@Module
export class MigrationsModule extends BaseModule<Migration> {
	// We can't use `service`, since it would have to be derived from BaseService<Migration>.
	// That wouldn't work well with our approach to have two HttpClients in MigratorService.
	protected readonly service = undefined;
	protected readonly svc = new MigratorService();

	// Map: ErpId -> Group
	public migrationGroupByErpId: Record<string, string> = {};

	constructor(protected pages: PageModule, options: RegisterOptions) {
		super(pages, options, Migration);
	}

	public get filteredItems(): Migration[] {
		return this.itemsList;
	}

	/**
	 * 1) Creates a new Migration object in the Migration Service.
	 * 2) Adds a migration job to the QueueService.
	 * 3) Migration worker takes job and starts the migration.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param migration Migration object stub to create. Attributes to be provided:
	 *         - Class: "Migration"
	 *         - Workspace: Workspace UUID
	 */
	@Action
	public async createMigration({region, migration} : { region: WebshareRegion, migration: Partial<IMigration> }): Promise<Migration> {
		const migrationJson = await this.svc.create(region, migration);
		const createdMigration = Migration.fromResponse(migrationJson);
		this.setItem(createdMigration);
		return createdMigration;
	}

	/**
	 * Gets the requested Migration object from the Migration Service.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 * @param emailBounces a way to know how/if to get the email bounces
	 * 'simple' => is used get the bounces on the first load of migration details page, not on the polling.
	 * 'force' => manual (via button) to get the migration and get/refresh it's email bounces
	 */
	@Action
	public async getMigration({region, uuid, emailBounces} : {
			region: WebshareRegion,
			uuid: string,
			emailBounces?: 'simple' | 'force'
		}): Promise<Migration> {
		const migrationJson = await this.svc.getSingle(region, uuid, emailBounces);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Gets the migrations for a workspace.
	 * @author MF
	 * @param region WebShare region of the server to call.
	 * @param workspaceuuid Workspace UUID.
	 */
	@Action
	public async getMigrationsForWorkspace({region, workspaceuuid, state} : {
			region: WebshareRegion,
			workspaceuuid: string,
			state?: string
		}): Promise<IMigration[]> {
		return await this.svc.getMigrationsForWorkspace(region, workspaceuuid, state);
	}

	/**
	 * Gets the presigned URL from AWS S3 for the log file.
	 * @author MF
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async getMigrationLogFileURL({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<string> {
		const response = await this.svc.getLogFileURL(region, uuid);
		return response.fileUrl;
	}

	/**
	 * Gets all Migration objects from the Migration Service in both regions.
	 * @author OK
	 * @param withSteps Flag to include the large Steps attribute in the returned Migration objects.
	 */
	@Action
	public async getAllMigrations({withSteps} : { withSteps: boolean }): Promise<Migration[]> {
		const migrations: Migration[] = [];
		const migrationsJson = await this.svc.getAll('all', withSteps);

		// Add new migrations:
		for (const migrationJson of migrationsJson) {
			const existingMigration = this.ItemsMap[migrationJson.UUID];
			// Migration.fromResponse() does a lot of work, so better avoid calling it.
			if (existingMigration && existingMigration.UpdateDate === migrationJson.UpdateDate) {
				migrations.push(existingMigration);
			} else {
				const migration = Migration.fromResponse(migrationJson);
				this.setItem(migration);
				migrations.push(migration);
			}
		}

		// Remove deleted migrations:
		const migrationUuidsNew = new Set(migrationsJson.map((m) => m.UUID));
		for (const uuid in this.ItemsMap) {
			if (!migrationUuidsNew.has(uuid)) {
				this.removeItem(uuid);
			}
		}
		return migrations;
	}

	/**
	 * Gets migration stub objects from the Migration Service in both regions.
	 * Only finished migrations with a StartDate within the provided date range are returned.
	 * @author OK
	 * @param dateStart Start date of the migrations to retrieve. Must be a string usable by the Date constructor.
	 * @param dateEnd End date of the migrations to retrieve. Must be a string usable by the Date constructor.
	 */
	@Action
	public async getMigrationStubs({dateStart, dateEnd} : { dateStart: string, dateEnd: string }): Promise<MigrationStubI[]> {
		return await this.svc.getMigrationStubs('all', dateStart, dateEnd, true);
	}

	/**
	 * Continue waiting for the project content migration of a failed/aborted migration.
	 * @author MH
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async continueMigration({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<Migration> {
		const migrationJson = await this.svc.continueMigration(region, uuid);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Requests an update of the project migration status of a failed or aborted migration.
	 * @author MH
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async updateProjectStatus({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<Migration> {
		const migrationJson = await this.svc.updateProjectStatus(region, uuid);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Publishes the migrated HoloBuilder company for public use.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async publish({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<Migration> {
		const migrationJson = await this.svc.publish(region, uuid);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Unpublishes the published HoloBuilder company to make it private again.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async unpublish({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<Migration> {
		const migrationJson = await this.svc.unpublish(region, uuid);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Rejects the unpublished migration by setting its state to error.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async reject({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<Migration> {
		const migrationJson = await this.svc.reject(region, uuid);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Approves the failed migration by setting its state to unpublished.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async approve({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<Migration> {
		const migrationJson = await this.svc.approve(region, uuid);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Aborts the job of a migration and sets the migration status to "aborted".
	 * @author BE
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async abort({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<Migration> {
		const migrationJson = await this.svc.abort(region, uuid);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Deletes the migration and the associated HoloBuilder company.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	@Action
	public async deleteMigration({region, uuid} : { region: WebshareRegion, uuid: string }): Promise<void> {
		await this.svc.deleteMigration(region, uuid);
		this.removeItem(uuid);
	}

	/**
	 * Sets the read-only state of the migration's workspace.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuidWorkspace Workspace UUID.
	 * @param readOnly New read-only state.
	 */
	@Action
	public async setReadOnly({region, uuidWorkspace, readOnly} : { region: WebshareRegion, uuidWorkspace: string, readOnly: boolean }): Promise<void> {
		await this.svc.setReadOnly(region, uuidWorkspace, readOnly);
	}

	/**
	 * Enables or disables the XG redirect for the migration and workspace.
	 * Enabling also sets the workspace to readonly.
	 * @author MH
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 * @param readOnly True to enable the redirect, false to disable.
	 */
	@Action
	public async setXgRedirect({region, uuid, xgRedirect} : { region: WebshareRegion, uuid: string, xgRedirect: boolean }): Promise<Migration> {
		const migrationJson = await this.svc.setXgRedirect(region, uuid, xgRedirect);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Enables or disables the auto-publish feature for the migration and workspace.
	 * Enabling it will publish the migration on success and enable the XG redirect flag.
	 * @author BE
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 * @param autoPublish True to enable.
	 */
	@Action
	public async setAutoPublish({region, uuid, autoPublish} : { region: WebshareRegion, uuid: string, autoPublish: boolean }): Promise<Migration> {
		const migrationJson = await this.svc.setAutoPublish(region, uuid, autoPublish);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Adds QA users, with a FARO email address, to a migration.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 * @param emails Email addresses.
	 */
	@Action
	public async addQAUsers({region, uuid, emails} : { region: WebshareRegion, uuid: string, emails: string[] }): Promise<Migration> {
		const migrationJson = await this.svc.addQAUsers(region, uuid, emails);
		const migration = Migration.fromResponse(migrationJson);
		this.setItem(migration);
		return migration;
	}

	/**
	 * Resets the WorkerLock table to its initial state.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 */
	@Action
	public async resetLockTable(region: WebshareRegion): Promise<void> {
		await this.svc.resetLockTable(region);
	}

	/**
	 * Gets the projects from the Migrator that can potentially be migrated.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuidWorkspace Workspace UUID.
	 */
	@Action
	public async getProjectsForMigration({region, uuidWorkspace} : { region: WebshareRegion, uuidWorkspace: string }): Promise<ProjectExtended[]> {
		return await this.svc.getProjectsForMigration(region, uuidWorkspace);
	}

	/**
	 * Gets the stats (StatsProjects and StatsAggregated) for a single workspace.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuidWorkspace Workspace UUID.
	 */
	@Action
	public async getWorkspaceStats({region, uuidWorkspace} : { region: WebshareRegion, uuidWorkspace: string }): Promise<WorkspaceStats> {
		const statsJson = await this.svc.getWorkspaceStats(region, uuidWorkspace);
		const statsObj = WorkspaceStats.fromResponse(statsJson);
		return statsObj;
	}

	/**
	 * Gets aggregated stats (StatsAggregated) for all workspaces.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 */
	@Action
	public async getAggregatedStats({region} : { region: WebshareRegion }): Promise<StatsAggregatedMap> {
		const statsAggregated = await this.svc.getAggregatedStats(region);
		return statsAggregated;
	}

	/**
	 * Gets aggregated stats (StatsAggregated) for all workspaces, for both regions.
	 * @author OK, MH
	 */
	@Action
	public async getAggregatedStatsGlobal(): Promise<StatsAggregatedMap> {
		const statsObjsEU = config.migratorApiEndpoints['eu'] ? await this.getAggregatedStats({ region: 'eu' }) : {};
		const statsObjsUS = config.migratorApiEndpoints['us'] ? await this.getAggregatedStats({ region: 'us' }) : {};
		return {
			...statsObjsEU,
			...statsObjsUS,
		};
	}

	@Action
	public async getMigrationGroups(): Promise<void> {
		if (Object.keys(this.migrationGroupByErpId).length > 0) {
			return;
		}

		const migrationGroupsObj = await this.svc.getMigrationGroups();
		const { migrationGroups } = migrationGroupsObj;

		const migrationGroupByErpId: Record<string, string> = {};
		for (const group in migrationGroups) {
			for (const erpId of migrationGroups[group]) {
				migrationGroupByErpId[erpId] = group;
			}
		}
		this.setMigrationGroupByErpId(migrationGroupByErpId);
	}

	@Mutation
	public setItem(migration: Migration) {
		Vue.set(this.ItemsMap, migration.UUID, migration);
	}

	@Mutation
	public removeItem(uuid: string) {
		Vue.delete(this.ItemsMap, uuid);
	}

	@Mutation
	protected setMigrationGroupByErpId(migrationGroupByErpId: Record<string, string>) {
		for (const erpId in migrationGroupByErpId) {
			Vue.set(this.migrationGroupByErpId, erpId, migrationGroupByErpId[erpId]);
		}
	}
}
