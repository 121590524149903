import Vue from 'vue';
import { Action, Module, Mutation, RegisterOptions } from 'vuex-class-modules';
import { Project } from '@/classes/authz/Project';
import { IWorkspace, Workspace } from '@/classes/authz/Workspace';
import { config } from '@/config';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import { PageModule } from '@/store/modules/PageModule';
import { SubscriptionWorkspaceService } from '@/store/services/SubscriptionWorkspaceService';
import { AuthzWorkspaceService, GetAllWorkspacesQuery } from '../services/authz/AuthzWorkspaceService';
import { $assert } from '@faroconnect/utils';

/**
 * Gets all active workspaces from the Subscription Service for the Sphere migration triggered by FARO developers.
 */
@Module
export class MigrationWorkspaceModule extends BaseFilterModule<Workspace> {
	// ###################################### Properties ######################################

	protected readonly service = new AuthzWorkspaceService({});
	protected readonly subscriptionService = new SubscriptionWorkspaceService(config.subscriptionApiEndpoint);

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions) {
		super(pages, options, Workspace);
	}

	// ###################################### Getters ######################################

	public get projectsForFilterList(): Project[] {
		// Since there are no projects in the Migration Dashboard page, just return an empty array.
		return [];
	}
	public get filterByProjectSourceOptions() {
		// Since there are no project sources in the Migration Dashboard page, just return an empty array.
		return [];
	}
	public get filterByProjectStatusOptions() {
		// Since there are no project statuses in the Migration Dashboard page, just return an empty array.
		return [];
	}

	// ###################################### Actions ######################################

	/**
	 * Gets all active workspaces from AuthZ and the Subscription Service, including those the requesting user is not
	 * a member of. The caller needs to filter them as desired.
	 * @author OK
	 */
	@Action
	public async getAll(): Promise<void> {
		// Make all requests in parallel, to speed things up.
		const subscriptionWorkspacesPromise = this.subscriptionService.getAllWorkspaceOverviews();
		const subscriptionWorkspacesFeaturesPromise = this.subscriptionService.getWorkspacesFeatures();
		const authzWorkspacesPromise = this.service.getAllAdmin<GetAllWorkspacesQuery>({
			withinviters: false, withsharedate: false, omitdeactivated: true,
		});

		let [subscriptionWorkspaces, subscriptionWorkspacesFeatures, authzWorkspaces] =
			await Promise.all([subscriptionWorkspacesPromise, subscriptionWorkspacesFeaturesPromise, authzWorkspacesPromise]);
		console.log(`Received ${subscriptionWorkspaces.length} workspaces from Subscription Service. ` +
			`Received ${subscriptionWorkspacesFeatures.length} workspaces with features from Subscription Service. ` +
			`Received ${authzWorkspaces.length} workspaces from AuthZ.`);
		// We omit pending, deactivated and deleted workspaces.
		subscriptionWorkspaces = subscriptionWorkspaces.filter((w) => (w.State === 'active'));
		// We omit workspaces with incorrect properties.
		if (!config.webShareEU) {
			subscriptionWorkspaces = subscriptionWorkspaces.filter((w) => (w.Region === 'us'));
		}

		const subMap: { [key: string]: IWorkspace } = {};
		for (const sw of subscriptionWorkspaces) {
			subMap[sw.UUID] = sw;
			const swf = subscriptionWorkspacesFeatures.find((w: any) => w.WorkspaceId === sw.UUID);
			subMap[sw.UUID].Features = swf?.Features || null;
			$assert.Array(swf?.Features as string[], `Could not get features for workspace ${sw.UUID}`);
		}

		const workspacesForMigration: Workspace[] = [];

		for (const aw of authzWorkspaces) {
			const sw = subMap[aw.UUID];
			if (sw) {
				const workspace = Workspace.fromResponse(aw);
				const subWorkspace = Workspace.fromResponse(sw);
				workspace.merge(subWorkspace);
				if (this.isWorkspaceForMigration(workspace)) {
					workspacesForMigration.push(workspace);
				}
			}
		}

		this.addItems(workspacesForMigration);
	}

	/**
	 * Gets workspaces from AuthZ and updates the following attributes as required by the Migration Dashboard:
	 *  * WebshareDomainName
	 *  * ReadOnly
	 *  * XgRedirect
	 * New workspaces not already in ItemsMap are ignored.
	 * !!! Make sure that getAll has been called before !!!
	 * @author OK
	 */
	@Action
	public async updatePropertiesFromAuthZ(): Promise<void> {
		const authzWorkspaces = await this.service.getAllAdmin<GetAllWorkspacesQuery>({
			withinviters: false, withsharedate: false, omitdeactivated: true,
		});

		for (const aw of authzWorkspaces) {
			const existingWorkspace = this.ItemsMap[aw.UUID];
			if (existingWorkspace && (existingWorkspace.ReadOnly !== aw.ReadOnly || existingWorkspace.XgRedirect !== aw.XgRedirect)) {
				const properties: Partial<IWorkspace> = {
					WebshareDomainName: aw.WebshareDomainName, // Not sure why we need to update this?
					ReadOnly: aw.ReadOnly,
					XgRedirect: aw.XgRedirect,
				};
				this.updateWorkspace({ workspaceUuid: aw.UUID, properties });
			}
		}
	}

	/**
	 * Gets a single workspace from AuthZ and the Subscription Service.
	 * @author OK
	 */
	@Action
	public async getSingle(uuid: string): Promise<void> {
		const subscriptionPromise = this.subscriptionService.getSingle(uuid);
		const authzPromise = this.service.getSingleAdmin(uuid);
		const [subscriptionResponse, authzResponse] = await Promise.all([subscriptionPromise, authzPromise]);
		this.addItems([
			Workspace.fromResponse(subscriptionResponse),
			Workspace.fromResponse(authzResponse),
		]);
	}

	// ###################################### Mutations ######################################

	@Mutation
	public addItems(items: Workspace[]): void {
		for (const item of items) {
			const oldValue = this.ItemsMap[item.UUID];

			// If new Workspace no merge required
			if (oldValue === undefined) {
				Vue.set(this.ItemsMap, item.UUID, item);
				continue;
			}

			item.merge(oldValue);
			Vue.set(this.ItemsMap, item.UUID, item);
		}
	}

	@Mutation
	public removeItem(workspaceUuid: string): void {
		Vue.delete(this.ItemsMap, workspaceUuid);
	}

	/**
	 * Updates the workspace in this.ItemsMap by calling its updateProperties method.
	 * @author OK
	 */
	@Mutation
	public updateWorkspace({ workspaceUuid, properties }: { workspaceUuid: string, properties: Partial<IWorkspace> }): void {
		if (this.ItemsMap[workspaceUuid]) {
			this.ItemsMap[workspaceUuid].updateProperties(properties);
		}
	}

	@Mutation
	public setReadOnlyState(payload: { workspaceUuid: string, readOnly: boolean, xgRedirect: boolean }): void {
		const item = this.ItemsMap[payload.workspaceUuid];
		if (item) {
			item.ReadOnly = payload.readOnly;
			item.XgRedirect = payload.xgRedirect;
			Vue.set(this.ItemsMap, item.UUID, item);
		}
	}

	// ###################################### Helper Methods ######################################

	/**
	 * Filters a list of workspaces by keeping only the ones whose name or description match the search text.
	 * @param workspaces Original workspace list.
	 * @param searchTxt Search text.
	 * @returns Filtered workspace list.
	 */
	protected filterByTextItems(workspaces: Workspace[], searchTxt: string): Workspace[] {
		searchTxt = searchTxt.toLowerCase();
		return workspaces.filter((workspace) =>
			workspace.Name.toLowerCase().includes(searchTxt) ||
			workspace.Description.toLowerCase().includes(searchTxt),
		);
	}

	/**
	 * Returns true if the workspace can potentially be migrated.
	 * @author OK
	 */
	protected isWorkspaceForMigration(workspace: Workspace): boolean {
		// The demo workspace exists on PRD, but is also shown on DEV and STG. Better remove there.
		return (!workspace.IsDemo || (workspace.IsDemo && config.env === 'prd')) &&
			!!workspace.Region &&
			!!config.migratorApiEndpoints[workspace.Region] &&
			!!workspace.Subscription &&
			workspace.Subscription.type !== 'S_TERMINATED' &&
			workspace.Subscription.type !== 'S_UNITTEST';
	}
}
