import Vue from 'vue';
import { config } from '@/config';
import router from '@/router/router';
import { AuthZClient, Generated } from '@faroconnect/authz-client';
import { Dictionary } from 'vue-router/types/router';
import { submitAuth0LoginForm } from './auth0';

/**
 * Class for those tasks that show the user that something is pending, e.g. missing compliance check or email verification.
 */
export class PendingTask {
	constructor(
		private vue: Vue,
		private data: {
			email: string | null,
			token: string | null,
			auth0Domain: string | null,
			auth0State: string | null,
			method: Extract<keyof Generated.UserService, 'complianceCheckApproved' | 'checkEmailVerified'>,
			query: Dictionary<string | Array<string | null>>,
		},
	) {}

	/**
	 * Checks whether the user is no longer pending and if so, executes the onContinuePostLogin method in Auth0
	 * by posting a form containing the Auth0 state and domain.
	 * @returns A promise that resolves after the check is done and the user was redirected if applies.
	 */
	public async redirectIfNoLongerPending() {
		if (!this.data.email || !this.data.token) {
			return;
		}
		try {
			// Use random values in apiId and apiKey. Those values are required but the checkEmailValidated route requires no authentication.
			const authzClient = new AuthZClient(config.authzApiEndpoint, { apiId: 'empty', apiKey: 'empty' });
			const result = await authzClient.user[this.data.method](this.data.email, {
				VerifiedEmailToken: this.data.token,
			});
			if (
				('ComplianceCheckApproved' in result && result.ComplianceCheckApproved) ||
				('EmailVerified' in result && result.EmailVerified)
			) {
				if (!this.data.auth0Domain || !this.data.auth0State) {
					await this.vue.$auth.loginWithRedirect();
				} else {
					// If you got the domain and state from the url you can continue with the login process
					// That way we don't annoy the customer by having to login again
					await submitAuth0LoginForm({
						auth0Domain: this.data.auth0Domain,
						auth0State: this.data.auth0State,
					});
				}
			// Check if the compliance check was not approved because it was denied.
			} else if ('ComplianceCheck' in result && result.ComplianceCheck === 'denied') {
				// In that case a simple redirection inside the SPA is enough.
				void router.replace({
					name: 'FailedValidationPage',
					// Keep the query so we can continue the login
					query: this.data.query,
				});
			}
		} catch (error) {
			console.error(error);
			// Do nothing since it is not a critical workflow
		}
	}
}
