
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { AuthzInterface, AuthzType } from '@faroconnect/authz-client';
import { StringUtils } from '@faroconnect/utils';
import { validateWorkspaceName, validateWorkspaceDescription } from '@/utils/validate';
import { config } from '@/config';
import UpdateOrCreateWorkspaceTaskBase, { FormData } from '@/components/Tasks/UpdateOrCreateWorkspaceTaskBase.vue';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { UserBtnTaskTopRightComponentData } from '@/utils/types';
import { BaseServiceAny } from '@/store/services/BaseServiceAny';
import { InitData } from '@/components/Tasks/UpdateOrCreateWorkspaceTask';
import { Workspace } from '@/classes/authz/Workspace';

@Component({
	components: {
		UpdateOrCreateWorkspaceTaskBase,
	},
})
export default class UpdateOrCreateWorkspaceTask extends Vue {
	@Prop(Object) public readonly initData!: InitData;

	public readonly lpUrl = window.location.origin;
	public loading: boolean = false;
	public readonly regions = [...config.webShareRegions];
	public form: FormData = {
		name: this.initData.workspace?.Name! ?? null,
		region: this.initData.workspace?.Region! ?? null,
		description: this.initData.workspace?.Description! ?? null,
		license: true,
	};
	public topRightComponent = UserBtn;
	public topRightComponentData: UserBtnTaskTopRightComponentData = { isFullscreenTask: true };
	public wsNameError = '';

	public get isFullscreen(): boolean {
		return this.initData.isFullscreen;
	}

	public get isUpdateMode(): boolean {
		return !!this.initData.workspace;
	}

	public get isCompleteMode(): boolean {
		return this.isUpdateMode && this.initData.workspace?.State === 'pending';
	}

	public get title(): string {
		if (this.isCompleteMode) {
			return this.$tc('LP_COMPLETE_WORKSPACE_CREATION');
		} else if (this.isUpdateMode) {
			return this.$tc('LP_EDIT_WORKSPACE');
		}
		return this.$tc('LP_CREATE_WORKSPACE');
	}

	public get subtitle(): string {
		if (this.isCompleteMode) {
			return this.$tc('LP_FINALIZE_WORKSPACE');
		}
		if (this.isUpdateMode) {
			return '';
		}
		if (this.isFullscreen) {
			return this.$tc(this.initData.skippable ? 'LP_WORKSPACE_SETUP_OPTIONAL' : 'LP_WORKSPACE_SETUP');
		}
		return '';
	}

	public get subtitle2(): string {
		if (!this.isCompleteMode && !this.isUpdateMode && this.isFullscreen && !this.initData.skippable) {
			return this.$tc('LP_COMPANY_WORKSPACE_INVITE'); // Should be shown exactly if LP_WORKSPACE_SETUP is shown.
		}
		return '';
	}

	public get subscriptionDisplayName(): string {
		const subType = this.initData.workspace?.Subscription?.type;
		if (subType) {
			return StringUtils.subscriptionDisplayName(subType);
		} else {
			return '';
		}
	}

	public get subscriptionStartDate(): string {
		const startDate = this.initData.workspace?.Subscription?.startDate;
		if (startDate) {
			return startDate.substring(0, 10);
		} else {
			return '';
		}
	}

	public get okText(): string {
		if (this.isCompleteMode) {
			return this.$tc('UI_CREATE');
		} else if (this.isUpdateMode) {
			return this.$tc('UI_UPDATE');
		}
		return this.$tc('UI_CREATE');
	}

	public get hasChanges(): boolean {
		const ws = this.initData.workspace;
		if (ws) {
			return ws.Name !== this.form.name ||
				ws.Region !== this.form.region ||
				ws.Description !== this.form.description;
		}
		return !!this.form.name || !!this.form.region || !!this.form.description;
	}

	public get taskReady(): boolean {
		if (!this.hasChanges) {
			return false;
		}
		if (!validateWorkspaceName(this.form.name, this.$tsStore.users.isStrictFaroUser ?? false)) {
			return false;
		}
		if (this.wsNameError !== '') {
			return false;
		}
		if (!validateWorkspaceDescription(this.form.description)) {
			return false;
		}
		if (!this.validateWorkspaceRegion(this.form.region)) {
			return false;
		}
		return true;
	}

	public onWsNameError(msg: string) {
		this.wsNameError = msg;
	}

	public getWorkspaceLink(workspaceUuid: string): string {
		return `${config.landingpageUI}/workspaces?wid=${workspaceUuid}`;
	}

	public async created() {
		this.loading = true;
		await BaseServiceAny.getTokenSilentlyWithRedirect();
		this.loading = false;
	}

	public async closeTask() {
		if (this.isFullscreen) {
			await this.$faroTaskService.closeFullscreenTask();
		} else {
			await this.$faroTaskService.closeTask();
		}
		this.$faroComponents.$emit('close-create-workspace-task');

		// After creating the initial workspace, make sure that the user can see it (as spinner / creation in progress).
		// The previous behavior was to show the projects of the demo workspace, with no hint about the new workspace.
		if (this.isFullscreen) {
			this.$router.push({ name: 'SelectWorkspacePage', params: { includeActive: 'true' } });
		}
	}

	public async completeWorkspace() {
		if (this.initData.workspace && this.initData.workspace.State === 'pending') {
			try {
				await this.$tsStore.workspaces.completeWorkspace({
					UUID: this.initData.workspace.UUID,
					Description: this.form.description ?? '',
					Name: this.form.name!,
					Region: this.form.region! as AuthzType.WebshareRegion,
				} as Workspace);
			} catch (error) {
				this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_UPDATE_WORKSPACE' });
			} finally {
				await this.closeTask();
			}
		} else {
			throw new Error('completeWorkspace should only be called when initData.workspace is set and workspace state is "pending".');
		}
	}

	public async updateWorkspace() {
		if (this.initData.workspace) {
			try {
				const updateData: Partial<AuthzInterface.IWorkspace> = {
					UUID: this.initData.workspace.UUID,
				};
				if (this.form.name !== this.initData.workspace.Name) {
					updateData.Name = this.form.name;
				}
				if (this.form.description !== this.initData.workspace.Description) {
					updateData.Description = this.form.description;
				}
				await this.$tsStore.workspaces.updateSingle(updateData as Workspace);
			} catch (error) {
				this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_UPDATE_WORKSPACE' });
			} finally {
				await this.closeTask();
			}
		} else {
			throw new Error('updateWorkspace should only be called when initData.workspace is set.');
		}
	}

	public async createWorkspace() {
		try {
			await this.$tsStore.workspaces.create(
				Workspace.forRequest({
					Name: this.form.name,
					Description: this.form.description ?? '',
					Region: this.form.region! as AuthzType.WebshareRegion,
					License: '', // TODO: no longer used in subscription service, remove from authz? https://faro01.atlassian.net/browse/FC-4634
				}),
			);
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_CREATE_WORKSPACE' });
		} finally {
			await this.closeTask();
		}
	}

	public async leaveIfComplianceCheckFailed() {
		const callingUser = await this.$tsStore.users.readCallingUser({ compliancecheck: true });
		if (callingUser.ComplianceCheck?.ComplianceCheck === 'denied') {
			// TODO for now we can't close the task because once closed the following code would not be executed.
			// Implementation idea: Adapt BaseUI closeFullscreenTask method to accept and optional callback as parameter
			// to be executed after the task has been closed.
			// The solution for now is to close the task when you instanciate ComplianceCheckTask.
			await this.$router.replace({
				name: 'FailedValidationPage',
				query: {
					Email: callingUser?.Email ?? '',
					FirstName: callingUser.FirstName ?? '',
					LastName: callingUser.LastName ?? '',
				},
			});
			// This code should not be reached because of the previous redirect, but just in case to prevent to continue with the workspace creation.
			throw new Error('Denied compliance check');
		}
	}

	public async ok() {
		this.loading = true;

		if (this.isCompleteMode) {
			// Before completing a workspace make sure to get a new Auth0 token in case the compliance check was rejected
			// while the current token is still active.
			await this.leaveIfComplianceCheckFailed();
			await this.completeWorkspace();
		} else if (this.isUpdateMode) {
			// Since users can update a workspace as many times as they want better we don't get a new Auth0 toke
			// to limit the amount of requests to Auth0.
			await this.updateWorkspace();
		} else {
			// Before creating a workspace make sure to get a new Auth0 token in case the compliance check was rejected
			// while the current token is still active.
			await this.leaveIfComplianceCheckFailed();
			await this.createWorkspace();
		}
		this.loading = false;
	}

	public async cancel() {
		if (this.initData.isFullscreen && this.initData.skippable) {
			this.loading = true;
			try {
				await this.$tsStore.settings.updateSkipWorkspaceCreationDialog(true);
			} catch (error) {
				// Don't display an error. Failing to update this setting is not critical and does not affect the user experience.
				console.error(error);
			} finally {
				this.loading = false;
			}
		}
		this.closeTask();
	}

	public back() {
		this.closeTask();
		this.$faroComponents.$emit('select-pending-workspace');
	}

	@Watch('form.name')
	public transformInput() {
		// Make life easier for the user by converting the name to lowercase (since uppercase is not allowed).
		this.form.name = this.form.name?.toLowerCase();
	}

	public readonly validateWorkspaceRegion = (region?: string) => region && this.regions.includes(region as AuthzType.WebshareRegion);
}
