import Vue from 'vue';

/**
 * Submits the form to Auth0.
 * This allows that the user will continue with the login process and therefore will be redirected to
 * the page he was trying to access before being redirected to the signup form.
 */
export async function submitAuth0LoginForm(payload: { auth0Domain: string, auth0State: string }) {
	try {
		// Fakes a form to call the onContinuePostLogin method on Auth0.
		// Can't be done using axios.post due to CORS, and also it wouldn't trigger a page navigation.
		const form = document.createElement('form');
		form.method = 'POST';
		if (!payload.auth0Domain || !payload.auth0State) {
			// Use the fallback redirection.
			throw new Error('Missing Auth0 parameters');
		}
		form.action = `https://${payload.auth0Domain}/continue?state=${payload.auth0State}`;
		document.body.appendChild(form);
		// Await a tick to make sure that the form is ready in the DOM.
		await Vue.nextTick();
		form.submit();
	} catch (error) {
		console.error(error);
		// If it fails to create the form for whatever reason, then just redirect to the landing page.
		const origin = window.location.origin; // this will return for example http://localhost
		const endpoint = Vue.prototype.$router.resolve('/').href; // this will return for example /authz/faro
		window.location.assign(origin + endpoint);
	}
}
