
import axios, { AxiosRequestConfig } from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { $assert } from '@faroconnect/utils';
import { ImageSize } from '@/utils/types';
import { HintMsg } from '@/utils/types';
import ClickableLabel from '@/components/Embeddable/ClickableLabel.vue';

// https://css-tricks.com/snippets/html/base64-encode-of-1x1px-transparent-gif/
// When auth = true, we first set this image to trigger the onLoad() callback.
// This allows us to leverage the lazy loading implemented in v-img.
// An alternative would be to first try to load the image without auth, and add auth in onError().
// But this approach would result in lots of HTTP 401 responses, would be slower and could lead to monitoring alerts.
const GIF_1X1_TRANSPARENT = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

/**
 * Wrapper for the vImg component.
 * It includes the possibility to add a default image, in case it fails to load the original image.
 * It also supports loading images with Bearer token authentication.
 */
@Component({
	components: {
		ClickableLabel,
	},
})
export default class LpImg extends Vue {
	@Prop(Boolean) public readonly contain!: boolean;
	@Prop(String) public readonly height!: string;
	/** If true, use the Auth0 token to load the image. */
	@Prop(Boolean) public readonly auth!: boolean;
	@Prop(String) public readonly src!: string;
	@Prop(String) public readonly dataSrc!: string;
	@Prop(String) public readonly defaultSrc!: string;
	// Custom hint message to be placed on the top left corner of the image.
	@Prop(Object) public readonly hintMsg!: HintMsg | null;
	@Prop(Boolean) public readonly clickable!: boolean;
	@Prop(String) public readonly imageSize!: ImageSize;
	/**
	 * Used for the project image to display a "Change thumbnail" overlay when the mouse hovers over it.
	 */
	@Prop(Boolean) public readonly overlay!: boolean;
	/**
	 * The URL of a project image doesn't change when the image gets changed, so we need to trigger the
	 * UI element to reload another way.
	 */
	@Prop(String) public readonly reloadRandom!: string;

	public internalSrc = this.useAuth() ? GIF_1X1_TRANSPARENT : this.src;
	// Store value, to ensure consistency in case that some props change later.
	public usingAuth = this.useAuth();

	public async onLoad() {
		this.load(/*force*/ false);
	}

	/**
	 * Loads the image from this.src if authentication is enabled.
	 * @param force Force reloading the image even if already loaded.
	 */
	public async load(force: boolean) {
		if (force) {
			// Make sure to base the decision on the latest URL.
			this.usingAuth = this.useAuth();
		}
		if (!force && (!this.usingAuth || this.internalSrc !== GIF_1X1_TRANSPARENT)) {
			// Nothing to do: We already loaded the final image.
			return;
		}

		// We only loaded the dummy image GIF_1X1_TRANSPARENT so far.
		// Now try to load the real image with the Auth0 Bearer token.
		try {
			// To avoid that the browser continues to use the cached project image after its exchange,
			// append a random UUID to the request.
			// For the the default project image, this should not be required, since it already includes a hash in the filename.
			let url = this.src;
			if (this.reloadRandom && !url.includes('defaultProjectImage')) {
				url += (url.includes('?') ? '&r=' : '?r=' ) + this.reloadRandom;
			}

			// In this case (with force === true), we don't need authentication. Just apply the new URL.
			// 1. Make demo project image load correctly on localhost.
			// 2. Make loading the default image more efficient, allowing the browser to load it from cache.
			if (!this.usingAuth) {
				this.internalSrc = url;
				return;
			}

			const $tsStore: $tsStore = Vue.prototype.$tsStore;
			const token = await $tsStore.users.getTokenSilently();
			const options: AxiosRequestConfig = {
				responseType: 'blob',
			};
			if (token) {
				options.headers = { Authorization: `Bearer ${token}` };
			}
			// Possible alternative: Use responseType: 'arraybuffer'.
			const response = await axios.get(url, options);
			// We get a Blob object in response.data.
			// The blobUrl looks e.g. like this: 'blob:http://localhost:4804/c1318dc5-386e-45f8-a0ac-03ab3a940b2c'
			const blobUrl = window.URL.createObjectURL(response.data);
			this.internalSrc = blobUrl;
		} catch (e) {
			// Either we don't have a Bearer token, or the Axios request failed.
			// We don't get into onError() in this case.
			$assert.Assert(this.defaultSrc, 'No defaultSrc set for image! Check your object\'s DefaultImgUrl property. ' + this.src);
			this.internalSrc = this.defaultSrc;
		}
	}

	public useAuth(): boolean {
		if (!this.auth || !this.src) {
			return false;
		}

		// Could be useful later: Authentication isn't needed when a data URL or blob URL is provided.
		// if (this.src.startsWith('data:') || this.src.startsWith('blob:')) {
		// 	return false;
		// }

		// Make project images work on localhost.
		if (this.src.includes('/data/project') && this.src.includes('project-image')) {
			return true;
		}

		// Make default images load more efficiently, without generating a 'blob:' URL.
		if (this.src.includes('defaultProjectImage')) {
			return false;
		}

		// We must not use Bearer token authentication for images loaded from the frontend,
		// since this breaks the Basic authentication that we use in Dev and Staging.
		// -> Enable auth only for absolute URLs that don't start with location.origin (= frontend base URL).
		return (this.src.startsWith('http:') || this.src.startsWith('https:')) && !this.src.startsWith(location.origin + '/');
	}

	@Watch('reloadRandom')
	public async onReloadRandom() {
		this.load(/*force*/ true);
	}
}
