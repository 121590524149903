
import Vue from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { AuthzConstant, AuthzInterface, AuthzReq, AuthzType, SalesforceUtils } from '@faroconnect/authz-client';
import { countryNames } from '@faroconnect/baseui';
import { $assert, BadRequestError, InternationalizationUtils, UuidUtils } from '@faroconnect/utils';
import {
	validateEnum,
	validateString,
	validateStringIfDefined,
	validateUuid,
	validatePhoneNumber,
	queryToString,
	queryToBool,
} from '@/utils/validate';
import { getErrorMessage } from '@/utils/errorhandler';
import { UserBtnTaskTopRightComponentData, WithUserRegistrationToken } from '@/utils/types';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { submitAuth0LoginForm } from '@/utils/auth0';
import { User } from '@/classes/authz/User';
import * as UserValidation from '@/utils/uservalidation';
import * as UserUtils from '@/utils/userutils';
import { setLanguageLikeBrowser } from '@/utils/browser';

@Component
export default class SignupTask extends Vue {
	// ##################################### Data #####################################

	// Constants

	public readonly TOP_RIGHT_COMPONENT = UserBtn;
	public readonly MIN_CHARS = UserValidation.MIN_CHARS;
	public readonly MIN_NAME_CHARS = UserValidation.MIN_NAME_CHARS;
	public readonly MAX_CHARS = UserValidation.MAX_CHARS;
	public readonly lpUrl = window.location.origin;

	// Variables

	public loading = false;
	public isFormValid = false;
	public error: string | null | false = false;
	public registrationToken: string | null = null;
	public redirectTo: string | null = null;
	public auth0Domain: string = '';
	public auth0State: string = '';
	// Flag whether it is the first time that the user logs in.
	// If true it means that the user hasn't signed up for the first time
	// If false it means that the user already signed up but due to changes in the requirements some fields are missing.
	public firstSignup: boolean = true;
	public isValidated: boolean = false;
	public showPostalCode: boolean = true;

	// Form data

	public firstName: string = '';
	public middleName: string = '';
	public lastName: string = '';
	public email: string = '';
	public language: InternationalizationUtils.Language | '' = '';
	public company: string = '';
	public phoneNumber: string = '';
	public postalCode: string = '';
	public country: InternationalizationUtils.CountryCode | '' = '';
	public stateOrProvince: UserUtils.StateOrProvince | '' = '';
	public industry: AuthzType.Industry | '' = '';
	public primaryApplicationInterest: AuthzType.ApplicationInterest | '' = '';
	public phoneCountryCode: InternationalizationUtils.CountryCode | null = null;
	public experienceProgram: boolean = false;
	public sanitizedFirstName: string = '';
	public sanitizedMiddleName: string = '';
	public sanitizedLastName: string = '';
	public sanitizedCompany: string = '';
	public sanitizedPhoneNumber: string = '';
	public sanitizedPostalCode: string = '';

	// Binded methods
	public firstNameRule = UserValidation.firstNameRule;
	public middleNameRule = UserValidation.middleNameRule;
	public lastNameRule = UserValidation.lastNameRule;
	public uiEmailRule = UserValidation.uiEmailRule;
	public companyRule = UserValidation.companyRule;
	public zipOrPostalCodeRequiredRule = UserValidation.zipOrPostalCodeRequiredRule;
	public zipOrPostalCodeNotRequiredRule = UserValidation.zipOrPostalCodeNotRequiredRule;
	public phoneNumberRule = UserValidation.phoneNumberRule;
	public phoneCountryCodeRule = UserValidation.phoneCountryCodeRule;
	public languageRule = UserValidation.languageRule;
	public countryRule = UserValidation.countryRule;
	public industryRule = UserValidation.industryRule;
	public primaryApplicationInterestRule = UserValidation.primaryApplicationInterestRule;
	public requiredSelectedRule = UserValidation.requiredSelectedRule;

	// ##################################### Getters #####################################

	public get colClassLeft(): string {
		return this.$vuetify.breakpoint.smAndUp ? 'reCol-right-2cols' : 'reCol-right-1col';
	}

	public get colClassRight(): string {
		return this.$vuetify.breakpoint.smAndUp ? 'reCol-left-2cols' : 'reCol-left-1col';
	}

	public get greetingName(): string {
		const firstName = this.firstName ?? '';
		const middleName = this.middleName ?? '';
		const lastName = this.lastName ?? '';
		if (!firstName && !middleName && !lastName) {
			return User.getNameStringFromEmail(this.email || '');
		}
		let greetingName: string = firstName;
		if (middleName) {
			if (greetingName) {
				// Only add one space if the greeting name is initialized.
				greetingName += ' ';
			}
			greetingName += middleName;
		}
		if (lastName) {
			if (greetingName) {
				// Only add one space if the greeting name is initialized.
				greetingName += ' ';
			}
			greetingName += lastName;
		}
		return greetingName;
	}

	public get topRightComponentData(): UserBtnTaskTopRightComponentData {
		return {
			isFullscreenTask: true,
			mockState: {
				userEmail: this.email,
				// If neither the first nor the last name have been provided,
				// we just use the first part of the email (before @) as a greeting name.
				greetingName: this.greetingName,
				isAuthenticated: true,
			},
		};
	}

	public get languages(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.languageNames).map((key) => {
			const languageCode: InternationalizationUtils.Language = key as InternationalizationUtils.Language;
			return {
				text: InternationalizationUtils.languageNames[languageCode],
				value: languageCode,
			};
		}).sort((a, b) => a.text.localeCompare(b.text));
	}

	public get countries(): DataTableHeader[] {
		return Object.keys(countryNames).map((key) => {
			return {
				text: this.$tc(countryNames[key as keyof typeof countryNames]),
				value: key,
			};
		}).sort((a, b) => a.text.localeCompare(b.text));
	}

	public get requiresStateOrProvince(): boolean {
		return UserUtils.requiresStateOrProvince(this.country);
	}

	public get requiresPostalCode(): boolean {
		return SalesforceUtils.requirePostalCode(this.country || null);
	}

	public get stateOrProvinces(): DataTableHeader[] {
		return UserUtils.stateOrProvinces(this.country);
	}

	public get industries(): DataTableHeader[] {
		return AuthzConstant.INDUSTRIES.map((industry) => {
			return {
				text: UserUtils.translateIndustry(industry),
				value: industry,
			};
		}).sort((a, b) => a.text.localeCompare(b.text));
	}

	public get primaryApplicationInterests(): DataTableHeader[] {
		let primaryApplicationInterests: AuthzType.ApplicationInterest[] = [];

		switch (this.industry) {
			case 'AEC':
				primaryApplicationInterests = AuthzConstant.AEC_APPLICATION_INTERESTS;
				break;
			case 'MANUFACTURING':
				primaryApplicationInterests = AuthzConstant.MANUFACTURING_APPLICATION_INTERESTS;
				break;
			case 'PS':
				primaryApplicationInterests = AuthzConstant.PS_APPLICATION_INTERESTS;
				break;
		}

		return primaryApplicationInterests.map((primaryApplicationInterest) => {
			return {
				text: UserUtils.translatePrimaryApplicationInterest(primaryApplicationInterest),
				value: primaryApplicationInterest,
			};
		}).sort((a, b) => a.text.localeCompare(b.text));
	}

	public get missingAttributes(): boolean {
		if (this.requiresStateOrProvince && !this.stateOrProvince) {
			return true;
		}
		if (this.requiresPostalCode && !(this.sanitizedPostalCode ?? '').length) {
			return true;
		}
		return false;
	}

	public get phoneCodes(): DataTableHeader[] {
		return UserUtils.phoneCodes();
	}

	// ##################################### Methods #####################################

	// -------------------------- Input field rule validators ---------------------------

	public validateFirstNameRule(value: string): true | string {
		this.sanitizedFirstName = UserUtils.removeUnnecessarySpaces(value);
		return this.firstNameRule(this.sanitizedFirstName);
	}

	public validateMiddleNameRule(value: string): true | string {
		this.sanitizedMiddleName = UserUtils.removeUnnecessarySpaces(value);
		return this.middleNameRule(this.sanitizedMiddleName);
	}

	public validateLastNameRule(value: string): true | string {
		this.sanitizedLastName = UserUtils.removeUnnecessarySpaces(value);
		return this.lastNameRule(this.sanitizedLastName);
	}

	public validateCompanyRule(value: string): true | string {
		this.sanitizedCompany = UserUtils.removeUnnecessarySpaces(value);
		return this.companyRule(this.sanitizedCompany);
	}

	public validatePhoneNumberRule(value: string): true | string {
		const preSanitizedPhoneNumber = UserUtils.removeUnnecessarySpaces(value);
		const phoneNumberRuleResult = this.phoneNumberRule(preSanitizedPhoneNumber, this.phoneCountryCode);
		this.sanitizedPhoneNumber = phoneNumberRuleResult.phoneNumber;
		return phoneNumberRuleResult.result;
	}

	public validatePostalCodeRule(value: string): true | string {
		this.sanitizedPostalCode = UserUtils.removeUnnecessarySpaces(value);
		if (!this.requiresPostalCode) {
			return this.zipOrPostalCodeNotRequiredRule(value, this.country);
		}
		return this.zipOrPostalCodeRequiredRule(value, this.country);
	}

	public validatePrimaryApplicationInterestRule(value: AuthzType.ApplicationInterest & string | undefined): true | string {
		const industry = this.industry as AuthzType.Industry & string | undefined;
		return this.primaryApplicationInterestRule(value, industry);
	}

	// -------------------------- Input field change methods ---------------------------

	/**
	 * This is a quick fix to reload the country text field.
	 * Otherwise sometimes it doesn't adapt the rules that can change depending
	 * on whether the postal code is required or not.
	 */
	public async reloadCountry() {
		this.showPostalCode = false;
		await this.$nextTick();
		this.showPostalCode = true;
	}

	public async changedCountry(value: string | null | undefined) {
		this.postalCode = '';
		this.sanitizedPostalCode = '';
		this.stateOrProvince = '';
		this.updateUrl(value, 'Country');
		this.reloadCountry();
		if (this.isValidated) {
			await this.validateForm();
		}
	}

	public changedIndustry(value: string | null | undefined) {
		// Reset primaryApplicationInterest to avoid sending mismatching values.
		this.primaryApplicationInterest = '';
		this.updateUrl(value, 'Industry');
	}

	public changedLanguage(value: string | null | undefined) {
		try {
			if (value) {
				this.$i18n.locale = value;
			}
		} catch (error) {
			console.error('Cannot set language');
			console.error(error);
		}
		this.updateUrl(value, 'Language');
	}

	/**
	 * Triggers a phone number rule validaton.
	 */
	public async ValidatePhoneOnCountryCodeChange() {
		const formPhone = this.$refs.formPhone as HTMLFormElement;
		try {
			await Vue.nextTick();
			formPhone.validate();
		} catch (error) {
			console.error(error);
		}
	}

	public async changedPhoneCountryCode(value: string | null | undefined) {
		this.updateUrl(value, 'PhoneCountryCode');
		if (this.isValidated) {
			await this.validateForm();
		} else if (!this.isValidated && this.phoneNumber) {
			// Trigger an isolated phone validation if the form is not yet validated and the user has already typed something in the phone field.
			this.ValidatePhoneOnCountryCodeChange();
		}
	}

	public changedStateOrProvince(value: string | null | undefined) {
		this.updateUrl(value, 'StateOrProvince');
	}

	public changedPrimaryApplicationInterest(value: string | null | undefined) {
		this.updateUrl(value, 'PrimaryApplicationInterest');
	}

	public onBlurFirstName(value: string | null | undefined) {
		this.updateUrl(this.sanitizedFirstName, 'FirstName');
	}

	public onBlurMiddleName(value: string | null | undefined) {
		this.updateUrl(this.sanitizedMiddleName, 'MiddleName');
	}

	public onBlurLastName(value: string | null | undefined) {
		this.updateUrl(this.sanitizedLastName, 'LastName');
	}

	public onBlurCompany(value: string | null | undefined) {
		this.updateUrl(this.sanitizedCompany, 'Company');
	}

	public onBlurPostalCode(value: string | null | undefined) {
		this.updateUrl(this.sanitizedPostalCode, 'PostalCode');
	}

	public onBlurPhoneNumber(value: string | null | undefined) {
		this.updateUrl(this.sanitizedPhoneNumber, 'PhoneNumber');
	}

	/**
	 * Updates the route to include the user attributes in it.
	 * That way if the users reloads the page, the same attributes will be filled.
	 */
	public updateUrl(value: string | null | undefined, attrName: keyof AuthzInterface.IUser) {
		this.$router.replace({
			query: {
				...this.$route.query,
				[attrName]: value,
			},
		});
	}

	public async validateForm() {
		const form = this.$refs.form as HTMLFormElement;
		const formPhone = this.$refs.formPhone as HTMLFormElement;
		try {
			await Vue.nextTick();
			this.isValidated = true;
			// Force a form validation to show the missing fields.
			form.validate();
			formPhone.validate();
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Gets the string id to translate an attribute name.
	 */
	public getAttrNameStringId(attrName: string | undefined): string {
		switch (attrName) {
			case 'FirstName':
				return 'FIRST_NAME';
			case 'MiddleName':
				return 'UI_MIDDLE_NAME';
			case 'LastName':
				return 'LAST_NAME';
			case 'Email':
				return 'UI_EMAIL';
			case 'Company':
				return 'UI_COMPANY';
			case 'PhoneCountryCode':
				return 'LP_COUNTRY_CODE';
			case 'PhoneNumber':
				return 'UI_PHONE';
			case 'Industry':
				return 'LP_INDUSTRY';
			case 'PrimaryApplicationInterest':
				return 'LP_APP_INTEREST';
			case 'PostalCode':
				return 'LP_ZIP_OR_POSTAL_CODE';
			case 'Country':
				return 'COUNTRY';
			case 'StateOrProvince':
				return 'LP_STATE_OR_PROVINCE';
			case 'Language':
				return 'LANGUAGE';
			default:
				return 'UI_UNKNOWN';
		}
	}

	public async registerUser() {
		let user: AuthzReq.RegisterUserReq & WithUserRegistrationToken;

		try {
			if (!this.sanitizedFirstName) {
				throw new BadRequestError('FirstName', undefined, 'FirstName');
			}
			if (!this.sanitizedLastName) {
				throw new BadRequestError('LastName', undefined, 'LastName');
			}
			if (!this.sanitizedCompany) {
				throw new BadRequestError('Company', undefined, 'Company');
			}
			if (!this.country) {
				throw new BadRequestError('Country', undefined, 'Country');
			}
			if (!this.industry) {
				throw new BadRequestError('Industry', undefined, 'Industry');
			}
			if (!this.primaryApplicationInterest) {
				throw new BadRequestError('PrimaryApplicationInterest', undefined, 'PrimaryApplicationInterest');
			}
			if (!this.phoneCountryCode) {
				throw new BadRequestError('PhoneCountryCode', undefined, 'PhoneCountryCode');
			}
			if (!this.sanitizedPhoneNumber) {
				throw new BadRequestError('PhoneNumber', undefined, 'PhoneNumber');
			}
			if (!this.language) {
				throw new BadRequestError('Language', undefined, 'Language');
			}
			if (this.requiresPostalCode && !this.sanitizedPostalCode) {
				throw new BadRequestError('PostalCode', undefined, 'PostalCode');
			}
			if (this.requiresStateOrProvince && !this.stateOrProvince) {
				throw new BadRequestError('StateOrProvince', undefined, 'StateOrProvince');
			}

			user = {
				FirstName: validateString(this.sanitizedFirstName, 'FirstName'),
				MiddleName: validateString(this.sanitizedMiddleName ?? '', 'MiddleName'),
				LastName: validateString(this.sanitizedLastName, 'LastName'),
				Email: validateString(this.email, 'Email'),
				Company: validateString(this.sanitizedCompany, 'Company'),
				Industry: validateEnum(this.industry, AuthzConstant.INDUSTRY_ENUM, 'Industry'),
				PrimaryApplicationInterest: validateEnum(this.primaryApplicationInterest,
					AuthzConstant.APPLICATION_INTEREST_ENUM, 'PrimaryApplicationInterest'),
				PostalCode: validateString(this.sanitizedPostalCode, 'PostalCode'),
				Country: validateEnum(this.country, InternationalizationUtils.countryCodes, 'Country'),
				StateOrProvince: validateStringIfDefined(this.stateOrProvince, 'StateOrProvince') ?? '',
				RegistrationToken: validateUuid(this.registrationToken, 'RegistrationToken'),
				PhoneCountryCode: validateEnum(this.phoneCountryCode, InternationalizationUtils.countryCodes),
				PhoneNumber: validatePhoneNumber(this.sanitizedPhoneNumber, this.phoneCountryCode),
				Language: validateEnum(this.language, InternationalizationUtils.languages),
				ExperienceProgram: this.experienceProgram,
			};
		} catch (error) {
			const attrName = this.$tc(this.getAttrNameStringId((error as BadRequestError).attribute));
			const message = this.$tc('LP_ERR_INVALID_VALUE', undefined, { name: attrName });
			this.$faroComponents.$emit('show-error', { error, message });
			return;
		}

		try {
			this.loading = true;
			await this.$tsStore.users.register(user);
			await submitAuth0LoginForm({
				auth0Domain: this.auth0Domain,
				auth0State: this.auth0State,
			});
		} catch (error) {
			const message = getErrorMessage(error, {
				ConflictError: this.$tc('LP_ERR_ALREADY_SIGNED_UP'),
				AuthenticationError: this.$tc('UI_HTTP_401') + '. ' + this.$tc('LP_EXPIRED_URL'),
			});
			this.loading = false;
			this.$faroComponents.$emit('show-error', { error, message });
		}
	}

	// ######################## Lifecycle hooks ########################

	public async mounted() {
		const userQuery = this.$route.query as unknown as AuthzInterface.IUser;
		this.firstName = queryToString(userQuery.FirstName);
		if (userQuery.MiddleName) {
			this.middleName = queryToString(userQuery.MiddleName);
		}
		this.lastName = queryToString(userQuery.LastName);
		this.email = queryToString(userQuery.Email);
		if (this.firstName && this.firstName === this.email) {
			this.firstName = ''; // Avoid bad default value.
		}
		if (this.email && !this.firstName && !this.lastName) {
			const names = User.getNameFromEmail(this.email);
			this.firstName = names[0];
			this.middleName = names[1];
			this.lastName = names[2];
		}
		this.company = queryToString(userQuery.Company);
		this.country = queryToString(userQuery.Country);
		this.language = queryToString(userQuery.Language);
		this.postalCode = queryToString(userQuery.PostalCode);
		this.phoneCountryCode = queryToString<InternationalizationUtils.CountryCode>(userQuery.PhoneCountryCode) || null;
		this.phoneNumber = queryToString(userQuery.PhoneNumber);
		this.stateOrProvince = queryToString(userQuery.StateOrProvince);
		this.industry = queryToString(userQuery.Industry);
		this.primaryApplicationInterest = queryToString(userQuery.PrimaryApplicationInterest);
		this.experienceProgram = queryToBool(userQuery.ExperienceProgram);
		this.registrationToken = queryToString(this.$route.query.token);
		this.auth0Domain = queryToString(this.$route.query.auth0_domain);
		this.auth0State = queryToString(this.$route.query.state);

		// Those asserted values are not mandatory but if they are missing it won't be possible to continue the login process after submit.
		// And the page will be redirected to the landing page instead.
		$assert.Assert(this.auth0State.length > 0, 'state was not included in the url.');
		$assert.Assert(this.auth0State.length > 0, 'auth0_domain was not included in the url.');

		// If any of the values was already provided it means that the user was already registered but somehing is now missing
		// probably due to changes in the requirements.
		// email, country and language are always provided by default
		if (this.firstName ||
			this.middleName ||
			this.lastName ||
			this.company ||
			this.postalCode ||
			this.phoneCountryCode ||
			this.phoneNumber ||
			this.stateOrProvince ||
			this.industry ||
			this.primaryApplicationInterest
		) {
			this.firstSignup = false;
			await this.validateForm();
		}

		// If the token is not provided, it makes no sense to show the registration form since the backend will throw an AuthenticationError.
		if (!this.email || !UuidUtils.isRfcUuid(this.registrationToken)) {
			this.error = this.$tc('LP_ERR_INVALID_REGISTRATION_URL');
		}

		if (!this.language) {
			setLanguageLikeBrowser();
		} else {
			this.$i18n.locale = this.language;
		}

		// We need to fake that this page is authenticated, because even though it looks that the user is authenticated,
		// in reality the login flow was interrupted by Auth0.
		localStorage.setItem('sphere-is-authenticated', 'true');
	}
}
