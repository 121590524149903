
import Vue from 'vue';
import Component from 'vue-class-component';
import { IWorkspace } from '@/classes/authz/Workspace';
import { setLanguageLikeBrowser } from '@/utils/browser';

interface InvitationError {
	isActive: boolean;
	showWrongTitle: boolean;
	message: string;
	showTryAgain: boolean;
}

@Component
export default class InviteResultPage extends Vue {
	public readonly DEFAULT_TIMER_SECONDS = 10;
	public readonly lpUrl = window.location.origin;
	public isRedirectMode: boolean = false;
	public redirectTime: number = 0;
	public isReady: boolean = false;
	public error: InvitationError = {
		isActive: false,
		showWrongTitle: false,
		message: '',
		showTryAgain: true,
	};
	public workspace: IWorkspace | null = null;
	public successMessage: string | null = null;

	public startRedirectTimer(payload: { callback: () => void, seconds?: number }) {
		this.isRedirectMode = true;
		this.redirectTime = payload.seconds ? payload.seconds : this.DEFAULT_TIMER_SECONDS;
		const interval = setInterval(() => {
			if (this.redirectTime === 1) {
				clearInterval(interval);
				return payload.callback();
			}
			this.redirectTime -= 1;
		}, 1000);
	}

	public redirectToHomeStartTimer() {
		const that = this;
		this.startRedirectTimer({
			callback() {
				that.redirectToHome();
			},
		});
	}

	public redirectToHome() {
		window.location.href = this.$router.resolve({ name: 'HomePage' }).href;
	}

	public async created() {
		const token: string = this.$route.params.token;
		const user = this.$route.params.user;
		const workspace = this.$route.params.workspace;
		const action: string = this.$route.query.action as string ?? '';

		if (!action || !['accept', 'reject'].includes(action)) {
			this.error = {
				isActive: true,
				showWrongTitle: false,
				message: this.$tc('LP_INVALID_INVITATION_LINK'),
				showTryAgain: true,
			};
			this.isReady = true;
			return;
		}

		try {
			const response = await this.$tsStore.workspaces.userRespondToInvitation({ token, action, user, workspace });
			this.workspace = response.Workspace;
			this.successMessage = this.$tc('LP_THANK_YOU_FOR_ANSWER');
			if (response.PasswordResetUrl) {
				// In this case, this page is shown only for a fraction of a second before the user gets
				// redirected to Auth0's password reset page.
				window.location.href = response.PasswordResetUrl;
			} else {
				this.redirectToHomeStartTimer();
			}
		} catch (err) {
			if ((err as any)?.status === 409) {
				this.error.message = this.$tc('LP_ANSWERED_BEFORE');
				this.error.showTryAgain = false;
			} else {
				this.error.message = this.$tc('LP_INVALID_URL_OR_USED_BEFORE');
				this.error.showTryAgain = true;
			}
			this.error.showWrongTitle = true;
			this.error.isActive = true;
		} finally {
			this.isReady = true;
		}
	}

	public mounted() {
		// If the user is already on the store the language was already set to match the user's language.
		if (!this.$tsStore.users.user) {
			setLanguageLikeBrowser();
		}
	}
}

