import {
  OperationURLParameter,
  OperationParameter,
  OperationQueryParameter
} from "@azure/core-http";
import {
  Order as OrderMapper,
  OrderUpdate as OrderUpdateMapper,
  Customer as CustomerMapper,
  UpdateCustomerParams as UpdateCustomerParamsMapper,
  CreateWorkspaceParams as CreateWorkspaceParamsMapper,
  UpdateWorkspaceParams as UpdateWorkspaceParamsMapper,
  Deactivate as DeactivateMapper,
  ReactivateSubscription as ReactivateSubscriptionMapper,
  ReportsParams as ReportsParamsMapper
} from "../models/mappers";

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String"
    }
  },
  skipEncoding: true
};

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String"
    }
  }
};

export const body: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: OrderMapper
};

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const accept1: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const workspaceId: OperationURLParameter = {
  parameterPath: "workspaceId",
  mapper: {
    serializedName: "workspaceId",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const orderId: OperationURLParameter = {
  parameterPath: "orderId",
  mapper: {
    serializedName: "orderId",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body1: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: OrderUpdateMapper
};

export const email: OperationQueryParameter = {
  parameterPath: ["options", "email"],
  mapper: {
    serializedName: "email",
    type: {
      name: "String"
    }
  }
};

export const userId: OperationURLParameter = {
  parameterPath: "userId",
  mapper: {
    serializedName: "userId",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body2: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CustomerMapper
};

export const customerId: OperationURLParameter = {
  parameterPath: "customerId",
  mapper: {
    serializedName: "customerId",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body3: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateCustomerParamsMapper
};

export const body4: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateWorkspaceParamsMapper
};

export const withoutOwner: OperationQueryParameter = {
  parameterPath: ["options", "withoutOwner"],
  mapper: {
    serializedName: "withoutOwner",
    type: {
      name: "Boolean"
    }
  }
};

export const fromParam: OperationQueryParameter = {
  parameterPath: ["options", "fromParam"],
  mapper: {
    serializedName: "from",
    type: {
      name: "Number"
    }
  }
};

export const to: OperationQueryParameter = {
  parameterPath: ["options", "to"],
  mapper: {
    serializedName: "to",
    type: {
      name: "Number"
    }
  }
};

export const year: OperationQueryParameter = {
  parameterPath: ["options", "year"],
  mapper: {
    serializedName: "year",
    type: {
      name: "Number"
    }
  }
};

export const body5: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateWorkspaceParamsMapper
};

export const accept2: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "text/plain",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const body6: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: DeactivateMapper
};

export const body7: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ReactivateSubscriptionMapper
};

export const workspaceId1: OperationURLParameter = {
  parameterPath: "workspaceId",
  mapper: {
    serializedName: "workspace_id",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const subscriptionId: OperationURLParameter = {
  parameterPath: "subscriptionId",
  mapper: {
    serializedName: "subscriptionId",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body8: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ReportsParamsMapper
};

export const email1: OperationQueryParameter = {
  parameterPath: "email",
  mapper: {
    serializedName: "email",
    required: true,
    type: {
      name: "String"
    }
  }
};
