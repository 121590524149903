import { Service } from '@/classes/Service';
import { Module, RegisterOptions } from 'vuex-class-modules';
import { PageModule } from '@/store/modules/PageModule';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import { ServiceService } from '@/store/services/ServiceService';
import { ServiceAny } from '@/classes/ServiceAny';

@Module
export class ServiceModule extends BaseFilterModule<Service> {
	// ###################################### Properties ######################################

	// ###### Public ######

	// ###### Protected ######

	protected readonly service = new ServiceService({});

	// ###### Private ######


	// ###################################### Getters ######################################

	// ###### Public ######

	public get projectsForFilterList() {
		// Since there are no projects in the services page, just return an empty array.
		return [];
	}

	public get filterByProjectSourceOptions() {
		// Since there are no project sources in the services page, just return an empty array.
		return [];
	}
	public get filterByProjectStatusOptions() {
		// Since there are no project statuses in the services page, just return an empty array.
		return [];
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions) {
		// Using "ServiceAny" here allows the module to construct different types of Services.
		super(pages, options, ServiceAny);
	}


	// ###################################### Actions ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######


	// ###################################### Mutations ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######


	// ###################################### Helper Methods ######################################

	// ###### Public ######

	// ###### Protected ######

	/**
	 * Filters a list of applications by keeping only the ones that have some attribute,
	 * that matches some search text.
	 * @param services The original application list.
	 * @param searchTxt The search text.
	 * @returns A new filtered application list.
	 */
	protected filterByTextItems(services: Service[], searchTxt: string): Service[] {
		searchTxt = searchTxt.toLowerCase();
		return services.filter((service) =>
			service.Name.toLowerCase().includes(searchTxt) ||
			service.Description.toLowerCase().includes(searchTxt),
		);
	}

	// ###### Private ######
}
