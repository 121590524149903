
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class DemoWorkspaceOrProjectLabel extends Vue {
	@Prop({type: String, required: false, default: 'large'}) public readonly size!: 'list-sm' | 'list-lg' | 'thumbnail-lg';

	public get listLarge(): boolean {
		return this.size === 'list-lg';
	}

	public get listSmall(): boolean {
		return this.size === 'list-sm';
	}

	public get thumbnailLarge(): boolean {
		return this.size === 'thumbnail-lg';
	}
}
