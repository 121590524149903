import { DataHubClient } from '@faroconnect/datahub-client';
import { Project } from '@/classes/authz/Project';
import { BaseServiceAny } from './BaseServiceAny';

/**
 * DataHubService is a wrapper around the @faroconnect/datahub-client library.
 */
export class DataHubService {
	/**
	 * Returns the sync ID for the provided Stream project on the DataHub.
	 * @param project Project Stream project for which the user wants to generate a SCENE project.
	 * @author OK
	 * @throws {DataHubError}
	 */
	public async getSyncId(project: Project): Promise<string> {
		const client = new DataHubClient(project.DomainUrl, /* apiId */ undefined, /* apiKey */ undefined, project.Domain);
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		return await client.getSyncId(project.UUID, 'STREAM', { customHeaders });
	}
}
