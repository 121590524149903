import { faroLocalization, countryNames } from '@faroconnect/baseui';
import { StringUtils, InternationalizationUtils, InternationalizationExtendedUtils } from '@faroconnect/utils';
import { AuthzConstant, AuthzType } from '@faroconnect/authz-client';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

const { countryPhoneCodesFromCountryCode } = InternationalizationExtendedUtils;

interface PhoneNumberRuleResult {
	result: true | string;
	phoneNumber: string;
}

export const MIN_CHARS = 1;
export const MIN_NAME_CHARS = 1;
export const MAX_CHARS = 127;
export const MAX_POSTAL_CODE_CHARS = 10;

// Length constraints for phone number (without the country code).
// Examples for Germany:
//   - my mobile number:    11 chars
//   - my landline number:  11 chars
//   - FARO Korntal office:  9 chars (incl. extension "0").
// Apparently some countries have only 4 digits. 15 should be plenty.
// https://stackoverflow.com/questions/14894899/what-is-the-minimum-length-of-a-valid-international-phone-number
// https://www.quora.com/What-is-maximum-and-minimum-length-of-any-mobile-number-across-the-world
const PHONE_MIN_CHARS = 4;
const PHONE_MAX_CHARS = 15;

// ################################ User input fields rules #####################################

export function firstNameRule(value: string | undefined): true | string {
	return lengthRule(value, 'UI_FIRST_NAME', MIN_NAME_CHARS, MAX_CHARS, true);
}

export function middleNameRule(value: string | undefined): true | string {
	return lengthRule(value, 'UI_MIDDLE_NAME', 0, MAX_CHARS, true);
}

export function lastNameRule(value: string | undefined): true | string {
	return lengthRule(value, 'UI_LAST_NAME', MIN_NAME_CHARS, MAX_CHARS, true);
}

export function uiEmailRule(value: string | undefined): true | string {
	return lengthRule(value, 'UI_EMAIL', MIN_CHARS, MAX_CHARS, true);
}

/**
 * Validator for email address that uses the same regular expression as AuthZ's validateEmail.
 * @author OK
 */
export function emailRule(value: string | undefined): true | string {
	const lengthCheck = lengthRule(value, 'UI_EMAIL', MIN_CHARS, MAX_CHARS, true);
	if (!value || lengthCheck !== true) {
		return lengthCheck;
	}
	const regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/i;
	return value.match(regex) ? true : faroLocalization.i18n.tc('LP_EMAIL_INVALID');
}

export function companyRule(value: string | undefined): true | string {
	return lengthRule(value, 'UI_COMPANY', MIN_CHARS, MAX_CHARS, true);
}

export function phoneCountryCodeRule(value: InternationalizationUtils.CountryCode & string | undefined): true | string {
	if (!value) {
		return '';
	}
	return Object.values(InternationalizationUtils.countryCodes).includes(value) ? true : '';
}

/**
 * Phone number validation function.
 * @param value Phone number to be validated.
 * @param phoneCountryCode User's country code in iso-3166 alpha 2 (E.g: 'DE') that will validate @param value.
 * @returns Object {result, phoneNumber}:
 *          result: `true`, or string with error message.
 *          phoneNumber: string with the sanitized + normalized phone number, or empty string if invalid.
 *          For simplicity, a single leading zero is not removed.
 *          E.g. "+49 (0) 173 111 222 x11" is normalized to "017311122211".
 *          E.g. "111.222.333" is normalized to "111222333".
 */
export function phoneNumberRule(value: string, phoneCountryCode: InternationalizationUtils.CountryCode | null): PhoneNumberRuleResult {
	// If no country has been given then return a failed validaton result.
	const cc = phoneCountryCode as InternationalizationUtils.CountryCode;
	const countryPrefix = countryPhoneCodesFromCountryCode[cc];
	if (!phoneCountryCode || !countryPrefix) {
		return { result: faroLocalization.i18n.tc('LP_SELECT_COUNTRY_CODE'), phoneNumber: '' };
	}

	// The previous validation logic allowed e.g. phone numbers like "(22) 111-335 x1".
	// The non-numeric chars were stripped from the input before saving the number.
	value = value.replace(/[\s().x-]/g, '');

	// The previous validation logic allowed the number to be prefixed with e.g. "+49" or "0049" if Germany was selected.
	if (countryPrefix && value.startsWith('+' + countryPrefix)) {
		value = value.substring(countryPrefix.length + 1);
	} else if (countryPrefix && value.startsWith('00' + countryPrefix)) {
		value = value.substring(countryPrefix.length + 2);
	} else if (countryPrefix && (value.startsWith('+') || value.startsWith('00'))) {
		// E.g. country "Germany +49" was selected, but a US number "+1 ..." was entered.
		return {
			result: faroLocalization.i18n.tc('LP_INVALID_PHONE_NUMBER', undefined, {
				countryCode: faroLocalization.i18n.tc(countryNames[cc]) + ' +' + countryPrefix,
			}),
			phoneNumber: '',
		};
	}

	const numberRule = onlyNumberRule(value, 'UI_PHONE');
	if (typeof numberRule === 'string') {
		return { result: numberRule, phoneNumber: '' };
	}
	const lengthRuleResult = lengthRule(value, 'UI_PHONE', PHONE_MIN_CHARS, PHONE_MAX_CHARS, false);
	return { result: lengthRuleResult, phoneNumber: lengthRuleResult === true ? value : '' };
}

export function languageRule(value: InternationalizationUtils.Language & string | undefined): true | string {
	if (!value) {
		return '';
	}
	return Object.values(InternationalizationUtils.languages).includes(value) ? true : '';
}

export function countryRule(value: InternationalizationUtils.CountryCode & string | undefined): true | string {
	if (!value) {
		return '';
	}
	return Object.values(InternationalizationUtils.countryCodes).includes(value) ? true : '';
}

/**
 * Zip or Postal Code validation function.
 * @param value String to be validated. It is always first validated against undesired characters.
 * @param country Current country code for the user.
 * @param options Optional flag. If set to true @param value is a required field and is compared against valid postal codes for the @param country.
 * If there isn't a validator for @param country, then only validate @param value against lengthRule.
 * If set to false @param value is not a required field and is only compared against lengthRule with a min. string length of 0 chars.
 * @returns True @param value is passes the validation. If not it returns a string with information about the rule that was not validated.
 */
function zipOrPostalCodeRule(
	value: string | undefined,
	country: InternationalizationUtils.CountryCode | '',
	options?: { isRequired: boolean }): true | string {
	const undesiredChars = StringUtils.getUndesiredChars(
		value ?? '', /*allowNumbers=*/ true, /*allowLowerCase=*/ true, /*allowUpperCase=*/ true, ['-', ' ', '/', '_']);
	if (undesiredChars) {
		return faroLocalization.i18n.tc('LP_INVALID_CHARACTERS_ATTR', undesiredChars.length, {
			chars: undesiredChars,
		});
	}

	if (options?.isRequired) {
		// If the postcode validator exists for the given country, then validate the given post code agaisnt the given country.
		// If not, then only validate agaisnt the length rule.
		if (postcodeValidatorExistsForCountry(country) === true) {
			if (!value) {
				return faroLocalization.i18n.tc('LP_POSTAL_CODE_REQUIRED');
			}
			return postcodeValidator(value, country) ? true : faroLocalization.i18n.tc('LP_INVALID_POSTAL_CODE', undefined, {
				country: faroLocalization.i18n.tc(countryNames[country as InternationalizationUtils.CountryCode]),
			});
		} else {
			return lengthRule(value, 'LP_ZIP_OR_POSTAL_CODE', MIN_CHARS, MAX_POSTAL_CODE_CHARS, true);
		}
	} else {
		return lengthRule(value, 'LP_ZIP_OR_POSTAL_CODE', 0, MAX_POSTAL_CODE_CHARS, true);
	}
}

export function zipOrPostalCodeRequiredRule(value: string | undefined, country: InternationalizationUtils.CountryCode | ''): true | string {
	return zipOrPostalCodeRule(value, country, { isRequired: true });
}

export function zipOrPostalCodeNotRequiredRule(value: string | undefined, country: InternationalizationUtils.CountryCode | ''): true | string {
	return zipOrPostalCodeRule(value, country, { isRequired: false });
}

export function cityRule(value: string | undefined): true | string {
	return lengthRule(value, 'LP_CITY', 0, MAX_CHARS, true);
}

export function industryRule(value: AuthzType.Industry & string | undefined): true | string {
	if (!value) {
		return '';
	}
	return Object.values(AuthzConstant.INDUSTRY_ENUM).includes(value) ? true : '';
}

export function primaryApplicationInterestRule(
	value: AuthzType.ApplicationInterest & string | undefined,
	industry: AuthzType.Industry & string | undefined,
): true | string {
	if (!value || !industry) {
		return '';
	}

	let primaryApplicationInterests: AuthzType.ApplicationInterest[] = [];
	switch (industry) {
		case 'AEC':
			primaryApplicationInterests = AuthzConstant.AEC_APPLICATION_INTERESTS;
			break;
		case 'MANUFACTURING':
			primaryApplicationInterests = AuthzConstant.MANUFACTURING_APPLICATION_INTERESTS;
			break;
		case 'PS':
			primaryApplicationInterests = AuthzConstant.PS_APPLICATION_INTERESTS;
			break;
		default:
			return '';
	}

	return Object.values(primaryApplicationInterests).includes(value) ? true : '';
}

// ################################ Helper rules #####################################

/**
 * Length and (optional) whitespace validation function. It returns true if the input string satisfies the min. length and max. length char limits.
 * Optionally it also validates that there is at least one character that is not a space ' ' in the string.
 * @param value String to be avalidated.
 * @param attrNameId Name of the attribute to be evaluated.
 * @param minChars Minimun number of characters that @param value needs to have.
 * @param maxChars Maxumun number of characters that @param value needs to have.
 * @param whitespace Optional flag. If true, there most be at least one character different from space ' ' in @param value.
 * @returns True if @param value passes the validation. If not it returns a string with information about the rule that was not validated.
 */
export function lengthRule(
	value: string | undefined,
	attrNameId: string,
	minChars: number | null,
	maxChars: number | null,
	whitespace?: boolean): true | string {
	const str = value ?? '';

	if (minChars !== null && str.length < minChars) {
		if (minChars === 0) {
			return faroLocalization.i18n.tc('LP_MUST_BE_PROVIDED', undefined, {
				name: faroLocalization.i18n.tc(attrNameId),
			});
		} else {
			return faroLocalization.i18n.tc('UI_MIN_CHARS', undefined, {
				name: faroLocalization.i18n.tc(attrNameId),
				minChars,
			});
		}
	}
	if (maxChars !== null && str.length > maxChars) {
		return faroLocalization.i18n.tc('UI_MAX_CHARS', undefined, {
			name: faroLocalization.i18n.tc(attrNameId),
			maxChars,
		});
	}
	if (whitespace && whitespace === true) {
		return notAllCharsAreSpacesRule(value, attrNameId);
	}
	return true;
}

export function requiredSelectedRule(value: any): true | string {
	return value ? true : '';
}

export function onlyNumberRule(value: any, attrNameId: string): true | string {
	if (!StringUtils.isNumeric(value)) {
		return faroLocalization.i18n.tc('LP_ONLY_NUMBERS', undefined, { name: faroLocalization.i18n.tc(attrNameId) });
	}
	return true;
}

export function notAllCharsAreSpacesRule(value: any, attrNameId: string): true | string {
	const str = value ?? '';
	// true if string is empty or if it includes at least one "non empty" character.
	return /^$|\S/.test(str) ? true : faroLocalization.i18n.tc('LP_ALL_CHARS_SPACES', undefined, {
		name: faroLocalization.i18n.tc(attrNameId),
	});
}
