
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class OverflowLabel extends Vue {
	@Prop({type: String, required: false, default: 'large'}) public readonly size!: 'small' | 'large';

	public get large(): boolean {
		return this.size === 'large';
	}

	public get small(): boolean {
		return this.size === 'small';
	}
}
