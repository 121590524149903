
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WebUtils } from '@faroconnect/utils';

@Component
export default class BaseUpgradeNote extends Vue {
	@Prop({ type: String, required: true }) public readonly workspaceUuid!: string;

	public async startTrial(): Promise<void> {
		try {
			await this.$tsStore.workspaces.updateSingle2({ uuid: this.workspaceUuid, data: { BaseSelected: true }});
			this.$faroNotify.showSnackbar('success', this.$tc('UI_BASE_UPGRADE_ALERT'));
			this.closeBar();
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error });
		}
	}

	public learnMore(): void {
		WebUtils.openExternalLink('https://www.holobuilder.com/sphere-xg-start/', '_blank');
	}

	public closeBar(): void {
		this.$faroComponents.$emit('closebaseupgrademsg');
	}
}
