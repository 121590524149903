import { Module, Action, RegisterOptions } from 'vuex-class-modules';
import { AuthzProjectRoleService } from '@/store/services/authz/AuthzProjectRoleService';
import { ProjectRole } from '@/classes/authz/ProjectRole';
import { PageModule } from '@/store/modules/PageModule';
import { BaseModule } from '../BaseModule';

@Module
export class ProjectRoleModule extends BaseModule<ProjectRole> {
	// ###################################### Properties ######################################

	// ###### Public ######

	// ###### Protected ######

	protected readonly service = new AuthzProjectRoleService({});

	// ###### Private ######

	// ###################################### Getters ######################################

	// ###### Public ######

	public get filteredItems(): ProjectRole[] {
		return this.itemsList;
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions) {
		super(pages, options, ProjectRole);
	}

	// ###################################### Actions ######################################

	// ###### Public ######

	@Action
	public async getAllFromWorkspace(workspaceUuid: string): Promise<ProjectRole[]> {
		const iProjectRoles = await this.service.getAllFromWorkspace(workspaceUuid);
		const projectRoles = iProjectRoles.map((projectRole) => ProjectRole.fromResponse(projectRole));
		this.addItems(projectRoles);
		return projectRoles;
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Mutations ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######
}
