
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { config } from '@/config';
import { Locale } from 'vue-i18n';
import { Workspace } from '@/classes/authz/Workspace';
import { isFeatureEnabledByAuth0PermissionsSync } from '@/utils/permissions';

@Component({
	components: {
		UserBtn,
	},
})
export default class LeftNavigationDrawer extends Vue {
	@Prop(String) public readonly version!: string;
	@Prop(String) public readonly commit!: string;
	@Prop(Boolean) public value!: boolean;

	// Stores the HEX color to show whenever something is active.
	public activeColor = this.$faroColors.brandInfo;

	public get innerValue() {
		return this.value;
	}

	public set innerValue(value: boolean) {
		this.$emit('input', value);
	}

	public get isAuthenticated(): boolean {
		return this.$auth.isAuthenticated ?? false;
	}

	public get activeWorkspace(): Workspace | null {
		return this.$tsStore.workspaces.activeWorkspace;
	}

	/**
	 * Determines if the "Dashboard" element should be visible.
	 * @author OK
	 */
	public get canDashboard(): boolean {
		return !!this.activeWorkspace &&
			this.$tsStore.users.getWorkspaceAccessControl(this.activeWorkspace.UUID).viewWorkspaceInsightsDashboard.hasPermission;
	}

	/**
	 * Determines if the "Access Control" element should be visible.
	 */
	public get canAccessControl(): boolean {
		return !!this.activeWorkspace &&
			this.$tsStore.users.getWorkspaceAccessControl(this.activeWorkspace.UUID).openAuthz.hasPermission;
	}

	/**
	 * Determines if the "Subscription Service" element should be visible.
	 */
	public get canAdminSubscription(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('adminSubscriptions') || isFeatureEnabledByAuth0PermissionsSync('viewSubscriptions');
	}

	/**
	 * Determines if the "Admin SSO" element should be visible.
	 */
	public get canAdminSso(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('adminSso');
	}

	/**
	 * Determines if the "Migration Dashboard" element should be visible.
	 */
	public get canViewMigrations(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('viewMigrations') &&
			(isFeatureEnabledByAuth0PermissionsSync('viewSubscriptions') || isFeatureEnabledByAuth0PermissionsSync('adminSubscriptions'));
	}

	public get accessControlHref(): string {
		// Use same permissions as in AuthZ > 2_frontend/src/router/routeaccess.ts.
		const workspaceUuid = this.activeWorkspace?.UUID;
		const canReadUsers = !!workspaceUuid && this.$tsStore.users.getWorkspaceAccessControl(workspaceUuid).readUser.hasPermission;
		const canReadProjects = !!workspaceUuid && this.$tsStore.users.getWorkspaceAccessControl(workspaceUuid).readProject.hasPermission;

		// Most users with permissions to open AuthZ are probably Workspace Admins, and will be able to open the Users page.
		// TF-1155 showed that other users may only have access to the Projects and Collections pages.
		// As last fallback, we open the AuthZ entry page of the workspace; not sure if this can occur in practice.
		const page = canReadUsers ? '/users' : (canReadProjects ? '/projects' : '');
		return config.authzUI + '/' + workspaceUuid + page;
	}

	public get subscriptionHref(): string {
		return location.hostname === 'localhost' ? 'http://localhost:8088/subscription' : '/subscription';
	}

	public get ssoConfigHref(): string {
		return location.hostname === 'localhost' ? 'http://localhost:4808/sso-config/admin' : '/sso-config/admin';
	}

	/**
	 * Returns the url for the FARO Imprint (contact information) page.
	 * Currently the FARO Imprint is available in only 3 languages: german, french and italian.
	 */
	public get imprintUrl(): string {
		const locale: Locale = this.$i18n.locale;
		let imprintHref: string = 'https://www.faro.com/de-DE/Imprint';
		if (locale.startsWith('fr')) {
			imprintHref = 'https://www.faro.com/fr-FR/Imprint';
		} else if (locale.startsWith('it')) {
			imprintHref = 'https://www.faro.com/it-IT/Imprint';
		}
		return imprintHref;
	}

	public get isLeftNavigationDrawerMinified(): boolean {
		return this.$tsStore.pages.isLeftNavigationDrawerMinified;
	}

	public set isLeftNavigationDrawerMinified(isLeftNavigationDrawerMinified: boolean) {
		this.$tsStore.pages.setIsLeftNavigationDrawerMinified(isLeftNavigationDrawerMinified);
	}
}
