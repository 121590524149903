
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { config } from '@/config';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { UserBtnTaskTopRightComponentData } from '@/utils/types';
import { BaseServiceAny } from '@/store/services/BaseServiceAny';
import { Generated } from '@faroconnect/authz-client';

export interface SelectWorkspaceInitData {
	pendingWorkspaces: Generated.SubSvcPendingWorkspaces;
	isFullscreen: boolean;
}

@Component
export default class UpdateOrCreateWorkspaceTaskSelect extends Vue {
	@Prop(Object) public readonly initData!: SelectWorkspaceInitData;

	public loading: boolean = false;
	public topRightComponent = UserBtn;
	public topRightComponentData: UserBtnTaskTopRightComponentData = { isFullscreenTask: true };
	public workspaceUuid: string = '';

	public get isFullscreen(): boolean {
		return this.initData.isFullscreen;
	}

	public get title(): string {
		return this.$tc('LP_COMPLETE_WORKSPACE_CREATION');
	}

	public get subtitle(): string {
		return this.$tc('LP_SELECT_PENDING_WORKSPACE');
	}

	public get taskReady(): boolean {
		return !!this.workspaceUuid;
	}

	public get selectedWorkspaceUuid(): string {
		return this.workspaceUuid;
	}

	public getWorkspaceLink(workspaceUuid: string): string {
		return `${config.landingpageUI}/workspaces?wid=${workspaceUuid}`;
	}

	public selectWorkspace(workspaceUuid: string) {
		this.workspaceUuid = workspaceUuid;
	}

	public cancel() {
		this.closeTask();
	}

	public async next() {
		this.$faroComponents.$emit('pending-workspace-selected', this.selectedWorkspaceUuid);
		await this.closeTask();
	}

	public async closeTask() {
		if (this.isFullscreen) {
			await this.$faroTaskService.closeFullscreenTask();
		} else {
			await this.$faroTaskService.closeTask();
		}
	}

	public async created() {
		this.loading = true;
		await BaseServiceAny.getTokenSilentlyWithRedirect();
		this.loading = false;
	}
}
