
import Vue from 'vue';
import Component from 'vue-class-component';
import VueRouter from 'vue-router';
import { Workspace } from '@/classes/authz/Workspace';

@Component
export default class HomePage extends Vue {
	public get activeWorkspace(): Workspace | null {
		return this.$tsStore.workspaces.activeWorkspace;
	}

	public async created() {
		try {
			const workspaceUuid = this.activeWorkspace?.UUID || '';
			await this.$router.replace({ name: 'ProjectsPage', params: { workspace: workspaceUuid } });
		} catch (error) {
			// Omit redirected error, because this is expected.
			if (!VueRouter.isNavigationFailure(error, 2)) {  // 2 = NavigationFailureType.redirected
				this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_REDIRECT' });
				throw error;
			}
		}
	}
}
