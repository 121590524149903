import { Project } from '@/classes/authz/Project';
import { Workspace } from '@/classes/authz/Workspace';
import { IWebShareProject } from '@/definitions/interfaces';
import { HttpClient } from '@faroconnect/clientbase';
import { BaseServiceAny } from './BaseServiceAny';

export interface WebshareDomain {
	Domain: string;
	UUID: string;
	Enabled: boolean;
	TrialExpires: Date;
	DomainExpires: Date;
}

/**
 * Webshare API wrapper.
 */
export class WebshareService {
	/**
	 * Returns webshare domain information for the given subdomain.
	 * @param workspace Workspace.
	 * @author Arden Ercelik
	 */
	public async getDomain(workspace: Workspace): Promise<WebshareDomain> {
		if (workspace.webShareUrl === null) {
			throw Error('Workspace object not fully initialized.');
		}

		// Add trailing slash to avoid getting a redirect.
		const url = workspace.webShareUrl + 'domain/';
		// There is no access check for the read domain route in the WebShare backend. The route can be
		// called by anonymous callers for any domain.

		const client = new HttpClient();
		// throws HttpError
		return await client.get(url);
	}

	/**
	 * Calls WebShare to put a job on the queue for the SCENE generator to create a SCENE project in the
	 * DataHub from the provided Stream project.
	 * @param project Project Stream project for which the user wants to generate a SCENE project.
	 * @param syncId The sync ID from the app key of the Stream project's main file. This is then used as
	 *        value of the "Object" attribute in the job description for the SCENE generator.
	 * @author OK
	 * @throws {HttpError}
	 */
	public async startSceneGenerator(project: Project, syncId: string): Promise<void> {
		// The :projectId URL parameter is unused in DataController::generateSceneDataFromDHAction and so an
		// arbitrary value can be provided for it.
		const url = `${project.DomainUrl}/data/project/${syncId}/generate-scene-data-from-dh?workspaceuuid=${project.Workspace}`;
		const data = {
			Project: project.Name,
			Object: syncId,
			MetaIn: {
				WorkspaceUUID: project.Workspace,
				AuthZUserUUID: project.user?.UUID || null,
			},
		};

		const client = await this.getClientWithCreds();
		// throws HttpError
		await client.post(url, { data });
	}

	/**
	 * Updates project
	 * @param project Project to be updated
	 * @param data New data to update the project with
	 * @throws {HttpError}
	 * @author Shoaib Feda
	 */
	public async updateProject(project: Project, data: object): Promise<IWebShareProject> {
		const client = await this.getClientWithCreds();

		// throws HttpError
		return await client.put(`${project.DomainUrl}/project/${project.UrlId}`, { data });
	}

	/**
	 * Gets project details
	 * @param project Project
	 * @throws {HttpError}
	 * @author MF
	 */
	public async getProjectDetails(project: Project): Promise<IWebShareProject> {
		const client = await this.getClientWithCreds();

		// throws HttpError
		return await client.get(`${project.DomainUrl}/project/${project.UrlId}?workspaceuuid=${project.Workspace}`);
	}

	/**
	 * Gets a client with the current user access_token
	 * @author BE
	 */
	private async getClientWithCreds() {
		const jwt = await BaseServiceAny.getTokenSilentlyWithRedirect();
		const client = new HttpClient();
		// This also works, but adds a lot of logic that we don't need because we don't use refresh tokens:
		// client.authStateless = { jwt };
		client.axiosInstance.defaults.headers.common['authorization'] = 'Bearer ' + jwt;
		return client;
	}
}
