
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { AuthzInterface } from '@faroconnect/authz-client';
import { BASE_URL } from '@/config';
import Component from 'vue-class-component';
import { DeleteUserResponse } from '@/utils/types';

type OnConfirmDelete = { Success: true, Data: DeleteUserResponse } | { Success: false, Data: Error };

@Component
export default class DeleteConfirmationDialog extends Vue {
	/**
	 * Visibility flag.
	 */
	@Prop(Boolean) public value!: boolean;
	public readonly faroSphereTeam = 'FARO Sphere Team';

	public get isVisible(): boolean {
		return this.value;
	}

	public set isVisible(isVisible: boolean) {
		this.$emit('input', isVisible);
	}

	public showSuccess() {
		this.$faroNotify.showConfirmationDialog({
			text: this.$tc('LP_DELETE_ACCOUNT_SUCCESS'),
			title: this.$tc('LP_SUCCESS_ACCOUNT_DELETE_TITLE'),
			group: 'success',
			persistent: true,
			rightButton: {
				color: 'success',
				text: this.$tc('UI_OK'),
				click: this.logout,
			},
		});
	}

	public async onFinally(payload: { Success: true, Data: DeleteUserResponse } | { Success: false, Data: Error }) {
		if (!payload.Success) {
			// It means that the response threw an error, we don't have to handle it here since it is handled in onError.
			return;
		}

		// payload.Success = true + payload.Data.Success = false means the user is the owner of some workspaces and something else has to be done.
		// payload.Success = true + payload.Data.Success = true means the user account was successfully deleted.
		const response: DeleteUserResponse = payload.Data;

		// The user account was deleted correctly, after this the user account does not exist therefore we need to logout the user.
		if (response.Success) {
			this.showSuccess();
		// The workspace could not be deleted because the user is the owner of at least one paid workspace.
		} else if (!response.Success && response.OtherWorkspacesOwner && response.OtherWorkspacesOwner.length > 0) {
			const responseWorkspaces: AuthzInterface.IWorkspace[] = response.OtherWorkspacesOwner;
			const workspacesNames: string = responseWorkspaces.map((workspace) => workspace.Name).join(', ');
			this.$faroNotify.showConfirmationDialog({
				text: this.$tc('LP_ERR_OTHER_WORKSPACE_OWNER_DESC') + '\n\n' + workspacesNames,
				title: this.$tc('LP_ERR_OTHER_WORKSPACE_OWNER_TITLE'),
				group: 'error',
				persistent: false,
				leftButton: {
					textId: 'UI_CANCEL',
				},
			});
		// The workspace could not be deleted because the user is the owner of at least one base workspace.
		} else if (!response.Success && response.BaseWorkspacesOwner && response.BaseWorkspacesOwner.length > 0) {
			const responseWorkspaces: AuthzInterface.IWorkspace[] = response.BaseWorkspacesOwner;
			const workspacesNames: string = responseWorkspaces.map((workspace) => workspace.Name).join(', ');
			this.$faroNotify.showConfirmationDialog({
				text: this.$tc('LP_WARNING_BASE_WORKSPACE_OWNER_DESC') + '\n\n' + workspacesNames,
				title: this.$tc('LP_WARNING_BASE_WORKSPACE_OWNER_TITLE'),
				group: 'warning',
				persistent: false,
				leftButton: {
					textId: 'UI_CANCEL',
				},
				rightButton: {
					color: 'success',
					text: this.$tc('UI_OK'),
					click: this.onConfirmDelete,
					finally: this.onConfirmDeleteFinally,
				},
			});
		} else {
			this.$faroComponents.$emit('show-error', { title: 'UI_UNKNOWN_ERROR', message: 'UI_TRY_AGAIN' });
		}
	}

	public logout() {
		this.$auth.logout({returnTo: window.location.origin + BASE_URL});
	}

	public async onConfirmDelete(): Promise<OnConfirmDelete> {
		try {
			const response = await this.$tsStore.users.deleteCallingUserIgnoreBaseWorkspaces();
			return {
				Success: true,
				Data: response,
			};
		} catch (error) {
			return {
				Success: false,
				Data: error as Error,
			};
		}
	}

	public async onConfirmDeleteFinally(payload: OnConfirmDelete) {
		if (payload.Success) {
			this.onFinally({
				Success: true,
				Data: payload.Data,
			});
		} else {
			this.onError(payload.Data);
		}
	}

	public onError(error: any) {
		if (error.status === 404) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_USER_ACCOUNT_NOT_EXISTS' });
		} else {
			// show internal error to user
			this.$faroComponents.$emit('show-error', { error });
			console.error(error);
		}
	}

	public async deleteAccount(): Promise<DeleteUserResponse> {
		return await this.$tsStore.users.deleteCallingUser();
	}
}
