
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Project, ProjectSource } from '@/classes/authz/Project';
import { ProjectBaseModule } from '@/store/modules/authz/ProjectBaseModule';
import { ProjectStatusModule } from '@/store/modules/projz/ProjectStatusModule';

@Component
export default class FilterProjectSourceTypeSubtype extends Vue {
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: ProjectBaseModule<Project> | ProjectStatusModule;
	@Prop(Boolean) public readonly showSourceFilterHeader!: boolean;

	public get filterByProjectSourceOptions() {
		return this.store.filterByProjectSourceOptions;
	}

	public get filterByAllProjectSources() {
		return this.store.filterByAllProjectSources;
	}

	public set filterByAllProjectSources(filterByAllProjectSources: boolean) {
		if (!filterByAllProjectSources) {
			this.store.setFilterByProjectSource([]);
		}
		this.store.setFilterByAllProjectSources(filterByAllProjectSources);
	}

	public get filterByProjectSource() {
		if (this.filterByAllProjectSources) {
			return this.store.projectSources;
		}
		return this.store.filterByProjectSource;
	}

	public set filterByProjectSource(filterByProjectSource: ProjectSource[]) {
		if (filterByProjectSource.length < this.store.projectSources.length) {
			this.filterByAllProjectSources = false;
		}
		if (filterByProjectSource.length === this.filterByProjectSourceOptions.length) {
			this.filterByAllProjectSources = true;
		}
		this.store.setFilterByProjectSource(filterByProjectSource);
	}
}
