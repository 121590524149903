import { IProject, Project } from '@/classes/authz/Project';
import { BASE_API_URL, config } from '@/config';
import { ErrorInviteUsersProject, ErrorInviteUsersWorkspace, ErrorReadUsersWorkspace } from '@/definitions/errors';
import {
	InviteUsersAndGroupsToProjectAndWorkspaceBody,
	InviteUsersAndGroupsToProjectAndWorkspaceResponse,
	IProjectVisitString,
} from '@/definitions/interfaces';
import { ClientOptions } from '@/store/services/BaseService';
import { getErrorMessage } from '@/utils/errorhandler';
import { getInviteUserProjectConflictErrorHandled } from '@/utils/projecterrors';
import { faroLocalization } from '@faroconnect/baseui';
import { HttpError } from '@faroconnect/clientbase';
import { ConflictError, InternalError } from '@faroconnect/utils';
import Vue from 'vue';
import { ApiResponse } from '../types';
import { BaseServiceAny } from './BaseServiceAny';

export class UtilsProjectService extends BaseServiceAny {
	constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'project',
			apiEndpoint: config.apiEndpoint,
			baseApiUrl: BASE_API_URL,
		}, clientConfig);
	}

	public async getProjects<QueryT extends object>(workspaceUuid: string, query?: QueryT): Promise<IProject[]> {
		const url = this.makeUrl(workspaceUuid, query);
		return await this.get(url);
	}

	public async getLastVisited(projectUuid: string): Promise<IProjectVisitString> {
		const url = this.makeUrl() + '/' + projectUuid + '/get-visited';
		return await this.get(url);
	}

	public async getAllLastVisited(): Promise<IProjectVisitString[]> {
		const url = this.makeUrl() + '/get-all-visited';
		return await this.get(url);
	}

	public async updateLastVisited(projectUuid: string): Promise<IProjectVisitString> {
		const url = this.makeUrl() + '/' + projectUuid + '/update-visit-time';
		return await this.put(url);
	}

	public async removeProject(workspaceUuid: string, project: Project): Promise<{ Success: boolean }> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		const workspace = Vue.prototype.$tsStore.workspaces.ItemsMap[workspaceUuid];
		return await this.client.del(`${config.apiEndpoint}/home/v1/project/${workspaceUuid}/${project.UUID}/${workspace.Region}/uuid`, {
			customHeaders,
		});
	}

	/**
	 * Calls WebShare to update the project image.
	 * @author OK
	 * @param project Project whose image should be updated.
	 * @param contentType The MIME type of the selected image file.
	 * @param data The base64 encoded image data.
	 */
	public async updateProjectImage(
		project: Project,
		contentType: string,
		data: string,
	): Promise<void> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();

		if (project.Type === 'WebShare') {
			const url = `${project.DomainUrl}/data/project/${project.UrlId}/project-image`;
			await this.client.put(url, { customHeaders, contentType, data });
		} else {
			const url = `${project.DomainUrl}/data/project/${project.UUID}/sphere-project-image`;
			await this.client.post(url, { customHeaders, contentType, data });
		}
	}

	public async inviteUsersAndGroupsToProjectAndWorkspace(
		workspaceUuid: string,
		data: InviteUsersAndGroupsToProjectAndWorkspaceBody,
	): Promise<ApiResponse<InviteUsersAndGroupsToProjectAndWorkspaceResponse>> {
		try {
			const customHeaders = await BaseServiceAny.getCustomHeaders();
			const response: InviteUsersAndGroupsToProjectAndWorkspaceResponse =
				await this.client.put(
					`${config.apiEndpoint}/home/v1/project/${workspaceUuid}/invite/user-and-groups`, {
						customHeaders,
						data,
					},
				);
			return {
				Success: true,
				Data: response,
			};
		} catch (error) {
			console.error(error);
			let title: string | undefined;
			let messages: string | undefined | string[];

			const httpError: HttpError = error as HttpError;
			const internalError: InternalError = httpError.responseBody;
			const conflictError: ConflictError = httpError.responseBody;
			const invitedWorkspaceUserEmailsStr: string = data.UserEmailsForWorkspace?.join(', ') ?? '';

			if (httpError.name === 'InternalError') {
				switch (internalError.message) {
					case 'ERROR_INVITE_USERS_WORKSPACE': {
						title = faroLocalization.i18n.tc('LP_ERR_INVITE_USERS_WORKSPACE');
						const infos: ErrorInviteUsersWorkspace = internalError.infos as ErrorInviteUsersWorkspace;
						messages = getErrorMessage(infos.Error.body);
						break;
					}
					case 'ERROR_READ_USERS_WORKSPACE': {
						title = faroLocalization.i18n.tc('LP_ERR_FETCH_USERS_BELONG_WORKSPACE');
						const infos: ErrorReadUsersWorkspace = internalError.infos as ErrorReadUsersWorkspace;
						if (invitedWorkspaceUserEmailsStr) {
							messages = faroLocalization.i18n.tc(
								'LP_INVITE_USERS_WORKSPACE_STILL_SUCCEED',
								undefined,
								{ emails: infos.InvitedSphereUsers.map((user) => user.Email).join(', ') });
						}
						break;
					}
				}
			} else if (httpError.name === 'ConflictError') {
				const infos: ErrorInviteUsersProject = conflictError.infos as ErrorInviteUsersProject;
				const response = getInviteUserProjectConflictErrorHandled(infos.Error.body);
				title = response.title;
				messages = [];

				// Currently, response.messages is always undefined, so none of these cases is hit.
				if (Array.isArray(response.messages)) {
					messages = messages.concat(response.messages);
				} else if (typeof response.messages === 'string') {
					messages.push(response.messages);
				}
				messages.push(
					faroLocalization.i18n.tc(
						'LP_INVITE_USERS_WORKSPACE_STILL_SUCCEED',
						undefined,
						{ emails: infos.InvitedSphereUsers.concat(infos.InvitedWorkspaceUsers).map((user) => user.Email).join(', ') },
					),
				);
			}

			if (!title) {
				title = faroLocalization.i18n.tc('LP_ERR_INVITE_USERS');
			}
			if (!messages) {
				messages = getErrorMessage(error);
			}

			return {
				Success: false,
				Data: {
					ErrorTitle: title,
					ErrorMessages: messages,
				},
				traceId: httpError.traceId,
			};
		}
	}
}
