import { config } from '@/config';
import { $assert } from '@faroconnect/utils';

/**
 * Build a WebShare URL, considering special cases for localhost.
 * @param region Sphere region "eu" / "us"-
 * @param domain Subdomain (WebshareDomainName or WebshareAlias). Ignored for localhost URLs.
 * @param path Optional path to add to the URL.
 * @returns URL.
 */
export function webShareUrl(region: 'eu' | 'us' | '', domain: string, path: string = '') {
	const webShareHost = region === 'eu' ? config.webShareEU : config.webShareUS;
	// For DEV, it's a known issue that some workspaces have Region === 'eu' but are either in the US or don't exist.
	$assert.Assert(webShareHost || (region === 'eu' && config.env === 'dev'), 'WebShare host is undefined for region ' + region);

	if (webShareHost?.startsWith('localhost')) {
		return `${config.webShareProtocol}://${webShareHost!}${path}`;
	} else {
		return `${config.webShareProtocol}://${domain}.${webShareHost!}${path}`;
	}
}

/**
 * Encodes a URL query parameter for WebShare.
 * Due to WebShare's custom URL encoding, the standard URL encoding of special chars with % must be exchanged with $.
 * @author OK
 * @param value Parameter value to encode, e.g. "DataHub (2)" --> "DataHub$20(2)"
 */
export function encodeParamForWebShare(value: string): string {
	return encodeURIComponent(value).replace(/%/gi, '$');
}
