
import Vue from 'vue';
import Component from 'vue-class-component';
import { LpEntity } from '@/classes';
import { Prop } from 'vue-property-decorator';
import { BaseFilterModule, FilterByDate, FilterByDateLimit } from '@/store/modules/BaseFilterModule';
import DatePickerInput from '@/components/Embeddable/DatePickerInput.vue';
import { MathUtils } from '@faroconnect/utils';

@Component({
	components: {
		DatePickerInput,
	},
})
export default class FilterDateMenu<EntityT extends LpEntity> extends Vue {
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: BaseFilterModule<EntityT>;

	// -------------------------

	public get dateTypes(): FilterByDateLimit[] {
		return this.store.dateTypes;
	}

	// -------------------------

	public get filterByDateOptions() {
		return this.store.filterByDateOptions;
	}

	public get dateType(): FilterByDate<EntityT>['dateType'] {
		return this.store.filterByDate.dateType;
	}

	public set dateType(dateType: FilterByDate<EntityT>['dateType']) {
		this.store.updateFilterByDate({ dateType });
	}

	// -------------------------

	public get dateLimit(): FilterByDate<EntityT>['dateLimit'] {
		return this.store.filterByDate.dateLimit;
	}

	public set dateLimit(dateLimit: FilterByDate<EntityT>['dateLimit']) {
		this.store.updateFilterByDate({ dateLimit });
	}

	// -------------------------

	public get datePeriodNumber(): number {
		return this.store.filterByDate.datePeriodNumber;
	}

	public set datePeriodNumber(datePeriodNumber: number) {
		const parsedDatePeriodNumber = MathUtils.parseIntOrNull(datePeriodNumber);
		this.store.updateFilterByDate({ datePeriodNumber: parsedDatePeriodNumber ?? 1 });
	}

	// -------------------------

	public get datePeriodTimeMeasure(): FilterByDate<EntityT>['datePeriodTimeMeasure'] {
		return this.store.filterByDate.datePeriodTimeMeasure;
	}

	public set datePeriodTimeMeasure(datePeriodTimeMeasure: FilterByDate<EntityT>['datePeriodTimeMeasure']) {
		this.store.updateFilterByDate({ datePeriodTimeMeasure });
	}

	public get timeMeasures() {
		return this.store.timeMeasures;
	}

	// -------------------------

	public get dateRange(): [string, string?] {
		const dateRange: [string, string?] = [this.store.getPrettyDate(this.store.filterByDate.dateRangeFrom)];

		if (this.store.filterByDate.dateRangeTo) {
			dateRange.push(this.store.getPrettyDate(this.store.filterByDate.dateRangeTo));
		}
		return dateRange;
	}

	public set dateRange(dateRange: [string, string?]) {
		const dateRangeFrom = this.store.getPrettyDate(new Date(dateRange[0]));
		this.store.updateFilterByDate({ dateRangeFrom });
		if (dateRange[1]) {
			const dateRangeTo = this.store.getPrettyDate(new Date(dateRange[1]));
			this.store.updateFilterByDate({ dateRangeTo });
		} else {
			this.store.updateFilterByDate({ dateRangeTo: null });
		}
	}

	// -------------------------

	public selectFilterByDate() {
		this.store.updateFilterByDate({ dateLimit: 'DATE' });
	}

	public async onRadioClicked(ev: PointerEvent) {
		await Vue.nextTick();
		// Focus the event that was clicked. This is helpful to edit a text field if it was disabled
		// because the radio was not chosen.
		(ev?.target as any)?.focus();
	}
}
