
import Component from 'vue-class-component';
import PageBaseMixin from '@/mixins/PageBaseMixin';
import { Application } from '@/classes/Application';
import { Header } from '@/utils/listview';
import { ItemButtons } from '@/utils/types';
import InfoAppTask from '@/components/Tasks/InfoAppTask.vue';
import { ApplicationMobile } from '@/classes/ApplicationMobile';

@Component
export default class ApplicationsPage extends PageBaseMixin<Application> {
	// ######################## Inherited Getters ####################

	public get xGetAllErrString(): string {
		return this.$tc('LP_ERR_GET_APPLICATIONS');
	}

	// Return type should be ModuleNameOf<Application> but the TypeScript compiler gives the error message
	//   Type '"applications"' is not assignable to type '"services"'.
	// Neither Muksitur Rahman nor Oliver Knörzer could find the reason for this since all relevant code
	// parts look fine.
	public get xStoreName(): any {
		return 'applications';
	}

	public get xPageName(): string {
		return 'ApplicationsPage';
	}

	public get xItems() {
		return this.$tsStore.applications.itemsList;
	}

	public get xFilteredItems() {
		return this.$tsStore.applications.filteredItems;
	}

	public get xEntitiesOnPage() {
		return this.$tsStore.applications.entitiesOnPage;
	}

	public get xItemButtons(): ItemButtons<Application> {
		return [
			{
				icon: '$vuetify.icons.36_action-info',
				caption: this.$tc('UI_INFO'),
				click: (entity: ApplicationMobile) => this.openInfoMobileApp(entity),
				// Hiding the meatballs menu entirely would change the tile size, so for now we disable the single menu item instead.
				getDisabled: (entity: Application) => !(entity instanceof ApplicationMobile),
			},
		];
	}

	public get xSortItems() {
		return [
			this.xEntitySortBy.name,
		];
	}

	public get xHeaders(): Array<Header<Application>> {
		return [
			this.xEntityHeader.imgUrl,
			{
				text: 'Name',
				value: 'translatedStringIdOrName',
				width: '200px',
			},
			{
				...this.xEntityHeader.actions,
				width: '100px',
			},
		];
	}

	// ######################## Inherited Methods ####################

	public async xOpenEntity(entity: Application) {
		if (entity instanceof ApplicationMobile) {
			this.openInfoMobileApp(entity);
		} else {
			window.open(entity.LocalizedUrl, '_blank');
		}
	}

	// ######################## Class Methods ####################

	public openInfoMobileApp(entity: ApplicationMobile) {
		this.$faroTaskService.showTask<ApplicationMobile>(InfoAppTask, entity);
	}

	public async mounted() {
		await this.xInitialize();
	}
}
