
import Vue, { VueConstructor } from 'vue';
import Component from 'vue-class-component';

@Component
export default class HorizontalExpand extends Vue {
	public menuVisible = false;

	public get appBarRightComponents(): VueConstructor<Vue>[] {
		return this.$tsStore.pages.appBarRightButtons.filter(({visible}) => visible).map(({component}) => component);
	}

	public created() {
		this.$faroComponents.$on('close-horizontal-expand', () => {
			this.menuVisible = false;
		});
	}
}
