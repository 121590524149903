import Vue from 'vue';
import Vuex from 'vuex';
import { PageModule } from '@/store/modules/PageModule';
import { SettingsModule } from '@/store/modules/SettingsModule';
import { ProjectModule } from '@/store/modules/authz/ProjectModule';
import { WorkspaceModule } from '@/store/modules/authz/WorkspaceModule';
import { ApplicationModule } from '@/store/modules/ApplicationModule';
import { ServiceModule } from '@/store/modules/ServiceModule';
import { UserModule } from '@/store/modules/authz/UserModule';
import { WorkspaceRoleModule } from '@/store/modules/authz/WorkspaceRoleModule';
import { ProjectRoleModule } from '@/store/modules/authz/ProjectRoleModule';
import { GroupModule } from '@/store/modules/authz/GroupModule';
import { ProjectStatusModule } from '@/store/modules/projz/ProjectStatusModule';
import { MigrationsModule } from './modules/MigrationsModule';
import { MigrationWorkspaceModule } from './modules/MigrationWorkspaceModule';

Vue.use(Vuex);

// Debugging in AWS Dev environment may be slow if there are many projects
const isFaroEnv = process?.env?.VUE_APP_FAROENV === 'dev';

// No debugging in production
const isProd = process.env.NODE_ENV === 'production';

// Enable Debugging in development
const debug = !isFaroEnv && !isProd;
if (debug) {
	console.warn('Enabling strict mode of Store. For development only!');
}

enum Modules {
	authentication = 'authentication',
}

const store = new Vuex.Store({
	actions: {
		/**
		 * Resets the stores' states to the original states.
		 * Requires a reset mutation in every store.
		 * Seems unused.
		 */
		reset({dispatch}) {
			Object.keys(Modules).forEach((moduleName) => {
				if (store.hasModule(moduleName)) {
					void dispatch(`${moduleName}/reset`);
				}
			});
		},
	},
	strict: debug,
});

export const pages = new PageModule({store, name: 'pages'});
export const projects = new ProjectModule(pages, {store, name: 'projects'});
export const workspaces = new WorkspaceModule(pages, {store, name: 'workspaces'});
export const applications = new ApplicationModule(pages, {store, name: 'applications'});
export const services = new ServiceModule(pages, {store, name: 'services'});
export const users = new UserModule( {store, name: 'users'});
export const workspaceRoles = new WorkspaceRoleModule( {store, name: 'workspaceRoles'});
export const projectRoles = new ProjectRoleModule(pages, {store, name: 'projectRoles'});
export const settings = new SettingsModule({ store, name: 'settings' });
export const groups = new GroupModule( {store, name: 'groups'});
export const projectStatuses = new ProjectStatusModule(pages, projects, {store, name: 'projectStatuses'});
export const migrationWorkspaces = new MigrationWorkspaceModule(pages, { store, name: 'migrationWorkspaces'});
export const migrations = new MigrationsModule(pages, { store, name: 'migrations'});

export class ModulesClass {
	public pages = pages;
	public projects = projects;
	public workspaces = workspaces;
	public applications = applications;
	public services = services;
	public users = users;
	public workspaceRoles = workspaceRoles;
	public projectRoles = projectRoles;
	public groups = groups;
	public settings = settings;
	public projectStatuses = projectStatuses;
	public migrationWorkspaces = migrationWorkspaces;
	public migrations = migrations;
}

Vue.prototype.$tsStore = new ModulesClass();
export const tsStore: ModulesClass = Vue.prototype.$tsStore;

declare global {
	// Declares globally the type $tsStore, this is helpful in cases that this file can't be imported due to circular references,
	// for example in all module files. For those cases you can simply create a variable const $tsStore: $tsStore = Vue.prototype.$tsStore;
	// and use $tsStore normally.
	type $tsStore = ModulesClass;
}

declare module 'vue/types/vue' {
	// Declare augmentations for Vue.
	interface Vue {
		$tsStore: ModulesClass;
	}
}

export default store;
