import { AuthzInterface, AuthzType } from '@faroconnect/authz-client';
import { BaseEntity } from '@/classes/BaseEntity';
import { faroLocalization } from '@faroconnect/baseui';

type WorkspaceRoleClass = 'WorkspaceRole';

export const WorkspaceRoleNameStringId: { [key in AuthzType.PredefinedWorkspaceRoleId]: string } = {
	'workspace-admin': 'UI_WORKSPACE_ADMIN',
	'project-admin': 'UI_PROJECT_ADMIN',
	'project-creator': 'UI_PROJECT_CREATOR',
	'analyst': 'UI_ANALYST',
	'workspace-viewer': 'UI_WORKSPACE_VIEWER',
};

export const WorkspaceRoleDescStringId: { [key in AuthzType.PredefinedWorkspaceRoleId]: string } = {
	'workspace-admin': 'UI_WORKSPACE_ADMIN_DESC',
	'project-admin': 'UI_PROJECT_ADMIN_DESC',
	'project-creator': 'UI_PROJECT_CREATOR_DESC',
	'analyst': 'UI_ANALYST_DESC',
	'workspace-viewer': 'UI_WORKSPACE_VIEWER_DESC',
};

export class WorkspaceRole extends BaseEntity implements AuthzInterface.IWorkspaceRole {
	public static readonly constructorName = WorkspaceRole.name;

	public static fromResponse(json: AuthzInterface.IWorkspaceRole) {
		return new WorkspaceRole(json);
	}

	public static forRequest(json: Partial<AuthzInterface.IWorkspaceRole>) {
		return new WorkspaceRole(json);
	}

	public readonly isWorkspaceRole: boolean = true;

	public ImgUrl: string = '';
	public DefaultImgUrl: string = '';

	public Class: WorkspaceRoleClass = 'WorkspaceRole';
	public Id: AuthzType.PredefinedWorkspaceRoleId | null;
	public NumberOfUsers: number;
	public StillAvailable: number;
	public Workspace: string;
	public WorkspacePermissionUuids: string[];
	public WorkspacePermissionNames: string[];
	public WorkspacePermissions!: never;
	public Type: AuthzType.WorkspaceRoleType;
	public Predefined: boolean;

	protected constructor(obj: Partial<AuthzInterface.IWorkspaceRole>) {
		super(obj);
		this.Id = obj.Id ?? null;
		this.Workspace = obj.Workspace ?? '';
		this.Type = obj.Type ?? 'member';
		this.Predefined = obj.Predefined ?? true; // true as default puts the role into read-only mode.
		this.NumberOfUsers = obj.NumberOfUsers ?? 0;
		this.WorkspacePermissionUuids = obj.WorkspacePermissionUuids ?? [];
		this.WorkspacePermissionNames = obj.WorkspacePermissionNames ?? [];

		this.StillAvailable = 0;
		if (obj.StillAvailable) {
			this.StillAvailable = obj.StillAvailable === -1 ? Infinity : obj.StillAvailable;
		}

		if (obj.WorkspacePermissions) {
			this.WorkspacePermissionUuids = obj.WorkspacePermissions.map((permission) => permission.UUID);
			this.WorkspacePermissionNames = obj.WorkspacePermissions.map((permission) => permission.Name);
		}
	}

	public get displayName(): string {
		if (this.Predefined && this.Id && WorkspaceRoleNameStringId[this.Id]) {
			return faroLocalization.i18n.tc(WorkspaceRoleNameStringId[this.Id]);
		}
		return this.Name;
	}

	public get predefinedDescription(): string {
		if (this.Predefined && this.Id && WorkspaceRoleDescStringId[this.Id]) {
			return faroLocalization.i18n.tc(WorkspaceRoleDescStringId[this.Id]);
		}
		return '';
	}

	public get displayDescription(): string {
		return this.Description || this.predefinedDescription;
	}
}
