import { UuidUtils } from '@faroconnect/utils';
import { IBaseEntity } from '@/definitions/interfaces';

export abstract class BaseEntity implements IBaseEntity {
	public abstract Class: string;
	public UUID: string;
	public CyId: string;
	public Name: string;
	public Description: string;
	public CreationDate: string;
	public UpdateDate: string;
	public Version: number;
	public abstract ImgUrl: string;
	public abstract DefaultImgUrl: string;

	// Authz
	public readonly isGroup: boolean = false;
	public readonly isProject: boolean = false;
	public readonly isProjectRole: boolean = false;
	public readonly isUser: boolean = false;
	public readonly isWorkspace: boolean = false;
	public readonly isWorkspaceRole: boolean = false;

	// Projz
	public readonly isProjectStatus: boolean = false;

	// LP
	public readonly isApplication: boolean = false;
	public readonly isApplicationAny: boolean = false;
	public readonly isApplicationWebShare: boolean = false;

	public readonly isService: boolean = false;
	public readonly isServiceAcademy: boolean = false;
	public readonly isServiceAny: boolean = false;
	public readonly isServiceFaroNow: boolean = false;

	public constructor(json: Partial<IBaseEntity>) {
		this.UUID = json.UUID ?? UuidUtils.generateUuid();
		// Use the UUID as default if not provided so we have always an id to identify elements.
		this.CyId = json.CyId ?? this.UUID;
		this.Name = json.Name ?? '';
		this.Description = json.Description ?? '';
		this.Version = json.Version ?? 1;
		// TODO: CreationDate should not have date strings in two different formats, the SQL DateTime format and the extended ISO format.
		this.CreationDate = json.CreationDate ?? new Date().toISOString();
		// TODO: UpdateDate should not have date strings in two different formats, the SQL DateTime format and the extended ISO format.
		this.UpdateDate = json.UpdateDate ?? new Date().toISOString();
	}

	public get prettyCreationDate(): string {
		return this.getPrettyDate(this.CreationDate);
	}

	public get prettyUpdateDate(): string {
		// 1970 means "no date", so in this case "never updated".
		// The code should fall back to the creation date if the UpdateDate is 1970.
		if (this.UpdateDate !== '1970-01-01T00:00:00.000Z') {
			return this.getPrettyDate(this.UpdateDate);
		} else {
			return this.getPrettyDate(this.CreationDate);
		}
	}

	/**
	 * Gets a date in a pretty format (without any additional information than the date itself).
	 * @param date A string containing a date in the ISO format yyyy-mm-dd.
	 * @returns A string with the prettified date.
	 */
	protected getPrettyDate(date: string): string {
		if (!date) {
			return '';
		}
		return date.substr(0, 10);
	}
}
