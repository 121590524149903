
// ################################################################################################
// ################################################################################################
// ####                                     COPIED FILE!                                       ####
// ####    The files in this folder were copied from 4_shared/.., using '''npm run build'''    ####
// #### in 4_shared. Please ONLY adjust the files in 4_shared and check in the modified files. ####
// ################################################################################################
// ################################################################################################

import { PageLayout, FilterTabs } from './types';

export const PAGE_LAYOUT_ENUM: { [key in PageLayout]: key } = {
	thumbnail: 'thumbnail',
	list: 'list',
};

export const PAGE_LAYOUTS: PageLayout[] = Object.values(PAGE_LAYOUT_ENUM);

export const FILTER_TABS_ENUM: { [key in FilterTabs]: key} = {
	TAB_DATE: 'TAB_DATE',
	TAB_PROJECT: 'TAB_PROJECT',
	TAB_TYPE: 'TAB_TYPE',
};

export const FILTER_TABS: FilterTabs[] = Object.values(FILTER_TABS_ENUM);
