import * as coreHttp from "@azure/core-http";

export const Order: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Order",
    modelProperties: {
      id: {
        serializedName: "Id",
        type: {
          name: "String"
        }
      },
      customerId: {
        serializedName: "CustomerId",
        required: true,
        type: {
          name: "String"
        }
      },
      workspaceId: {
        serializedName: "WorkspaceId",
        type: {
          name: "String"
        }
      },
      subscriptionId: {
        serializedName: "SubscriptionId",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "Type",
        required: true,
        type: {
          name: "String"
        }
      },
      subscriptionType: {
        serializedName: "SubscriptionType",
        type: {
          name: "String"
        }
      },
      isDemo: {
        serializedName: "IsDemo",
        type: {
          name: "Boolean"
        }
      },
      startDate: {
        serializedName: "StartDate",
        type: {
          name: "DateTime"
        }
      },
      endDate: {
        serializedName: "EndDate",
        type: {
          name: "DateTime"
        }
      },
      orderNumber: {
        serializedName: "OrderNumber",
        required: true,
        type: {
          name: "String"
        }
      },
      region: {
        serializedName: "Region",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "Country",
        type: {
          name: "String"
        }
      },
      currency: {
        serializedName: "Currency",
        type: {
          name: "String"
        }
      },
      internalContacts: {
        serializedName: "InternalContacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      storageAmount: {
        serializedName: "StorageAmount",
        type: {
          name: "Number"
        }
      },
      advancedUsersAmount: {
        serializedName: "AdvancedUsersAmount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OrderCreatedInfo: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OrderCreatedInfo",
    modelProperties: {
      orderId: {
        serializedName: "OrderId",
        required: true,
        type: {
          name: "String"
        }
      },
      workspaceId: {
        serializedName: "WorkspaceId",
        required: true,
        type: {
          name: "String"
        }
      },
      orderNumber: {
        serializedName: "OrderNumber",
        required: true,
        type: {
          name: "String"
        }
      },
      erpId: {
        serializedName: "ErpId",
        required: true,
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "Type",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OrderUpdate: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OrderUpdate",
    modelProperties: {
      type: {
        serializedName: "Type",
        type: {
          name: "String"
        }
      },
      startDate: {
        serializedName: "StartDate",
        type: {
          name: "DateTime"
        }
      },
      endDate: {
        serializedName: "EndDate",
        type: {
          name: "DateTime"
        }
      },
      orderNumber: {
        serializedName: "OrderNumber",
        type: {
          name: "String"
        }
      },
      region: {
        serializedName: "Region",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "Country",
        type: {
          name: "String"
        }
      },
      currency: {
        serializedName: "Currency",
        type: {
          name: "String"
        }
      },
      storageAmount: {
        serializedName: "StorageAmount",
        type: {
          name: "Number"
        }
      },
      advancedUsersAmount: {
        serializedName: "AdvancedUsersAmount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Customer: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Customer",
    modelProperties: {
      id: {
        serializedName: "ID",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "State",
        type: {
          name: "String"
        }
      },
      preSignUpEmail: {
        serializedName: "PreSignUpEmail",
        required: true,
        type: {
          name: "String"
        }
      },
      sAPCustomerNumber: {
        serializedName: "SAPCustomerNumber",
        type: {
          name: "String"
        }
      },
      erpid: {
        serializedName: "ERPID",
        type: {
          name: "String"
        }
      },
      company: {
        serializedName: "Company",
        type: {
          name: "String"
        }
      },
      defaultRegion: {
        serializedName: "DefaultRegion",
        type: {
          name: "String"
        }
      },
      defaultCountry: {
        serializedName: "DefaultCountry",
        type: {
          name: "String"
        }
      },
      defaultCurrency: {
        serializedName: "DefaultCurrency",
        type: {
          name: "String"
        }
      },
      additionalInformation: {
        serializedName: "AdditionalInformation",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateCustomerParams: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateCustomerParams",
    modelProperties: {
      company: {
        serializedName: "Company",
        type: {
          name: "String"
        }
      },
      sAPCustomerNumber: {
        serializedName: "SAPCustomerNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateWorkspaceParams: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateWorkspaceParams",
    modelProperties: {
      ownerId: {
        serializedName: "OwnerId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "Name",
        required: true,
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "Description",
        required: true,
        type: {
          name: "String"
        }
      },
      region: {
        serializedName: "Region",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Workspace: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Workspace",
    modelProperties: {
      id: {
        serializedName: "Id",
        required: true,
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "State",
        required: true,
        type: {
          name: "String"
        }
      },
      ownerId: {
        serializedName: "OwnerId",
        required: true,
        type: {
          name: "String"
        }
      },
      erpId: {
        serializedName: "ErpId",
        required: true,
        type: {
          name: "String"
        }
      },
      subscription: {
        serializedName: "Subscription",
        type: {
          name: "Composite",
          className: "Subscription"
        }
      },
      region: {
        serializedName: "Region",
        required: true,
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "Name",
        required: true,
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "Description",
        type: {
          name: "String"
        }
      },
      image: {
        serializedName: "Image",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "CreatedAt",
        required: true,
        type: {
          name: "DateTime"
        }
      },
      faroContacts: {
        serializedName: "FaroContacts",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      notes: {
        serializedName: "Notes",
        type: {
          name: "String"
        }
      },
      deactivatedAt: {
        serializedName: "DeactivatedAt",
        type: {
          name: "DateTime"
        }
      },
      deactivationDate: {
        serializedName: "DeactivationDate",
        type: {
          name: "DateTime"
        }
      },
      billedModule: {
        serializedName: "BilledModule",
        type: {
          name: "Boolean"
        }
      },
      billedSubscription: {
        serializedName: "BilledSubscription",
        type: {
          name: "Boolean"
        }
      },
      commerciallyRelevant: {
        serializedName: "CommerciallyRelevant",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const WorkspaceOverview: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkspaceOverview",
    modelProperties: {
      id: {
        serializedName: "Id",
        required: true,
        type: {
          name: "String",
        },
      },
      state: {
        serializedName: "State",
        required: true,
        type: {
          name: "String",
        },
      },
      createdAt: {
        serializedName: "CreatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      cloudRegion: {
        serializedName: "CloudRegion",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "Name",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "Description",
        required: true,
        type: {
          name: "String",
        },
      },
      notes: {
        serializedName: "Notes",
        required: true,
        type: {
          name: "String",
        },
      },
      erpId: {
        serializedName: "ErpId",
        required: true,
        type: {
          name: "String",
        },
      },
      faroContacts: {
        serializedName: "FaroContacts",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      deactivationDate: {
        serializedName: "DeactivationDate",
        type: {
          name: "DateTime",
        },
      },
      ownerId: {
        serializedName: "OwnerId",
        required: true,
        type: {
          name: "String",
        },
      },
      ownerEmail: {
        serializedName: "OwnerEmail",
        type: {
          name: "String",
        },
      },
      subscriptionType: {
        serializedName: "SubscriptionType",
        type: {
          name: "String",
        },
      },
      subscriptionIsTrial: {
        serializedName: "SubscriptionIsTrial",
        type: {
          name: "Boolean",
        },
      },
      subscriptionStartDate: {
        serializedName: "SubscriptionStartDate",
        type: {
          name: "DateTime",
        },
      },
      customerId: {
        serializedName: "CustomerId",
        type: {
          name: "String",
        },
      },
      customerPreSignUpEmail: {
        serializedName: "CustomerPreSignUpEmail",
        type: {
          name: "String",
        },
      },
      customerCompany: {
        serializedName: "CustomerCompany",
        type: {
          name: "String",
        },
      },
      customerSAPNumber: {
        serializedName: "CustomerSAPNumber",
        type: {
          name: "String",
        },
      },
      commerciallyRelevant: {
        serializedName: "CommerciallyRelevant",
        type: {
          name: "Boolean",
        },
      },
      billedSubscription: {
        serializedName: "BilledSubscription",
        type: {
          name: "Boolean",
        },
      },
      billedModule: {
        serializedName: "BilledModule",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Subscription: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Subscription",
    modelProperties: {
      id: {
        serializedName: "Id",
        required: true,
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "Type",
        type: {
          name: "String"
        }
      },
      startDate: {
        serializedName: "StartDate",
        type: {
          name: "DateTime"
        }
      },
      endDate: {
        serializedName: "EndDate",
        type: {
          name: "DateTime"
        }
      },
      expirationDate: {
        serializedName: "ExpirationDate",
        type: {
          name: "DateTime"
        }
      },
      isTrial: {
        serializedName: "IsTrial",
        type: {
          name: "Boolean"
        }
      },
      items: {
        serializedName: "Items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubscriptionItem"
            }
          }
        }
      },
      billing: {
        serializedName: "Billing",
        type: {
          name: "Composite",
          className: "Billing"
        }
      }
    }
  }
};

export const SubscriptionItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SubscriptionItem",
    modelProperties: {
      id: {
        serializedName: "Id",
        required: true,
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "Type",
        type: {
          name: "String"
        }
      },
      isBase: {
        serializedName: "IsBase",
        type: {
          name: "Boolean"
        }
      },
      amount: {
        serializedName: "Amount",
        type: {
          name: "Number"
        }
      },
      billing: {
        serializedName: "Billing",
        type: {
          name: "Composite",
          className: "Billing"
        }
      }
    }
  }
};

export const Billing: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Billing",
    modelProperties: {
      id: {
        serializedName: "ID",
        required: true,
        type: {
          name: "String"
        }
      },
      interval: {
        serializedName: "Interval",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "CreatedAt",
        type: {
          name: "DateTime"
        }
      },
      canceledAt: {
        serializedName: "CanceledAt",
        type: {
          name: "DateTime"
        }
      },
      endedAt: {
        serializedName: "EndedAt",
        type: {
          name: "DateTime"
        }
      },
      startDate: {
        serializedName: "StartDate",
        type: {
          name: "DateTime"
        }
      },
      currentPeriodStart: {
        serializedName: "CurrentPeriodStart",
        type: {
          name: "DateTime"
        }
      },
      currentPeriodEnd: {
        serializedName: "CurrentPeriodEnd",
        type: {
          name: "DateTime"
        }
      },
      cancelAtPeriodEnd: {
        serializedName: "CancelAtPeriodEnd",
        type: {
          name: "Boolean"
        }
      },
      collectionMethod: {
        serializedName: "CollectionMethod",
        type: {
          name: "String"
        }
      },
      additionalInformation: {
        serializedName: "AdditionalInformation",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AdminWorkspace: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AdminWorkspace",
    modelProperties: {
      workspace: {
        serializedName: "Workspace",
        type: {
          name: "Composite",
          className: "Workspace"
        }
      },
      customer: {
        serializedName: "Customer",
        type: {
          name: "Composite",
          className: "Customer"
        }
      },
      owner: {
        serializedName: "Owner",
        type: {
          name: "Composite",
          className: "Owner"
        }
      }
    }
  }
};

export const Owner: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Owner",
    modelProperties: {
      email: {
        serializedName: "Email",
        required: true,
        type: {
          name: "String"
        }
      },
      company: {
        serializedName: "Company",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "Country",
        type: {
          name: "String"
        }
      },
      currency: {
        serializedName: "Currency",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkspaceSubscriptions: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkspaceSubscriptions",
    modelProperties: {
      workspaceId: {
        serializedName: "WorkspaceId",
        required: true,
        type: {
          name: "String"
        }
      },
      subscriptions: {
        serializedName: "Subscriptions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Subscription"
            }
          }
        }
      }
    }
  }
};

export const WorkspaceQuotaLimits: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkspaceQuotaLimits",
    modelProperties: {
      workspaceId: {
        serializedName: "WorkspaceId",
        required: true,
        type: {
          name: "String"
        }
      },
      quotaLimits: {
        serializedName: "QuotaLimits",
        type: {
          name: "Composite",
          className: "QuotaLimits"
        }
      }
    }
  }
};

export const QuotaLimits: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "QuotaLimits",
    modelProperties: {
      advancedUsers: {
        serializedName: "AdvancedUsers",
        required: true,
        type: {
          name: "Number"
        }
      },
      storage: {
        serializedName: "Storage",
        required: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkspacePricing: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkspacePricing",
    modelProperties: {
      workspaceId: {
        serializedName: "WorkspaceId",
        required: true,
        type: {
          name: "String"
        }
      },
      currency: {
        serializedName: "Currency",
        required: true,
        type: {
          name: "String"
        }
      },
      pricing: {
        serializedName: "Pricing",
        required: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkspaceState: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkspaceState",
    modelProperties: {
      workspaceId: {
        serializedName: "WorkspaceId",
        required: true,
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "State",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateWorkspaceParams: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateWorkspaceParams",
    modelProperties: {
      name: {
        serializedName: "Name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "Description",
        type: {
          name: "String"
        }
      },
      region: {
        serializedName: "Region",
        type: {
          name: "String"
        }
      },
      customer: {
        serializedName: "Customer",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "Notes",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Deactivate: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Deactivate",
    modelProperties: {
      deactivateAt: {
        serializedName: "DeactivateAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ReactivateSubscription: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReactivateSubscription",
    modelProperties: {
      subscriptionType: {
        serializedName: "SubscriptionType",
        required: true,
        type: {
          name: "String"
        }
      },
      isTrial: {
        serializedName: "IsTrial",
        type: {
          name: "Boolean"
        }
      },
      trialExpiration: {
        serializedName: "TrialExpiration",
        type: {
          name: "DateTime"
        }
      },
      advancedUsers: {
        serializedName: "AdvancedUsers",
        type: {
          name: "Number"
        }
      },
      storage: {
        serializedName: "Storage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DeactivateHint: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeactivateHint",
    modelProperties: {
      canBeDeactivated: {
        serializedName: "CanBeDeactivated",
        required: true,
        type: {
          name: "Boolean"
        }
      },
      subscriptionPeriodEnd: {
        serializedName: "SubscriptionPeriodEnd",
        type: {
          name: "DateTime"
        }
      },
      deactivatedAt: {
        serializedName: "DeactivatedAt",
        type: {
          name: "DateTime"
        }
      },
      scheduledForDeactivationAt: {
        serializedName: "ScheduledForDeactivationAt",
        type: {
          name: "DateTime"
        }
      },
      deactivationScheduledFor: {
        serializedName: "DeactivationScheduledFor",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ReportsParams: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReportsParams",
    modelProperties: {
      dates: {
        serializedName: "Dates",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "DateTime"
            }
          }
        }
      }
    }
  }
};

export const Report: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Report",
    modelProperties: {
      title: {
        serializedName: "Title",
        type: {
          name: "String"
        }
      },
      from: {
        serializedName: "From",
        type: {
          name: "DateTime"
        }
      },
      to: {
        serializedName: "To",
        type: {
          name: "DateTime"
        }
      },
      categories: {
        serializedName: "Categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReportCategory"
            }
          }
        }
      }
    }
  }
};

export const ReportCategory: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReportCategory",
    modelProperties: {
      title: {
        serializedName: "Title",
        type: {
          name: "String"
        }
      },
      from: {
        serializedName: "From",
        type: {
          name: "DateTime"
        }
      },
      to: {
        serializedName: "To",
        type: {
          name: "DateTime"
        }
      },
      entries: {
        serializedName: "Entries",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReportEntry"
            }
          }
        }
      }
    }
  }
};

export const ReportEntry: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReportEntry",
    modelProperties: {
      title: {
        serializedName: "Title",
        type: {
          name: "String"
        }
      },
      from: {
        serializedName: "From",
        type: {
          name: "DateTime"
        }
      },
      to: {
        serializedName: "To",
        type: {
          name: "DateTime"
        }
      },
      comment: {
        serializedName: "Comment",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ValidationResult: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ValidationResult",
    modelProperties: {
      valid: {
        serializedName: "Valid",
        required: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Text: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Text",
    modelProperties: {
      text: {
        serializedName: "Text",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CheckExistResult: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CheckExistResult",
    modelProperties: {
      exists: {
        serializedName: "Exists",
        required: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};
