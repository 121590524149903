
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface Page {
	href: string;
	text: string;
}

@Component
export default class ServicesBtn extends Vue {
	@Prop(Boolean) public readonly listDropdown!: boolean;

	public get services() {
		return this.$tsStore.services.itemsList;
	}

	public get pages(): Page[] {
		return this.services.map((service) => ({
			href: service.Url,
			text: service.Name,
		}));
	}

	public async created() {
		await this.$tsStore.services.getAll();
	}
}
