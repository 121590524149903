import { AuthzInterface } from '@faroconnect/authz-client';
import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { BASE_AUTHZ_API_URL, config } from '@/config';
import { WorkspaceRole } from '@/classes/authz/WorkspaceRole';

export interface GetPermissionsQuery {
	numberofusers?: true;
	permissions?: true;
	permissionuuids?: true;
	permissionnames?: true;
}

/**
 * This service is in charge of CRUD operations to manipulate the Sphere users in the Authz backend.
 */
export class AuthzWorkspaceRoleService extends BaseService<WorkspaceRole> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'workspacerole',
			apiEndpoint: config.authzApiEndpoint,
			baseApiUrl: BASE_AUTHZ_API_URL,
		}, clientConfig);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<AuthzInterface.IWorkspaceRole> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<AuthzInterface.IWorkspaceRole[]> {
		throw new Error('Function not yet implemented');
	}

	public async create<QueryT extends object>(body?: WorkspaceRole, query?: QueryT): Promise<AuthzInterface.IWorkspaceRole> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(
		body?: Partial<AuthzInterface.IWorkspaceRole>,
		query?: QueryT,
	): Promise<AuthzInterface.IWorkspaceRole> {
		throw new Error('Function not yet implemented');
	}

	public async updateMulti<QueryT extends object>(body?: WorkspaceRole[], query?: QueryT): Promise<AuthzInterface.IWorkspaceRole[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	public async getAllFromWorkspace(workspaceUuid: string, query?: GetPermissionsQuery): Promise<AuthzInterface.IWorkspaceRole[]> {
		const url = this.makeUrl(workspaceUuid, query);
		return await this.get<AuthzInterface.IWorkspaceRole[]>(url);
	}
}
