import Vue from 'vue';
import { AuthzBaseInterface, AuthzConstant, AuthzInterface } from '@faroconnect/authz-client';
import { BaseEntity } from '@/classes/BaseEntity';
import { faroLocalization } from '@faroconnect/baseui';
import { User } from './User';
import { ProjzInterface } from '@faroconnect/projz-client';
import { config } from '@/config';
import { IWebShareProject } from '@/definitions/interfaces';
import { DateUtils } from '@faroconnect/utils';

type ProjectClass = 'Project';

export type ProjectSource =
	typeof AuthzConstant.PROJECT_TYPE_STREAM |
	typeof AuthzConstant.PROJECT_TYPE_SCENE |
	typeof AuthzConstant.PROJECT_TYPE_WEBSHARE |
	string;

export const PROJECT_SOURCE_NAME: { [key in ProjectSource]: string } = {
	SCENE: 'SCENE',
	Stream: 'Stream',
	WebShare: 'WebShare',
};

export const PROJECT_SOURCE_ICON: { [key in ProjectSource]: string } = {
	SCENE: '$vuetify.icons.144_logo-scene',
	Stream: '$vuetify.icons.144_logo-stream',
	WebShare: '$vuetify.icons.144_logo-webshare',
};

export interface IProject extends AuthzInterface.IProject {
	/**
	 * The WebShare subdomain, e.g. "volvo".
	 * Must always be the original, immutable workspace.WebshareDomainName, and not WebshareAlias,
	 * since it will be used for DataHub API paths. (One could use the workspace UUID instead, though.)
	 */
	Domain: string;
	/**
	 * The WebShare base URL, e.g. "https://volvo.webshare-america.net".
	 * This may be based on workspace.WebshareAlias for renamed domains.
	 */
	DomainUrl: string;
	/**
	 * The WebShare project URL ID, e.g. "skatepark-small"
	 */
	UrlId: string;
	LastVisitDate: string;
	/**
	 * Flag set to true if it's a Faro demo project
	 */
	IsDemo: boolean;
	WebshareDetails: IWebShareProject | null;
}

const defaultProjectImage = require('@/assets/defaultProjectImage.jpg');

/**
 * Project Type known aliases.
 * Required for matching them to known project types
 * Aliases must be added to these arrays in lowercase, since the matching method compares them
 * to lowercase project types (E.g: 'webshare', 'scene', 'stream')
 */
const WEBSHARE_NAMES = ['ws', 'webshare', 'wsc', 'inc'];
const SCENE_NAMES = ['scene'];
const STREAM_NAMES = ['stream'];

export class Project extends BaseEntity implements IProject {
	public static constructorName = Project.name;

	public static getProjectTypeName(type: ProjectSource): string {
		return PROJECT_SOURCE_NAME[type] ?? type;
	}

	public static getProjectTypeIcon(type: ProjectSource): string | null {
		return PROJECT_SOURCE_ICON[type] ?? null;
	}

	public get prettyLastVisitDate(): string {
		return this.getPrettyDate(this.LastVisitDate);
	}

	public static fromResponse(json: IProject) {
		return new Project(json);
	}

	public static forRequest(json: Partial<IProject>) {
		return new Project(json);
	}

	public readonly isProject: boolean = true;

	public ImgUrl: string;
	public DefaultImgUrl: string = defaultProjectImage;

	public Class: ProjectClass = 'Project';
	public Workspace: string;
	public LastVisitDate: string;
	public ShareDate: string;
	/**
	 * The WebShare subdomain, e.g. "volvo"
	 */
	public Domain: string = '';
	/**
	 * The WebShare base URL, e.g. "https://volvo.webshare-america.net"
	 */
	public DomainUrl: string = '';
	/**
	 * The WebShare project URL ID, e.g. "skatepark-small"
	 */
	public UrlId: string = '';
	/**
	 * The WebShare full URL (including query string)
	 * e.g. "https://volvo.webshare-america.net/?v=pd&t=p:default,c:projectinfo,h:f,m:t,pr:t&pd=pd1&pd1=&p=p:29623baa-dd4e-4f9b-be94-52a767027893"
	 */
	public FullUrl: string | null = null;
	public Inviter?: AuthzBaseInterface.InviterI;
	public Type: string;
	public IsDemo: boolean;
	public Creator: string;
	public WebshareDetails: IWebShareProject | null = null;

	protected constructor(obj: Partial<IProject>) {
		super(obj);
		this.Workspace = obj.Workspace ?? '';
		this.LastVisitDate = obj.LastVisitDate ?? '';
		this.ShareDate = obj.ShareDate ?? '';
		this.Domain = obj.Domain ?? '';
		this.DomainUrl = obj.DomainUrl ?? '';
		this.UrlId = obj.WebshareUrlId ?? '';
		this.Inviter = obj.Inviter;
		this.Type = this.setType(obj.Type ?? '');
		this.IsDemo = obj.IsDemo ?? false;
		this.Creator = obj.Creator ?? '';
		this.WebshareDetails = obj.WebshareDetails ?? null;

		this.ImgUrl = defaultProjectImage;
		let widLocalhost = '';
		// The ?workspaceuuid parameter is required for http://localhost[:port], since we're not using subdomains.
		// For DEV/STG/PROD, let's not use it yet, since it results in an additional WebShare database query, see
		// SubscriptionService::getIdByWorkspaceUuid().
		if (config.env === 'local' && obj.Workspace && !obj.IsDemo) {
			widLocalhost += `&workspaceuuid=${obj.Workspace}`;
		}
		if (obj.DomainUrl && this.Type === 'WebShare' && obj.WebshareUrlId) {
			this.ImgUrl = `${obj.DomainUrl}/data/project/${obj.WebshareUrlId}/project-image?throw404=true${widLocalhost}`;
		} else if (obj.DomainUrl && this.Type !== 'WebShare') {
			this.ImgUrl = `${obj.DomainUrl}/data/project/${obj.UUID}/sphere-project-image?throw404=true${widLocalhost}`;
		}
	}

	/**
	 * Sets Type value to match defined project type values according to known aliases
	 * If the Type value is not known, just return the input parameter
	 * @param {string} projectType E.g. 'webshare' or 'ws'
	 * @returns {string} Matched known project type. E.g. 'WebShare'
	 */
	public setType(projectType: string): string {
		const applicationIdLowerCase = (projectType ?? '').toLowerCase();
		if (WEBSHARE_NAMES.includes(applicationIdLowerCase)) {
			return AuthzConstant.PROJECT_TYPE_WEBSHARE;
		} else if (SCENE_NAMES.includes(applicationIdLowerCase)) {
			return AuthzConstant.PROJECT_TYPE_SCENE;
		} else if (STREAM_NAMES.includes(applicationIdLowerCase)) {
			return AuthzConstant.PROJECT_TYPE_STREAM;
		} else {
			return projectType ?? '';
		}
	}

	public get workspaceName(): string {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const workspace = $tsStore.workspaces.ItemsMap[this.Workspace];
		return workspace?.Name ?? faroLocalization.i18n.tc('UI_UNKNOWN');
	}

	/**
	 * Returns the current logged in user.
	 */
	public get user(): User | null {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.users.user ?? null;
	}

	/**
	 * Returns the name of the project type icon, or null if no known project type is set.
	 * @author OK
	 */
	public get typeIcon(): string | null {
		return Project.getProjectTypeIcon(this.Type);
	}

	public get typeName(): string {
		return Project.getProjectTypeName(this.Type);
	}

	public get projectStatus(): ProjzInterface.IProjectStatus | null {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.projectStatuses.getMainProjectStatus(this);
	}

	/**
	 * Returns the StatusId for the main project status of this project, otherwise an empty string.
	 */
	public get projectStatusStatusId(): string {
		return this.projectStatus?.StatusId ?? '';
	}

	/**
	 * Returns true if the project status is considered to be a successfully completed status.
	 * According to ProjZClientWrap.ts in datahub-sync, only the following statuses are considered to be complete:
	 *   WebShare_complete, SCENE_uploaded, Stream_uploaded
	 * @author OK
	 */
	public get isStatusSuccessful(): boolean {
		switch (this.projectStatusStatusId) {
			case 'WebShare_complete':
			case 'SCENE_uploaded':
			case 'Stream_uploaded':
				return true;
			default:
				return false;
		}
	}

	public get prettyUpdateDate(): string {
		const date = this.propertiesOrContentUpdateDate;
		return (date && date !== '1970-01-01T00:00:00.000Z') ? this.getPrettyDate(date) : super.prettyUpdateDate;
	}

	/**
	 * Return the latest of these two dates:
	 * - project properties update date,
	 * - project contents update date (WebShare projects only).
	 */
	public get propertiesOrContentUpdateDate(): string {
		if (this.UpdateDate && this.contentUpdateDate) {
			return this.UpdateDate >= this.contentUpdateDate ? this.UpdateDate : this.contentUpdateDate;
		} else if (!this.UpdateDate && this.contentUpdateDate) {
			return this.contentUpdateDate;
		} else {
			return this.UpdateDate;
		}
	}

	public get contentUpdateDate(): string | null {
		if (!this.WebshareDetails?.UpdateDate || this.WebshareDetails?.UpdateDate?.startsWith('1970')) {
			return null;
		}
		return DateUtils.convertToUTC(this.WebshareDetails.UpdateDate) ?? null;
	}

	public get latitude(): number | null {
		if (!this.WebshareDetails?.Latitude && !this.WebshareDetails?.Longitude) {
			return null; // Don't use location (0, 0).
		}
		return this.WebshareDetails?.Latitude ?? null;
	}

	public get longitude(): number | null {
		if (!this.WebshareDetails?.Latitude && !this.WebshareDetails?.Longitude) {
			return null; // Don't use location (0, 0).
		}
		return this.WebshareDetails?.Longitude ?? null;
	}

	public get googleMapsUrl(): string | null {
		if (!this.latitude && !this.longitude) {
			return null;
		}
		const latLngStr = this.latitude + ',' + this.longitude;
		return `https://www.google.com/maps/place/${latLngStr}/@${latLngStr},15z`;
	}

	/**
	 * Update project properties with new data.
	 * @param project project that its properties replaced the current project.
	 * It should not be another project, it should be the same projects with some more/less properties.
	 * If UUID is set in "project", is should be equal to the current class ("this").
	 * @returns Current class by new properties.
	 */
	public updateProperties(project: Partial<IProject>) {
		if (project.UUID && project.UUID !== this.UUID) {
			throw Error('You can\'t change uuid of the project');
		}
		return Object.assign(this, project);
	}
}
