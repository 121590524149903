
import Vue from 'vue';
import Component from 'vue-class-component';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { SendEmailVerificationQuery, UserBtnTaskTopRightComponentData } from '@/utils/types';
import { getErrorMessage } from '@/utils/errorhandler';
import { ConflictError } from '@faroconnect/utils';
import { submitAuth0LoginForm } from '@/utils/auth0';
import { queryToString } from '@/utils/validate';
import { User } from '@/classes/authz/User';
import { PendingTask } from '@/utils/PendingTask';
import { setLanguageLikeBrowser } from '@/utils/browser';

@Component
export default class PendingEmailConfirmationTask extends Vue {
	public readonly SUPPORT_TEAM_EMAIL = 'support@faro.com';
	public readonly lpUrl = window.location.origin;
	public topRightComponent = UserBtn;

	public email: string | null = null;
	public token: string | null = null;
	public auth0UserId: string | null = null;
	public sendingEmail = false;
	public auth0Domain: string = '';
	public auth0State: string = '';

	public get topRightComponentData(): UserBtnTaskTopRightComponentData {
		return {
			isFullscreenTask: true,
			mockState: {
				userEmail: this.email ?? '',
				// We can't get the greeting name since in this step the name hasn't been provided.
				// We just use a guess from the first part of the email (before @) instead.
				greetingName: User.getNameStringFromEmail(this.email || ''),
				isAuthenticated: true,
			},
		};
	}

	public async sendEmailVerification() {
		if (!this.email || !this.token || !this.auth0UserId) {
			this.$faroComponents.$emit('show-error', { message: 'LP_ERR_INVALID_REGISTRATION_URL' });
			return;
		}
		this.sendingEmail = true;
		try {
			await this.$tsStore.users.sendEmailVerification({
				Auth0UserId: this.auth0UserId,
				Email: this.email,
				Token: this.token,
			});
			this.sendingEmail = false;
		} catch (error) {
			console.error(error);
			const message = getErrorMessage(error, {
				ConflictError: this.$tc('LP_ERR_USER_ALREADY_CONFIRMED_EMAIL'),
			});
			this.$faroComponents.$emit('show-error', { error, message });
			if (error instanceof ConflictError || (error as ConflictError).name === 'ConflictError') {
				// If fails because of a conflict error, the user should have been validated, therefore we could try to close the task.
				await submitAuth0LoginForm({
					auth0Domain: this.auth0Domain,
					auth0State: this.auth0State,
				});
			} else {
				this.sendingEmail = false;
			}
		}
	}

	/**
	 * Checks whether the email has been verified and in that case it continues with the login process.
	 */
	public async redirectIfEmailVerified() {
		const pendingTask = new PendingTask(this, {
			email: this.email,
			token: this.token,
			auth0Domain: this.auth0Domain,
			auth0State: this.auth0State,
			method: 'checkEmailVerified',
			query: this.$route.query,
		});
		return pendingTask.redirectIfNoLongerPending();
	}

	public async mounted() {
		setLanguageLikeBrowser();
		const query: SendEmailVerificationQuery = this.$route.query as unknown as SendEmailVerificationQuery;
		this.email = queryToString(query.Email);
		this.token = queryToString(query.Token);
		this.auth0UserId = queryToString(query.Auth0UserId);
		this.auth0Domain = queryToString(this.$route.query.auth0_domain);
		this.auth0State = queryToString(this.$route.query.state);

		await this.redirectIfEmailVerified();

		// Poll every minute for changes in the email verification
		setInterval(async () => {
			await this.redirectIfEmailVerified();
		}, 60000);

		// We need to fake that this page is authenticated, because even though it looks that the user is authenticated,
		// in reality the login flow was interrupted by Auth0.
		localStorage.setItem('sphere-is-authenticated', 'true');
	}
}
