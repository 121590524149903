
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { User } from '@/classes/authz/User';
import { Group } from '@/classes/authz/Group';
import { ProjectRole } from '@/classes/authz/ProjectRole';
import { ArrayUtils, StringUtils } from '@faroconnect/utils';
import { AssignProjectRolesUsersAndGroupsQuery } from '@/store/services/authz/AuthzProjectService';
import { ShareProjectTaskInitData } from '@/components/Tasks/ShareProjectTask';

import { InviteUsersAndGroupsToProjectAndWorkspaceBody } from '@/definitions/interfaces';
import { ApiResponse } from '@/store/types';

interface UserOrGroupItem {
	/**
	 * User name or group name
	 */
	text: string
	/**
	 * Entity UUID
	 */
	value: string
	/**
	 * True if it's a User entity, false if it's a Group entity
	 */
	isUser: boolean;
	/**
	 * User email for a User entity, empty string '' for a Group entity
	 */
	email: string;
	/**
	 * True if it's a User entity and is not member of the workspace
	 */
	isTempUser: boolean;
}

@Component
export default class ShareProjectTask extends Vue {
	@Prop(Object) public readonly initData!: ShareProjectTaskInitData;

	// ######################## Component Data ####################

	protected loading: boolean = false;
	protected selectedUsersAndGroups: UserOrGroupItem[] = [];
	protected selectedRoles: ProjectRole[] = [];
	protected selectAllRoles: boolean = false;
	protected searchUsersAndGroupsTxt: string | null = null;
	protected searchProjectRolesTxt: string | null = '';
	protected tempUsers: User[] = [];

	// ######################## Class Getters ####################

	protected get currentUser(): User | null {
		return this.$tsStore.users.user;
	}

	protected get allWorkspaceUsers(): User[] {
		return (this.$tsStore.users.users[this.initData.project.Workspace] ?? []).filter((u) =>
			// The current user should not be shown because you should not assign projects to yourself.
			this.currentUser?.UUID !== u.UUID);
	}

	protected get allUsers(): User[] {
		return this.allWorkspaceUsers.concat(this.tempUsers);
	}

	protected get inviteUserWorkspacePermissions() {
		const workspaceAccess = this.$tsStore.users.workspaceAccesses[this.initData.project.Workspace];
		return workspaceAccess?.inviteUser;
	}

	protected get hasShareWorkspacePermission(): boolean {
		return this.inviteUserWorkspacePermissions?.hasPermission ?? false;
	}

	protected get missingInviteUsersWorkspacePermissionTxt(): string {
		return this.$tc(
			'LP_ACCESS_DENIED_WORKSPACE_PERMISSIONS_INVITE_WORKSPACE',
			this.inviteUserWorkspacePermissions?.permissions?.length ?? 1,
			{
				permissions: (this.inviteUserWorkspacePermissions?.permissions ?? ['?']).join(', '),
			},
		);
	}

	protected get users(): User[] {
		return this.allUsers
			.sort((u1, u2) => this.userText(u1).toLowerCase().localeCompare(this.userText(u2).toLowerCase()));
	}

	protected get userItems(): UserOrGroupItem[] {
		return this.users.map((user) => ({
			text: this.userText(user),
			value: user.UUID,
			isUser: true,
			email: user.Email,
			isTempUser: user.isTempUser,
		}));
	}

	protected get selectedUsers(): UserOrGroupItem[] {
		return this.selectedUsersAndGroups.filter((selectedItem) => selectedItem.isUser);
	}

	protected get allGroups(): Group[] {
		return ArrayUtils.shallowCopy(this.$tsStore.groups.groups[this.initData.project.Workspace] ?? []);
	}

	protected get groups(): Group[] {
		return this.allGroups
			.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
	}

	protected get groupItems(): UserOrGroupItem[] {
		return this.groups.map((group) => ({
			text: group.displayName,
			value: group.UUID,
			isUser: false,
			email: '',
			isTempUser: false,
		}));
	}

	protected get selectedGroups(): UserOrGroupItem[] {
		return this.selectedUsersAndGroups.filter((selectedItem) => !selectedItem.isUser);
	}

	protected get usersAndGroups(): UserOrGroupItem[] {
		return this.userItems.concat(this.groupItems);
	}

	protected get allRolesAllWorkspaces(): ProjectRole[] {
		return ArrayUtils.shallowCopy(this.$tsStore.projectRoles.itemsList ?? []);
	}

	protected get roles(): ProjectRole[] {
		return this.allRolesAllWorkspaces
			// First filter only the ones relevant to the used workspace
			.filter((role) => role.Workspace === this.initData.project.Workspace)
			.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
	}

	/**
	 * Returns an array filled with the sanitized emails from the users input.
	 * For example if you type:
	 * <John Doe> john.doe@faro.com; <Foo Bar> foo.bar@faro.com; <Foo Bar> foo.bar@faro.com
	 * It will return
	 * [john.doe@faro.com, foo.bar@faro.com]
	 */
	protected get sanitizedSearchTxtEmails(): string[] {
		if (!this.searchUsersAndGroupsTxt?.trim()) {
			return [];
		}
		const sanitized = StringUtils.sanitizeEmailsInput(this.searchUsersAndGroupsTxt);
		// Stores all sanitized emails by filtering only the valid ones and without duplications.
		const noDuplicates: string[] = [];

		sanitized.forEach((email) => {
			const emailLower = email.toLowerCase();

			if (
				// It seems that sanitizeEmailsInput returns emails that are sometimes invalid, e.g. test@faro
				StringUtils.isValidEmail(email) &&
				// Remove emails that were added to the form previously
				!this.selectedUsers.some((user) => user.email.toLowerCase() === emailLower) &&
				// Removed emails that were typed more than once.
				!noDuplicates.includes(emailLower)
			) {
				noDuplicates.push(emailLower);
			}
		});
		return noDuplicates;
	}

	/**
	 * Search text emails but only the new users.
	 */
	protected get newUsersEmailsSearch(): string[] {
		const newUsersEmails: string[] = [];
		this.sanitizedSearchTxtEmails.forEach((email) => {
			const isUser: boolean = this.users.some((user) => user.Email.toLowerCase() === email.toLowerCase());
			if (!isUser) {
				newUsersEmails.push(email);
			}
		});
		return newUsersEmails;
	}

	protected get isSomeGroupVisible(): boolean {
		return this.groups.some(this.isGroupVisible);
	}

	protected get isSomeRoleVisible(): boolean {
		return this.roles.some(this.isRoleVisible);
	}

	protected get areAllRolesSelected(): boolean {
		return this.selectedRoles.length > 0 && this.selectedRoles.length === this.visibleRoles.length;
	}

	protected get visibleRoles(): ProjectRole[] {
		return this.roles.filter(this.isRoleVisible);
	}

	protected get taskReady(): boolean {
		return !!this.selectedUsersAndGroups.length && !!this.selectedRoles.length;
	}

	protected get okText(): string {
		return this.$tc('LP_SHARE');
	}

	protected get title(): string {
		return this.$tc('LP_SHARE_PROJECT');
	}

	protected get subtitle(): string {
		return this.initData.project.Name;
	}

	protected get hasNonWorkspaceUsersSelected(): boolean {
		return this.selectedUsers.some((user) => user.isTempUser);
	}

	protected get selectedAdvancedRolesCount(): number {
		return this.selectedRoles.filter((role) => role.Type === 'advanced').length;
	}

	protected get advancedUsersLeft(): number | string {
		const workspaceLimits = this.$tsStore.workspaces.workspaceLimits[this.initData.project.Workspace];
		return workspaceLimits?.AdvancedUsers?.StillAvailable ?? '?';
	}

	protected get selectedUsersUuids(): string[] {
		return this.selectedUsers.map((user) => user.value);
	}

	protected get selectedGroupsUuids(): string[] {
		return this.selectedGroups.map((group) => group.value);
	}

	/**
	 * Flag whether the dropdown is showing at least one user.
	 */
	protected get isSomeUserVisible(): boolean {
		return this.userItems.some((user) => this.filterUsers(user, this.searchUsersAndGroupsTxt));
	}

	/**
	 * Flag whether the invite user to workspace item should be appended in the dropdown.
	 * It is only the case if the user type a valid email, and there are some similar emails in the workspace.
	 * E.g.
	 * If i have the user test@faro.com and I type test@faro.co it should let me invite test@faro.com
	 */
	protected get appendUserInvite(): boolean {
		return this.isSomeUserVisible &&
			(this.sanitizedSearchTxtEmails.length > 0) &&
			!this.users.some((user) => this.searchUsersAndGroupsTxt?.toLowerCase() === user.Email);
	}

	// ######################## Component Methods ####################

	protected userText(user: User): string {
		if (user.isTempUser) {
			return user.Email.trim();
		}
		if (!user.Name || user.Name.trim().length === 0) {
			return user.Email;
		}
		return user.Name;
	}

	protected userOrGroupTextReduced(item: UserOrGroupItem): string {
		const MAX_LENGTH = 50;
		const text = item.text;
		if (text.length <= MAX_LENGTH) {
			return text;
		}
		if (text.includes('@')) {
			// it is an email
			return StringUtils.shortenEmail(text, MAX_LENGTH);
		}
		return StringUtils.shortenString(text, MAX_LENGTH, Math.floor(MAX_LENGTH / 2) - 1);
	}

	protected roleText(role: ProjectRole): string {
		return role.displayName;
	}

	protected roleValue(role: ProjectRole): ProjectRole {
		return role;
	}

	protected isGroupVisible(group: Group): boolean {
		return group.displayName.toLowerCase().includes((this.searchUsersAndGroupsTxt ?? '').toLowerCase());
	}

	protected isRoleVisible(role: ProjectRole): boolean {
		return role.displayName.toLowerCase().includes((this.searchProjectRolesTxt ?? '').toLowerCase());
	}

	protected toggleSelectProjectRoles(): void {
		this.selectAllRoles = !this.selectAllRoles;
		if (!this.selectAllRoles) {
			this.selectedRoles = [];
		} else {
			this.selectedRoles = this.visibleRoles.map((role) => this.roleValue(role));
		}
	}

	protected onSelectedRolesChange(): void {
		if (this.areAllRolesSelected) {
			this.selectAllRoles = true;
		} else {
			this.selectAllRoles = false;
		}
	}

	protected async assignToUsersAndGroups(): Promise<ApiResponse> {
		const userEmailsForWorkspace: string[] = [];

		const hasPermissionInviteUserWorkspacePermissions: boolean = this.inviteUserWorkspacePermissions?.hasPermission ?? false;
		const existingWorkspaceUsers = this.selectedUsers.filter((user) => {
			if (user.isTempUser) {
				// Only add the emails if the user has permission to invite other users.
				if (hasPermissionInviteUserWorkspacePermissions) {
					userEmailsForWorkspace.push(user.email);
				}
				return false;
			}
			return true;
		});
		const userUuidsForProject = existingWorkspaceUsers.map((user) => user.value);
		const groupUuidsForProject = this.selectedGroups.map((group) => group.value);
		const projectRoleUuidsForProject = this.selectedRoles.map((role) => role.UUID) ?? [];
		const projectUuid = this.initData.project.UUID;

		let response: ApiResponse<any>;
		if (userEmailsForWorkspace.length) {
			const payload: InviteUsersAndGroupsToProjectAndWorkspaceBody = {
				UserEmailsForWorkspace: userEmailsForWorkspace,
				// Do not add users to any group.
				GroupUuidsForWorkspace: [],
				// Do not add users to any workspace role.
				WorkspaceRoleUuidsForWorkspace: [],
				UserUuidsForProject: userUuidsForProject,
				GroupUuidsForProject: groupUuidsForProject,
				ProjectRoleUuidsForProject: projectRoleUuidsForProject,
				ProjectUuid: projectUuid,
			};
			response = await this.$tsStore.projects.inviteUsersAndGroupsToProjectAndWorkspace(
				this.initData.project.Workspace,
				payload,
			);
		} else {
			// If no users will be invited to the workspace use the route directly in authz to invite users
			// to project since it is more efficient than the one in LP BE.
			const payload: AssignProjectRolesUsersAndGroupsQuery = {
				userUuids: userUuidsForProject,
				groupUuids: groupUuidsForProject,
				projectRolesUuids: projectRoleUuidsForProject,
				projectUuid,
			};
			response = await this.$tsStore.projects.assignProjectRolesUsersAndGroups(
				this.initData.project.Workspace,
				payload,
			);
		}

		return response;
	}

	protected selectUserWithEmail(email: string) {
		const emailLower = email.toLowerCase();
		// Check first if the input email is one of the workspace users or the temporary users.
		let user = this.users.find((user) => user.Email.toLowerCase() === emailLower);
		if (!user) {
			if (!this.inviteUserWorkspacePermissions?.hasPermission) {
				return;
			}
			user = User.forRequest({
				Email: emailLower,
				isTempUser: true,
			});
			this.tempUsers.push(user);
		}

		const userItem: UserOrGroupItem = {
			text: this.userText(user),
			value: user.UUID,
			isUser: true,
			email: user.Email,
			isTempUser: user.isTempUser,
		};

		if (!this.selectedUsers.some((selectedUser) => selectedUser.email.toLowerCase() === emailLower)) {
			this.selectedUsersAndGroups.push(userItem);
		}
	}

	protected selectUsersWithEmail() {
		this.sanitizedSearchTxtEmails.forEach(this.selectUserWithEmail);
	}

	protected addSelectedUser() {
		if (!this.searchUsersAndGroupsTxt) {
			return false;
		}

		if (!this.searchUsersAndGroupsTxt.includes('@')) {
			// If does not include at least one @ it cannot be a email list.
			return false;
		}
		this.selectUsersWithEmail();

		this.searchUsersAndGroupsTxt = null;
	}

	protected removeUserOrGroupItem(item: UserOrGroupItem) {
		const removedSelection = this.selectedUsersAndGroups.filter((selectedItem) => selectedItem.value !== item.value);
		this.selectedUsersAndGroups = removedSelection;
	}

	protected addSelectedGroup() {
		const searchTxt = (this.searchUsersAndGroupsTxt ?? '').toLowerCase();
		const groupItem = this.groupItems.find((group) => group.text.toLowerCase() === searchTxt);
		if (groupItem && !this.selectedGroups.some((group) => group.text.toLowerCase() === searchTxt)) {
			this.selectedUsersAndGroups.push(groupItem);
		}
	}

	protected addSelectedUserOrGroup() {
		if (!this.searchUsersAndGroupsTxt) {
			return false;
		}
		this.addSelectedGroup();
		this.addSelectedUser();
		this.searchUsersAndGroupsTxt = null;
	}

	protected filterUsersAndGroups(item: UserOrGroupItem, searchTxtRaw: string | null): boolean {
		return item.isUser ? this.filterUsers(item, searchTxtRaw) : this.filterGroups(item, searchTxtRaw);
	}

	/**
	 * Custom filter function instead of the Vuetify one to determine which users should be shown in the combobox.
	 * The problem with the Vuetify default function in that if I have a user test@faro.com and I type t@faro.com
	 * it will still show me test@faro.com when it is clearly a new email.
	 */
	protected filterUsers(item: UserOrGroupItem, searchTxtRaw: string | null): boolean {
		const searchTxt: string = (searchTxtRaw ?? '').toLowerCase();
		return (item.text.toLowerCase().includes(searchTxt) || item.email.toLowerCase().includes(searchTxt)) &&
			// Do not include already selected users or new users (user.isTempUser === true).
			!this.selectedUsersUuids.includes(item.value) && !item.isTempUser;
	}

	protected filterGroups(item: UserOrGroupItem, searchTxtRaw: string | null): boolean {
		const searchTxt: string = (searchTxtRaw ?? '').toLowerCase();
		// Do not include already selected users.
		return (item.text.toLowerCase().includes(searchTxt) && !this.selectedGroupsUuids.includes(item.value));
	}

	protected onKeyUp(event: KeyboardEvent) {
		if (event.key === ';' || event.key === ',') {
			event.stopPropagation();
			event.preventDefault();
			this.addSelectedUserOrGroup();
		}
	}

	protected async ok(): Promise<void> {
		this.loading = true;
		try {
			const response = await this.assignToUsersAndGroups();
			if (response.Success) {
				// Only close the task if succeeds to invite the user.
				// Otherwise it is better to leave the task open to let the user try again.
				await this.cancel();
			} else {
				const title = response.Data.ErrorTitle;
				let message: string | undefined;
				if (Array.isArray(response.Data.ErrorMessages)) {
					message = response.Data.ErrorMessages.join('<br>');
				} else {
					message = response.Data.ErrorMessages;
				}
				this.$faroComponents.$emit('show-error', { title, message, error: { traceId: response.traceId } });
			}
		} catch (error) {
			// The method assignToUsersAndGroups should not throw an error but catch it just in case.
			console.error(error);
		} finally {
			this.loading = false;
		}
	}

	protected async cancel() {
		await this.$faroTaskService.closeFullscreenTask();
	}

	protected async mounted() {
		this.loading = true;

		if (!this.initData?.project?.Workspace) {
			this.$faroComponents.$emit('show-error', { message: 'LP_ERR_GET_WORKSPACE' });
			this.$faroTaskService.closeFullscreenTask();
		}

		await Promise.all([

			this.$tsStore.users.getAllFromWorkspace({
				workspaceUuid: this.initData.project.Workspace,
				query: {},
			})
				.catch((error) => {
					this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_USERS' });
					throw error;
				}),

			this.$tsStore.groups.getAllFromWorkspace({
				workspaceUuid: this.initData.project.Workspace,
				query: {
					useruuids: true,
				},
			})
				.catch((error) => {
					this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_GROUPS' });
					throw error;
				}),

			this.$tsStore.projectRoles.getAllFromWorkspace(this.initData.project.Workspace)
				.catch((error) => {
					this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_PROJECT_ROLES' });
					throw error;
				}),

			this.$tsStore.workspaces.getWorkspaceLimits(this.initData.project.Workspace)
				.catch((error) => {
					this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_WORKSPACE_LIMITS' });
					throw error;
				}),
		]).catch(() => {
			this.$faroTaskService.closeFullscreenTask();
		});

		const viewerRole = this.visibleRoles.find((role) => role.Id === 'viewer');
		if (viewerRole) {
			// Always pre-select the Viewer role
			this.selectedRoles = [this.roleValue(viewerRole)];
		}

		this.loading = false;
	}
}
