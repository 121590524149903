import { IService, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';
import { validateString } from '@/utils/validate';
import { BaseEntity } from '@/classes/BaseEntity';
import Vue from 'vue';
import { User } from './authz/User';

const defaultWorkspaceImage = require('@/assets/defaultProjectImage.jpg');

export class Service extends BaseEntity implements IService, IGradientBackground, IMeshBackground {
	public static readonly constructorName = Service.name;

	public static fromResponse(json: IService) {
		return new Service(json);
	}

	public static forRequest(json: Partial<IService> & IGradientBackground & IMeshBackground) {
		return new Service(json);
	}

	public readonly isService: boolean = true;

	public Class: string = 'Service';
	public Url: string;
	public ImgUrl: string;
	public DefaultImgUrl: string = defaultWorkspaceImage;
	public GradientBackground: boolean;
	public MeshBackground: boolean;
	/**
	 * Some Services use a title that needs to be translated, like "Access Training", instead of a product name.
	 */
	public StringId?: string;
	/**
	 * If set to true, the Service will be disabled, and a flag will be added,
	 * to show the user that the Service will be available soon.
	 */
	public ComingSoon: boolean;

	protected constructor(json: Partial<IService> & IGradientBackground & IMeshBackground) {
		super(json);
		this.ImgUrl = json.ImgUrl ?? defaultWorkspaceImage;
		this.Url = validateString(json.Url);
		this.GradientBackground = json.GradientBackground ?? false;
		this.MeshBackground = json.MeshBackground ?? false;
		this.StringId = json.StringId ?? undefined;
		this.ComingSoon = json.ComingSoon ?? false;
	}

	/**
	 * Returns the current logged in user.
	 */
	public get user(): User | null {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.users.user ?? null;
	}

	/**
	 * This method could be overriden to modify the Service's url based on the user's language or any other settings.
	 */
	public get LocalizedUrl(): string {
		return this.Url;
	}
}

