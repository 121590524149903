
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { LanguageCode } from '@faroconnect/baseui';
import { dynamicConfig } from '@/config';
import $router from '@/router/router';

interface HelpPage {
	icon: string;
	href: string;
	stringId: string;
}

/**
 * This function seems to return the full URL for a route name.
 */
function getHref(routeName: RawLocation): string {
	const origin = window.location.origin; // this will return for example http://localhost
	const endpoint = $router.resolve(routeName).href; // this will return for example '/authz/faro'
	return origin + endpoint;
}

@Component
export default class HelpBtn extends Vue {
	@Prop(Boolean) public readonly listDropdown!: boolean;

	public get userManual(): string {
		return dynamicConfig.url?.manual?.[this.$i18n.locale as LanguageCode] ??
			dynamicConfig.url?.manual?.en_US ??
			'https://farotechnologies.mcoutput.com/sphere/index.htm';
	}

	public get pages(): HelpPage[] {
		let imprintHref: string;
		const locale = this.$i18n.locale;
		if (locale.startsWith('fr')) {
			imprintHref = 'https://www.faro.com/fr-FR/Imprint';
		} else if (locale.startsWith('it')) {
			imprintHref = 'https://www.faro.com/it-IT/Imprint';
		} else {
			imprintHref = 'https://www.faro.com/de-DE/Imprint';
		}

		return [
			{
				icon: '$vuetify.icons.36_action-shield-warning',
				href: getHref({ name: 'LegalNoticesPage' }),
				stringId: 'UI_TERMS_OF_SERVICE',
			},
			{
				icon: '$vuetify.icons.36_generic-phone',
				href: imprintHref,
				stringId: 'UI_CONTACT_INFORMATION',
			},
		];
	}
}
