
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserBtnTaskTopRightComponentData } from '@/utils/types';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { setLanguageLikeBrowser } from '@/utils/browser';

@Component
export default class NotFoundTask extends Vue {
	public readonly lpUrl = window.location.origin;
	public topRightComponent = UserBtn;
	public topRightComponentData: UserBtnTaskTopRightComponentData = { isFullscreenTask: true };

	public mounted() {
		// If the user is already on the store the language was already set to match the user's language/
		if (!this.$tsStore.users.user) {
			setLanguageLikeBrowser();
		}

		// We need to fake that this page is authenticated, because even though it looks that the user is authenticated,
		// in reality the login flow was interrupted by Auth0.
		localStorage.setItem('sphere-is-authenticated', 'true');
	}
}
