
import Component from 'vue-class-component';
import FilterDateMenu from '@/components/EntitiesPage/FilterMenu/Submenus/FilterDateMenu.vue';
import FilterMenuBase from '@/components/EntitiesPage/FilterMenu/FilterMenuBase.vue';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import { Project } from '@/classes/authz/Project';
import { FaroTab } from '@/utils/types';
import { FilterMenuBaseMixin } from '@/components/EntitiesPage/FilterMenu/FilterMenuBaseMixin';
import FilterTypeMenu from '@/components/EntitiesPage/FilterMenu/Submenus/FilterTypeMenu.vue';
import FilterProjectMenu from '@/components/EntitiesPage/FilterMenu/Submenus/FilterProjectMenu.vue';
import { FILTER_TABS_ENUM } from '@/definitions/constants';

@Component({
	components: {
		FilterMenuBase,
		FilterDateMenu,
		FilterTypeMenu,
		FilterProjectMenu,
	},
})
export default class ProjectFilterMenu extends FilterMenuBaseMixin<Project> {
	public openedTab = this.xTabs[0].value;

	public get xStore(): BaseFilterModule<Project> {
		return this.$tsStore.projects;
	}

	public get xTabs(): FaroTab[] {
		return [
			{
				value: FILTER_TABS_ENUM.TAB_DATE,
				text: 'UI_DATE',
				isTabActive: this.xStore.isfilterByDateActive,
			},
			// TODO: fix bug that freezes fe when user has too many projects (around 200 or more): https://faro01.atlassian.net/browse/FC-6417
			// {
			// 	value: FILTER_TABS_ENUM.TAB_PROJECT,
			// 	text: 'UI_PROJECT',
			// 	isTabActive: this.xStore.isfilterByProjectActive,
			// },
			{
				value: FILTER_TABS_ENUM.TAB_TYPE,
				text: 'UI_TYPE',
				isTabActive: this.isTypeTabActive,
			},
		];
	}

	public get isTypeTabActive(): boolean {
		return this.xStore.isfilterByProjectSourceActive || this.xStore.isfilterByProjectStatusActive;
	}
}
