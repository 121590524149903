
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CancelPaidSubscriptionDialogInitData } from '@/utils/subscriptionutils';

@Component
export default class CancelPaidSubscriptionDialog extends Vue {
	@Prop(Object) public readonly initData!: CancelPaidSubscriptionDialogInitData;

	public async onOk(): Promise<void> {
		await this.$faroTaskService.closeFullscreenTask();
	}
}
