import Component from 'vue-class-component';
import PageBaseMixin from '@/mixins/PageBaseMixin';
import { Project } from '@/classes/authz/Project';
import { ModuleNameOf } from '@/store/types';
import { ProjectSortBy, SortItem } from '@/utils/sortitems';

/**
 * Base component for a page that shows some projects, either the full list, the recent projects or the shared with me.
 */
@Component
export default class ProjectsPageBaseMixin extends PageBaseMixin<Project> {
	public xProjectSortBy = new ProjectSortBy();

	public get xStoreName(): ModuleNameOf<Project> {
		return 'projects';
	}

	/**
	 * Overrides the sort items array to include sort by workspace and last visit.
	 */
	public get xSortItems(): Array<SortItem<Project>> {
		return [
			this.xEntitySortBy.name,
			this.xEntitySortBy.description,
			this.xEntitySortBy.creationDate,
			this.xEntitySortBy.updateDate,
			this.xProjectSortBy.type,
			this.xProjectSortBy.status,
			this.xProjectSortBy.lastVisitDate,
		];
	}
}
