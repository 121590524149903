import { AuthzInterface } from '@faroconnect/authz-client';
import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { BASE_AUTHZ_API_URL, config } from '@/config';
import { Group } from '@/classes/authz/Group';

export interface GetGroupsQuery {
	useruuids?: true;
	workspaceroleuuids?: true;
	workspacerolenames?: true;
	projectrole?: true;
	projectroles?: string | true;
	numberofusers?: true;
	workspaceroles?: true;
}

export interface AssignProjectRolesQuery {
	projectRolesUuids: string[];
	projectUuid: string;
}

export interface AssignProjectRolesGroupsQuery {
	groupUuids: string[];
	projectRolesUuids: string[];
	projectUuid: string;
}


/**
 * This service is in charge of CRUD operations to manipulate the Sphere users in the Authz backend.
 */
export class AuthzGroupService extends BaseService<Group> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'group',
			apiEndpoint: config.authzApiEndpoint,
			baseApiUrl: BASE_AUTHZ_API_URL,
		}, clientConfig);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<AuthzInterface.IGroup> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<AuthzInterface.IGroup[]> {
		throw new Error('Function not yet implemented');
	}

	public async create<QueryT extends object>(body?: Group, query?: QueryT): Promise<AuthzInterface.IGroup> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(body?: Partial<AuthzInterface.IGroup>, query?: QueryT): Promise<AuthzInterface.IGroup> {
		throw new Error('Function not yet implemented');
	}

	public async updateMulti<QueryT extends object>(body?: Group[], query?: QueryT): Promise<AuthzInterface.IGroup[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	public async getAllFromWorkspace(workspaceUuid: string, query?: GetGroupsQuery): Promise<AuthzInterface.IGroup[]> {
		const url = this.makeUrl(workspaceUuid, query);
		return await this.get<AuthzInterface.IGroup[]>(url);
	}

	public async assignProjectRoles(workspaceUuid: string, userUuid: string, query: AssignProjectRolesQuery): Promise<void> {
		const body = {
			ProjectRoleUuids: query.projectRolesUuids,
			CollectionOrProjectUuid: query.projectUuid,
			Target: 'Project',
		};
		await this.put(this.makeUrl(`${workspaceUuid}/${userUuid}/assign-projectroles`), body);
	}

	public async assignProjectRolesGroups(workspaceUuid: string, query: AssignProjectRolesGroupsQuery): Promise<void> {
		const body = {
			UserOrGroupUuids: query.groupUuids,
			ProjectRoleUuids: query.projectRolesUuids,
			CollectionOrProjectUuid: query.projectUuid,
			Target: 'Project',
		};
		await this.put(this.makeUrl(`${workspaceUuid}/assign-projectroles/groups`), body);
	}
}
