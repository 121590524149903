
import Vue from 'vue';
import { VueConstructor } from 'vue';
import Component from 'vue-class-component';
import PageBaseMixin from '@/mixins/PageBaseMixin';
import {ProjectStatus} from '@/classes/projz/ProjectStatus';
import { Workspace } from '@/classes/authz/Workspace';
import {ModuleNameOf} from '@/store/types';
import { ItemButtons } from '@/utils/types';
import { Header, ProjectStatusHeader } from '@/utils/listview';
import { ProjectStatusSortBy } from '@/utils/sortitems';
import { VBreadcrumbItem } from '@/definitions-frontend/globals';
import { Project } from '@/classes/authz/Project';
import { $assert } from '@faroconnect/utils';
import ProjectStatusFilterMenu from '@/components/EntitiesPage/FilterMenu/ProjectStatusFilterMenu.vue';
import { ProjectPageUtility } from '@/shared/PageUtilities';

@Component
export default class ProjectsStatusesPage extends PageBaseMixin<ProjectStatus> {
	// ######################## Component Data ####################
	public readonly projectStatusHeader = new ProjectStatusHeader(this.$vuetify, this.$tc);
	public readonly projectStatusSortBy = new ProjectStatusSortBy();

	// ######################## Inherited Getters ####################

	public get xStoreName(): ModuleNameOf<ProjectStatus> {
		return 'projectStatuses';
	}

	public get xPageName(): string {
		return 'ProjectStatusPage';
	}

	public get xGetAllErrString(): string {
		return this.$tc('LP_ERR_FETCH_PROJECT_STATUS');
	}

	public get xItems() {
		return this.$tsStore.projectStatuses.itemsList;
	}

	public get xFilteredItems() {
		return this.$tsStore.projectStatuses.filteredItems;
	}

	public get xEntitiesOnPage() {
		return this.$tsStore.projectStatuses.entitiesOnPage;
	}

	public get xItemButtons(): ItemButtons<ProjectStatus> {
		return [];
	}

	public get xFilterMenu(): VueConstructor<Vue> | null {
		return ProjectStatusFilterMenu;
	}

	public get xSortItems() {
		return [
			this.xEntitySortBy.creationDate,
			this.xEntitySortBy.updateDate,
			this.projectStatusSortBy.projectName,
			this.projectStatusSortBy.statusName,
		];
	}

	public get xHeaders(): Array<Header<ProjectStatus>> {
		return [
			this.projectStatusHeader.projectName,
			this.projectStatusHeader.statusName,
			this.xEntityHeader.creationDate,
			this.xEntityHeader.updateDate,
			this.xEntityHeader.actions,
		];
	}

	// ######################## Class Getters ####################

	public get pageErrorTitle(): string | null {
		return this.$tc('LP_ERR_FETCH_PROJECT_STATUS');
	}

	public get pageErrorMsg(): string | null {
		return null;
	}

	public get initializedConnection(): boolean {
		return this.$tsStore.projectStatuses.initializedConnection;
	}

	public get failedConnection(): boolean {
		return this.$tsStore.projectStatuses.failedConnection;
	}

	public get pageLoading(): boolean {
		return !this.initializedConnection && !this.failedConnection;
	}

	public get pageError(): boolean {
		return !this.initializedConnection && this.failedConnection;
	}

	public get activeWorkspace(): Workspace | null {
		return this.$tsStore.workspaces.activeWorkspace;
	}

	public get breadcrumbItems(): VBreadcrumbItem[] {
		const workspaceUuid = this.activeWorkspace?.UUID || '';

		return [
			{
				text: this.$tc('UI_PROJECTS'),
				to: { name: 'ProjectsPage', params: { workspace: workspaceUuid } },
			},
			{
				text: this.$tc('LP_PROJECTS_STATUS'),
				to: { name: 'ProjectsStatusPage' },
				disabled: true,
			},
		];
	}

	// ######################## Inherited Methods ####################

	/**
	 * Override xInitialize so that it does not fetch projectStatuses when projectStatuses page is opened.
	 */
	public async xInitialize() {
		this.hideLoadingSpinner();
	}

	// ######################## Component Methods ####################

	public hideLoadingSpinner() {
		this.$tsStore.pages.setFinishedPageLoading(true);
		if (this.$tsStore.pages.finishedMainLoading) {
			this.$faroLoading.stop();
		}
	}

	/**
	 * Opens a Sphere website that shows the provided project depending on its project status.
	 * @param projectStatus Project Status.
	 */
	public async openProjectFromStatus(projectStatus: ProjectStatus) {
		const project: Project | null = this.$tsStore.projects.getProjectByUuid(projectStatus.ProjectUUID);
		if (project) {
			const projectPageUtils = new ProjectPageUtility(this);
			projectPageUtils.openPageUtil(project);
		} else {
			this.$faroComponents.$emit('show-error', { message: 'LP_ERR_OPEN_PROJECT' });
			$assert.Assert(project, 'openProjectFromStatus() could not find the Project');
		}
	}

	public async created() {
		// Make sure to set the default sort by because it is different in project status than in other pages.
		this.$tsStore.projectStatuses.setSortByDefault();
		this.hideLoadingSpinner();
		this.xStore.updateFiltersState();
	}
}
