
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ItemsViewBaseMixin from '@/mixins/ItemsViewBaseMixin';
import { LpEntity } from '@/classes';
import { ImageSize } from '@/utils/types';
import EntityCard from './EntityCard/EntityCard.vue';

@Component({
	components: {
		EntityCard,
	},
})
export default class PageThumbnailView<EntityT extends LpEntity> extends ItemsViewBaseMixin<EntityT> {
	@Prop({type: Boolean, default: false}) public readonly hideSecondaryInfo!: boolean;

	public get imageSize(): ImageSize {
		return 'thumbnail-lg';
	}
}
