import Vue from 'vue';
import * as coreHttp from '@azure/core-http';

export class BearerToken implements coreHttp.TokenCredential {
	public async getToken(): Promise<coreHttp.AccessToken | null> {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return {
			token: await $tsStore.users.getTokenSilently() ?? '',
			expiresOnTimestamp: 0,
		};
	}
}

/*
 * This policies purpose is to replace the default coreHttp BearerTokenAuthenticationPolicy
 * in local development environments. This is only required in localhost environments
 * since the default policy doesn't allow BearerToken authentication on non ssl routes.
 * @author Daniel Püngel
 * @param nextPolicy The next RequestPolicy to be called after this one succeeds.
 * @param options Loglevel options
 * @param credential Object with getToken function to return a coreHttp.AccessToken
 * @returns coreHttp.RequestPolicy
 */
export class LocalBearerTokenAuthenticationPolicy extends coreHttp.BaseRequestPolicy {
	// The default coreHttp bearerTokenAuthenticationPolicy is defined here:
	// https://github.com/Azure/azure-sdk-for-js/blob/d322a18f086e279f6616a70f92400841a60de5fd/sdk/core/core-http/src/policies/bearerTokenAuthenticationPolicy.ts#L230
	private credential: BearerToken;

	constructor(nextPolicy: coreHttp.RequestPolicy, options: coreHttp.RequestPolicyOptionsLike, credential: BearerToken) {
		super(nextPolicy, options);
		this.credential = credential;
	}

	public async sendRequest(webResource: coreHttp.WebResourceLike) {
		const token = await this.credential.getToken();
		webResource.headers.set('authorization', `Bearer ${token ? token.token : ''}`);
		return this._nextPolicy.sendRequest(webResource);
	}
}

/*
 * Creates a coreHttp.PolicyFactory which handles use of bearer token in local environments.
 * LocalBearerTokenAuthenticationPolicy is designed to replace the default policy in localhost dev environment.
 * @author Daniel Püngel
 * @param credential Object with getToken function to return a coreHttp.AccessToken
 * @returns coreHttp.RequestPolicyFactory
 */
export const localBearerTokenAuthenticationPolicyFactory = (credential: BearerToken): coreHttp.RequestPolicyFactory => {
	return {
		create: (nextPolicy: coreHttp.RequestPolicy, options: coreHttp.RequestPolicyOptionsLike) => {
			return new LocalBearerTokenAuthenticationPolicy(nextPolicy, options, credential);
		},
	};
};
