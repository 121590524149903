import { $assert } from '@faroconnect/utils';
import Vue from 'vue';

/**
 * Login to WebShare and redirect to the given redirectUrl
 * Fallbacks to window.location.href = redirectUrl, if no auth0Token is found.
 * @param domainUrl   Domain to login to.
 * @param redirectUrl Redirect url to redirect after login
 */
export function webShareLoginAndRedirect(domainUrl: string, redirectUrl: string) {
	const $tsStore: $tsStore = Vue.prototype.$tsStore;
	const token = $tsStore.users.auth0Token;
	if (!token) {
		$assert.Assert(false, 'Unexpected: No token available');
		window.location.href = redirectUrl;
		return;
	}

	const loginUrl = domainUrl + '/login_oidc';

	// Create form to send a post request
	const form = document.createElement('form');
	form.setAttribute('method', 'post');
	form.setAttribute('action', loginUrl);

	// Set the JWT
	const jwt = document.createElement('INPUT');
	jwt.setAttribute('type', 'HIDDEN');
	jwt.setAttribute('name', '_password');
	jwt.setAttribute('value', token);
	form.appendChild(jwt);

	// Set the redirect_url
	const redirect = document.createElement('INPUT');
	redirect.setAttribute('type', 'HIDDEN');
	redirect.setAttribute('name', 'redirect_url');
	redirect.setAttribute('value', redirectUrl);
	form.appendChild(redirect);

	document.body.appendChild(form);

	form.submit();
}
