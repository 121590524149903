import { PAGE_LAYOUT_ENUM } from '@/definitions/constants';
import { UserService } from '@/store/services/UserService';
import { Module, VuexModule, Mutation, Action } from 'vuex-class-modules';
import { Resetable } from '@/store/modules/interfaces';
import { PageLayout } from '@/definitions/types';
import { IMFATicketInfo } from '@/definitions/interfaces';

@Module
export class SettingsModule extends VuexModule implements Resetable {
	public defaultView: PageLayout = PAGE_LAYOUT_ENUM.thumbnail;
	public showMfaDialog: boolean = true;
	public skipWorkspaceCreationDialog: boolean = false;
	/**
	 * UUID of the active workspace. null if none is (yet) set to active.
	 * The frontend code should usually access the workspace store to get the active workspace, and not this member.
	 */
	public activeWorkspace: string | null = null;
	public showActiveWorkspaceDialog: boolean = false;

	protected readonly userService = new UserService({});

	@Action
	/**
	 * Get user settings from database.
	 */
	public async initializeSettings(): Promise<void> {
		const settings = await this.userService.getUserSettings();
		this.setDefaultView(settings.DefaultView);
		this.setShowMfaDialog(settings.ShowMfaDialog);
		this.setSkipWorkspaceCreationDialog(settings.SkipWorkspaceCreationDialog);
		this.setActiveWorkspace(settings.ActiveWorkspace);
	}

	@Action
	/**
	 * Do something to reset this module.
	 */
	public reset(): void {
		this.setDefaultView(PAGE_LAYOUT_ENUM.thumbnail);
		this.setSkipWorkspaceCreationDialog(false);
	}

	@Action
	public async updateDefaultView(defaultView: PageLayout) {
		const response = await this.userService.changeUserSettings({ DefaultView: defaultView });
		this.setDefaultView(response.DefaultView);
	}

	@Action
	public async updateSkipWorkspaceCreationDialog(skipWorkspaceCreationDialog: boolean) {
		const response = await this.userService.changeUserSettings({ SkipWorkspaceCreationDialog: skipWorkspaceCreationDialog });
		this.setSkipWorkspaceCreationDialog(response.SkipWorkspaceCreationDialog);
	}

	@Action
	public async updateActiveWorkspace(activeWorkspace: string | null) {
		const response = await this.userService.changeUserSettings({ ActiveWorkspace: activeWorkspace });
		this.setActiveWorkspace(response.ActiveWorkspace);
	}

	@Mutation
	public setDefaultView(defaultView: PageLayout) {
		this.defaultView = defaultView ?? PAGE_LAYOUT_ENUM.thumbnail;
	}

	@Mutation
	public setShowMfaDialog(showMfaDialog: boolean) {
		this.showMfaDialog = showMfaDialog ?? true;
	}

	@Mutation
	public setSkipWorkspaceCreationDialog(skipWorkspaceCreationDialog: boolean) {
		this.skipWorkspaceCreationDialog = skipWorkspaceCreationDialog ?? false;
	}

	@Mutation
	public setActiveWorkspace(activeWorkspace: string | null) {
		this.activeWorkspace = activeWorkspace;
	}

	@Action
	public async enrollMFA(): Promise<IMFATicketInfo> {
		return await this.userService.enrollMFA();
	}

	@Action
	public async disableMFA() {
		await this.userService.disableMFA();
	}

	@Action
	public async hasUserEnabledMFA(): Promise<{ MFAEnabled: boolean }> {
		return await this.userService.checkUserHasMFAEnabled();
	}
}
