
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SettingsBtn extends Vue {
	@Prop(Boolean) public readonly listDropdown!: boolean;
	public openSettings() {
		if (!this.active) {
			this.$router.push({ name: 'SettingsPage' });
		}
		this.$faroComponents.$emit('close-horizontal-expand');
	}

	public get active() {
		return this.$route.name === 'SettingsPage';
	}
}
