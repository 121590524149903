import { BaseEntity } from '@/classes/BaseEntity';
import { IBaseEntity } from '@/definitions/interfaces';
import { sortObject } from '@/utils/sortitems';
import { $assert } from '@faroconnect/utils';

// ----------------------------------------------------------------------------

/**
 * Aggregated project statistics for the whole workspace.
 */
export interface StatsAggregatedI {
	Name: string;
	WebshareDomainName?: string;
	ErpId: string;
	Users: number;
	Size: number;
	Projects: number;
	ProjectsCreatedWithScanPlan?: number;
	ProjectsCreatedWithWSGenerator?: number;
	ProjectsCreatedWithScene?: number;
	Scans?: number;
	PCs?: number;
	PCsSize?: number;
	PCsBasedOn1CPE?: number;
	PCsNotBasedOn1CPE?: number;
	[key: string]: any;
}

export interface StatsAggregatedMap {
	[key: string]: StatsAggregatedI; // Key = Workspace UUID
}

/**
 * Statistics for a WebShare projects.
 */
export interface StatsProjectsI {
	[key: string]: { // Project ID
		Name: string;
		UpdateDate: string;
		Type: string;
		Size: number;
		Scans?: number;
		PCs?: number;
		PCsSize?: number;
		PCsBasedOn1CPE?: number;
		PCsNotBasedOn1CPE?: number;
		PCObjects?: {
			[key: string]: { // WSPointCloud UUID
				Name: string;
				BasedOn1CPE: boolean;
				PointsOfWebSharePC: number;
				Size: number;
				Entity?: string; // EntityPC UUID
			};
		};
		[key: string]: any;
	}
}

export interface WorkspaceStatsI extends IBaseEntity {
	Class: string;
	Workspace: string; // Workspace UUID
	StatsAggregated: StatsAggregatedI;
	StatsProjects: StatsProjectsI;
}

// ----------------------------------------------------------------------------

/**
 * Workspace statistics object received from Migrator backend.
 */
export class WorkspaceStats extends BaseEntity implements WorkspaceStatsI {
	public static readonly constructorName = WorkspaceStats.name;

	public static fromResponse(json: WorkspaceStatsI) {
		return new WorkspaceStats(json);
	}

	public static forRequest(json: WorkspaceStatsI) {
		$assert.Assert(false, 'Why is the Landing Page trying to create/update a WorkspaceStats object?');
		return new WorkspaceStats(json);
	}

	public Class: string = 'WorkspaceStats';
	/**
	 * Workspace UUID.
	 */
	public Workspace: string;
	public StatsAggregated: StatsAggregatedI;
	public StatsProjects: StatsProjectsI;
	// Inherited but unused
	public ImgUrl: string = '';
	public DefaultImgUrl: string = '';

	protected constructor(obj: WorkspaceStatsI) {
		super(obj);
		this.Workspace = obj.Workspace;
		const statsAggregated = obj.StatsAggregated ?? {};
		const statsProjects = obj.StatsProjects ?? {};

		// The JSON attributes are weirdly ordered by attribute name length by the database layer of the Migrator
		// backend. Alphabetical sorting makes more sense for display.
		this.StatsAggregated = sortObject(statsAggregated);
		for (const projectId in statsProjects) {
			statsProjects[projectId] = sortObject(statsProjects[projectId]);
		}
		this.StatsProjects = sortObject(statsProjects);
	}
}
