
import Vue from 'vue';
import Component from 'vue-class-component';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { UserBtnTaskTopRightComponentData } from '@/utils/types';
import { queryToString } from '@/utils/validate';
import { BASE_URL } from '@/config';
import { setLanguageLikeBrowser } from '@/utils/browser';

const loginFailedImg = require('@/assets/login-failed.png');
const loginFailedBlockedImg = require('@/assets/login-failed-blocked.png');

@Component
export default class LoginFailedTask extends Vue {
	public loginFailedOrBlockedImg = loginFailedImg;
	public readonly SUPPORT_TEAM_EMAIL = 'support@faro.com';
	public readonly lpUrl = window.location.origin;
	public topRightComponent = UserBtn;
	public error: string | null = null;
	public errorDescription: string | null = null;
	public userBlockedError = false;


	public get topRightComponentData(): UserBtnTaskTopRightComponentData {
		return {
			isFullscreenTask: true,
			mockState: {
				isAuthenticated: 'maybe',
			},
		};
	}

	/**
	 * Returns the translations for the text
	 */
	public get tryLoggingInAgainLaterText(): string[] {
		const placeholder = 'XXXXX';
		// Add XXXXX as placeholder to be replaced with the text for logging in.
		const text = this.$tc('LP_LOGIN_FAILED_TRY_AGAIN_LATER', undefined, {login: placeholder}).split(placeholder);
		// If the length is two it means that it is in the form ['Try', 'again or check...']
		if (text.length === 2) {
			return text;
		} else {
			return [this.$tc('LP_LOGIN_FAILED_TRY_AGAIN_LATER'), ''];
		}
	}

	public logout(event: PointerEvent | undefined) {
		if (event) {
			event.preventDefault();
		}
		this.$faroLoading.start();
		this.$auth.logout({returnTo: window.location.origin + BASE_URL});
	}

	public async mounted() {
		this.error = queryToString(this.$route.query.error);
		this.errorDescription = queryToString(this.$route.query.error_description);
		this.userBlockedError = this.errorDescription?.includes('blocked') || false;

		if (this.userBlockedError) {
			this.loginFailedOrBlockedImg = loginFailedBlockedImg;
		}

		setLanguageLikeBrowser();

		localStorage.setItem('sphere-is-authenticated', 'false');
	}
}
