import Vue from 'vue';
import Component from 'vue-class-component';
import EntitiesPageBase from '@/components/EntitiesPage/EntitiesPageBase.vue';
import { ModuleNameOf } from '@/store/types';
import { ItemButtons } from '@/utils/types';
import { getErrorMessage } from '@/utils/errorhandler';
import { LpEntity } from '@/classes';
import { EntityHeader, Header } from '@/utils/listview';
import { EntitySortBy, SortItem } from '@/utils/sortitems';

/**
 * Base mixin component for a page that displays an entities list.
 */
@Component({
	components: {
		EntitiesPageBase,
	},
})
export default class PageBaseMixin<EntityT extends LpEntity> extends Vue {
	// ######################## Data to Inherit ##################
	public xLoadingEntity: EntityT | null = null;
	public xLoading: boolean = false;
	public xError: boolean = false;
	public xErrorTitle: string | null = null;
	public xErrorMsg: string | null = null;
	public xEntityHeader = new EntityHeader(this.$vuetify, this.$tc);
	public xEntitySortBy = new EntitySortBy();

	// ######################## Getters to Override ##################

	/**
	 * Text to show when get all items failed.
	 */
	public get xGetAllErrString(): string {
		throw new Error('This getter should be overriden');
	}

	/**
	 * Name of the currently shown page.
	 */
	public get xPageName(): string {
		throw new Error('This getter should be overriden');
	}

	/**
	 * Name of the store for the currently shown entity.
	 */
	public get xStoreName(): ModuleNameOf<EntityT> {
		throw new Error('This getter should be overriden');
	}

	/**
	 * Array filled all the unfiltered items. This array won't be show, but it is needed
	 * to know the amount of items without any filter.
	 */
	public get xItems(): EntityT[] {
		throw new Error('This getter should be overriden');
	}

	/**
	 * Array filled with all the filtered and sorted items. It will be used to show the entities on the page.
	 */
	public get xFilteredItems(): EntityT[] {
		throw new Error('This getter should be overriden');
	}

	/**
	 * Array filled with all the filtered and sorted items. It will be used to show the entities on the page.
	 */
	public get xEntitiesOnPage(): EntityT[] {
		throw new Error('This getter should be overriden');
	}

	/**
	 * Array filled with the buttons that should be shown in each entity card.
	 */
	public get xItemButtons(): ItemButtons<EntityT> {
		throw new Error('This getter should be overriden');
	}

	public get xHeaders(): Array<Header<EntityT>> {
		throw new Error('This method should be overriden');
	}

	// ######################## Getters to Inherit ##################

	/**
	 * Gets the store instance based on the provided store module.
	 */
	public get xStore() {
		return this.$tsStore[this.xStoreName];
	}

	/**
	 * Can be overriden to show more sorting items.
	 */
	public get xSortItems(): Array<SortItem<EntityT>> {
		throw new Error('This method should be overriden');
	}

	// ######################## Methods to Override ##################

	/**
	 * Callback whenever the add tile button is clicked.
	 */
	public async xAddNewTile(): Promise<void> {
		throw new Error('This method should be overriden');
	}

	/**
	 * Callback whenever an entity is clicked and should be opened.
	 * @param entity The entity to be opened.
	 */
	public async xOpenEntity(entity: EntityT) {
		throw new Error('This method should be overriden');
	}

	public handleError(error: any) {
		this.xError = true;
		this.xErrorTitle = this.xGetAllErrString;
		this.xErrorMsg = getErrorMessage(error);
	}

	// ######################## Methods to Inherit ##################

	public async xInitialize() {
		this.xLoading = true;
		try {
			this.$faroLoading.start();
			await this.xStore.getAll();
		} catch (error) {
			console.error(error);
			this.handleError(error);
		} finally {
			this.$tsStore.pages.setFinishedPageLoading(true);
			if (this.$tsStore.pages.finishedMainLoading) {
				this.$faroLoading.stop();
			}
			this.xLoading = false;
		}
	}

	/**
	 * Initializes the store for the current page only if it is not the first page that the user opens.
	 * This way we avoid loading two times the same entities, for example projects since they were
	 * loaded already in App.vue.
	 * @returns A promise resolved with true if the page module was initialized.
	 */
	public async xInitializeAfterFirstPage() {
		if (this.$tsStore.pages.firstOpenedPage === this.xPageName) {
			this.$tsStore.pages.setFirstOpenedPage(null);
			this.$tsStore.pages.setFinishedPageLoading(true);
			if (this.$tsStore.pages.finishedMainLoading) {
				this.$faroLoading.stop();
			}
			return false;
		} else {
			await this.xInitialize();
			return true;
		}
	}
}
