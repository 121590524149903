import { AuthzInterface, AuthzType } from '@faroconnect/authz-client';
import { BaseEntity } from '@/classes/BaseEntity';
import { faroLocalization } from '@faroconnect/baseui';

type ProjectRoleClass = 'ProjectRole';

export const ProjectRoleNameStringId: { [key in AuthzType.PredefinedProjectRoleId]: string } = {
	editor: 'UI_EDITOR',
	manager: 'UI_MANAGER',
	uploader: 'UI_UPLOADER',
	viewer: 'UI_VIEWER',
};

export const ProjectRoleDescStringId: { [key in AuthzType.PredefinedProjectRoleId]: string } = {
	editor: 'UI_EDITOR_DESC',
	manager: 'UI_MANAGER_DESC',
	uploader: 'UI_UPLOADER_DESC',
	viewer: 'UI_VIEWER_DESC',
};

export class ProjectRole extends BaseEntity implements AuthzInterface.IProjectRole {
	public static constructorName = ProjectRole.name;

	public static fromResponse(json: AuthzInterface.IProjectRole) {
		return new ProjectRole(json);
	}

	public static forRequest(json: Partial<AuthzInterface.IProjectRole>) {
		return new ProjectRole(json);
	}

	public readonly isProjectRole: boolean = true;

	public ImgUrl: string = '';
	public DefaultImgUrl: string = '';

	public Class: ProjectRoleClass = 'ProjectRole';
	public Workspace: string;
	public Predefined: boolean;
	public Id: AuthzType.PredefinedProjectRoleId | null;
	public Type: AuthzType.ProjectRoleType;
	public NumberOfUsers: number | null;
	public StillAvailable: number | null;

	protected constructor(obj: Partial<AuthzInterface.IProjectRole>) {
		super(obj);
		this.Workspace = obj.Workspace ?? '';
		this.Predefined = obj.Predefined ?? false;
		this.Id = obj.Id ?? null;
		this.Type = obj.Type ?? 'standard';
		this.NumberOfUsers = obj.NumberOfUsers ?? null;

		this.StillAvailable = null;
		if (obj.StillAvailable) {
			this.StillAvailable = obj.StillAvailable === -1 ? Infinity : obj.StillAvailable;
		}
	}

	public get displayName(): string {
		if (this.Predefined && this.Id && ProjectRoleNameStringId[this.Id]) {
			return faroLocalization.i18n.tc(ProjectRoleNameStringId[this.Id]);
		}
		return this.Name;
	}

	public get predefinedDescription(): string {
		if (this.Predefined && this.Id && ProjectRoleDescStringId[this.Id]) {
			return faroLocalization.i18n.tc(ProjectRoleDescStringId[this.Id]);
		}
		return '';
	}

	public get displayDescription(): string {
		return this.Description || this.predefinedDescription;
	}
}
