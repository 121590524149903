var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"gradientBackgroundImageBackground",class:[
		_vm.imageSize,
		{
			'thumnail-coming-soon': _vm.comingSoon,
			'thumbnail-lg-width': _vm.$vuetify.breakpoint.lgAndUp && !_vm.listView,
			'thumbnail-md-width': _vm.$vuetify.breakpoint.md && !_vm.listView,
			'thumbnail-sm-width': _vm.$vuetify.breakpoint.sm && !_vm.listView,
			'thumbnail-xs-width': _vm.$vuetify.breakpoint.xs && !_vm.listView
		}
	]},[_c('div',{staticClass:"gradientBackgroundImageWhite"},[_c('div',{staticClass:"gradientBackgroundImageWrapper"},[_c('img',{staticClass:"gradientBackgroundImage",attrs:{"src":_vm.src}})])]),(_vm.comingSoon && _vm.comingSoonSize !== 'x-small')?_c('coming-soon-label',{attrs:{"size":_vm.comingSoonSize}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }