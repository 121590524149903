import { AuthzInterface } from '@faroconnect/authz-client';
import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { BASE_AUTHZ_API_URL, config } from '@/config';
import { ProjectRole } from '@/classes/authz/ProjectRole';

/**
 * This service is in charge of CRUD operations to manipulate the Sphere users in the Authz backend.
 */
export class AuthzProjectRoleService extends BaseService<ProjectRole> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'projectrole',
			apiEndpoint: config.authzApiEndpoint,
			baseApiUrl: BASE_AUTHZ_API_URL,
		}, clientConfig);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<AuthzInterface.IProjectRole> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<AuthzInterface.IProjectRole[]> {
		throw new Error('Function not yet implemented');
	}

	public async create<QueryT extends object>(body?: ProjectRole, query?: QueryT): Promise<AuthzInterface.IProjectRole> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(
		body?: Partial<AuthzInterface.IProjectRole>,
		query?: QueryT,
	): Promise<AuthzInterface.IProjectRole> {
		throw new Error('Function not yet implemented');
	}

	public async updateMulti<QueryT extends object>(body?: ProjectRole[], query?: QueryT): Promise<AuthzInterface.IProjectRole[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	public async getAllFromWorkspace(workspaceUuid: string): Promise<AuthzInterface.IProjectRole[]> {
		const url = this.makeUrl(workspaceUuid);
		return await this.get<AuthzInterface.IProjectRole[]>(url);
	}
}
