import { AuthzReq } from '@faroconnect/authz-client';
import { Workspace, IWorkspace } from '@/classes/authz/Workspace';
import { BASE_AUTHZ_API_URL, config } from '@/config';
import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { IUserRespondToInvitationPayload } from '@/definitions/interfaces';

export interface GetAllWorkspacesQuery {
	withinviters: boolean;
	withsharedate: boolean;
	withstate?: boolean;
	omitdeactivated?: boolean;
}

export interface IInvitationResponse {
	Workspace: IWorkspace,
	InvitedUserUUID: string,
	PasswordResetUrl: string|null
}

/**
 * This service is in charge of CRUD operations to manipulate the Sphere workspaces.
 */
export class AuthzWorkspaceService extends BaseService<Workspace> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'workspace',
			apiEndpoint: config.authzApiEndpoint,
			baseApiUrl: BASE_AUTHZ_API_URL,
		}, clientConfig);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<IWorkspace> {
		return await this.get(this.makeUrl(uuid, query));
	}

	/**
	 * Requires the "migrate-workspaces" Auth0 permission.
	 * @author OK
	 */
	public async getSingleAdmin<QueryT extends object>(uuid: string, query?: QueryT): Promise<IWorkspace> {
		return await this.get(this.makeUrl(uuid + '/admin', query));
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<IWorkspace[]> {
		const workspaces: IWorkspace[] = await this.get(this.makeUrl(undefined, query));
		return workspaces.map((workspace) => Workspace.fromResponse(workspace));
	}

	/**
	 * Returns all workspaces, including those the requesting user is not a member of.
	 * Requires the "migrate-workspaces" Auth0 permission.
	 * @author OK
	 */
	public async getAllAdmin<QueryT extends object>(query?: QueryT): Promise<IWorkspace[]> {
		const workspaces: IWorkspace[] = await this.get(this.makeUrl('all/admin', query));
		return workspaces.map((workspace) => Workspace.fromResponse(workspace));
	}

	public async create<QueryT extends object>(body?: Workspace, query?: QueryT): Promise<IWorkspace> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(body?: Workspace, query?: QueryT): Promise<IWorkspace> {
		return await this.put(this.makeUrl(body!.UUID), body);
	}

	public async updateMulti<QueryT extends object>(body?: Workspace[], query?: QueryT): Promise<IWorkspace[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	public async inviteUsers(workspaceUuid: string, data: AuthzReq.InviteUsersReq): Promise<{ Success: true }> {
		const url = this.makeUrl(`${workspaceUuid}/invite-users`);
		return this.put(url, data);
	}

	public activateUser(workspaceUuid: string): Promise<void> {
		const url = this.makeUrl(`${workspaceUuid}/activate-user`);
		return this.post(url);
	}

	public userRespondToInvitation(payload: IUserRespondToInvitationPayload): Promise<IInvitationResponse> {
		const url = this.makeUrl('invite-result');
		return this.post(url, payload, {
			// Use an empty object to prevent using the default headers,
			// since they include the bearer token by default and for that the user has to be logged in,
			// which should not be required in this case.
			headers: {},
		});
	}

	public unassignCallingUser(workspaceUuid: string, action: 'reject' | 'leave'): Promise<void> {
		const url = this.makeUrl(`${workspaceUuid}/unassign-calling-user?action=${action}`);
		return this.put(url);
	}

	/**
	 * Calls AuthZ to update workspace owner.
	 * @author MF
	 * @param workspaceUuid Workspace uuid.
	 * @param userUuid User UUID.
	 */
	public async updateOwner(workspaceUuid: string, userUuid: string): Promise<void> {
		const url = `${this.makeUrl()}/${workspaceUuid}/${userUuid}/update-owner`;
		return await this.put(url);
	}
}
