
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AuthzType } from '@faroconnect/authz-client';
import { validateWorkspaceName, validateWorkspaceDescription } from '@/utils/validate';
import { Workspace } from '@/classes/authz/Workspace';
import * as WorkspaceUtils from '@/utils/workspaceutils';
import { isFeatureEnabledByAuth0PermissionsSync } from '@/utils/permissions';
import { config } from '@/config';

/* global NodeJS */

export interface FormData {
	name?: string;
	region?: string;
	description?: string;
	license: boolean;
}

export interface InitData {
	workspace?: Workspace;
	isFullscreen: boolean;
}

@Component
export default class UpdateOrCreateWorkspaceTaskBase extends Vue {
	@Prop({ required: true }) public value!: FormData;
	@Prop({ required: true }) public readonly isUpdateMode!: boolean;
	@Prop(Function) public readonly onWsNameError?: (msg?: string) => void;

	protected workspaceNameErrorMsg = '';
	protected checkNameTimeout: NodeJS.Timeout | null = null;
	protected lastCheckedName: string = '';
	protected initialName: string = '';

	protected get regions(): WorkspaceUtils.DataTableHeaderWithCypressLocator[] {
		return WorkspaceUtils.availableRegions();
	}

	protected get canUpdateWorkspaceName(): boolean {
		// This getter seems to be called only once per edit dialog, so it's okay to do some computation.
		return isFeatureEnabledByAuth0PermissionsSync('updateWorkspaceName');
	}

	// Rules for input fields
	protected readonly nameRequired = (v: string) => this.sanitizeName(v) !== '' || this.$t('LP_REQUIRED_NAME');
	protected readonly nameValid = (v: string) => {
		const isFaroUser = this.$tsStore.users.isStrictFaroUser; // "Strict" to be in sync with SubSvc backend logic.

		const sv = this.sanitizeName(v);
		if (validateWorkspaceName(sv, !!isFaroUser)) {
			return true;
		} else if (sv.startsWith('-') || sv.endsWith('-')) {
			return this.$t('LP_NAME_INVALID_HYPHEN');
		} else if (!isFaroUser && sv.includes('faro')) {
			return this.$t('LP_NAME_INVALID_FARO');
		} else {
			return this.$t('LP_NAME_INVALID');
		}
	};
	protected readonly validateWorkspaceRegion = (region?: string) =>
		region && this.regions.map((region) => region.value).includes(region as AuthzType.WebshareRegion);
	protected readonly regionRequired = (v?: string) => this.validateWorkspaceRegion(v) || this.$t('LP_REQUIRED_REGION');
	protected readonly descriptionLen = (v: string) => validateWorkspaceDescription(v) || this.$t('LP_DESC_LEN_INVALID');

	/**
	 * Remove trailing and leading spaces from name string.
	 * @param name string to be sanitized.
	 */
	protected sanitizeName(name: string | undefined): string {
		return name ? name.trim() : '';
	}

	protected async suggestWorkspaceName(): Promise<{ Success: boolean, Message: string, Suggestion: string }> {
		return await this.$tsStore.workspaces.suggestName();
	}

	protected checkNameExists() {
		// If there's already a timeout, clear it to initiate a new one.
		// That way if you continue to type fast enough, it will wait until you are done
		// typing to send a request to check for the name
		if (this.checkNameTimeout) {
			clearTimeout(this.checkNameTimeout);
		}

		const name = this.sanitizeName(this.value.name);
		this.value.name = name;
		if (!name || !validateWorkspaceName(name, !!this.$tsStore.users.isStrictFaroUser)) {
			this.workspaceNameErrorMsg = ''; // Avoid double error message.
			return;
		}

		if (name === this.lastCheckedName) {
			return; // Name hasn't changed since last edit (e.g. user just pressed <LeftArrow>).
		}

		// Notify parent dialog that the "Create"/"Update" button should be disabled until validation has completed.
		// The message doesn't matter, it's not shown anywhere.
		const cb = this.onWsNameError || ((msg: string) => {});
		cb('Validating name...');

		this.checkNameTimeout = setTimeout(async () => {
			const setError = (msg: string) => {
				this.workspaceNameErrorMsg = msg;
				cb(msg); // Notify parent dialog.
			};

			// Only do the check of this name if it's the latest one, and wasn't checked last time.
			if (name !== this.value.name) {
				return; // Name was updated in the meantime, check is obsolete.
			}
			if (name === this.initialName) {
				setError(''); // Name hasn't changed compared to original value, so there should be no error.
				this.lastCheckedName = name;
				return;
			}
			if (name === this.lastCheckedName) {
				return; // Name hasn't changed since last edit (e.g. user just pressed <LeftArrow>).
			}

			if (await this.$tsStore.workspaces.workspaceExists(name)) {
				setError(this.$t('LP_NAME_ALREADY_TAKEN').toString());
			} else {
				setError('');
			}
			this.lastCheckedName = name;
		// 300 ms avoids sending too often requests for the name but still feels to the user that it is almost immediate.
		}, 300);
	}

	protected async created() {
		// Avoid that we report the current name as "already taken" if the user changes it, then changes back
		// in the same dialog.
		this.lastCheckedName = this.value.name ?? '';
		this.initialName = this.value.name ?? '';
		// When updating a workspace (`this.value.name` is set), we don't need or want the suggestion.
		if (this.$tsStore.users.user && !this.value.name) {
			const country = this.$tsStore.users.user.Country;
			// In DEV, the region should always be "us".
			this.value.region = config.webShareRegions.length === 1 ?
			                    config.webShareRegions[0] : WorkspaceUtils.isUSorEU(country);
			// Since the request may take some time, check again afterwards if `this.value.name` is still empty.
			// Don't disturb the user if he has already started typing.
			const suggestion = await this.suggestWorkspaceName();
			if (!this.value.name &&
				suggestion.Success && validateWorkspaceName(suggestion.Suggestion, !!this.$tsStore.users.isStrictFaroUser)
			) {
				this.value.name = suggestion.Suggestion;
			}
		}
	}
}
