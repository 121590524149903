
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BaseSelectedNote extends Vue {
	@Prop({ type: Boolean, required: true }) public readonly isInternalWorkspaceForUpgrade!: boolean;

	public closeBar(): void {
		this.$faroComponents.$emit('closebaseselectedmsg');
	}
}
