import { IService, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';
import { Service } from '@/classes/Service';
import { dynamicConfig } from '@/config';
import { $assert } from '@faroconnect/utils';

export class ServiceAcademy extends Service {
	// Boilerplate
	public static forRequest(json: Partial<IService> & IGradientBackground & IMeshBackground) {
		return new ServiceAcademy(json);
	}

	public readonly isServiceAcademy: boolean = true;

	public Class: string = 'ServiceAcademy';

	public get LocalizedUrl(): string {
		const isFaro = this.user && (this.user.Email.endsWith('@faro.com') || this.user.Email.endsWith('@faroeurope.com'));
		const url = dynamicConfig.url;

		// We were told that FARO users should currently log in via Okta instead of Auth0.
		// If this should be changed in the future, just set academyUrlFaro = undefined. Then academyUrlCustomers will be used for everyone.
		if (this.Url && this.Url === url.academyDevUrlCustomers) {
			// Academy DEV (login with Sphere DEV):
			$assert.Strings([url.academyDevUrlFaro || '', url.academyDevUrlCustomers!]);
			return (isFaro && url.academyDevUrlFaro) ? url.academyDevUrlFaro! : url.academyDevUrlCustomers!;
		} else {
			// Academy PROD (login with Sphere PROD):
			$assert.Strings([url.academyUrlFaro || '', url.academyUrlCustomers!]);
			return (isFaro && url.academyUrlFaro) ? url.academyUrlFaro : url.academyUrlCustomers!;
		}
	}
}
