import Vue from 'vue';
import { Mutation, RegisterOptions } from 'vuex-class-modules';
import { faroLocalization, faroLocalStorage } from '@faroconnect/baseui';
import { BaseModule } from '@/store/modules/BaseModule';
import { Resetable } from '@/store/modules/interfaces';
import { PageModule } from '@/store/modules/PageModule';
import { Constructable, LpEntity } from '@/classes';
import { Project, ProjectSource } from '@/classes/authz/Project';
import { $assert } from '@faroconnect/utils';

export interface FilterDateOptionBase {
	text: string;
	value: any;
}

export interface FilterByDateType<EntityT extends LpEntity> extends FilterDateOptionBase {
	text: string;
	value: keyof EntityT;
}

export interface FilterByDateLimit extends FilterDateOptionBase {
	text: string;
	value: 'NO_DATE' | 'TIME_PERIOD' | 'DATE';
}

export type DatePeriodTimeMeasure = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export interface FilterByDate<EntityT extends LpEntity> {
	dateType: FilterByDateType<EntityT>['value'];
	dateLimit: FilterByDateLimit['value'],
	datePeriodNumber: number;
	datePeriodTimeMeasure: DatePeriodTimeMeasure;
	dateRangeFrom: string;
	dateRangeTo: string | null;
}

export interface FilterByType {
	text: string;
	value: string;
	icon?: string | null;
}

export interface FilterByProjectSourceType extends FilterDateOptionBase {
	text: string;
	value: ProjectSource;
}

export interface FilterByProjectStatusType {
	text: string;
	value: string;
}

export type EntityType = 'Project';

interface FilterByEntityTypeOption extends FilterDateOptionBase {
	value: EntityType;
}

interface BaseFilter<EntityT extends LpEntity> {
	filterByDate: FilterByDate<EntityT>;
	filterByAllProjects: boolean;
	filterByProjectUuids: string[];
	filterByAllEntityTypes: boolean;
	filterByEntityTypes: EntityType[];
	filterByAllProjectSources: boolean;
	filterByProjectSource: string[];
	filterByAllProjectStatuses: boolean;
	filterByProjectStatusIds: string[];
}

export interface BaseFilterOption {
	text: string;
	value: string;
}

/**
 * Available filter options.
 */
export const PAGE_FILTERS = {
	Date: 'Date',
	Project: 'Project',
	EntityType: 'EntityType',
	ProjectSource: 'ProjectSource',
	ProjectStatus: 'ProjectStatus',
} as const;

export type PageFilters = typeof PAGE_FILTERS[keyof typeof PAGE_FILTERS];

/**
 * Interface for the active filter indicators.
 */
export interface FilterByActiveIndicator {
	/**
	 * Text to display in active filter indicator
	 */
	text: string;
	/**
	 * Filter type: Worskpace, Date, etc.
	 */
	filter: PageFilters;
	/**
	 * True if filter indicator can be clicked to open a list of selected filter options.
	 */
	isExpandable: boolean;
	/**
	 * True if the user expanded the fitler indicator by clicking on it.
	 */
	expanded: boolean;
	/**
	 * Array of options selected by the user.
	 */
	activeFilterItems: BaseFilterOption[];
	/**
	 * Optional text that can be used as tooltip for the filter indicator.
	 */
	tooltip?: string;
}

export abstract class BaseFilterModule<EntityT extends LpEntity> extends BaseModule<EntityT> implements Resetable, BaseFilter<EntityT> {
	// ###################################### Properties ######################################

	// ###### Public ######

	// ## Constants ##

	public readonly DEFAULT_SELECTED_DATE_TYPE: FilterByDateType<EntityT>['value'] = 'CreationDate';
	public readonly DEFAULT_DATE_LIMIT: FilterByDateLimit['value'] = 'NO_DATE';
	public readonly DEFAULT_FILTER_BY_DATE: FilterByDate<EntityT> = {
		dateType: this.DEFAULT_SELECTED_DATE_TYPE,
		dateLimit: this.DEFAULT_DATE_LIMIT,
		datePeriodNumber: 1,
		datePeriodTimeMeasure: 'DAY',
		dateRangeFrom: this.getPrettyDate(new Date()),
		dateRangeTo: null,
	};

	// ## Variables ##

	public filterByDate: FilterByDate<EntityT> = this.DEFAULT_FILTER_BY_DATE;
	public filterByAllProjects: boolean = true;
	public filterByProjectUuids: string[] = [];
	public expandFilterByProjectIndicator: boolean = false;
	public filterByAllEntityTypes: boolean = true;
	public filterByEntityTypes: EntityType[] = [];
	public expandFilterByEntityTypeIndicator: boolean = false;
	public filterByAllProjectSources: boolean = true;
	public filterByProjectSource: ProjectSource[] = [];
	public expandFilterByProjectSourceIndicator: boolean = false;
	public filterByAllProjectStatuses: boolean = true;
	public filterByProjectStatusIds: string[] = [];
	public expandFilterByProjectStatusIndicator: boolean = false;

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Getters ######################################

	// ###### Public ######

	// ## Abstract ##

	public abstract get projectsForFilterList(): Project[];
	public abstract get filterByProjectSourceOptions(): FilterByProjectSourceType[];
	public abstract get filterByProjectStatusOptions(): FilterByProjectStatusType[];

	// ## Concrete ##

	/**
	 * Returns post-processed entities:
	 *  - Filtered by text search.
	 *  - Filtered by filter menu.
	 *  - Sorted.
	 * @author OK
	 */
	public get filteredItems(): EntityT[] {
		const searchedItems = this.filterByTextItems(this.itemsList, this.pages.searchTxt);
		const filteredItems = searchedItems.filter(this.filterItem);
		return filteredItems.sort(this.sortItems);
	}

	public get filterByDateOptions(): Array<FilterByDateType<EntityT>> {
		return [
			{
				text: faroLocalization.i18n.tc('UI_CREATION_DATE'),
				value: 'CreationDate',
			},
		];
	}

	public get dateTypes(): FilterByDateLimit[] {
		return [
			{
				text: faroLocalization.i18n.tc('UI_ALL'),
				value: 'NO_DATE',
			},
			{
				text: '',
				value: 'TIME_PERIOD',
			},
			{
				text: faroLocalization.i18n.tc('UI_DATE'),
				value: 'DATE',
			},
		];
	}

	public get timeMeasures() {
		return [
			{
				value: 'DAY',
				text: faroLocalization.i18n.tc('LP_DAYS'),
			},
			{
				value: 'WEEK',
				text: faroLocalization.i18n.tc('LP_WEEKS'),
			},
			{
				value: 'MONTH',
				text: faroLocalization.i18n.tc('LP_MONTHS'),
			},
			{
				value: 'YEAR',
				text: faroLocalization.i18n.tc('LP_YEARS'),
			},
		];
	}

	/**
	 * Text to display in the active date filter indicator.
	 * E.g. "Creation Date: Last 7 days".
	 */
	public get filterByActiveDateText(): string | null {
		if (this.filterByDate.dateLimit === 'NO_DATE') {
			return null;
		}

		let dateLimitText: string = '';
		if (this.filterByDate.dateLimit === 'TIME_PERIOD') {
			const timeNumber: number = this.filterByDate.datePeriodNumber;
			let timeMeasure: string = '';
			switch (this.filterByDate.datePeriodTimeMeasure) {
				case 'DAY':
					timeMeasure = this.timeMeasures[0].text;
					break;
				case 'WEEK':
					timeMeasure = this.timeMeasures[1].text;
					break;
				case 'MONTH':
					timeMeasure = this.timeMeasures[2].text;
					break;
				case 'YEAR':
					timeMeasure = this.timeMeasures[3].text;
					break;
			}
			dateLimitText = `: ${faroLocalization.i18n.tc('LP_LAST')} ${timeNumber} ${timeMeasure}`;
		}
		if (this.filterByDate.dateLimit === 'DATE') {
			const dateFrom = this.filterByDate.dateRangeFrom;
			dateLimitText = this.filterByDate.dateRangeTo ? ': ' + faroLocalization.i18n.tc('LP_DATE_RANGE') : `: ${dateFrom}`;
		}

		const dateTypeText = this.filterByDateOptions.find((filterByDateOption) =>
			filterByDateOption.value === this.filterByDate.dateType)?.text ?? null;

		return dateTypeText && dateLimitText ? `${dateTypeText}${dateLimitText}` : null;
	}

	/**
	 * Text to display in the active project filter indicator.
	 * E.g. "Projects: my-project".
	 */
	protected get filterByProjectActiveText(): string | null {
		if (this.filterByAllProjects) {
			return null;
		}

		const $tsStore: $tsStore = Vue.prototype.$tsStore;

		let projectNames: string;
		if (this.existingFilterByProjectUuids.length === 0) {
			// UI_UNKNOWN: A project was included in the filter earlier, but it's no longer accessible to the user.
			projectNames = ': ' + faroLocalization.i18n.tc(this.filterByProjectUuids.length > 0 ? 'UI_UNKNOWN' : 'UI_NONE');
		} else if (this.existingFilterByProjectUuids.length === 1) {
			const projectName = $tsStore.projects.getProjectNameFromUuid(this.existingFilterByProjectUuids[0]);
			projectNames = projectName ? `: ${projectName}` : '';
		} else {
			projectNames = ' +' + this.existingFilterByProjectUuids.length.toString();
		}
		const projectTranslation = faroLocalization.i18n.tc(this.existingFilterByProjectUuids.length === 1 ?
			'UI_PROJECT' : 'UI_PROJECTS');
		return `${projectTranslation}${projectNames}`;
	}

	/**
	 * Text to display in the active entity type filter indicator.
	 * E.g. "Type: Project".
	 */
	protected get filterByEntityTypesActiveText(): string | null {
		if (this.filterByAllEntityTypes) {
			return null;
		}

		let typeNames: string;
		if (this.filterByEntityTypes.length === 0) {
			typeNames = ': ' + faroLocalization.i18n.tc('UI_NONE');
		} else if (this.filterByEntityTypes.length === 1) {
			const typeName = this.filterByEntityTypes[0];
			typeNames = typeName ? `: ${typeName}` : '';
		} else {
			typeNames = ' +' + this.filterByEntityTypes.length.toString();
		}
		const entityTypeTranslation = faroLocalization.i18n.tc('UI_TYPE');
		return `${entityTypeTranslation}${typeNames}`;
	}

	/**
	 * Text to display in the project source type filter indicator.
	 * E.g. "Project Source +2".
	 */
	protected get filterByProjectSourceTypeActiveText(): string | null {
		if (this.filterByAllProjectSources) {
			return null;
		}

		if (!this.filterByAllEntityTypes && !this.filterByEntityTypes.includes('Project')) {
			return null;
		}

		let projectSourceNames: string;
		if (this.filterByProjectSource.length === 0) {
			projectSourceNames = ': ' + faroLocalization.i18n.tc('UI_NONE');
		} else if (this.filterByProjectSource.length === 1) {
			const projectSourceName = Project.getProjectTypeName(this.filterByProjectSource[0]);
			projectSourceNames = projectSourceName ? `: ${projectSourceName}` : '';
		} else {
			projectSourceNames = ' +' + this.filterByProjectSource.length.toString();
		}
		const projectSourceTranslation = faroLocalization.i18n.tc('LP_PROJECT_SOURCE');
		return `${projectSourceTranslation}${projectSourceNames}`;
	}

	/**
	 * Text to display in the project status type filter indicator.
	 * E.g. "Project Status: SCENE project ready".
	 */
	protected get filterByProjectStatusTypeActiveText(): string | null {
		if (this.filterByAllProjectStatuses) {
			return null;
		}

		if (!this.filterByAllEntityTypes && !this.filterByEntityTypes.includes('Project')) {
			return null;
		}

		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		let projectStatusNames: string;
		if (this.existingFilterByProjectStatusIds.length === 0) {
			projectStatusNames = ': ' + faroLocalization.i18n.tc('UI_NONE');
		} else if (this.existingFilterByProjectStatusIds.length === 1) {
			const projectStatusName = $tsStore.projectStatuses.getStatusTextFromValue(this.existingFilterByProjectStatusIds[0]);
			projectStatusNames = projectStatusName ? `: ${projectStatusName}` : '';
		} else {
			projectStatusNames = ' +' + this.existingFilterByProjectStatusIds.length.toString();
		}
		const projectStatusTranslation = faroLocalization.i18n.tc('LP_STATUS');
		return `${projectStatusTranslation}${projectStatusNames}`;
	}

	/**
	 * Text to display as tooltip in the date filter indicator.
	 */
	protected get filterByActiveDateTooltip(): string {
		if (this.filterByDate.dateLimit === 'DATE') {
			const dateFrom = this.filterByDate.dateRangeFrom;
			const dateTo = this.filterByDate.dateRangeTo ? ` - ${this.filterByDate.dateRangeTo}` : '';
			return dateTo ? `${dateFrom}${dateTo}` : '';
		}
		return '';
	}

	/**
	 * Filter indicators that show the user information about the activated filters.
	 */
	public get filterByActiveIndicators(): FilterByActiveIndicator[] {
		const activeTextList: FilterByActiveIndicator[] = [];
		if (this.filterByActiveDateText) {
			activeTextList.push({
				text: this.filterByActiveDateText,
				filter: PAGE_FILTERS.Date,
				isExpandable: false,
				expanded: false,
				activeFilterItems: [],
				tooltip: this.filterByActiveDateTooltip,
			});
		}
		if (this.filterByProjectActiveText) {
			activeTextList.push({
				text: this.filterByProjectActiveText,
				filter: PAGE_FILTERS.Project,
				isExpandable: this.isFilterByProjectIndicatorExpandable,
				expanded: this.isFilterByProjectIndicatorExpanded,
				activeFilterItems: this.filterByProjectOptions.filter((option) => this.filterByProjectUuids.includes(option.value)),
			});
		}
		if (this.filterByEntityTypesActiveText) {
			activeTextList.push({
				text: this.filterByEntityTypesActiveText,
				filter: PAGE_FILTERS.EntityType,
				isExpandable: this.isFilterByEntityTypeIndicatorExpandable,
				expanded: this.isFilterByEntityTypeIndicatorExpanded,
				activeFilterItems: this.filterByEntityTypesOptions.filter((option) => this.filterByEntityTypes.includes(option.value)),
			});
		}
		if (this.filterByProjectSourceTypeActiveText) {
			activeTextList.push({
				text: this.filterByProjectSourceTypeActiveText,
				filter: PAGE_FILTERS.ProjectSource,
				isExpandable: this.isFilterByProjectSourceIndicatorExpandable,
				expanded: this.isFilterByProjectSourceIndicatorExpanded,
				activeFilterItems: this.filterByProjectSourceOptions.filter((option) => this.filterByProjectSource.includes(option.value)),
			});
		}
		if (this.filterByProjectStatusTypeActiveText) {
			activeTextList.push({
				text: this.filterByProjectStatusTypeActiveText,
				filter: PAGE_FILTERS.ProjectStatus,
				isExpandable: this.isFilterByProjectStatusIndicatorExpandable,
				expanded: this.isFilterByProjectStatusIndicatorExpanded,
				activeFilterItems: this.filterByProjectStatusOptions.filter((option) => this.filterByProjectStatusIds.includes(option.value)),
			});
		}

		return activeTextList;
	}

	/**
	 * Gets an ordered array of project options for the status list.
	 */
	public get filterByProjectOptions(): FilterByType[] {
		return this.projectsForFilterList.map((project) => ({
			text: project.Name,
			value: project.UUID,
			icon: project.typeIcon,
		})).sort((a, b) => a.text.localeCompare(b.text));
	}

	/**
	 * Gets an ordered array of project options values (project UUID) for the status list
	 */
	public get filterByProjectOptionUuids(): string[] {
		return this.filterByProjectOptions.map((option) => option.value);
	}

	public get filterByEntityTypesOptions(): FilterByEntityTypeOption[] {
		return [
			{
				text: faroLocalization.i18n.tc('UI_PROJECT'),
				value: 'Project',
			},
		];
	}

	/**
	 * Gets an array of the selected project UUIDs that are assigned and existing for the user.
	 * This avoids showing a filter for a project that was removed or that the user lost access after setting the filter.
	 */
	public get existingFilterByProjectUuids(): string[] {
		return this.filterByProjectUuids.filter((projectUuid) => this.filterByProjectOptionUuids.includes(projectUuid));
	}

	/**
	 * Gets an ordered array of project status Id options for the current array of filtered project sources
	 */
	public get projectStatusIdList(): string[] {
		return this.filterByProjectStatusOptions.map((statusType) => statusType.value);
	}

	/**
	 * Gets an array of the selected project status ids that are assigned and existing for the user.
	 * This avoids showing a filter for a project that was removed or that the user lost access after setting the filter.
	 */
	public get existingFilterByProjectStatusIds(): string[] {
		return this.filterByProjectStatusIds.filter((statusId) => this.projectStatusIdList.includes(statusId));
	}

	/**
	 * True if at least one of the filters in a page is active.
	 */
	public get isPageFilterActive(): boolean {
		return (this.isfilterByDateActive || this.isfilterByProjectActive ||
			this.isfilterByEntityTypeActive || this.isfilterByProjectSourceActive || this.isfilterByProjectStatusActive);
	}

	// Getters that return true if a filter is active.

	public get isfilterByDateActive(): boolean {
		return this.filterByDate.dateLimit !== 'NO_DATE' ? true : false;
	}

	public get isfilterByProjectActive(): boolean {
		return !this.filterByAllProjects;
	}

	public get isfilterByEntityTypeActive(): boolean {
		return !this.filterByAllEntityTypes;
	}

	public get isfilterByProjectSourceActive(): boolean {
		return !this.filterByAllProjectSources;
	}

	public get isfilterByProjectStatusActive(): boolean {
		return !this.filterByAllProjectStatuses;
	}

	// Getters that return trie if the filter has at least one option selected.
	// If true it means that the filter indicator can be clicked to show the items list.

	public get isFilterByProjectIndicatorExpandable(): boolean {
		return this.filterByProjectUuids.length > 1 && this.filterByProjectUuids.length < this.filterByProjectOptions.length;
	}

	public get isFilterByEntityTypeIndicatorExpandable(): boolean {
		return this.filterByEntityTypes.length > 1 && this.filterByEntityTypes.length < this.filterByEntityTypesOptions.length;
	}

	public get isFilterByProjectSourceIndicatorExpandable(): boolean {
		return this.filterByProjectSource.length > 1 && this.filterByProjectSource.length < this.filterByProjectSourceOptions.length;
	}

	public get isFilterByProjectStatusIndicatorExpandable(): boolean {
		return this.filterByProjectStatusIds.length > 1 && this.filterByProjectStatusIds.length < this.filterByProjectStatusOptions.length;
	}

	// Getters that return true if a filter indicator has it's items list open/expanded.

	public get isFilterByProjectIndicatorExpanded(): boolean {
		return this.expandFilterByProjectIndicator;
	}

	public get isFilterByEntityTypeIndicatorExpanded(): boolean {
		return this.expandFilterByEntityTypeIndicator;
	}

	public get isFilterByProjectSourceIndicatorExpanded(): boolean {
		return this.expandFilterByProjectSourceIndicator;
	}

	public get isFilterByProjectStatusIndicatorExpanded(): boolean {
		return this.expandFilterByProjectStatusIndicator;
	}

	// ###### Protected ######

	// ###### Private ######

	private get timePeriodDays(): number {
		switch (this.filterByDate.datePeriodTimeMeasure) {
			case 'DAY':
				return this.filterByDate.datePeriodNumber * 1;
			case 'WEEK':
				return this.filterByDate.datePeriodNumber * 7;
			case 'MONTH':
				return this.filterByDate.datePeriodNumber * 30;
			case 'YEAR':
				return this.filterByDate.datePeriodNumber * 365;
			default:
				return this.filterByDate.datePeriodNumber * 1;
		}
	}

	private get offsetDates(): { startDate: Date, endDate: Date } {
		switch (this.filterByDate.dateLimit) {
			case 'TIME_PERIOD':
				return {
					startDate: this.getBeginningOfDay(this.getOffsetDate(-this.timePeriodDays)),
					endDate: this.getOffsetDate(1), // One day from today
				};
			case 'DATE':
				return {
					startDate: this.getBeginningOfDay(new Date(this.filterByDate.dateRangeFrom)),
					endDate: this.getEndOfDay(new Date(this.filterByDate.dateRangeTo ?? this.filterByDate.dateRangeFrom)),
				};
			case 'NO_DATE':
			default:
				return {
					startDate: this.getBeginningOfDay(new Date(0)), // 7 days ago
					endDate: this.getOffsetDate(1), // One day from today
				};
		}
	}

	// ###################################### Constructor ######################################

	constructor(
		pages: PageModule,
		options: RegisterOptions,
		classConstructor: Constructable<EntityT>,
	) {
		super(pages, options, classConstructor);
	}

	// ###################################### Actions ######################################

	// ###### Public ######

	// ###### Protected ######

	protected get filterLocalStorageName() {
		return 'filter-' + this.storeName;
	}

	// ###### Private ######

	// ###################################### Mutations ######################################

	// ###### Public ######

	@Mutation
	public resetFilter(): void {
		const filterByDate: FilterByDate<EntityT> = {
			dateType: this.DEFAULT_SELECTED_DATE_TYPE,
			dateLimit: this.DEFAULT_DATE_LIMIT,
			dateRangeFrom: this.getPrettyDate(new Date()),
			dateRangeTo: null,
			datePeriodNumber: 1,
			datePeriodTimeMeasure: 'DAY',
		};
		const baseFilter: BaseFilter<EntityT> = {
			filterByDate,
			filterByAllProjects: true,
			filterByProjectUuids: [],
			filterByAllEntityTypes: true,
			filterByEntityTypes: [],
			filterByAllProjectSources: true,
			filterByProjectSource: [],
			filterByAllProjectStatuses: true,
			filterByProjectStatusIds: [],
		};
		let filterName: keyof BaseFilter<EntityT>;
		for (filterName in baseFilter) {
			Vue.set(this, filterName, baseFilter[filterName]);
		}
		this.storePageFilter();
	}

	@Mutation
	public updateFilterByDate(filterByDate: Partial<FilterByDate<EntityT>>) {
		let key: keyof FilterByDate<EntityT>;
		for (key in filterByDate) {
			// Required by TS compiler
			if (key in filterByDate) {
				Vue.set(this.filterByDate, key, filterByDate[key]);
			}
		}
		// Force to reset items.
		this.ItemsMap = { ...this.ItemsMap };
		this.storePageFilter();
	}

	@Mutation
	public setFilterByAllEntityTypes(filterByAllEntityTypes: boolean) {
		this.filterByAllEntityTypes = filterByAllEntityTypes;
		this.storePageFilter();
	}

	@Mutation
	public setFilterByEntityTypes(filterByEntityTypes: EntityType[]) {
		this.filterByEntityTypes = filterByEntityTypes;
		this.storePageFilter();
	}

	@Mutation
	public setFilterByAllProjects(filterByAllProjects: boolean) {
		this.filterByAllProjects = filterByAllProjects;
		this.storePageFilter();
	}

	@Mutation
	public setFilterByProjectUuids(filterByProjectUuids: string[]) {
		this.filterByProjectUuids = filterByProjectUuids;
		this.storePageFilter();
	}

	@Mutation
	public setFilterByAllProjectSources(filterByAllProjectSources: boolean) {
		this.filterByAllProjectSources = filterByAllProjectSources;
		this.storePageFilter();
	}

	@Mutation
	public setFilterByProjectSource(filterByProjectSource: ProjectSource[]) {
		this.filterByProjectSource = filterByProjectSource;
		this.storePageFilter();
	}

	@Mutation
	public setFilterByAllProjectStatuses(filterByAllProjectStatuses: boolean) {
		this.filterByAllProjectStatuses = filterByAllProjectStatuses;
		this.storePageFilter();
	}

	@Mutation
	public setFilterByProjectStatusIds(filterByProjectStatusIds: string[]) {
		this.filterByProjectStatusIds = filterByProjectStatusIds;
		this.storePageFilter();
	}

	@Mutation
	public setExpandFilterByProjectIndicator(expandFilterByProjectIndicator: boolean) {
		this.expandFilterByProjectIndicator = expandFilterByProjectIndicator;
	}

	@Mutation
	public setExpandFilterByEntityTypeIndicator(expandFilterByEntityTypeIndicator: boolean) {
		this.expandFilterByEntityTypeIndicator = expandFilterByEntityTypeIndicator;
	}

	@Mutation
	public setExpandFilterByProjectSourceIndicator(expandFilterByProjectSourceIndicator: boolean) {
		this.expandFilterByProjectSourceIndicator = expandFilterByProjectSourceIndicator;
	}

	@Mutation
	public setExpandFilterByProjectStatusIndicator(expandFilterByProjectStatusIndicator: boolean) {
		this.expandFilterByProjectStatusIndicator = expandFilterByProjectStatusIndicator;
	}

	@Mutation
	public readPageFilter(userUUID?: string) {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const userUUIDDefined = userUUID ?? $tsStore.users.user?.UUID;
		if (!userUUIDDefined) {
			$assert.Assert(false, 'Missing current user, maybe readPageFilter was called too soon');
			return;
		}
		const storeName = this.storeName;
		if (!storeName) {
			$assert.Assert(false, 'Missing store name, maybe readPageFilter was called too soon');
			return;
		}
		const baseFilter: BaseFilter<EntityT> = faroLocalStorage.getJSONItem(`filter-${userUUIDDefined}-${storeName}`) as BaseFilter<EntityT>;
		if (!baseFilter) {
			return;
		}
		let filterName: keyof BaseFilter<EntityT>;
		for (filterName in baseFilter) {
			Vue.set(this, filterName, baseFilter[filterName]);
		}
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	public getPrettyDate(date: string | Date): string {
		const dateObj: Date = date instanceof Date ? date : new Date(date);
		return dateObj.toISOString().slice(0, 10);
	}

	/**
	 * Sync the locally stored filters state with the vuex store. The filters state might be outdated since some workspaces, projects or status
	 * could have been deleted, unassigned or deactivated. This method takes care of removing outdated items from the local storage.
	 */
	public updateFiltersState() {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const userUUIDDefined = $tsStore.users.user?.UUID;
		const baseFilter: BaseFilter<EntityT> = faroLocalStorage.getJSONItem(`filter-${userUUIDDefined}-${this.storeName}`) as BaseFilter<EntityT>;
		if (!baseFilter) {
			return;
		}
		let filterName: keyof BaseFilter<EntityT>;
		for (filterName in baseFilter) {
			let filterValue = baseFilter[filterName];
			if (filterName === 'filterByProjectUuids') {
				filterValue = baseFilter[filterName].filter((project) => this.existingFilterByProjectUuids.includes(project));
				this.setFilterByProjectUuids(filterValue);
			}
			if (filterName === 'filterByProjectStatusIds') {
				filterValue = baseFilter[filterName].filter((projectStatus) => this.existingFilterByProjectStatusIds.includes(projectStatus));
				this.setFilterByProjectStatusIds(filterValue);
			}
		}
	}

	// ###### Protected ######

	protected storePageFilter() {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const userUuid = $tsStore.users.user?.UUID;
		if (!userUuid) {
			$assert.Assert(false, 'Missing current user, maybe storePageFilter was called too soon');
			return;
		}
		const storeName = this.storeName;
		if (!storeName) {
			$assert.Assert(false, 'Missing store name, maybe storePageFilter was called too soon');
			return;
		}

		const baseFilter: BaseFilter<EntityT> = {
			filterByDate: this.filterByDate,
			filterByAllProjects: this.filterByAllProjects,
			filterByProjectUuids: this.filterByProjectUuids,
			filterByAllEntityTypes: this.filterByAllEntityTypes,
			filterByEntityTypes: this.filterByEntityTypes,
			filterByAllProjectSources: this.filterByAllProjectSources,
			filterByProjectSource: this.filterByProjectSource,
			filterByAllProjectStatuses: this.filterByAllProjectStatuses,
			filterByProjectStatusIds: this.filterByProjectStatusIds,
		};
		faroLocalStorage.setItem(`filter-${userUuid}-${storeName}`, JSON.stringify(baseFilter));
	}

	// ## Abstract ##

	/**
	 * Returns if the provided entity should be filtered according to the current filter settings.
	 * @author PS
	 * @param entity Entity to check.
	 */
	protected filterItem(entity: EntityT): boolean {
		// First it should be filtered by entity type, because entities might not be possible to filter them by something else.
		if (!this.filterByAllEntityTypes && !this.filterByEntityTypes.includes(entity.Class as EntityType)) {
			return false;
		}

		// Generic filters

		if (!this.compareFilterByDate(entity)) {
			return false;
		}

		// Project filters
		if (!this.compareFilterByProject(entity)) {
			return false;
		}

		// Type filters

		if (!this.compareFilterByProjectSource(entity)) {
			return false;
		}

		// Project status filter
		if (!this.compareFilterByProjectStatus(entity)) {
			return false;
		}

		return true;
	}

	/**
	 * Override to filter the items based on a search text.
	 * @param itemsList The original items list.
	 * @param searchTxt The search text.
	 * @returns The filtered entities list.
	 */
	protected abstract filterByTextItems(itemsList: EntityT[], searchTxt: string): EntityT[];

	// ## Concrete ##

	/**
	 * Returns if the provided entity should be filtered according to the current filter settings.
	 * @param entity Entity to check.
	 */
	protected compareFilterByDate(entity: EntityT): boolean {
		// If the 'NO_DATE' option is selected, all entities option should be displayed
		// Entities with dates in the future should also be displayed.
		if (this.filterByDate.dateLimit === 'NO_DATE') {
			return true;
		}

		// If the entity does not have that attribute then do not include it.
		// For example do not return projects that don't have LastVisitDate.
		if (!entity[this.filterByDate.dateType]) {
			return false;
		}

		const entityDate = new Date(entity[this.filterByDate.dateType] as any);

		const { startDate, endDate } = this.offsetDates;

		// If the time period option is selected, all antities with a date value higher(more recent) than the start date value
		// should be displayed. That includes entities with dates in the future.
		if (this.filterByDate.dateLimit === 'TIME_PERIOD') {
			if (entityDate.getTime() > startDate.getTime() || entityDate.getTime() === startDate.getTime()) {
				return true;
			}
		}

		if (entityDate.getTime() < startDate.getTime()) {
			return false;
		} else if (entityDate.getTime() > endDate.getTime()) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Compares whether the provided entity has the same project as one of the selected ones.
	 * @param entity The entity to be evaluated.
	 * @returns True if the entity project matches one of the selected projects.
	 */
	protected compareFilterByProject(entity: EntityT): boolean {
		if (this.filterByAllProjects) {
			return true;
		}
		if (this.filterByProjectUuids.length === 0) {
			return false;
		}
		let projectUUID: string;
		if (entity instanceof Project) {
			projectUUID = entity.UUID;
		} else if ('ProjectUUID' in entity) {
			projectUUID = entity.ProjectUUID;
		} else {
			// Cannot be filtered out by project
			return false;
		}
		return this.filterByProjectUuids.includes(projectUUID);
	}

	protected compareFilterByProjectSource(entity: EntityT) {
		return true;
	}

	protected compareFilterByProjectStatus(entity: EntityT) {
		return true;
	}

	// ###### Private ######

	private getOffsetDate(offsetDays: number): Date {
		return new Date(new Date().getTime() + (offsetDays * 24 * 60 * 60 * 1000));
	}

	private getBeginningOfDay(date: Date): Date {
		return new Date(date.setHours(0, 0, 0, 0));
	}

	private getEndOfDay(date: Date): Date {
		return new Date(date.setHours(23, 59, 59, 59));
	}
}
