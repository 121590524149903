
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BackgroundImage from '@/components/Embeddable/BackgroundImage.vue';

/**
 * Base page to show a header and some content.
 */
@Component({
	components: {
		BackgroundImage,
	},
})
export default class TopPageBase extends Vue {
	@Prop(Boolean) public readonly error!: boolean;
	@Prop(Boolean) public readonly loading!: boolean;

	@Prop(String) public readonly errorTitle!: string;
	@Prop(String) public readonly errorText!: string;
}
