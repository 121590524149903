
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { BASE_URL } from '@/config';
import { UserBtnTaskTopRightComponentData } from '@/utils/types';
import { OidcUser } from '@faroconnect/auth0-frontend';
import { User } from '@/classes/authz/User';

@Component
export default class UserBtn extends Vue {
	@Prop(Boolean) public readonly listDropdown!: boolean;
	@Prop(Object) public readonly topRightComponentData!: UserBtnTaskTopRightComponentData | undefined;

	public greetingNameAuth0User: string = '';
	public userEmailAuth0User: string = '';

	public get isAuthenticatedBoolean(): boolean {
		if (typeof this.topRightComponentData?.mockState?.isAuthenticated === 'boolean') {
			return this.topRightComponentData.mockState.isAuthenticated;
		}
		return this.$auth.isAuthenticated ?? false;
	}

	public get isAuthenticated(): boolean | 'maybe' {
		if (typeof this.topRightComponentData?.mockState?.isAuthenticated === 'boolean' ||
			this.topRightComponentData?.mockState?.isAuthenticated === 'maybe'
		) {
			return this.topRightComponentData.mockState.isAuthenticated;
		}
		return this.$auth.isAuthenticated ?? false;
	}

	public get isFullscreenTask(): boolean {
		return this.topRightComponentData?.isFullscreenTask ?? false;
	}

	public get authzUser(): User | null {
		return this.$tsStore.users.user ?? null;
	}

	public get greetingName(): string | null {
		if (this.topRightComponentData?.mockState?.greetingName) {
			return this.topRightComponentData.mockState.greetingName;
		}
		if (this.authzUser?.FullNameShort) {
			return this.authzUser.FullNameShort;
		}
		return null;
	}

	public get userEmail(): string | null {
		if (this.topRightComponentData?.mockState?.userEmail) {
			return this.topRightComponentData.mockState.userEmail;
		}
		if (this.authzUser?.Email) {
			return this.authzUser.Email;
		}
		return null;
	}

	public logout() {
		this.$faroLoading.start();
		this.$auth.logout({returnTo: window.location.origin + BASE_URL});
	}

	public login() {
		if (this.isAuthenticated === 'maybe') {
			// If we don't know for sure if the user is logged in, it's safer to perform a logout instead of a login.
			// Otherwise, the user may be caught in a login loop, without asking for interactive login again.
			return this.logout();
		}

		if (this.topRightComponentData?.mockState?.login) {
			this.topRightComponentData.mockState.login();
			return;
		}
		this.$auth.loginWithRedirect();
	}

	public async setUser() {
		// If we already have the greeting name and the email address, either because the user was provided
		// or the values were directly provided, in both cases we don't need to fetch them from Auth0.
		if (this.greetingName && this.userEmail) {
			return;
		}

		try {
			const auth0User: OidcUser | undefined = await this.$auth.getUser();
			if (!this.greetingName) {
				this.greetingNameAuth0User = auth0User?.nickname || '';
			}
			if (!this.userEmail) {
				this.userEmailAuth0User = auth0User?.email || '';
			}
		} catch (error) {
			// Do nothing, the fields will be empty.
		}
	}

	public async mounted() {
		await this.setUser();
	}

	@Watch('isAuthenticated')
	public async onIsAuthenticatedChanged() {
		await this.setUser();
	}

	@Watch('authzUser')
	public async onAuthzUserChanged() {
		await this.setUser();
	}
}
