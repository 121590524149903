import jwtDecode from 'jwt-decode';
import Vue from 'vue';

import { IAuth0Token } from '@/definitions/interfaces';
import { Auth0Permission } from '@/definitions/types';
import { AuthzType } from '@faroconnect/authz-client';
import { dynamicConfig } from '@/config';
import { $assert } from '@faroconnect/utils';
import { FaroConfigLp } from '@/types/config';

export function isFeatureEnabled(
	featureFlag: keyof FaroConfigLp['featureFlag'],
	user: {
		auth0Permissions?: Auth0Permission[],
		workspacePermissions?: AuthzType.WorkspacePermissionName[],
		isFaroUser?: boolean;
	},
): boolean {
	const defaultFunc = (auth0Permissions: string[], workspacePermissions: string[], isFaroUser: boolean) => false;
	const featureFuncOptional = dynamicConfig?.featureFlag;
	let featureFunc = defaultFunc;
	if (featureFuncOptional) {
		$assert.Function(featureFuncOptional[featureFlag] as any, `Missing rule for feature flag ${featureFlag}`);
		featureFunc = featureFuncOptional[featureFlag] ?? defaultFunc;
	} else {
		$assert.Assert(false, 'Missing configuration featureFlag');
	}

	const featureFuncDefined = featureFunc ?? defaultFunc;
	return featureFuncDefined(user?.auth0Permissions ?? [], user?.workspacePermissions ?? [], user?.isFaroUser ?? false);
}

// For feature flag purposes, it is safe to cache the decoded contents of the token, so we don't waste time or browser CPU.
let decodedTokenCached: Partial<IAuth0Token> | undefined;
// Didn't figure out how to use jest.mock/spyOn for mocking vue.$auth.getTokenSilently. Can be improved later.
let mockedTokenForTest: string | undefined;
let mockedIsFaroUser: boolean | undefined;

export function mockTokenForTest(token: string | undefined, isFaroUserForTest?: boolean| undefined) {
	decodedTokenCached = undefined;
	mockedTokenForTest = token;
	mockedIsFaroUser =  isFaroUserForTest;
}

export async function isFeatureEnabledByAuth0Token(
	featureFlag: keyof FaroConfigLp['featureFlag'],
): Promise<boolean> {
	const $tsStore: $tsStore = Vue.prototype.$tsStore;
	if (!decodedTokenCached) {
		const token = mockedTokenForTest || await $tsStore.users.getTokenSilently();
		if (token) {
			try {
				decodedTokenCached = jwtDecode(token);
			} catch (e) {
				console.error(e); // Unexpected, but don't make the function fail.
			}
		}
	}
	const auth0Permissions: Auth0Permission[] = (decodedTokenCached?.permissions ?? []) as Auth0Permission[];
	const isFaroUser =  (mockedIsFaroUser !== undefined) ? mockedIsFaroUser : ($tsStore?.users?.isFaroUser ?? false);
	return isFeatureEnabled(featureFlag, { auth0Permissions, isFaroUser});
}

/**
 * Similar to isFeatureEnabledByAuth0Token gets whether the user has a certain feature enabled.
 * The only difference is that it uses a cached decoded auth0 token, so it is helpful to use it inside
 * getters but you have to make sure that a request to get the token was previously called.
 * @param featureFlag The feature flag name to be checked
 * @returns True if the feature is enabled.
 */
export function isFeatureEnabledByAuth0PermissionsSync(
	featureFlag: keyof FaroConfigLp['featureFlag'],
): boolean {
	const $tsStore: $tsStore = Vue.prototype.$tsStore;
	const decodedToken = $tsStore.users.decodedToken;
	const auth0Permissions: Auth0Permission[] = decodedToken?.permissions ?? [];
	return isFeatureEnabled(featureFlag, { auth0Permissions });
}
