import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { config } from '@/config';
import { ProjectStatus } from '@/classes/projz/ProjectStatus';
import { ProjzInterface } from '@faroconnect/projz-client';

/**
 * This service is in charge of CRUD operations to manipulate the Sphere users in the Authz backend.
 */
export class ProjectStatusService extends BaseService<ProjectStatus> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiEndpoint: config.projectServiceEnpoint,
			baseApiUrl: '/projectservice/v1',
			apiRoute: 'projectstatus',
		}, clientConfig);
	}

	public get websocketUrl() {
		return config.projectServiceEnpoint;
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<ProjzInterface.IProjectStatus> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<ProjzInterface.IProjectStatus[]> {
		throw new Error('Function not yet implemented');
	}

	public async create<QueryT extends object>(body?: ProjectStatus, query?: QueryT): Promise<ProjzInterface.IProjectStatus> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(
		body?: Partial<ProjzInterface.IProjectStatus>,
		query?: QueryT,
	): Promise<ProjzInterface.IProjectStatus> {
		throw new Error('Function not yet implemented');
	}

	public async updateMulti<QueryT extends object>(body?: ProjectStatus[], query?: QueryT): Promise<ProjzInterface.IProjectStatus[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	public async readUser(workspaceUuid: string, uuid: string): Promise<ProjzInterface.IProjectStatus> {
		const url = this.makeUrl(`${workspaceUuid}/${uuid}`);
		return this.get(url);
	}
}
