import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-class-modules';
import { AuthzGroupService, GetGroupsQuery, AssignProjectRolesQuery, AssignProjectRolesGroupsQuery } from '@/store/services/authz/AuthzGroupService';
import { Group } from '@/classes/authz/Group';
import { AuthzInterface } from '@faroconnect/authz-client';
import { ProjectRole } from '@/classes/authz/ProjectRole';

interface Groups {
	[key: string]: Group[];
}

function groupsInterceptor(groups: AuthzInterface.IGroup[]) {
	const $tsStore: $tsStore = Vue.prototype.$tsStore;

	// Store each group ProjectRoleMap in the ProjectRole Store.
	for (const group of groups) {
		if (group.ProjectRolesMap) {
			for (const projectUuid of Object.keys(group.ProjectRolesMap)) {
				for (const projectRole of group.ProjectRolesMap[projectUuid]) {
					$tsStore.projectRoles.addItem(ProjectRole.fromResponse(projectRole));
				}
			}
		}
	}
}

@Module
export class GroupModule extends VuexModule {
	public groups: Groups = {};

	protected readonly service = new AuthzGroupService({});

	@Action
	public async getAllFromWorkspace(payload: { workspaceUuid: string, query: GetGroupsQuery }) {
		const iGroups = await this.service.getAllFromWorkspace(payload.workspaceUuid, payload.query);
		groupsInterceptor(iGroups);
		const groups = iGroups.map((workspaceRole) => Group.fromResponse(workspaceRole));
		this.replaceGroups({ workspaceUuid: payload.workspaceUuid, groups });
	}

	@Mutation
	public replaceGroups(payload: { workspaceUuid: string, groups: Group[] }) {
		Vue.set(this.groups, payload.workspaceUuid, payload.groups);
	}

	public async assignProjectRoles(workspaceUuid: string, userUuid: string, query: AssignProjectRolesQuery) {
		await this.service.assignProjectRoles(workspaceUuid, userUuid, query);
	}

	public async assignProjectRolesGroups(workspaceUuid: string, query: AssignProjectRolesGroupsQuery) {
		await this.service.assignProjectRolesGroups(workspaceUuid, query);
	}
}
