var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"px-4 ma-0 f_ptv_wrapper",attrs:{"data-cy":"ptv_co","justify":_vm.$vuetify.breakpoint.smAndUp ? 'start':'center'}},[_vm._l((_vm.entitiesOnPage),function(entity,index){return _c('v-col',{key:'pl' + entity.UUID,class:{
			'f-tile-col-lg-width': _vm.$vuetify.breakpoint.lgAndUp,
			'f-tile-col-md-width': _vm.$vuetify.breakpoint.md,
			'f-tile-col-sm-width': _vm.$vuetify.breakpoint.sm,
			'f-tile-col-xs-width': _vm.$vuetify.breakpoint.xs
		},attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('entity-card',{attrs:{"entity":entity,"index":index,"X_DESCRIPTION_PREVIEW_LENGTH":_vm.X_DESCRIPTION_PREVIEW_LENGTH,"X_TITLE_PREVIEW_LENGTH":_vm.X_TITLE_PREVIEW_LENGTH,"itemButtons":_vm.itemButtons,"xGetHintMsg":_vm.xGetHintMsg,"xGetItemButtons":_vm.xGetItemButtons,"xOnEntityClicked":_vm.xOnEntityClicked,"xIsEntityClickable":_vm.xIsEntityClickable,"xIsEntityDisabled":_vm.xIsEntityDisabled,"xisEntityLoading":_vm.xisEntityLoading,"xUseMeshBackground":_vm.xUseMeshBackground,"xUseGradientBackground":_vm.xUseGradientBackground,"imageSize":_vm.imageSize,"hideSecondaryInfo":_vm.hideSecondaryInfo}})],1)}),(!_vm.filteredItems.length)?_c('v-col',{attrs:{"cols":"12"}},[(!_vm.items.length)?_c('faro-alert',{staticClass:"mt-3",attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.$t('UI_NO_ITEMS_SHOW')))]):(!_vm.filteredItems.length)?_c('faro-alert',{staticClass:"mt-3",attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.$t('UI_NO_ITEMS_MATCH'))+" ")]):_vm._e()],1):_vm._e(),(_vm.addNewTile)?_c('v-col',{staticClass:"addtile_container",class:{
			'addtile-lg-width': _vm.$vuetify.breakpoint.lgAndUp,
			'addtile-md-width': _vm.$vuetify.breakpoint.md,
			'addtile-sm-width': _vm.$vuetify.breakpoint.sm,
			'addtile-xs-width': _vm.$vuetify.breakpoint.xs
	},attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"addtile_card",on:{"click":_vm.addNewTile}},[_c('v-card-text',{staticClass:"text-center addtile_text"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.36_active_generic-plus")]),_vm._v(" "+_vm._s(_vm.addNewTileText || _vm.$t('LP_ADD_TILE'))+" ")],1)],1)],1):_vm._e(),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.xNumberOfPages > 1),expression:"xNumberOfPages > 1"}],attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"data-cy":"appPagination","length":_vm.xNumberOfPages,"total-visible":"10","next-icon":"$vuetify.icons.36_generic-right","prev-icon":"$vuetify.icons.36_generic-left"},model:{value:(_vm.xPageNumber),callback:function ($$v) {_vm.xPageNumber=$$v},expression:"xPageNumber"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }