import { LpEntity } from '@/classes';
import { Project } from '@/classes/authz/Project';
import { ProjectStatus } from '@/classes/projz/ProjectStatus';

export interface SortItem<EntityT extends LpEntity> {
	value: keyof EntityT;
	text?: string;
	stringId?: string;
}

abstract class BaseHeader {}

export class EntitySortBy<EntityT extends LpEntity> extends BaseHeader {
	/**
	 * Sort item by name ascending.
	 */
	public get name(): SortItem<EntityT> {
		return {
			value: 'Name',
			stringId: 'UI_NAME',
		};
	}

	/**
	 * Sort item by description ascending.
	 */
	public get description(): SortItem<EntityT> {
		return {
			value: 'Description',
			stringId: 'UI_DESCRIPTION',
		};
	}

	/**
	 * Sort item by creation date ascending.
	 */
	public get creationDate(): SortItem<EntityT> {
		return {
			value: 'prettyCreationDate',
			stringId: 'UI_CREATION_DATE',
		};
	}

	/**
	 * Sort item by update date ascending.
	 */
	public get updateDate(): SortItem<EntityT> {
		return {
			value: 'prettyUpdateDate',
			stringId: 'LP_RECENTLY_UPDATED',
		};
	}
}

export class ProjectSortBy extends BaseHeader {
	/**
	 * Sort item by type ascending.
	 */
	public get status(): SortItem<Project> {
		return {
			value: 'projectStatusStatusId',
			stringId: 'UI_STATUS',
		};
	}

	/**
	 * Sort item by type ascending.
	 */
	public get type(): SortItem<Project> {
		return {
			value: 'Type',
			stringId: 'UI_TYPE',
		};
	}

	/**
	 * Sort item by workspace ascending.
	 */
	public get lastVisitDate(): SortItem<Project> {
		return {
			value: 'prettyLastVisitDate',
			stringId: 'LP_LAST_VISIT_DATE',
		};
	}
}

export class ProjectStatusSortBy extends BaseHeader {
	public get projectName(): SortItem<ProjectStatus> {
		return {
			value: 'projectName',
			stringId: 'UI_PROJECT',
		};
	}

	public get statusName(): SortItem<ProjectStatus> {
		return {
			value: 'displayText',
			stringId: 'UI_STATUS',
		};
	}
}

/**
 * Returns a copy of the object with the attributes on the first level sorted alphabetically.
 * @author OK
 */
export function sortObject(obj: any): any {
	const sorted: { [key: string]: any } = {};
	const a: string[] = [];

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			a.push(key);
		}
	}
	a.sort();

	for (const key of a) {
		sorted[key] = obj[key];
	}
	return sorted;
}
