import { AuthzInterface, AuthzType } from '@faroconnect/authz-client';
import { faroLocalization, GroupStringById } from '@faroconnect/baseui';
import { BaseEntity } from '@/classes/BaseEntity';

type GroupClass = 'Group';

export class Group extends BaseEntity implements AuthzInterface.IGroup {
	public static constructorName = Group.name;

	public static fromResponse(json: AuthzInterface.IGroup) {
		return new Group(json);
	}

	public static forRequest(json: Partial<AuthzInterface.IGroup>) {
		return new Group(json);
	}

	public readonly isGroup: boolean = true;

	public UserUuids?: string[];

	public ImgUrl: string = '';
	public DefaultImgUrl: string = '';

	public Class: GroupClass = 'Group';
	public Workspace: string;
	// key is a project uuid.
	// ProjectRolesMap is not set, please use ProjectRoleUuidsMap instead and get each project role from the store instead.
	public ProjectRolesMap: { [key: string]: AuthzInterface.IProjectRole[] } = {};
	// keys are project uuids and the values of such keys are the project roles uuids associated to each key.
	public ProjectRoleUuidsMap: { [key: string]: string[] } = {};
	public WorkspaceRoleUuids: string[];
	public Id: AuthzType.PredefinedGroupId | null;
	public Predefined: boolean;
	public Default: boolean;

	protected constructor(obj: Partial<AuthzInterface.IGroup>) {
		super(obj);
		this.Workspace = obj.Workspace ?? '';
		this.UserUuids = obj.UserUuids;
		this.WorkspaceRoleUuids = obj.WorkspaceRoleUuids ?? [];
		this.Id = obj.Id ?? null;
		this.Predefined = obj.Predefined ?? false;
		this.Default = obj.Default ?? false;

		// We are just storing each project role UUID, if the project role object is required please use the ProjectRole Store.
		const projectRolesMap = obj.ProjectRolesMap ?? {};
		for (const projectUuid of Object.keys(projectRolesMap)) {
			this.ProjectRoleUuidsMap[projectUuid] = projectRolesMap[projectUuid].map((projectRole) => projectRole.UUID);
		}
	}

	public get displayName() {
		if (this.Id && GroupStringById[this.Id]) {
			return faroLocalization.i18n.t(GroupStringById[this.Id]).toString();
		} else {
			return this.Name;
		}
	}
}
