
import Component from 'vue-class-component';
import { $assert, StringUtils } from '@faroconnect/utils';
import { AuthzType } from '@faroconnect/authz-client';
import { WebshareRegion } from '@faroconnect/authz-client/public/definitions/AuthzType';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';
import { ApplicationWebShare } from '@/classes/ApplicationWebShare';
import { Workspace } from '@/classes/authz/Workspace';
import {
	AnyState,
	Migration,
	ProjectExtended,
	Step2I,
	Step4I,
	TaskStates,
} from '@/classes/migrator/Migration';
import PageBaseMixin from '@/mixins/PageBaseMixin';
import { VBreadcrumbItem } from '@/definitions-frontend/globals';
import { config } from '@/config';
import { isFeatureEnabledByAuth0PermissionsSync } from '@/utils/permissions';
import { IWebShareProject, IWorkspaceSizes } from '@/definitions/interfaces';

/**
 * Page that shows info for an ongoing migration of a Sphere 1.0 workspace to Sphere 2.0 based on HoloBuilder.
 */
@Component({
	components: {
		TopPageBase,
	},
})
export default class MigrationPage extends PageBaseMixin<Migration> {
	public SIZE_FACTOR = Migration.SIZE_FACTOR;

	protected pollingInterval: ReturnType<typeof setInterval> | null = null;

	public error: boolean = false;

	public upgradedOnce: boolean = false;

	public projects: { [key: string]: ProjectExtended } = {};

	// Not all APIs include the CreatorEmail, so we store the value once we get it.
	public creatorEmail: string = '';

	/**
	 * WebShare project size information retrieved in getWorkspaceSize.
	 */
	public size: IWorkspaceSizes | null = null;
	/**
	 * Flag if the dialog to add QA users is currently visible.
	 */
	public showAddQAUsersDlg: boolean = false;
	 /**
	  * String associated with the input field for the email addresses of the QA users to add.
	  */
	public qaUsersToAdd: string = '';

	public redirectOptions = [
		{ text: 'No redirect', value: '' },
		{ text: 'Same behavior as for end user', value: 'default' },
		{ text: 'Source ➜ Migrator', value: 'src_migrator' },
		{ text: 'Source ➜ Migrator ➜ Redirect Service', value: 'src_migrator_redirectservice' },
	];
	public redirectOption = localStorage.getItem('migrationPage_redirect') || '';
	public showAdvanced = !!this.redirectOption;
	public redirectOptionUpdate(): void {
		localStorage.setItem('migrationPage_redirect', this.redirectOption);
	}

	/**
	 * The estimated durations of the migration: [standard, min, max]
	 * Directly using durationInfo didn't work since it resulted in errors:
	 *   Property or method "durationInfo" is not defined on the instance but referenced during render.
	 * It's unclear why this was the case since the code looked totally fine.
	 */
	public durationInfos: string[] = [];

	// ---------------------------------------------------------------------------

	public get xGetAllErrString(): string {
		return this.$tc('UI_ERROR');
	}

	public get workspaceUuid(): string {
		return this.$route.params?.workspace ?? '';
	}

	public get workspace(): Workspace {
		return this.$tsStore.migrationWorkspaces.ItemsMap[this.workspaceUuid];
	}

	public get migrationUuid(): string {
		return this.$route.params?.migration ?? '';
	}

	public get migration(): Migration {
		return this.$tsStore.migrations.ItemsMap[this.migrationUuid];
	}

	public get showProjectContentInfo(): boolean {
		return this.migration.Steps.Step7.State === 'running' || this.migration.Steps.Step7.State === 'complete' ||
			this.migration.Steps.Step7.State === 'error';
	}

	public get showEstimatedDuration(): boolean {
		return !this.migration.Projects?.length && !!this.durationInfos.length;
	}

	public get xPageName(): string {
		return 'MigrationPage';
	}

	public get xStoreName(): any {
		return 'migrations';
	}

	public get breadcrumbItems(): VBreadcrumbItem[] {
		return [
			{
				text: 'Migration Dashboard',
				to: { name: 'MigrationDashboardPage' },
			},
			{
				text: this.workspace?.Name ?? '...', // Use placeholder while page is loading.
				disabled: true,
			},
		];
	}

	public get runsOnLocalhost(): boolean {
		// Correctly handle "npm run start-aws-dev", where the UI is on localhost, but it shows data from DEV.
		return config.env === 'local';
	}

	public get migrationSchedule(): string | null {
		if (!this.migration.ScheduledDate) {
			return null;
		}
		return this.migration.ScheduledDate.substring(0, 16).replace('T', ' ') + ' (UTC)';
	}

	public get isNotDeletedMigration(): boolean {
		return this.migration.DeleteDate === null;
	}

	// Store in-progress update, to make the toggle button more reactive.
	protected xgRedirectUpdate: boolean | null = null;
	protected autoPublishUpdate: boolean | null = null;

	public get xgRedirectEnabled(): boolean {
		return (this.xgRedirectUpdate !== null) ? this.xgRedirectUpdate : (this.migration?.XgRedirect || false);
	}
	public set xgRedirectEnabled(value: boolean) {
		// Update on server is handled in toggleXgRedirect() instead.
		this.xgRedirectUpdate = value;
	}

	public get autoPublishEnabled(): boolean {
		return (this.autoPublishUpdate !== null) ? this.autoPublishUpdate : (this.migration?.AutoPublish || false);
	}

	public set autoPublishEnabled(value: boolean) {
		// Update on server is handled in toggleAutoPublish() instead.
		this.autoPublishUpdate = value;
	}

	/**
	 * Returns true if the "Continue" button should be visible.
	 * Keep in sync with Migrator > getMigrationToContinueStep7().
	 * @author MH
	 */
	public get canContinueMigration(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			(this.migration.State === 'error' || this.migration.State === 'aborted') &&
			this.migration.Steps.Step6.State === 'complete' &&
			(this.migration.Steps.Step7.State === 'running' || this.migration.Steps.Step7.State === 'error');
	}

	/**
	 * Returns true if the "Update status" button should be visible.
	 * Keep in sync with Migrator > getMigrationToContinueStep7().
	 *
	 * For convenience, we allow using this button already with the viewMigrations permission.
	 * @author MH
	 */
	public get canUpdateProjectStatus(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('viewMigrations') &&
			this.isNotDeletedMigration &&
			(this.migration.State === 'error' || this.migration.State === 'aborted') &&
			this.migration.Steps.Step6.State === 'complete' &&
			(this.migration.Steps.Step7.State === 'running' || this.migration.Steps.Step7.State === 'error');
	}

	/**
	 * Returns true if the "Abort" button should be visible.
	 * @author BE
	 */
	public get canBeAborted(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			(this.migration.State === 'running' || this.migration.State === 'pending');
	}

	/**
	 * Returns true if the "Publish" button should be visible.
	 * @author OK
	 */
	public get canBePublished(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			this.migration.State === 'unpublished';
	}

	/**
	 * Returns true if the "Unpublish" button should be visible:
	 * - If the migration is published,
	 * - or if the migration is upgraded and the environment is local or dev (see canToggleXgRedirect).
	 * @author OK
	 */
	public get canBeUnpublished(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			(this.migration.State === 'published' ||
			 (this.migration.State === 'upgraded' && !this.migration.DataDeleted && (config.env === 'local' || config.env === 'dev')));
	}

	/**
	 * Returns true if the "Reject" button should be visible.
	 * @author OK
	 */
	public get canBeRejected(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			this.migration.State === 'unpublished';
	}

	/**
	 * Returns true if the "Approve" button should be visible.
	 * @author OK
	 */
	public get canBeApproved(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			this.migration.State === 'error';
	}

	/**
	 * Returns true if the "Redirect on/off" toggle button should be enabled.
	 * - If redirect is currently enabled,
	 * - or if the migration is published,
	 * - or if the migration is unpublished and the environment is local or dev (for easier testing).
	 * @author MH
	 */
	public get canToggleXgRedirect(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.xgRedirectUpdate === null &&
			!this.migration.DataDeleted &&
			(this.migration.XgRedirect || this.migration.State === 'published' ||
			 (this.migration.State === 'unpublished' && (config.env === 'local' || config.env === 'dev')));
	}

	/**
	 * Returns true if the "Auto-publish on/off" toggle button should be enabled.
	 */
	public get canToggleAutoPublish(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.autoPublishUpdate === null &&
			this.isNotDeletedMigration && (
			this.migration.State === 'pending' ||
			this.migration.State === 'running' ||
			this.migration.State === 'aborting' ||
			this.migration.State === 'aborted' ||
			this.migration.State === 'error');
	}

	/**
	 * Returns true if the "Auto-publish on/off" toggle button should be visible.
	 */
	public get showAutoPublish(): boolean {
		return this.migration.State !== 'unpublished' && this.migration.State !== 'published' && this.migration.State !== 'upgraded';
	}

	/**
	 * Returns true if the "Delete" button should be visible.
	 * @author OK
	 */
	public get canBeDeleted(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			(this.migration.State === 'unpublished' ||
			this.migration.State === 'error' || this.migration.State === 'aborted');
	}

	/**
	 * Returns true if the "Add QA users" button should be visible.
	 * @author OK
	 */
	public get canAddQAUsers(): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			this.isNotDeletedMigration &&
			(this.migration.State === 'unpublished' ||
			 (this.migration.State === 'error' && this.migration.hasBegunContentMigration()) ||
			 (this.migration.State === 'aborted' && this.migration.hasBegunContentMigration()) ||
			 (this.migration.State === 'running' && this.migration.hasBegunContentMigration()));
	}

	// ---------------------------------------------------------------------------

	/**
	 * Returns the CSS class to use for the provided state.
	 * @author OK
	 */
	public getCss(state: AnyState): string {
		return this.migration.getCss(state);
	}

	/**
	 * Returns the icon to use for the provided state.
	 * @author OK
	 */
	public getIcon(state: AnyState): string {
		return this.migration.getIcon(state);
	}

	/**
	 * [TF-1592] If the workspace is paid: There must not be another migration in status “Published” or “Upgraded” for the same workspace.
	 * @author MF
	 */
	public async isUpgradedOnce(): Promise<boolean> {
		if (this.migration.State === 'upgraded') {
			const migrations = await this.$tsStore.migrations.getMigrationsForWorkspace({
				region: this.workspace.Region as WebshareRegion,
				workspaceuuid: this.workspace.UUID,
				state: 'published',
			});
			return migrations.length === 1;
		}
		return false;
	}

	public getUpgradedWarning(): string {
		if (this.migration.State !== 'upgraded') {
			return '';
		}

		if (!this.workspace) {
			return 'Cannot do complete check - Workspace not accessible';
		} else if (!this.migration.Projects) {
			return 'Cannot do complete check - Migration.Projects not accessible';
		} else if (!this.migration.EmailSent && this.migration.CreationDate >= Migration.SUCCESS_EMAILS_SENT_SINCE) {
			return 'Success emails not sent';
		} else if (this.migration.Projects?.length) {
			return 'Not all projects were selected for migration';
		} else if (this.migration.UserSelection) {
			return 'Not all users were selected for migration';
		} else if (!this.workspace.ReadOnly) {
			return 'Workspace is not read-only';
		} else if (!this.workspace.XgRedirect) {
			return 'Workspace is not redirecting';
		} else if (this.workspace.CommerciallyRelevant && !this.upgradedOnce) {
			return 'Paid workspace, and more than one migration in status "Published" or "Upgraded"';
		}
		return '';
	}

	/**
	 * Returns the caption to use for the provided state.
	 * @author OK
	 */
	public getCaption(state: AnyState): string {
		return this.migration.getCaption(state);
	}

	/**
	 * Returns the CSS class name to colorize the task states line.
	 * @author OK
	 */
	public getTaskCss(states: TaskStates): string {
		if (states.Expected === states.Succeeded) {
			return 'complete';
		} else if (states.Aborted || states.Failed) {
			return 'error2';
		} else {
			return '';
		}
	}

	/**
	 * [TF-1321] Returns boolean indicating if the step was validated
	 * @author MF
	 * @param step migration step
	 */
	public isStepValidated(step: Step2I | Step4I): boolean | null {
		// Do not show if step not finished yet, or for older migrations without validation.
		if (['running', 'pending'].includes(step.State) || step.ValidationErrors === undefined) {
			return null;
		}
		if (step.ValidationErrors) {
			return false;
		}
		return true;
	}

	/**
	 * Returns the task states for a task.
	 * Example: "Expected: 48 | Scheduled: 12 | Created: 2 | Succeeded: 12 | Failed: 22"
	 * @author OK
	 * @param states The task states of an AggregatedTaskStateInfo object.
	 */
	public getTaskStates(states: TaskStates): string {
		let str = '';
		if (states.Expected) {
			str += `Expected: ${states.Expected} | `;
		}
		if (states.Scheduled) {
			str += `Scheduled: ${states.Scheduled} | `;
		}
		if (states.Created) {
			str += `Created: ${states.Created} | `;
		}
		if (states.Started) {
			str += `Started: ${states.Started} | `;
		}
		if (states.Succeeded) {
			str += `Succeeded: ${states.Succeeded} | `;
		}
		if (states.Aborted) {
			str += `Aborted: ${states.Aborted} | `;
		}
		if (states.Failed) {
			str += `Failed: ${states.Failed} | `;
		}

		if (str.endsWith('| ')) {
			str = str.substring(0, str.length - 2);
		}
		return str;
	}

	// ---------------------------------------------------------------------------

	/**
	 * Poll every 5 seconds for changes to the displayed migration.
	 * @author OK
	 */
	protected pollMigration(): void {
		let i = 0;
		this.pollingInterval = setInterval(async () => {
			i = (i + 1) % 6;
			// document.hidden: Save resources when browser tab is hidden.
			// $route.name:     Avoid problems on MigrationDashboardPage, which does its own polling.
			if (!this.workspace || !this.migration || document.hidden || this.$route.name !== 'MigrationPage') {
				return;
			}
			// Poll only every 30 seconds if the migration was successful, since changes are less likely.
			const prevState = this.migration.State;
			if (i !== 0 && (prevState === 'unpublished' || prevState === 'published' || prevState === 'upgraded')) {
				return;
			}

			await this.$tsStore.migrations.getMigration({
				region: this.workspace.Region as WebshareRegion,
				uuid: this.migration.UUID,
			});

			// Avoid warning 'Workspace is not redirecting' when migration state changes to 'upgraded'.
			if (this.migration.State !== prevState) {
				await this.$tsStore.migrationWorkspaces.getSingle(this.workspaceUuid);
			}
		}, 5_000);
	}

	/**
	 * @param bytes
	 * @return "0 GB" for 0 bytes. "x.x GB" for large values.
	 *         "x.xx GB" or "x.xxx GB" for small values, to avoid showing "0.0 GB" if the project has a few MB only.
	 */
	public getGB(bytes: number): string {
		if (!bytes) {
			return '0 GB';
		}
		const gb = bytes / 1073741824;
		const decimals = (gb < 0.005) ? 3 : ((gb < 0.05) ? 2 : 1);
		return gb.toFixed(decimals) + ' GB';
	}

	/**
	 * Gets workspace size information for the workspace from WebShare.
	 * @author OK
	 */
	protected async getWorkspaceSize(): Promise<void> {
		const date = new Date(this.migration.StartDate ?? this.migration.CreationDate);
		this.size = await this.$tsStore.workspaces.getWorkspaceSizes({ workspaceUuids: [this.workspaceUuid], date });
	}

	/**
	 * Gets the storage size of the WebShare project as retrieved previously with getWorkspaceSize,
	 * multiplied by the heuristic migration factor.
	 * @author OK
	 * @param projectUuid Project UUID.
	 */
	public getSizeStrOfProject(projectUuid: string): string {
		if (this.size &&
			this.size[this.workspaceUuid] &&
			this.size[this.workspaceUuid][projectUuid] &&
			0 < (this.size[this.workspaceUuid][projectUuid].Size || 0)
		) {
			const sizeBytes = this.size[this.workspaceUuid][projectUuid].Size || 0;
			return this.getGB(sizeBytes * Migration.SIZE_FACTOR);
		} else {
			return '';
		}
	}

	/**
	 * Gets the total storage size of all included WebShare projects as retrieved previously with getWorkspaceSize.
	 * @return Tuple: [total size in bytes, all sizes known?]
	 */
	protected getWebShareSize(): [number|null, boolean] {
		if (this.size && this.size[this.workspaceUuid]) {
			let totalBytes = 0;
			let allKnown = true;

			for (const result of this.migration.projectContentResults) {
				const projectUuid = result.UUID;
				const size = this.size[this.workspaceUuid][projectUuid]?.Size;
				if (typeof size === 'number') {
					totalBytes += size;
				} else {
					allKnown = false;
				}
			}

			return [totalBytes, allKnown];
		} else {
			return [null, false];
		}
	}

	/**
	 * Gets the total storage size of all included WebShare projects as retrieved previously with getWorkspaceSize.
	 * @author OK, MH
	 */
	public getWebShareSizeStr(): string {
		if (!this.size) {
			return 'loading...';
		}

		const [totalBytes, allKnown] = this.getWebShareSize();
		if (typeof totalBytes === 'number') {
			let str = this.getGB(totalBytes);
			if (!allKnown) {
				str += ' (incomplete)';
			}
			return str;
		} else {
			return 'n/a';
		}
	}

	/**
	 * Gets the size of data transferred from S3 to Azure, according to ProjectAPI.
	 */
	public getTransferredSizeStr(): string {
		if (typeof this.migration.TransferredFilesSize === 'number') {
			return this.getGB(this.migration.TransferredFilesSize);
		} else {
			return 'n/a';
		}
	}

	/**
	 * Gets the ratio (size of data transferred from S3 to Azure, according to ProjectAPI) / (size of WebShare projects),
	 * with a precision of 1/100.
	 */
	public getTransferredFactorStr(): string {
		if (!this.size) {
			return 'loading...';
		}

		const [bytesWebShare] = this.getWebShareSize();
		const bytesTransferred = this.migration.TransferredFilesSize;
		if (typeof bytesWebShare === 'number' && typeof bytesTransferred === 'number' && bytesWebShare > 0) {
			return (bytesTransferred / bytesWebShare).toFixed(2);
		} else {
			return 'n/a';
		}
	}

	protected adaptUrlWithRedirect(webShareOrSphereUrl: string) {
		const sep = webShareOrSphereUrl.includes('?') ? '&' : '?';
		const doRedirect = webShareOrSphereUrl.includes('/project/link/') ? 'xgredirect=true' : 'redirect=true';
		const dontRedirect = webShareOrSphereUrl.includes('/project/link/') ? 'xgredirect=false' : 'redirect=false';

		if (this.redirectOption === 'src_migrator') {
			return webShareOrSphereUrl + sep + doRedirect;
		} else if (this.redirectOption === 'src_migrator_redirectservice') {
			return webShareOrSphereUrl + sep + doRedirect + '&redirectservice=true';
		} else if (!this.redirectOption) {
			return webShareOrSphereUrl + sep + dontRedirect;
		} else {
			return webShareOrSphereUrl; // Same behavior as for end user.
		}
	}

	public getWebShareUrlForWorkspace(): string {
		const webShareUrl = this.workspace?.webShareUrl;
		if (!webShareUrl) {
			return '';
		}

		// Auto-Login on WebShare with the current user
		return this.adaptUrlWithRedirect(ApplicationWebShare.makeSsoUrl(webShareUrl, this.$tsStore.users.user));
	}

	/**
	 * Get the WebShare URL for the given project UUID of the migrated workspace.
	 * @author BE
	 * @param projectUuid The UUID of the project to get the WebShare URL for.
	 */
	public getWebShareUrlForProject(projectUuid: string): string {
		const webShareUrl = this.workspace?.webShareUrl;
		if (!webShareUrl) {
			return '';
		}

		// Allow to open private projects shared by link. The /project/link API doesn't support this, so we use it only as fallback.
		// In the XG Viewer, such "unlisted" projects can be opened by their regular URL.
		const projectWS: IWebShareProject | undefined = this.projects[projectUuid]?.WS;
		let projectUrl: string;
		if (!projectWS?.Name) {
			// For this API, '?redirect=true' is unrelated to the XG redirect, but just means "redirect to the WebShare UI instead of returning JSON".
			projectUrl = webShareUrl + 'project/link/' + projectUuid + '?redirect=true';
		} else {
			// Prefer Overview Map if available; otherwise at least one point cloud must exist for the project to be migrated.
			if (projectWS.Type === 'wsc') {
				projectUrl = webShareUrl + '?v=om&t=p:default,c:overviewmap,h:f,m:t,pr:t&om=om1&om1=auto:t&p=p:' + projectWS.Name;
			} else {
				// Alternative: Open Project Content page.
				// projectUrl = webShareUrl + '?v=ob&t=p:default,c:object,h:f,m:t,pr:f&ob=&p=p:' + projectWS.Name;
				projectUrl = webShareUrl + '?v=pv&t=p:default,c:panoramaview,h:f,m:t,pr:f&pv=pv1&pv1=vt:3d&p=p:' + projectWS.Name;
			}

			if (!projectWS.Public && projectWS.SecretEnabled) {
				projectUrl += ',share:' + projectWS.Secret;
			}
		}

		// Auto-Login on WebShare with the current user.
		// When opening a public project, or a project shared by link, no login is required.
		projectUrl = (projectWS?.Public || projectWS?.SecretEnabled) ? projectUrl : ApplicationWebShare.makeSsoUrl(projectUrl, this.$tsStore.users.user);
		return this.adaptUrlWithRedirect(projectUrl);
	}

	/**
	 * Get the link to AuthZ for the migrated workspace
	 * @author BE
	 */
	public get accessControlHref(): string {
		if (!this.workspace) {
			return '';
		}
		let res = this.adaptUrlWithRedirect(config.authzUI + '/' + this.workspace.UUID + '/users');
		const redirect = !!res.match(/[?&]redirect=true/);

		// Override the user on AuthZ, if there are sufficient permissions.
		// This implies "?redirect=false", so we don't add it if redirect = true.
		const decodedToken = this.$tsStore.users.decodedToken;
		if (!redirect && this.migration.HbEnterpriseAdmin && decodedToken?.permissions?.includes('override-users')) {
			const sep = res.includes('?') ? '&' : '?';
			res += sep + 'admin-mode-email=' + encodeURIComponent(this.migration.HbEnterpriseAdmin);
		}
		return res;
	}

	public get subSvcHref(): string {
		if (!this.workspace) {
			return '';
		}

		// In the real deployment, a relative URL is sufficient.
		const origin = window.location.hostname === 'localhost' ? (config.env === 'dev' ? 'https://www.dev.farosphere.com' : 'http://localhost:8088') : '';
		return origin + '/subscription/workspaces/' + this.workspace.UUID;
	}

	/**
	 * Get number of scans as string to display.
	 * @author BE
	 * @param projectUuid The UUID of the project to get the metadata for.
	 */
	public getProjectScansStr(projectUuid: string): string {
		const wsProject = this.projects[projectUuid]?.WS;
		const scanCount = wsProject?.NumberOfScans;
		return scanCount ? scanCount + ' Scans' : '';
	}

	/**
	 * Get number of pointclouds as string to display.
	 * @author BE
	 * @param projectUuid The UUID of the project to get the metadata for.
	 */
	public getProjectPCStr(projectUuid: string): string {
		const wsProject = this.projects[projectUuid]?.WS;
		const pointCloudCount = (wsProject?.PointClouds?.EntityPC?.complete || 0) + (wsProject?.PointClouds?.Project?.complete || 0);
		return pointCloudCount ? pointCloudCount + ' PCs' : '';
	}

	/**
	 * Get caption of a project.
	 * @author BE
	 * @param projectUuid The UUID of the project to get the metadata for.
	 */
	public getProjectCaption(projectUuid?: string): string {
		if (!projectUuid) {
			// Should not happen
			return '';
		}
		const wsProject = this.projects[projectUuid]?.WS;
		const caption = wsProject?.DisplayName;
		if (!caption) {
			return `[Project with UUID ${projectUuid} no longer exists!]`;
		}
		return caption;
	}

	/**
	 * Handler for the "Continue migration" button.
	 * @author MH
	 */
	public async continueMigration(): Promise<void> {
		$assert.Assert(this.canContinueMigration);

		this.$faroLoading.start();
		try {
			await this.$tsStore.migrations.continueMigration({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_UPDATE') });
		}
		this.$faroLoading.stop();
	}

	/**
	 * Handler for the "Update project migration status" button.
	 * @author MH
	 */
	public async updateProjectStatus(): Promise<void> {
		$assert.Assert(this.canUpdateProjectStatus);

		this.$faroLoading.start();
		try {
			await this.$tsStore.migrations.updateProjectStatus({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_UPDATE') });
		}
		this.$faroLoading.stop();
	}

	/**
	 * Handler for the "Abort" button that shows a confirmation dialog.
	 * @author OK
	 */
	public async askAbort(): Promise<void> {
		$assert.Assert(this.migration.State === 'running' || this.migration.State === 'pending');

		this.$faroNotify.showConfirmationDialog({
			text: 'Are you sure you want to abort the ongoing migration? Do you want to proceed?',
			title: 'Confirm Abortion',
			group: 'info',
			leftButton: {
				textId: 'UI_CANCEL',
			},
			rightButton: {
				color: 'info',
				textId: 'LP_CONFIRM',
				click: () => this.abort(),
			},
		});
	}

	/**
	 * Aborts the migration.
	 * @author BE
	 */
	public async abort(): Promise<void> {
		// It's possible that the migration is not longer in the desired state since showing the confirmation dialog.
		if (!(this.migration.State === 'running' || this.migration.State === 'pending')) {
			this.$faroComponents.$emit('show-error', { message: this.$tc('LP_ERR_MIGRATION_ABORT') });
			return;
		}

		try {
			await this.$tsStore.migrations.abort({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_ABORT') });
		}
	}

	/**
	 * Publishes the migrated HoloBuilder company for public use.
	 * @author OK
	 */
	public async publish(): Promise<void> {
		$assert.Assert(this.migration.State === 'unpublished');

		this.$faroLoading.start();
		try {
			await this.$tsStore.migrations.publish({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_PUBLISH') });
		}
		this.$faroLoading.stop();
	}

	/**
	 * Unpublishes the published HoloBuilder company to make it private again.
	 * @author OK
	 */
	public async unpublish(): Promise<void> {
		$assert.Assert(this.migration.State === 'published'); // Not including 'upgraded'.

		this.$faroLoading.start();
		try {
			await this.$tsStore.migrations.unpublish({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_UNPUBLISH') });
		}
		this.$faroLoading.stop();
	}

	/**
	 * Rejects the unpublished migration by setting its state to error.
	 * @author OK
	 */
	public async reject(): Promise<void> {
		$assert.Assert(this.migration.State === 'unpublished');

		try {
			await this.$tsStore.migrations.reject({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_REJECT') });
		}
	}

	/**
	 * Approves the failed migration by setting its state to unpublished.
	 * @author OK
	 */
	public async approve(): Promise<void> {
		$assert.Assert(this.migration.State === 'error');

		try {
			await this.$tsStore.migrations.approve({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_APPROVE') });
		}
	}

	public async toggleXgRedirect(): Promise<void> {
		const enableXgRedirect = !this.migration.XgRedirect;
		try {
			await this.$tsStore.migrations.setXgRedirect({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
				xgRedirect: enableXgRedirect,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: `Failed to ${enableXgRedirect ? 'enable' : 'disable'} redirect` });
		} finally {
			this.xgRedirectUpdate = null; // Allow the user to toggle again.
		}
	}

	public async toggleAutoPublish(): Promise<void> {
		const enableAutoPublish = !this.migration.AutoPublish;
		try {
			await this.$tsStore.migrations.setAutoPublish({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
				autoPublish: enableAutoPublish,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: `Failed to ${enableAutoPublish ? 'enable' : 'disable'} auto-publish` });
		} finally {
			this.autoPublishUpdate = null; // Allow the user to toggle again.
		}
	}

	/**
	 * Handler for the button to delete the migration that shows a confirmation dialog.
	 * @author OK
	 */
	public async askDelete(): Promise<void> {
		$assert.Assert(this.migration.State === 'unpublished' ||
			this.migration.State === 'error' || this.migration.State === 'aborted');

		this.$faroNotify.showConfirmationDialog({
			text: 'You are about to permanently remove the migration and all related data in Sphere XG. ' +
				'This is a final action and cannot be reversed. Do you want to proceed?',
			title: 'Confirm Deletion',
			group: 'info',
			leftButton: {
				textId: 'UI_CANCEL',
			},
			rightButton: {
				color: 'info',
				textId: 'LP_CONFIRM',
				click: () => this.deleteMigration(),
			},
		});
	}

	/**
	 * Handler for the Cancel button in the dialog box to add QA users.
	 * @author OK
	 */
	public async onQAUsersCancel(): Promise<void> {
		this.showAddQAUsersDlg = false;
		this.qaUsersToAdd = '';
	}

	/**
	 * Handler for the OK button in the dialog box to add QA users.
	 * @author OK
	 */
	public async onQAUsersOK(): Promise<void> {
		this.showAddQAUsersDlg = false;

		let emailsStr = this.qaUsersToAdd;
		this.qaUsersToAdd = '';

		emailsStr = emailsStr.toLowerCase();
		if (emailsStr.length === 0) {
			const error = new Error('You must provide at least one FARO email address!');
			this.$faroComponents.$emit('show-error', { error });
			return;
		}

		const emails = StringUtils.splitTrimKeepNonEmpty(emailsStr, /[\s,;]/);
		for (const email of emails) {
			if (!(email.endsWith('@faro.com') || email.endsWith('@faroeurope.com'))) {
				const error = new Error('Only @faro.com and @faroeurope.com email addresses are allowed!');
				this.$faroComponents.$emit('show-error', { error });
				return;
			}
		}

		this.$faroLoading.start();

		try {
			await this.$tsStore.migrations.addQAUsers({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
				emails,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_QA_USERS') });
		} finally {
			this.$faroLoading.stop();
		}
	}

	/**
	 * Deletes the migration and the associated HoloBuilder company.
	 * @author OK
	 */
	public async deleteMigration(): Promise<void> {
		// It's possible that the migration is not longer in the desired state since showing the confirmation dialog.
		if (!(this.migration.State === 'unpublished' ||
			this.migration.State === 'error' || this.migration.State === 'aborted'
		)) {
			this.$faroComponents.$emit('show-error', { message: this.$tc('LP_ERR_MIGRATION_DELETE') });
			return;
		}

		try {
			await this.$tsStore.migrations.deleteMigration({
				region: this.workspace.Region as AuthzType.WebshareRegion,
				uuid: this.migration.UUID,
			});
			await this.$router.push({ name: 'MigrationDashboardPage' });
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: this.$tc('LP_ERR_MIGRATION_DELETE') });
		}
	}

	/**
	 * Gets and downloads the presigned URL of the log file.
	 * @author MF
	 */
	public async downloadLog(): Promise<void> {
		const logUrl = await this.$tsStore.migrations.getMigrationLogFileURL({
			region: this.workspace.Region as WebshareRegion,
			uuid: this.migration.UUID,
		});
		window.location.assign(logUrl);
	}

	/**
	 * Copy a string to the clipboard.
	 * @param text: the string to copy to the clipboard
	 */
	public async copyToClipboard(text: string | undefined | null) {
		if (text) {
			void await navigator.clipboard.writeText(text);
			this.$faroNotify.showSnackbar(
				'success',
				'Copied to clipboard',
			);
		}
	}

	protected gettingMigrationWithBounces = false;

	public async getMigrationWithBounces() {
		this.gettingMigrationWithBounces = true;
		try {
			await this.$tsStore.migrations.getMigration({
				region: this.workspace.Region as WebshareRegion,
				uuid: this.migration.UUID,
				emailBounces: 'force', // refresh and force get emailBounces
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, title: 'Failed to get migration with bounces' });
		}
		this.gettingMigrationWithBounces = false;
	}

	public async xInitialize() {
		this.xLoading = true;
		try {
			this.$faroLoading.start();

			if (!this.workspaceUuid) {
				throw new Error('No workspace UUID provided!');
			} else if (!this.migrationUuid) {
				throw new Error('No migration UUID provided!');
			}

			await this.$tsStore.migrationWorkspaces.getSingle(this.workspaceUuid);

			const region = this.workspace.Region as AuthzType.WebshareRegion;
			await this.$tsStore.migrations.getMigration({
				region,
				uuid: this.migrationUuid,
				emailBounces: 'simple', // Get emailBounces if the migration was finished in the last 24 hours.
			});
			this.creatorEmail = this.migration?.CreatorEmail || '';
			this.gettingMigrationWithBounces = false;

			await this.getProjectData();

			// Calculate estimated duration; not essential for showing the page.
			Migration.calcEstimatedDurations(region, this.workspace.UUID).then((durationInfo) => {
				this.durationInfos = [durationInfo.StandardStr, durationInfo.MinStr, durationInfo.MaxStr];
			}).catch((error) => {
				console.error(error);
				this.$faroComponents.$emit('show-error', { error, title: 'Failed to calculate estimated duration' });
			});

			// Requesting the storage sizes can be slow, and is not essential for showing the page.
			this.getWorkspaceSize().catch((error) => {
				console.error(error);
				this.$faroComponents.$emit('show-error', { error, title: 'Failed to load storage sizes' });
			});

			this.upgradedOnce = await this.isUpgradedOnce();

			this.pollMigration();
		} catch (error) {
			console.error(error);
			this.handleError(error);
		} finally {
			this.$tsStore.pages.setFinishedPageLoading(true);
			if (this.$tsStore.pages.finishedMainLoading) {
				this.$faroLoading.stop();
			}
			this.xLoading = false;
		}
	}

	public async mounted() {
		await this.xInitialize();
	}

	beforeDestroy() {
		if (this.pollingInterval) {
			clearInterval(this.pollingInterval);
			this.pollingInterval = null;
		}
	}

	/**
	 * Gets the projects from the Migrator that can potentially be migrated.
	 * @author OK
	 */
	protected async getProjectsForMigration(): Promise<ProjectExtended[]> {
		$assert.Assert(this.workspace);

		return await this.$tsStore.migrations.getProjectsForMigration({
			region: this.workspace.Region as AuthzType.WebshareRegion,
			uuidWorkspace: this.workspace.UUID,
		});
	}

	/**
	 * Gets data from the projects
	 */
	protected async getProjectData(): Promise<void> {
		const projectsExtended = await this.getProjectsForMigration();
		for (const projectExtended of projectsExtended) {
			this.projects[projectExtended.WS.UUID] = projectExtended;
		}
	}
}
