
import Vue from 'vue';
import Component from 'vue-class-component';
import DeleteConfirmationDialog from '@/components/SettingsPage/DeleteConfirmationDialog.vue';
import { countryNames, countryAcronymIcons, LanguageCode } from '@faroconnect/baseui';
import { $assert, InternationalizationUtils, UuidUtils } from '@faroconnect/utils';
import { AuthzConstant, AuthzType, AuthzInterface, SalesforceUtils } from '@faroconnect/authz-client';
import { DataTableHeader } from 'vuetify';
import { User } from '@/classes/authz/User';
import { PAGE_LAYOUT_ENUM } from '@/definitions/constants';
import { PageLayout } from '@/definitions/types';
import { isFeatureEnabledByAuth0Token } from '@/utils/permissions';
import * as UserValidation from '@/utils/uservalidation';
import * as UserUtils from '@/utils/userutils';
import { Watch } from 'vue-property-decorator';
import { HttpError } from '@faroconnect/clientbase';
import { redirectAfterAuthenticationError } from '@/utils/errorhandler';
import { setChangeEmailNext, tempState } from '../../config';

@Component({
	components: {
		DeleteConfirmationDialog,
	},
})
export default class UserSettings extends Vue {
	// ############################# Constants #############################

	public readonly MIN_CHARS = 0;
	public readonly MAX_CHARS = 127;
	public readonly DEFAULT_LAYOUTS: { [ key in PageLayout]: { uiString: string, dataCy: string } } = {
		[PAGE_LAYOUT_ENUM.thumbnail]: { uiString: 'UI_THUMBNAIL_VIEW', dataCy: 'sethvira_radio' },
		[PAGE_LAYOUT_ENUM.list]: { uiString: 'UI_LIST_VIEW', dataCy: 'selivira_radio' },
	};
	public readonly DEFAULT_LAYOUTS_ARR = Object.keys(this.DEFAULT_LAYOUTS);

	// ############################# Variables #############################

	public currentDefualtView: PageLayout = this.$tsStore.settings.defaultView as PageLayout;
	public isUserRegisteredWithSSO: boolean | null = null;
	public countryTemp: InternationalizationUtils.CountryCode | '' = this.user?.Country ?? '';
	public stateOrProvinceTemp: UserUtils.StateOrProvince | string | null = this.user?.StateOrProvince ?? null;
	// Random UUIDs are used to avoid having duplicate key identifiers
	public postalCodeInputKey: string = UuidUtils.generateUuid();
	public visibleDeleteConfirmationDialog: boolean = false;
	public industry: AuthzType.Industry | '' =  this.user?.Industry ?? '';
	public industryTemp: AuthzType.Industry | null = this.user?.Industry ?? null;
	public primaryApplicationInterest: AuthzType.ApplicationInterest | null =  this.user?.PrimaryApplicationInterest ?? null;
	public sanitizedPhoneNumber: string = '';
	public sanitizedFirstName: string = '';
	public sanitizedMiddleName: string = '';
	public sanitizedLastName: string = '';
	public sanitizedCompany: string = '';
	public sanitizedCity: string = '';
	public sanitizedPostalCode: string = '';
	public showMFA: boolean = false;
	public enableMFA: boolean = false;
	public hasUserEnabledMFA: boolean | null = null;
	/**
	 * Flag if the mouse is currently hovering over the input field for the user's email address.
	 */
	public hoverEmail: boolean = false;
	/**
	 * Flag if the dialog to enter a new email address is currently visible.
	 */
	public showEmailDialog: boolean = false;
	/**
	 * The new email address entered by the user.
	 */
	public newEmail: string = '';

	// ############################# Validators #############################

	public readonly firstNameRule = UserValidation.firstNameRule;
	public readonly middleNameRule = UserValidation.middleNameRule;
	public readonly lastNameRule = UserValidation.lastNameRule;
	public readonly uiEmailRule = UserValidation.uiEmailRule;
	public readonly emailRule = UserValidation.emailRule;
	public readonly companyRule = UserValidation.companyRule;
	public readonly zipOrPostalCodeRequiredRule = UserValidation.zipOrPostalCodeRequiredRule;
	public readonly zipOrPostalCodeNotRequiredRule = UserValidation.zipOrPostalCodeNotRequiredRule;
	public readonly lengthRule = UserValidation.lengthRule;
	public readonly requiredSelectedRule = UserValidation.requiredSelectedRule;
	public readonly onlyNumberRule = UserValidation.onlyNumberRule;
	public readonly phoneNumberRule = UserValidation.phoneNumberRule;
	public readonly cityRule = UserValidation.cityRule;

	// ############################# Getters and Setters #############################

	public get user(): User | null {
		return this.$tsStore.users.user ?? null;
	}

	public get firstName(): string {
		return this.user?.FirstName ?? '';
	}

	public set firstName(firstName: string) {
		return;
	}

	public get middleName(): string {
		return this.user?.MiddleName ?? '';
	}

	public set middleName(middleName: string) {
		return;
	}

	public get lastName(): string {
		return this.user?.LastName ?? '';
	}

	public set lastName(lastName: string) {
		return;
	}

	public get company(): string {
		return this.user?.Company ?? '';
	}

	public set company(company: string) {
		return;
	}

	public get industries(): DataTableHeader[] {
		return AuthzConstant.INDUSTRIES.map((industry) => {
			return {
				text: UserUtils.translateIndustry(industry),
				value: industry,
			};
		}).sort((a, b) => a.text.localeCompare(b.text));
	}

	public get primaryApplicationInterests(): DataTableHeader[] {
		let primaryApplicationInterests: AuthzType.ApplicationInterest[] = [];

		switch (this.industry) {
			case 'AEC':
				primaryApplicationInterests = AuthzConstant.AEC_APPLICATION_INTERESTS;
				break;
			case 'MANUFACTURING':
				primaryApplicationInterests = AuthzConstant.MANUFACTURING_APPLICATION_INTERESTS;
				break;
			case 'PS':
				primaryApplicationInterests = AuthzConstant.PS_APPLICATION_INTERESTS;
				break;
		}

		return primaryApplicationInterests.map((primaryApplicationInterest) => {
			return {
				text: UserUtils.translatePrimaryApplicationInterest(primaryApplicationInterest),
				value: primaryApplicationInterest,
			};
		}).sort((a, b) => a.text.localeCompare(b.text));
	}

	public get phoneNumber(): string {
		return this.user?.PhoneNumber ?? '';
	}

	public set phoneNumber(phoneNumber: string) {
		return;
	}

	public get email(): string {
		return this.user?.Email ?? '';
	}

	public get country(): InternationalizationUtils.CountryCode | '' {
		return this.user?.Country ?? '';
	}

	public set country(country: InternationalizationUtils.CountryCode | '') {
		this.countryTemp = country;
	}

	public get stateOrProvince(): UserUtils.StateOrProvince | string {
		return this.user?.StateOrProvince ?? '';
	}

	public set stateOrProvince(stateOrProvince: UserUtils.StateOrProvince | string) {
		this.stateOrProvinceTemp = stateOrProvince;
	}

	public get phoneCountryCode(): InternationalizationUtils.CountryCode | null {
		return this.user?.PhoneCountryCode ?? null;
	}

	public set phoneCountryCode(phoneCountryCode: InternationalizationUtils.CountryCode | null) {
		return;
	}

	public get city(): string {
		return this.user?.City ?? '';
	}

	public set city(city: string) {
		return;
	}

	public get postalCode(): string {
		return this.user?.PostalCode ?? '';
	}

	public set postalCode(postalCode: string) {
		this.sanitizedPostalCode = UserUtils.removeUnnecessarySpaces(postalCode);
	}

	public get language(): string {
		return this.user?.Language ?? '';
	}

	public set language(company: string) {
		return;
	}

	public get timezone(): string {
		return this.user?.Timezone ?? '';
	}

	public set timezone(timezone: string) {
		return;
	}

	public get lengthUnit(): string {
		return this.user?.LengthUnit ?? '';
	}

	public set lengthUnit(lengthUnit: string) {
		return;
	}

	public get areaUnit(): string {
		return this.user?.AreaUnit ?? '';
	}

	public set areaUnit(areaUnit: string) {
		return;
	}

	public get angleUnit(): string {
		return this.user?.AngleUnit ?? '';
	}

	public set angleUnit(angleUnit: string) {
		return;
	}

	public get emailNotifications(): boolean {
		return this.user?.EmailNotifications ?? false;
	}

	public set emailNotifications(emailNotifications: boolean) {
		return;
	}

	public get defaultLayout() {
		return this.$tsStore.settings.defaultView;
	}

	public set defaultLayout(value: PageLayout) {
		if (value) {
			this.$tsStore.settings.updateDefaultView(value);
		}
	}

	// ############################# Getters #############################

	public get phoneCodes(): DataTableHeader[] {
		return UserUtils.phoneCodes();
	}

	public get stateOrProvinces(): DataTableHeader[] {
		return UserUtils.stateOrProvinces(this.countryTemp);
	}

	public get requiresStateOrProvince(): boolean {
		return UserUtils.requiresStateOrProvince(this.countryTemp);
	}

	public get countries(): DataTableHeader[] {
		return Object.keys(countryNames).map((countryCode) => ({
			text: this.$tc(countryNames[countryCode as InternationalizationUtils.CountryCode]),
			value: countryCode,
		})).sort((a, b) => a.text.localeCompare(b.text));
	}

	public get languages(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.languageNames).map((languageCode) => ({
			text: InternationalizationUtils.languageNames[languageCode as InternationalizationUtils.Language],
			value: languageCode,
			icon: this.getIcon(languageCode),
		}));
	}

	public get timezones(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.timezones).map((timezoneKey) => ({
			text: InternationalizationUtils.timezones[timezoneKey as InternationalizationUtils.Timezone].WindowsTimeZoneName,
			value: timezoneKey,
		}));
	}

	public get dateFormats(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.dateFormats).map((dateFormat) => ({
			text: dateFormat,
			value: dateFormat,
		}));
	}

	public get timeFormats(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.timeFormats).map((timeFormat) => ({
			text: timeFormat,
			value: timeFormat,
		}));
	}

	public get lengthUnits(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.lengthUnits).map((lengthUnit) => ({
			text: lengthUnit,
			value: lengthUnit,
		}));
	}

	public get areaUnits(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.areaUnits).map((areaUnit) => ({
			text: areaUnit,
			value: areaUnit,
		}));
	}

	public get angleUnits(): DataTableHeader[] {
		return Object.keys(InternationalizationUtils.angleUnits).map((angleUnit) => ({
			text: angleUnit,
			value: angleUnit,
		}));
	}

	public get countryErrorMessages() {
		if (this.requiresStateOrProvince && !this.stateOrProvinceTemp) {
			return [this.$tc('LP_STATE_OR_PROVINCE_REQUIRED')];
		}
		if (this.requiresPostalCode && !this.isValidPostalCode(this.sanitizedPostalCode)) {
			return [this.$tc('LP_POSTAL_CODE_REQUIRED')];
		}

		return [];
	}

	public get requiresPostalCode(): boolean {
		return SalesforceUtils.requirePostalCode(this.countryTemp || null);
	}

	// ############################# Methods #############################

	public changedIndustry(value: AuthzType.Industry | null) {
		// Reset primaryApplicationInterest to avoid sending mismatching values.
		this.primaryApplicationInterest = null;
		this.industryTemp =  value;
	}

	public changedPrimaryApplicationInterest(value: AuthzType.ApplicationInterest) {
		if (this.industryTemp === undefined || this.industryTemp === null || value === undefined || value === null) {
			this.updateProperty('Industry', null, { PrimaryApplicationInterest: null });
		} else {
			this.updateProperty('Industry', this.industryTemp, { PrimaryApplicationInterest: value});
		}
	}

	public isValidPostalCode(postalCode: string | undefined | null): boolean {
		// Since zipOrPostalCodeRequiredRule can return either true or string, make sure to evaluate === true.
		// Otherwise it will be always true.
		return this.zipOrPostalCodeRequiredRule(this.sanitizedPostalCode, this.countryTemp) === true;
	}

	public async updateProperty<
		PropertyT extends keyof AuthzInterface.IUser = keyof AuthzInterface.IUser,
	>(
		property: PropertyT, value: AuthzInterface.IUser[PropertyT],
		updateUser?: Partial<AuthzInterface.IUser>,
	) {
		if (!this.user) {
			return;
		}
		try {
			const attributes: Partial<AuthzInterface.IUser> = {
				[property]: value,
				...updateUser,
			};
			await this.$tsStore.users.updateSingle({
				uuid: this.user.UUID,
				attributes,
			});
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_UPDATE_USER_SETTINGS' });
		}
	}

	public updateFirstName(firstName: string | null) {
		return this.updateProperty('FirstName', this.sanitizedFirstName ?? '');
	}

	public updateMiddleName(middleName: string | null) {
		return this.updateProperty('MiddleName', this.sanitizedMiddleName ?? '');
	}

	public updateLastName(lastName: string | null) {
		return this.updateProperty('LastName', this.sanitizedLastName ?? '');
	}

	public updateCompany(company: string | null) {
		return this.updateProperty('Company', this.sanitizedCompany ?? '');
	}

	public async checkAndUpdateCountry(country: InternationalizationUtils.CountryCode) {
		if (UserUtils.requiresStateOrProvince(country)) {
			// Wait one tick to give time to instantiate stateOrProvinceForm.
			await Vue.nextTick();
			const stateOrProvinceForm = this.$refs.stateOrProvinceForm as HTMLFormElement;
			try {
				stateOrProvinceForm?.validate();
			} catch (_) {
				// Do nothing
			}
			return;
		}
		if (SalesforceUtils.requirePostalCode(country) && !this.isValidPostalCode(this.sanitizedPostalCode)) {
			// The validation is done automatic for postal code.
			return;
		}
		this.stateOrProvinceTemp = '';
		return this.updateProperty('Country', country, { StateOrProvince: '' });
	}

	public async checkAndUpdateStateOrProvince(stateOrProvince: UserUtils.StateOrProvince | string = '') {
		if (SalesforceUtils.requirePostalCode(this.countryTemp || null) && !this.isValidPostalCode(this.sanitizedPostalCode)) {
			return;
		}
		return this.updateProperty('StateOrProvince', stateOrProvince, {
			Country: this.countryTemp as InternationalizationUtils.CountryCode,
			PostalCode: this.sanitizedPostalCode,
		});
	}

	public checkAndupdatePostalCode(postalCode: string | null) {
		if (UserUtils.requiresStateOrProvince(this.countryTemp) && !this.stateOrProvinceTemp) {
			return;
		}
		return this.updateProperty('PostalCode', this.sanitizedPostalCode ?? '', {
			Country: this.countryTemp as InternationalizationUtils.CountryCode,
			StateOrProvince: this.stateOrProvinceTemp ?? '',
		});
	}

	public updatePhoneNumber(phoneNumber: string | null) {
		return this.updateProperty('PhoneNumber', this.sanitizedPhoneNumber);
	}

	public updatePhoneCountryCode(phoneCountryCode: InternationalizationUtils.CountryCode | null = null) {
		return this.updateProperty('PhoneCountryCode', phoneCountryCode);
	}

	public updateCity(city: string | null) {
		return this.updateProperty('City', this.sanitizedCity ?? '');
	}

	/**
	 * Updates the user's language, also making sure the UI strings get exchanged by calling setLanguage of BaseUI.
	 */
	public async updateLanguage(language: InternationalizationUtils.Language) {
		const result = await this.updateProperty('Language', language);
		await this.$faroLocalization.setLanguage(language as LanguageCode);
		return result;
	}

	public updateTimezone(timezone: InternationalizationUtils.Timezone) {
		return this.updateProperty('Timezone', timezone);
	}

	public updateLengthUnit(lengthUnit: InternationalizationUtils.LengthUnit) {
		return this.updateProperty('LengthUnit', lengthUnit);
	}

	public updateAreaUnit(areaUnit: InternationalizationUtils.AreaUnit) {
		return this.updateProperty('AreaUnit', areaUnit);
	}

	public updateAngleUnit(angleUnit: InternationalizationUtils.AngleUnit) {
		return this.updateProperty('AngleUnit', angleUnit);
	}

	public updateEmailNotifications(emailNotifications: boolean) {
		return this.updateProperty('EmailNotifications', emailNotifications);
	}

	public updateEnableMFA(enableMFA: boolean) {
		if (!enableMFA) {
			this.askDisableMFA();
		} else {
			this.askEnrollMFA();
		}
	}

	public deleteAccount() {
		this.visibleDeleteConfirmationDialog = true;
	}

	public changePasswordAccount() {
		this.$faroNotify.showConfirmationDialog({
			text: this.$tc('LP_CHANGE_PASSWORD_EMAIL'),
			title: this.$tc('LP_CHANGE_PASSWORD'),
			group: 'info',
			persistent: false,
			leftButton: {
				textId: 'UI_CANCEL',
			},
			rightButton: {
				color: 'info',
				text: this.$tc('UI_OK'),
				click:  () => this.onChangePasswordRequest(),
			},
		});
	}

	public async onChangePasswordRequest() {
		try {
			await this.$tsStore.users.changePasswordRequest();
			this.$faroNotify.showSnackbar(
				'success',
				this.$tc('LP_CHANGE_PASSWORD'),
				this.$tc('LP_EMAIL_SENT'),
			);
		} catch (error: any) {
			this.$faroComponents.$emit('show-error', { error, title: 'LP_CHANGE_PASSWORD', message: 'LP_ERR_SENDING_EMAIL' });
		}
	}

	public async askEnrollMFA() {
		try {
			this.$faroLoading.start();
			const response = await this.$tsStore.settings.enrollMFA();
			$assert.String(response.TicketUrl);
			this.$faroLoading.stop();

			this.$faroNotify.showConfirmationDialog({
				title: this.$tc('LP_ENABLE_MFA'),
				text: this.$tc('LP_ENABLE_MFA_CONFIRM_1') + '\n\n' + this.$tc('LP_ENABLE_MFA_CONFIRM_2'),
				group: 'info',
				persistent: false,
				leftButton: {
					textId: 'UI_CANCEL',
					click: () => {
						// No need to call this.checkUserEnabledMFA().
						this.hasUserEnabledMFA = false;
						this.enableMFA = false;
					},
				},
				rightButton: {
					color: 'info',
					text: this.$tc('UI_OK'),
					// Window.open() must be called in a synchronous callback, otherwise Firefox will block opening the tab.
					click: () => window.open(response.TicketUrl, '_blank'),
				},
			});
		} catch (error: any) {
			this.$faroLoading.stop();
			this.hasUserEnabledMFA = false;
			this.enableMFA = false;
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_ENABLE_MFA' });
		}
	}

	public askDisableMFA() {
		if (this.hasUserEnabledMFA) {
			this.$faroNotify.showConfirmationDialog({
				title: this.$tc('LP_DISABLE_MFA'),
				text: this.$tc('LP_DISABLE_MFA_CONFIRM_1') + '\n\n' + this.$tc('LP_DISABLE_MFA_CONFIRM_2'),
				group: 'error',
				persistent: false,
				leftButton: {
					textId: 'UI_CANCEL',
					click: () => this.checkUserEnabledMFA(),
				},
				rightButton: {
					color: 'error',
					text: this.$tc('UI_OK'),
					click: () => this.disableMFA(),
				},
			});
		}
	}

	public async disableMFA() {
		try {
			await this.$tsStore.settings.disableMFA();
			this.$faroNotify.showSnackbar(
				'success',
				this.$tc('LP_DISABLE_MFA'),
				this.$tc('LP_DISABLE_MFA_SUCCESS'),
			);
		} catch (error: any) {
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_DISABLE_MFA' });
		}
	}

	public getIcon(languageCode: string): string | null {
		const icon = countryAcronymIcons[languageCode as InternationalizationUtils.Language];
		return icon.normal;
	}

	public zipOrPostalCodeRule(value: string): true | string {
		this.sanitizedPostalCode = UserUtils.removeUnnecessarySpaces(value);
		if (!this.requiresPostalCode) {
			return this.zipOrPostalCodeNotRequiredRule(this.sanitizedPostalCode, this.countryTemp);
		}
		return this.zipOrPostalCodeRequiredRule(this.sanitizedPostalCode, this.countryTemp);
	}

	public validatePhoneNumberRule(value: string): true | string {
		const preSanitizedPhoneNumber = UserUtils.removeUnnecessarySpaces(value);
		const phoneNumberRuleResult = this.phoneNumberRule(preSanitizedPhoneNumber, this.phoneCountryCode);
		this.sanitizedPhoneNumber = phoneNumberRuleResult.phoneNumber;
		return phoneNumberRuleResult.result;
	}

	public validateFirstNameRule(value: string): true | string {
		this.sanitizedFirstName = UserUtils.removeUnnecessarySpaces(value);
		return this.firstNameRule(this.sanitizedFirstName);
	}

	public validateMiddleNameRule(value: string): true | string {
		this.sanitizedMiddleName = UserUtils.removeUnnecessarySpaces(value);
		return this.middleNameRule(this.sanitizedMiddleName);
	}

	public validateLastNameRule(value: string): true | string {
		this.sanitizedLastName = UserUtils.removeUnnecessarySpaces(value);
		return this.lastNameRule(this.sanitizedLastName);
	}

	public validateCompanyRule(value: string): true | string {
		this.sanitizedCompany = UserUtils.removeUnnecessarySpaces(value);
		return this.companyRule(this.sanitizedCompany);
	}

	public validateCityRule(value: string): true | string {
		this.sanitizedCity = UserUtils.removeUnnecessarySpaces(value);
		return this.cityRule(this.sanitizedCity);
	}

	// Check if the user has enabled MFA.
	public async checkUserEnabledMFA() {
		try {
			const result = await this.$tsStore.settings.hasUserEnabledMFA();
			this.hasUserEnabledMFA = result.MFAEnabled;
			this.enableMFA = result.MFAEnabled;
		} catch (error) {
			console.error(error);
			this.hasUserEnabledMFA = false;
			this.enableMFA = false;
		}
	}

	/**
	 * Assigns the entered new email address to the newEmail property and validates it.
	 * @author OK
	 */
	public validateEmail(value: string): true | string {
		this.newEmail = UserUtils.removeUnnecessarySpaces(value);
		return this.emailRule(this.newEmail);
	}

	/**
	 * Handler for the button to open the dialog to enter a new email address.
	 * @author OK
	 */
	public async onBtnChangeEmail() {
		if (this.isUserRegisteredWithSSO) {
			return; // Not allowed, since the process is unclear.
		}

		const info = await this.$tsStore.users.readTokenInfo();
		$assert.Numbers([info.MaxAuthAgeSensitiveOps, info.AuthAge]);
		const secondsLeft = info.MaxAuthAgeSensitiveOps - info.AuthAge;
		// Allow the user at least 3 minutes to enter the new email address.
		// With less time left, the initEmailChange() call might fail if the user types too slowly.
		if (secondsLeft < 180) {
			setChangeEmailNext();
			await redirectAfterAuthenticationError(true);
		} else {
			this.showEmailDialog = true;
			this.newEmail = this.email;
			// Before the time is up, we auto-close the email dialog; the user can try to open it again,
			// and will be redirected to the login page then.
			// Added just in case that a user opens the dialog and keeps it open for a long time.
			setTimeout(() => {
				if (this.showEmailDialog) {
					this.showEmailDialog = false;
					this.$faroComponents.$emit('show-error', { title: 'LP_EMAIL_CHANGE', message: 'UI_TRY_AGAIN' });
				}
			}, 1000 * (secondsLeft - 1));
		}
	}

	/**
	 * Handler for the button to submit the entered new email address.
	 * Calls AuthZ to init the email change process.
	 * @author OK
	 */
	public async onSubmitNewEmail() {
		this.showEmailDialog = false;

		if (this.newEmail.toLowerCase() === this.email.toLowerCase()) {
			return;
		}

		try {
			// throws HttpError
			await this.$tsStore.users.initEmailChange(this.newEmail);

			const text = this.$tc('LP_EMAIL_CHECK_INBOX_1') + '\n\n' +
				this.$tc('LP_EMAIL_CHECK_INBOX_2', undefined, { email: this.newEmail }) + '\n\n' +
				this.$tc('LP_EMAIL_CHECK_INBOX_3');
			this.$faroNotify.showConfirmationDialog({
				text,
				title: this.$tc('LP_EMAIL_CHANGE'),
				group: 'info',
				persistent: false,
				rightButton: {
					color: 'info',
					text: this.$tc('UI_OK'),
				},
			});
		} catch (error) {
			const e = error as HttpError;
			if (e.status === 409) {
				this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_EMAIL_IN_USE' });
			} else if ((e.name === 'AuthenticationError' || e.status === 401) && e.responseBody?.type === 'LoginRequired') {
				// If the user tries again, then onBtnChangeEmail() will detect that a login is required.
				// This avoids that we need to add special logic here (e.g. countdown before redirecting to login).
				this.$faroComponents.$emit('show-error', { error, title: 'LP_EMAIL_CHANGE', message: 'UI_TRY_AGAIN' });
			} else {
				this.$faroComponents.$emit('show-error', { error });
			}
		}
	}

	public async created() {
		this.isUserRegisteredWithSSO = await this.$tsStore.users.isUserRegisteredWithSSO();

		this.showMFA = await isFeatureEnabledByAuth0Token('mfa');
		if (this.showMFA) {
			// The check is done in the background; the MFA on/off slider is disabled in the meantime.
			setTimeout(async () => {
				await this.checkUserEnabledMFA();
			});
		}

		// Check if the user wanted to change his email address, but was interrupted by the login process.
		// In this case, we directly open the change-email dialog.
		if (tempState.changeEmailNext) {
			tempState.changeEmailNext = false;
			this.showEmailDialog = true;
			this.newEmail = this.email;
		}
	}

	@Watch('user.PostalCode')
	public onPostalCodeChanged(postalCode: string) {
		this.sanitizedPostalCode = UserUtils.removeUnnecessarySpaces(postalCode);
	}

	@Watch('user.Country')
	public onCountryChanged() {
		// Force update in postal code vue element by changing its key attribute
		this.postalCodeInputKey = UuidUtils.generateUuid();
	}
}
