import Vue from 'vue';
import { $assert, InternationalizationUtils } from '@faroconnect/utils';
import { faroLocalization, LanguageCode } from '@faroconnect/baseui';
import { BASE_URL, config } from '@/config';
import { AuthzType } from '@faroconnect/authz-client';

export function getBrowserLanguage(): InternationalizationUtils.Language | null {
	try {
		const language = navigator?.language?.replace('-', '_').split('_')[0];
		switch (language) {
			case 'es': case 'mx':
				return 'es_MX';
			case 'pt': case 'br':
				return 'pt_BR';
			case 'de':
				return 'de_DE';
			case 'fr':
				return 'fr_FR';
			case 'it':
				return 'it_IT';
			case 'ko': case 'kr':
				return 'ko_KR';
			case 'zh': case 'cn':
				return 'zh_CN';
			case 'ja': case 'jp':
				return 'ja_JP';
			case 'en': case 'us':
				return 'en_US';
			default:
				return null;
		}
	} catch (error) {
		return null;
	}
}

export function setLanguageLikeBrowser() {
	try {
		const language = getBrowserLanguage();
		if (language) {
			void faroLocalization.setLanguage(language as LanguageCode);
		}
	} catch (error) {
		console.error('Could not set the browser\'s language');
		console.error(error);
	}
}

/**
 * Get corresponding Sphere XG URL for current URL.
 * @param region
 * @param path [optional] Vue router path, e.g. '/projects/{uuid}'. Used to get reactive UI updates.
 */
export function getXgRedirectUrl(region: AuthzType.WebshareRegion | '', path?: string): string | null {
	const endpoint = (region && config.redirectEndpoint[region]) || config.redirectEndpoint['us'];
	if (!region || !config.redirectEndpoint[region]) {
		$assert.Assert(false, 'No redirect endpoint found for workspace region ' + region);
	}
	if (!endpoint) {
		$assert.Assert(false, 'No redirect endpoint found at all!');
		return null;
	}

	const vue: Vue = Vue.prototype;
	const url = new URL(endpoint);
	let href = path ? (window.location.origin + BASE_URL + path) : window.location.href;
	if (config.env === 'dev') {
		// Make redirect work for "npm run sphere-aws-dev".
		href = href.replace('http://localhost:4804/', 'https://www.dev.farosphere.com/');
	}
	url.searchParams.append('sourceUrl', href);
	const decodedToken = vue.$tsStore.users.decodedToken;
	const email = vue.$auth.user?.email || decodedToken['https://farosphere.com/email'];
	if (email) {
		url.searchParams.append('email', email);
	}
	return url.toString();
}

/**
 * Redirect from the current URL to the corresponding Sphere XG URL.
 * @param region
 */
export function redirectToXg(region: AuthzType.WebshareRegion | ''): void {
	const url = getXgRedirectUrl(region);
	if (url) {
		window.location.href = url;
	}
}

/**
 * Asks the browser to download the given contents as a file with the given filename and MIME type.
 * @param contents
 * @param filename
 * @param mimeType
 */
export function downloadBufferAsFile(contents: string|ArrayBuffer|Uint8Array, filename: string, mimeType: string): void {
	const blob = new Blob([contents], { type: mimeType });
	const link = document.createElement('a');

	link.download = filename;
	link.href = window.URL.createObjectURL(blob);
	link.dataset.downloadurl = [mimeType, link.download, link.href].join(':');

	const evt = new MouseEvent('click', {
		view: window,
		bubbles: true,
		cancelable: true,
	});

	link.dispatchEvent(evt);
	link.remove();
}
