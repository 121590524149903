import { IService, IGradientBackground, IMeshBackground } from '@/definitions/interfaces';
import { Service } from '@/classes/Service';

export class ServiceFaroNow extends Service {
	// Boilerplate
	public static forRequest(json: Partial<IService> & IGradientBackground & IMeshBackground) {
		return new ServiceFaroNow(json);
	}

	public readonly isServiceFaroNow: boolean = true;

	public Class: string = 'ServiceFaroNow';

	public get LocalizedUrl(): string {
		if (!this.user) {
			return this.Url;
		}

		switch (this.user.Language) {
			case 'de_DE':
				return 'https://de-knowledge.faro.com/Submit_Case';
			case 'es_MX':
				return 'https://es-knowledge.faro.com/Submit_Case';
			case 'fr_FR':
				return 'https://fr-knowledge.faro.com/Submit_Case';
			case 'it_IT':
				return 'https://it-knowledge.faro.com/Submit_Case';
			case 'ja_JP':
				return 'https://ja-knowledge.faro.com/Submit_Case';
			case 'pt_BR':
				return 'https://pt-knowledge.faro.com/Submit_Case';
			case 'zh_CN':
				return 'https://zh-knowledge.faro.com/Submit_Case';
			// Support page does not exist in Korean.
			case 'en_US':
			default:
				return 'https://knowledge.faro.com/Submit_Case';
		}
	}
}
