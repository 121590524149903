
import Vue from 'vue';
import Component from 'vue-class-component';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import { UserBtnTaskTopRightComponentData } from '@/utils/types';
import { AuthzInterface } from '@faroconnect/authz-client';
import { queryToString } from '@/utils/validate';
import { PendingTask } from '@/utils/PendingTask';
import { BASE_URL } from '@/config';
import { setLanguageLikeBrowser } from '@/utils/browser';

@Component
export default class ComplianceCheckTask extends Vue {
	public readonly SUPPORT_TEAM_EMAIL = 'support@faro.com';
	public readonly lpUrl = window.location.origin;
	public topRightComponent = UserBtn;
	public auth0Domain: string = '';
	public auth0State: string = '';
	public email: string | null = null;
	public token: string | null = null;

	public get registrationFailed(): boolean {
		return this.$route.name === 'FailedValidationPage';
	}

	public get registrationPending(): boolean {
		return this.$route.name === 'PendingValidationPage';
	}

	public get topRightComponentData(): UserBtnTaskTopRightComponentData {
		const userQuery = this.$route.query as unknown as AuthzInterface.IUser;
		const firstName = queryToString(userQuery?.FirstName ?? '');
		const lastName = queryToString(userQuery?.LastName ?? '');

		return {
			isFullscreenTask: true,
			mockState: {
				userEmail: this.email ?? '',
				greetingName: firstName + ' ' + lastName,
				isAuthenticated: true,
			},
		};
	}

	/**
	 * Returns the translations for the text:
	 * Try {login} again or check your email in the next hour to see if you have received an e-mail
	 * notifying any problems that were encountered while validating your account.
	 * It will split the text before and after the placeholder {login}.
	 * Example: ["Try", "again or check your email in the next hour to see if..."]
	 */
	public get tryLoggingInAgainLaterText(): string[] {
		const placeholder = 'XXXXX';
		// Add XXXXX as placeholder to be replaced with the text for logging in.
		const text = this.$tc('LP_REGISTATION_SUBMITED_DESC_5', undefined, {login: placeholder}).split(placeholder);
		// If the length is two it means that it is in the form ['Try', 'again or check...']
		if (text.length === 2) {
			return text;
		} else {
			return [this.$tc('LP_REGISTATION_SUBMITED_DESC_5'), ''];
		}
	}

	public logout() {
		this.$faroLoading.start();
		this.$auth.logout({returnTo: window.location.origin + BASE_URL});
	}

	public async redirectIfUserPassedCompliaceCheck() {
		const pendingTask = new PendingTask(this, {
			email: this.email,
			token: this.token,
			auth0Domain: this.auth0Domain,
			auth0State: this.auth0State,
			method: 'complianceCheckApproved',
			query: this.$route.query,
		});
		return pendingTask.redirectIfNoLongerPending();
	}

	public async mounted() {
		this.auth0Domain = queryToString(this.$route.query.auth0_domain);
		this.auth0State = queryToString(this.$route.query.state);
		this.token = queryToString(this.$route.query.Token);
		this.email = queryToString(this.$route.query.Email);

		setLanguageLikeBrowser();

		setTimeout(() => {
			// Do this in the background and ignore the error.
			// See TODO in leaveIfComplianceCheckFailed in UpdateOrCreateWorkspaceTask
			this.$faroTaskService.closeFullscreenTask();
		});

		await this.redirectIfUserPassedCompliaceCheck();

		// Poll every minute for changes in the compliance check
		setInterval(async () => {
			await this.redirectIfUserPassedCompliaceCheck();
		}, 60000);

		// We need to fake that this page is authenticated, because even though it looks that the user is authenticated,
		// in reality the login flow was interrupted by Auth0.
		localStorage.setItem('sphere-is-authenticated', 'true');
	}
}
