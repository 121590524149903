import { IWorkspace } from '@/classes/authz/Workspace';
import { IProject } from '@/classes/authz/Project';

/**
 * Faro Demo Workspace to display in the workspaces page.
 * It will be harcoded using this object information since it exists only in the PRD database.
 * Region is set as 'us' since WebShare DEV currently runs in the US only.
 */
const FARO_DEMO_WORKSPACE_UUID: string = 'd039dab0-f4aa-4d90-8847-999c6e9bbc5f';

const FARO_DEMO_WORKSPACE: Partial<IWorkspace> = {
	Class: 'Workspace',
	WebshareDomainName: 'faro-demo-workspace',
	Name: 'faro-demo-workspace',
	Description: 'Explore our demo workspace, a comprehensive real-world showcase of a project with 3D scans and CAD data.',
	Version: 1,
	WebshareRegion: 'us',
	CreationDate: '2022-04-12T06:43:11.000Z',
	UpdateDate: '2022-04-12T07:04:25.000Z',
	UUID: FARO_DEMO_WORKSPACE_UUID,
	IsDemo: true,
};

const FARO_DEMO_WORKSPACE_WEBSHARE_URL: string = 'https://faro-demo-workspace.websharecloud.com';

const FARO_DEMO_WORKSPACE_WEBSHARE_HOST_NAME: string = 'websharecloud.com';

/**
 * Demo project that belongs to the Faro Demo Workspace.
 * It will be harcoded using this object information since it exists only in the PRD database.
 */
const FARO_DEMO_PROJECT_UUID: string = 'd573bdce-d4bd-4dca-b465-e32e6404b6b9';

const FARO_DEMO_PROJECT: Partial<IProject> = {
	Class: 'Project',
	UUID: FARO_DEMO_PROJECT_UUID,
	Version: 1,
	Workspace: FARO_DEMO_WORKSPACE_UUID,
	Name: 'FARO EUROPE Office Demo',
	WebshareUrlId: 'faro-europe-office-demo',
	PreviewImage: 'project.jpg',
	Description: 'Scans at the FARO office in Stuttgart including a conceptual BIM model.',
	Meta: null,
	Type: 'WebShare',
	CreationDate: '2022-04-12T07:04:25.000Z',
	UpdateDate: '2022-04-12T07:04:25.000Z',
	IsDemo: true,
	Domain: 'faro-demo-workspace',
	DomainUrl: FARO_DEMO_WORKSPACE_WEBSHARE_URL,
};

export const demoWorkspace = {
	uuid: FARO_DEMO_WORKSPACE_UUID,
	workspace: FARO_DEMO_WORKSPACE,
	webshareUrl: FARO_DEMO_WORKSPACE_WEBSHARE_URL,
	webshareHostname: FARO_DEMO_WORKSPACE_WEBSHARE_HOST_NAME,
};

export const demoProject = {
	uuid: FARO_DEMO_PROJECT_UUID,
	project: FARO_DEMO_PROJECT,
};


