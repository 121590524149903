
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ShareWorkspaceTaskInitData, ShareWorkspaceTaskOkPayload } from '@faroconnect/baseui/public/export/ShareWorkspaceTask/ShareWorkspaceTask';
import ShareWorkspaceTaskBase from '@faroconnect/baseui/public/export/ShareWorkspaceTask/ShareWorkspaceTaskBase.v2.vue';
import { Group } from '@/classes/authz/Group';
import { IWorkspace } from '@/classes/authz/Workspace';
import { WorkspaceRole } from '@/classes/authz/WorkspaceRole';

export { ShareWorkspaceTaskInitData };

@Component({
	components: {
		ShareWorkspaceTaskBase,
	},
})
export default class ShareWorkspaceTask extends Vue {
	@Prop(Object) public readonly initData!: ShareWorkspaceTaskInitData;

	public loading: boolean = true;

	public get workspace(): IWorkspace | null {
		return this.$tsStore.workspaces.ItemsMap[this.initData.workspaceUuid] ?? null;
	}

	public get workspaceName(): string {
		return this.workspace?.Name ?? '';
	}

	public get workspaceRoles(): WorkspaceRole[] {
		return this.$tsStore.workspaceRoles.workspaceRoles[this.initData.workspaceUuid] ?? [];
	}

	public get groups(): Group[] {
		return (this.$tsStore.groups.groups[this.initData.workspaceUuid] ?? [])
			.map((group) => {
				for (const projectUuid of Object.keys(group.ProjectRoleUuidsMap)) {
					group.ProjectRolesMap[projectUuid] =  group.ProjectRoleUuidsMap[projectUuid]
						.map((projectRoleUuid) => this.$tsStore.projectRoles.ItemsMap[projectRoleUuid]);
				}
				return group;
			});
	}

	public async ok(payload: ShareWorkspaceTaskOkPayload) {
		this.loading = true;

		try {
			await this.$tsStore.workspaces.inviteUsers({
				workspaceUuid: payload.workspaceUuid,
				data: {
					emails: payload.emails,
					workspaceRoleUuids: payload.workspaceRoleUuids,
					groupUuids: payload.groupUuids,
				},
			});
		} catch (error) {
			// TODO: The API returns a ConflictError with "Number of users with advanced workspace role exceeds limit!",
			//       but we can't access the message here. We could check error.status === 409, but this might include other errors.
			//       -> Not easily possible to show a specialized error message.
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_INVITE_USERS' });
		} finally {
			this.$faroTaskService.closeFullscreenTask();
		}
	}

	public async mounted() {
		try {
			await this.$tsStore.workspaceRoles.getAllFromWorkspace({
				workspaceUuid: this.initData.workspaceUuid,
				query: {
					numberofusers: true,
				},
			});
		} catch (error) {
			this.$faroTaskService.closeFullscreenTask();
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_WORKSPACE_ROLES' });
			return;
		}
		try {
			await this.$tsStore.groups.getAllFromWorkspace({
				workspaceUuid: this.initData.workspaceUuid,
				query: {
					projectroles: true,
					numberofusers: true,
					workspaceroleuuids: true,
				},
			});
		} catch (error) {
			this.$faroTaskService.closeFullscreenTask();
			this.$faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_GROUPS' });
			return;
		}
		this.loading = false;
	}
}
