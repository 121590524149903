import { ProjzInterface, ProjZClient } from '@faroconnect/projz-client';
import { ArrayUtils, ObjectUtils, StringUtils } from '@faroconnect/utils';
import { faroComponents, faroLocalization, faroNotify } from '@faroconnect/baseui';

import Vue from 'vue';
import { Module, Mutation, Action, RegisterOptions } from 'vuex-class-modules';

// Connection using socket-io is disabled and uninstalled for now until we can have it running properly in AWS.
// import { io, Socket } from 'socket.io-client';

import { Project } from '@/classes/authz/Project';
import { ProjectStatus } from '@/classes/projz/ProjectStatus';
import { BaseFilterModule, FilterByDateType, FilterByProjectSourceType, FilterByProjectStatusType } from '@/store/modules/BaseFilterModule';
import { PageModule } from '@/store/modules/PageModule';
import { ProjectStatusService } from '@/store/services/projz/ProjectStatusService';
import { ProjectStatusSortBy, SortItem } from '@/utils/sortitems';
import { BaseServiceAny } from '@/store/services/BaseServiceAny';
import { config } from '@/config';
import { ProjectModule } from '@/store/modules/authz/ProjectModule';

/**
 * Defines how often in ms it should poll for the project statuses list.
 * Set currently to 30 seconds, which is the same time span that WebShare uses for polling the Queue Service
 * to update the icon in the header bar.
 */
const POLL_PROJECT_STATUS_TIME = 30000;

@Module
export class ProjectStatusModule extends BaseFilterModule<ProjectStatus> {
	// ###################################### Properties ######################################

	// ###### Public ######

	public defaultSort: SortItem<ProjectStatus> = new ProjectStatusSortBy().projectName;
	public initializedConnection: boolean = false;
	public failedConnection: boolean = false;
	public notifiedFailedConnection: boolean = false;

	// ###### Protected ######

	protected readonly service = new ProjectStatusService({});

	// ###### Private ######

	// ###################################### Getters ######################################

	// ###### Public ######

	/**
	 * The server response contains the project statuses of all workspaces of the user. But we're often only
	 * interested in those of projects from the active workspace, so this getter is used in several places instead of
	 * ItemsMap/itemsList.
	 * @author OK
	 */
	protected get statusesOfActiveWorkspace(): ProjectStatus[] {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const projectsInStoreMap = $tsStore.projects.ItemsMap;
		const statuses: ProjectStatus[] = [];

		for (const status of this.itemsList) {
			if (!status.projectItem) {
				continue;
			}
			if (projectsInStoreMap[status.projectItem.UUID]) {
				statuses.push(status);
			}
		}
		return statuses;
	}

	/**
	 * Overwritten to use statusesOfActiveWorkspace getter.
	 * @author OK
	 */
	public get filteredItems(): ProjectStatus[] {
		const searchedItems = this.filterByTextItems(this.statusesOfActiveWorkspace, this.pages.searchTxt);
		const filteredItems = searchedItems.filter(this.filterItem);
		return filteredItems.sort(this.sortItems);
	}

	/**
	 * Returns true if there's at least one project status in progress,
	 * meaning that the Process attribute is less than 100%.
	 */
	public get hasRunningProjects() {
		return this.statusesOfActiveWorkspace.some((item) => item.isRunning);
	}

	public get filterByDateOptions(): Array<FilterByDateType<ProjectStatus>> {
		return [
			{
				text: faroLocalization.i18n.tc('UI_CREATION_DATE'),
				value: 'CreationDate',
			},
			{
				text: faroLocalization.i18n.tc('UI_UPDATED'),
				value: 'prettyUpdateDate',
			},
		];
	}

	/**
	 * Gets all projects from the status list.
	 * @author OK
	 */
	public get projects(): Project[] {
		const projectsMap: { [key: string]: Project } = {};
		for (const status of this.statusesOfActiveWorkspace) {
			if (status.projectItem) {
				projectsMap[status.projectItem.UUID] = status.projectItem;
			}
		}
		return Object.values(projectsMap);
	}

	/**
	 * Gets an array of project sources (project Type) for the projects that have status(es)
	 */
	public get projectSources(): string[] {
		const projectSourceTypes: string[] = [];
		this.projects.forEach((project) => ArrayUtils.pushIfMissing(projectSourceTypes, project.Type));
		return projectSourceTypes;
	}

	/**
	 * Gets an ordered array of project source options
	 */
	public get filterByProjectSourceOptions(): FilterByProjectSourceType[] {
		return this.projectSources.map((projectSourceType) => ({
			text: Project.getProjectTypeName(projectSourceType),
			value: projectSourceType,
			icon: Project.getProjectTypeIcon(projectSourceType),
		})).sort((a, b) => a.text.localeCompare(b.text));
	}

	/**
	 * Get pre-filtered status(es): filter status list by all filters except project status.
	 * Getter used to set the status options dynamically depending on the other filters
	 */
	public get preFilteredStatusList(): ProjectStatus[] {
		const preFilteredStatusList: ProjectStatus[] = [];
		this.statusesOfActiveWorkspace.forEach((status) => {
			if (this.filterItemWithoutStatus(status)) {
				preFilteredStatusList.push(status);
			}
		});
		return preFilteredStatusList;
	}

	/**
	 * Gets an ordered array of project status type options for the current filtered project sources
	 */
	public get filterByProjectStatusOptions(): FilterByProjectStatusType[] {
		const projectStatusTypesOptions: FilterByProjectStatusType[] = [];
		const preFilteredStatusTypes: FilterByProjectStatusType[] = this.preFilteredStatusList.map((status) => ({
			text: status.displayText,
			value: status.statusValueId,
			icon: status.vuetifyIcon,
			color: status.Color,
		}));

		preFilteredStatusTypes.forEach((statusType) => {
			if (!ArrayUtils.contains(projectStatusTypesOptions, statusType, 'value')) {
				projectStatusTypesOptions.push(statusType);
			}
		});

		return projectStatusTypesOptions.sort((a, b) => a.text.localeCompare(b.text));
	}

	/**
	 * Gets an ordered array of project status Id options for the current array of filtered project sources
	 */
	public get projectStatusIdList(): string[] {
		return this.filterByProjectStatusOptions.map((statusType) => statusType.value);
	}

	/**
	 * Gets an array of updated status Ids currently selected and displayed.
	 */
	public get existingFilterByProjectStatusIds(): string[] {
		return this.filterByProjectStatusIds.filter((statusId) => this.projectStatusIdList.includes(statusId));
	}

	// ###### Protected ######

	public get projectsForFilterList(): Project[] {
		return this.projects;
	}

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(
		protected pagesStore: PageModule,
		protected projectsStore: ProjectModule,
		options: RegisterOptions,
	) {
		super(pagesStore, options, ProjectStatus);
	}

	// ###################################### Actions ######################################

	// ###### Public ######

	/**
	 * Starts the connection to fetch all project statuses for the current user and to get changes in them.
	 * If it fails to connect (because it can't reach the server) it will retry indefinetely but will get the "connect_error" message.
	 * If it reaches the server but an error is sent back it will use the error message.
	 * If the token expires while the connection is opened, it will use the "authenticate" message.
	 * If the token is invalid it will use the "error" message and will close the connection.
	 */
	@Action
	public async startConnection() {
		await this.fetchProjectStatuses();
		setInterval(this.fetchProjectStatuses, POLL_PROJECT_STATUS_TIME);
	}

	/**
	 * Fetches all project statuses for the calling user, and takes care of notifying the user
	 * if there was an error while calling project service.
	 * This method already takes care of failing promises so calling it should always resolve.
	 * @returns An empty promise resolved after all project statuses were fetched.
	 */
	@Action
	public async fetchProjectStatuses() {
		try {
			const accessToken = await BaseServiceAny.getTokenSilentlyWithRedirect();
			const projzClient = new ProjZClient(config.projectServiceEnpoint, { accessToken });
			const projectStatusesResponse = await projzClient.projectstatus.readProjectStatuses();
			// ProjectStatuses should be always defined;
			if (!projectStatusesResponse || !projectStatusesResponse.ProjectStatuses) {
				throw new Error('Empty response');
			}
			this.setItems(projectStatusesResponse.ProjectStatuses.map((projectStatus) =>
				ProjectStatus.fromResponse(projectStatus as ProjzInterface.IProjectStatus)));

			// Gets all the projects that are included in the ProjectUUID from project statuses
			// but that we don't have them in the store, most likely because they were created
			// after the last getAll projects call.
			await this.fetchMissingProjects();

			this.setFailedConnection(false);
			this.notifyConnectionReestablished();
			this.setInitializedConnection(true);
		} catch (error: any) {
			let message: string | undefined;
			if (!error || !ObjectUtils.isObject(error)) {
				return;
			// Parse the error, the main two reasons that it could fail is because either
			// project service or authz backend are down.
			} else if (error.toJSON) {
				const err = error.toJSON();
				if (err.message === 'Network Error') {
					message = faroLocalization.i18n.tc('LP_PROJZ_NOT_AVAILABLE');
				}
			} else if (error.name === 'ApiError') {
				if (error.body?.originalMessage?.includes('ECONNREFUSED')) {
					message = faroLocalization.i18n.tc('LP_AUTHZ_NOT_AVAILABLE');
				}
			}

			this.setFailedConnection(true);
			this.showProjectStatusError(error, message);
		}
	}

	// ###### Protected ######

	// ###### Private ######

	@Action
	private async fetchMissingProjects() {
		const projectsMap = this.projectsStore.ItemsMap;
		// Chech if there are some statuses that its project UUID is missing in the projects store.
		const hasMissingProjects = this.statusesOfActiveWorkspace.some((projectStatus) => projectsMap[projectStatus.ProjectUUID] === undefined);

		if (hasMissingProjects) {
			try {
				await this.projectsStore.getAll();
			} catch (error) {
				faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_PROJECTS' });
			}
		}

		this.statusesOfActiveWorkspace.forEach((projectStatus) => {
			if (projectsMap[projectStatus.ProjectUUID] === undefined) {
				// Remove from the store all statuses that don't have a project on the store.
				// This should normally not happen since some lines above we got all projects from the calling user.
				this.projectsStore.removeItem(projectStatus.ProjectUUID);
			}
		});
	}

	// ###################################### Mutations ######################################

	// ###### Public ######

	/**
	 * Notifies to the user that there was an error connecting to the project statuses changes.
	 * Since the user could in theory use the landing page even if it failed, do not throw an error.
	 */
	@Mutation
	public showProjectStatusError(error: any, message?: string) {
		if (!this.notifiedFailedConnection) {
			const msgStatus = faroLocalization.i18n.tc('LP_ERR_FETCH_PROJECT_STATUS');
			const msgWithStatus = message ? (msgStatus + ' ' + message) : msgStatus;
			faroComponents.$emit('show-error', { error, message: msgWithStatus });
		}
		this.notifiedFailedConnection = true;
	}

	/**
	 * Notifies to the user that the connection to fetch the project statuses has been re-established.
	 */
	@Mutation
	public notifyConnectionReestablished() {
		if (this.notifiedFailedConnection) {
			this.showConnectionReestablishedNotification();
		}
		this.notifiedFailedConnection = false;
	}

	/**
	 * Sets in the store a variable that the user was already notified that connection failed, useful to avoid
	 * notifying the user multiple times, since there's a retry mechanism that could constantly fail.
	 * @param notifiedFailedConnection True if the user was already notified that the connection failed.
	 */
	@Mutation
	public setNotifiedFailedConnection(notifiedFailedConnection: boolean) {
		this.notifiedFailedConnection = notifiedFailedConnection;
	}

	@Mutation
	public removeAllProjectStatuses() {
		Vue.set(this, 'ItemsMap', {});
	}

	/**
	 * Stores a value that the connection was successfully initialized, meaning that the connect request
	 * succeeded and that the init message was received.
	 * @param initializedConnection True if the connection was successfully initialized.
	 */
	@Mutation
	public setInitializedConnection(initializedConnection: boolean) {
		this.initializedConnection = initializedConnection;
	}

	/**
	 * Stores a value that the connection failed to be initialized, helpful to disabled some functionality that depends on this.
	 * @param failedConnection True if the connection failed to be initialized.
	 */
	@Mutation
	public setFailedConnection(failedConnection: boolean) {
		this.failedConnection = failedConnection;
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	public showConnectionReestablishedNotification() {
		faroNotify.showSnackbar('success', faroLocalization.i18n.tc('LP_FETCH_PROJECT_STATUS_REESTABLISHED'));
	}

	/**
	 * Gets all project statuses for the current user and for a particular project.
	 * @param project The project to get all the statuses from.
	 * @returns An array containing all project statuses (unsorted) or null if either there's no connection or the user has none.
	 */
	public getProjectStatuses(project: Project): ProjectStatus[] {
		return this.itemsList.filter((item) => item.ProjectUUID === project.UUID);
	}

	/**
	 * Gets the main project status for the current user and for a particular project.
	 * So far the only way we need to know that a status is the main one is to use the newest one,
	 * but that could change in the future.
	 * @param project The project to get the status from.
	 * @returns The project statuses or null if either there's no connection or the user has none.
	 */
	public getMainProjectStatus(project: Project): ProjectStatus | null {
		const projectStatuses = this.getProjectStatuses(project);
		if (!projectStatuses || !projectStatuses.length) {
			return null;
		}
		// Sorts them by UpdateDate and returns the newest one.
		return projectStatuses.sort((a, b) => b.UpdateDate.localeCompare(a.UpdateDate))[0];
	}

	/**
	 * Get a status text given it's status option value.
	 * @param value Status option value.
	 * @returns Status text. If no status could be fetch, reutrn StatusId or null.
	 */
	public getStatusTextFromValue(value: string): string | null {
		const splitValue: string[] = StringUtils.splitTrimKeepNonEmpty(value, '__FARO__');
		if (splitValue.length !== 0) {
			return splitValue[1] ?? splitValue [0];
		} else {
			return null;
		}
	}

	// ###### Protected ######

	/**
	 * Filters a list of applications by keeping only the ones that have some attribute,
	 * that matches some search text.
	 * @param applications The original application list.
	 * @param searchTxt The search text.
	 * @returns A new filtered application list.
	 */
	protected filterByTextItems(projectStatuses: ProjectStatus[], searchTxt: string): ProjectStatus[] {
		searchTxt = searchTxt.toLowerCase();
		if (!searchTxt) {
			return projectStatuses;
		}
		return projectStatuses.filter((projectStatus) =>
			projectStatus.projectName.toLowerCase().includes(searchTxt) ||
			projectStatus.displayText.toLowerCase().includes(searchTxt),
		);
	}

	/**
	 * Compares whether the provided entity has the same project source as one of the selected ones.
	 * @param entity The entity to be evaluated.
	 * @returns True if the entity project source matches one of the selected project sources.
	 */
	protected compareFilterByProjectSource(entity: ProjectStatus): boolean {
		if (this.filterByAllProjectSources) {
			return true;
		}

		if (this.filterByProjectSource.length === 0) {
			return false;
		}
		// Compare first against project type, if there is no project then use the applicationId
		return this.filterByProjectSource.includes(entity.projectItem?.Type ?? entity.ApplicationId);
	}

	/**
	 * Compares whether the provided entity has the same project status as one of the selected ones.
	 * @param entity The entity to be evaluated.
	 * @returns True if the entity project source matches one of the selected project status(es).
	 */
	protected compareFilterByProjectStatus(entity: ProjectStatus): boolean {
		if (this.filterByAllProjectStatuses) {
			return true;
		}
		if (this.existingFilterByProjectStatusIds.length === 0) {
			return false;
		}
		return this.existingFilterByProjectStatusIds.includes(entity.statusValueId);
	}

	/**
	 * Filters a project status by all filters except by project Status. Use to dynamically get the status options
	 * @param entity Project status to be filtered
	 * @returns true is the entity passes the filters
	 */
	protected filterItemWithoutStatus(entity: ProjectStatus): boolean {
		// Project source filter
		if (!this.compareFilterByProjectSource(entity)) {
			return false;
		}

		return true;
	}

	// ###### Private ######
}
