import { ConflictError } from '@faroconnect/utils';
import { faroLocalization } from '@faroconnect/baseui';

interface HandledError {
	title: string | undefined;
	messages?: string | string[];
}

/**
 * Handles the error whenever the route inviteUsersAndGroupsToProject in authz throws a ConflictError.
 * @param conflictError The error to be handled.
 * @returns Translated strings to be shown to the user.
 */
export function getInviteUserProjectConflictErrorHandled(conflictError: ConflictError): HandledError {
	let title: string | undefined;
	let messages: string | undefined | string[];
	if (conflictError.message === 'all-users' || conflictError.message === 'all-sso-users') {
		title = faroLocalization.i18n.tc('LP_ERR_ADVANCED_ROLES_ALL_USERS');
	} else if (conflictError.message.includes('exceeds limit')) {
		title = faroLocalization.i18n.tc('LP_ERR_EXCEED_ADVANCED_PROJECT_ROLES_WORKSPACE');
		// This is not used until https://dev.azure.com/faro-connect/Authorization/_git/faro.connect.authz/pullrequest/9259 is completed.
		// const expected: number = conflictError.infos?.StillAvailable;
		// const actual: string[] = conflictError.infos?.UserUuidsToAdd;

		// messages = faroLocalization.i18n.tc('LP_EXCEED_ADVANCED_PROJECT_ROLES_WORKSPACE_DESC', undefined, {
		// 	expected: expected ?? 'X',
		// 	actual: actual?.length ?? 'X',
		// });
	}
	return { title, messages };
}
