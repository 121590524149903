
import Component from 'vue-class-component';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import {Prop} from 'vue-property-decorator';
import Vue from 'vue';
import { LpEntity } from '@/classes';
import { SortItem } from '@/utils/sortitems';

@Component
export default class SortByMenu<EntityT extends LpEntity> extends Vue {
	// Array filled with the sorting items for the current page.
	@Prop(Array) public readonly sortItems!: Array<SortItem<EntityT>>;
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: BaseFilterModule<EntityT>;

	public menuVisible: boolean = false;

	public get sortItemsSorted(): Array<SortItem<EntityT>> {
		return this.sortItems
			.map((item) => ({
				...item,
				// Assign the text first since it will be needed for sorting.
				text: item.stringId ? this.$tc(item.stringId) : (item.text || ''),
			}))
			.sort((a, b) => a.text.localeCompare(b.text));
	}

	public get sortBy(): SortItem<EntityT> {
		return this.store.SortBy;
	}

	public get sortDesc(): boolean {
		return this.store.SortDesc;
	}

	public onSortByChanged(sortBy: SortItem<EntityT>) {
		this.store.setSortBy(sortBy);
	}

	public onSortDescChanged(sortDesc: boolean) {
		this.store.setSortDesc(sortDesc);
	}

	/**
	 * Before the page is destroyed it removes all sorting.
	 * @author PS
	 */
	public beforeDestroy() {
		this.store.setSortByDefault();
	}
}
