import { AxiosError } from 'axios';
import { AuthzType } from '@faroconnect/authz-client';
import { HttpClient, RequestOptions } from '@faroconnect/clientbase';
import { $assert } from '@faroconnect/utils';
import { IMigration, MigrationStubI, ProjectExtended } from '@/classes/migrator/Migration';
import { config } from '@/config';
import { handleAuthenticationError } from '@/utils/errorhandler';
import { BaseServiceAny } from './BaseServiceAny';
import { StatsAggregatedMap, WorkspaceStatsI } from '@/classes/migrator/WorkspaceStats';

type WebshareRegion = AuthzType.WebshareRegion;

/**
 * Service to communicate with the Sphere Migrator API.
 */
export class MigratorService {
	public clients: Record<WebshareRegion, HttpClient|undefined>;

	public constructor() {
		this.clients = {
			eu: config.migratorApiEndpoints['eu'] ? new HttpClient(config.migratorApiEndpoints['eu']) : undefined,
			us: config.migratorApiEndpoints['us'] ? new HttpClient(config.migratorApiEndpoints['us']) : undefined,
		};

		for (const region of ['eu', 'us'] as WebshareRegion[]) {
			if (this.clients[region]) {
				// Not sure why "!" is required.
				this.clients[region]!.onResponse({
					onRejected: (error: AxiosError) => {
						return handleAuthenticationError(error);
					},
				});
			}
		}
	}

	/**
	 * 1) Creates a new Migration object in the Migration Service.
	 * 2) Adds a migration job to the QueueService.
	 * 3) Migration worker takes job and starts the migration.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param data Migration object stub to create. Attributes to be provided:
	 *         - Class: "Migration"
	 *         - Workspace: Workspace UUID
	 */
	public async create(region: WebshareRegion, data: Partial<IMigration>): Promise<IMigration> {
		return await this.post(region, '/migrator/v1/migration', data);
	}

	/**
	 * Gets the requested Migration object from the Migration Service.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async getSingle(region: WebshareRegion, uuid: string, emailBounces?: 'simple' | 'force'): Promise<IMigration> {
		// 'withCreatorEmail=true' tries to fetch the email address from AuthZ.
		// The option is only available when reading a single migration.
		// 'withTransferredFiles=true' fetches + stores the info about transferred files for older migrations
		// (only for unpublished, published and upgraded ones).
		let qryStr = 'withCreatorEmail=true&withTransferredFiles=true';
		if (emailBounces) {
			qryStr += `&withEmailBounces=${emailBounces}`;
		}
		return await this.get(region, `/migrator/v1/migration/${uuid}?${qryStr}`);
	}

	/**
	 * Gets all Migration objects from the Migration Service.
	 * @author OK
	 * @param region WebShare region of the server to call. Provide "all" for both regions.
	 * @param withSteps Flag to include the large Steps attribute in the returned Migration objects.
	 */
	public async getAll(region: WebshareRegion | 'all', withSteps: boolean): Promise<IMigration[]> {
		const route = '/migrator/v1/migration' + (withSteps ? '?withSteps=true' : '');
		if (region !== 'all') {
			return await this.get(region, route);
		} else {
			// On DEV, there's only a US server. On localhost, only call once.
			const resultsEU: Promise<IMigration[]> = this.clients['eu']                           ? this.get('eu', route) : Promise.resolve([]);
			const resultsUS: Promise<IMigration[]> = this.clients['us'] && config.env !== 'local' ? this.get('us', route) : Promise.resolve([]);
			return (await resultsEU).concat(await resultsUS);
		}
	}

	/**
	 * Gets migration stub objects from the Migration Service in both regions.
	 * Only finished migrations with a (StartDate | FinishDate) within the provided date range are returned.
	 * @author OK
	 * @param dateStart Start date of the migrations to retrieve. Must be a string usable by the Date constructor.
	 * @param dateEnd End date of the migrations to retrieve. Must be a string usable by the Date constructor.
	 * @param byStartDate Find migrations by StartDate instead of FinishDate.
	 */
	public async getMigrationStubs(region: WebshareRegion | 'all', dateStart: string, dateEnd: string, byStartDate: boolean): Promise<MigrationStubI[]> {
		const route = `/migrator/v1/migration/get-stubs-for-stats/${dateStart}/${dateEnd}?byStartDate=${byStartDate}`;
		if (region !== 'all') {
			return await this.get(region, route);
		} else {
			// On DEV, there's only a US server. On localhost, only call once.
			let results: MigrationStubI[] = [];
			if (this.clients['eu']) {
				const resultEU: MigrationStubI[] = await this.get('eu', route);
				results = results.concat(resultEU);
			}
			if (config.env !== 'local' && this.clients['us']) {
				const resultUS: MigrationStubI[] = await this.get('us', route);
				results = results.concat(resultUS);
			}
			return results;
		}
	}

	/**
	 * Continue waiting for the project content migration of a failed/aborted migration.
	 * @author MH
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async continueMigration(region: WebshareRegion, uuid: string): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/continue/${uuid}`);
	}

	/**
	 * Requests an update of the project migration status of a failed or aborted migration.
	 * @author MH
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async updateProjectStatus(region: WebshareRegion, uuid: string): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/update-project-status/${uuid}`);
	}

	/**
	 * Publishes the migrated HoloBuilder company for public use.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async publish(region: WebshareRegion, uuid: string): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/publish/${uuid}`);
	}

	/**
	 * Unpublishes the published HoloBuilder company to make it private again.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async unpublish(region: WebshareRegion, uuid: string): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/unpublish/${uuid}`);
	}

	/**
	 * Rejects the unpublished migration by setting its state to error.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async reject(region: WebshareRegion, uuid: string): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/reject/${uuid}`);
	}

	/**
	 * Approves the failed migration by setting its state to unpublished.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async approve(region: WebshareRegion, uuid: string): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/approve/${uuid}`);
	}

	/**
	 * Aborts a running or pending migration.
	 * @author BE
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async abort(region: WebshareRegion, uuid: string): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/abort/${uuid}`);
	}

	/**
	 * Deletes the migration and the associated HoloBuilder company.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async deleteMigration(region: WebshareRegion, uuid: string): Promise<void> {
		await this.del(region, `/migrator/v1/migration/${uuid}`);
	}

	/**
	 * Gets the presigned URL from AWS S3 for the log file from the Migration Service.
	 * @author MF
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 */
	public async getLogFileURL(region: WebshareRegion, uuid: string): Promise<{ Success: boolean, fileUrl: string }> {
		return await this.get(region, `/migrator/v1/migration/logs/${uuid}`);
	}

	/**
	 * Gets the migrations for a workspace.
	 * @author MF
	 * @param region WebShare region of the server to call.
	 * @param workspaceuuid Workspace UUID.
	 */
	public async getMigrationsForWorkspace(region: WebshareRegion, workspaceuuid: string, state?: string): Promise<IMigration[]> {
		let qryStr = '';
		if (state) {
			qryStr = `?state=${state}`;
		}
		return await this.get(region, `/migrator/v1/migration/for-workspace/${workspaceuuid}${qryStr}`);
	}

	/**
	 * Sets the read-only state of the migration's workspace.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuidWorkspace Workspace UUID.
	 * @param readOnly New read-only state.
	 */
	public async setReadOnly(region: WebshareRegion, uuidWorkspace: string, readOnly: boolean): Promise<void> {
		return await this.post(region, `/migrator/v1/migration/readonly/${uuidWorkspace}/${readOnly}`);
	}

	/**
	 * Enables or disables the XG redirect for the migration and workspace.
	 * Enabling also sets the workspace to readonly.
	 * @author MH
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 * @param xgRedirect True to enable.
	 */
	public async setXgRedirect(region: WebshareRegion, uuid: string, xgRedirect: boolean): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/xgredirect/${uuid}/${xgRedirect}`);
	}

	/**
	 * Enables or disables the auto-publish feature for the migration.
	 * Enabling it will publish the migration on success and enable the XG redirect flag.
	 * @author BE
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 * @param autoPublish True to enable.
	 */
	public async setAutoPublish(region: WebshareRegion, uuid: string, autoPublish: boolean): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/autopublish/${uuid}/${autoPublish}`);
	}

	/**
	 * Resets the WorkerLock table to its initial state.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 */
	public async resetLockTable(region: WebshareRegion): Promise<void> {
		return await this.post(region, '/migrator/v1/migration/resetLockTable');
	}

	/**
	 * Gets the projects from the Migrator that can potentially be migrated.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuidWorkspace Workspace UUID.
	 */
	public async getProjectsForMigration(region: WebshareRegion, uuidWorkspace: string): Promise<ProjectExtended[]> {
		return await this.get(region, `/migrator/v1/migration/projects/${uuidWorkspace}`);
	}

	/**
	 * Adds QA users, with a FARO email address, to a migration.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuid Migration object UUID.
	 * @param emails Email addresses.
	 */
	public async addQAUsers(region: WebshareRegion, uuid: string, emails: string[]): Promise<IMigration> {
		return await this.post(region, `/migrator/v1/migration/add-qa-users/${uuid}`, { Emails: emails });
	}

	// -------------------------------------------------------------------------------------------

	/**
	 * Gets the stats (StatsProjects and StatsAggregated) for a single workspace.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param uuidWorkspace Workspace UUID.
	 */
	public async getWorkspaceStats(region: WebshareRegion, uuidWorkspace: string): Promise<WorkspaceStatsI> {
		return await this.get(region, `/migrator/v1/workspace-stats/${uuidWorkspace}`);
	}

	/**
	 * Gets aggregated stats (StatsAggregated) for all workspaces.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 */
	public async getAggregatedStats(region: WebshareRegion): Promise<StatsAggregatedMap> {
		return await this.get(region, '/migrator/v1/workspace-stats/');
	}

	/**
	 * @returns Map from group number to list of ERP IDs.
	 */
	public async getMigrationGroups(): Promise<{ migrationGroups: Record<string, string[]> }> {
		if (config.env === 'dev' && location.hostname === 'localhost') {
			// CORS is probably not allowed for this request, so hard-code the response instead.
			console.log('Using hard-coded migration groups for "npm run start-aws-dev".');
			return {
				migrationGroups: {
					'5': ['wsc-dev-us-2'],
					'6': ['mh-tf-824', 'sph-rvvnul', 'wsc-dev-us-389', 'sph-mcxwkd'],
					'7': ['sph-oyjwzm', 'sph-gwugfd', 'sph-wnfywy'],
				},
			};
		}

		const client = new HttpClient(location.origin);
		// If the request fails, it's most likely a configuration issue. -> Don't retry too often.
		const options = { raxConfig: { retry: 1, noResponseRetries: 1 } };
		return await client.get('/home/config/migrationgroups.json', options);
	}

	// -------------------------------------------------------------------------------------------

	/**
	 * Makes a GET request to the Migration Service.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param url URL to call.
	 * @returns Response body.
	 */
	protected async get<ResponseT>(region: WebshareRegion, url: string): Promise<ResponseT> {
		$assert.fatal.Assert(this.clients[region], 'Missing or not configured region: ' + region);
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		return this.clients[region]!.get(url, { customHeaders });
	}

	/**
	 * Makes a POST request to the Migration Service.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param url URL to call.
	 * @param data Request body.
	 * @param retry True to enable retries. Disabled by default, since they can be harmful for some operations, and mask the root cause of the error.
	 * @returns Response body.
	 */
	protected async post<ResponseT>(region: WebshareRegion, url: string, data?: any, retry?: boolean): Promise<ResponseT> {
		$assert.fatal.Assert(this.clients[region], 'Missing or not configured region: ' + region);

		const customHeaders = await BaseServiceAny.getCustomHeaders();
		const options: RequestOptions = { customHeaders, data };
		if (!retry) {
			options.raxConfig = { retry: 0, noResponseRetries: 0 };
		}
		return this.clients[region]!.post(url, options);
	}

	/**
	 * Makes a PUT request to the Migration Service.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param url URL to call.
	 * @param data Request body.
	 * @returns Response body.
	 */
	// protected async put<ResponseT>(region: WebshareRegion, url: string, data?: any): Promise<ResponseT> {
	// 	$assert.fatal.Assert(this.clients[region], 'Missing or not configured region: ' + region);
	// 	const customHeaders = await BaseServiceAny.getCustomHeaders();
	// 	return this.clients[region]!.put(url, { customHeaders, data });
	// }

	/**
	 * Makes a DELETE request to the Migration Service.
	 * @author OK
	 * @param region WebShare region of the server to call.
	 * @param url URL to call.
	 * @param retry True to enable retries. Disabled by default, since they can be harmful for some operations, and mask the root cause of the error.
	 * @returns Response body.
	 */
	protected async del<ResponseT>(region: WebshareRegion, url: string, retry?: boolean): Promise<ResponseT> {
		$assert.fatal.Assert(this.clients[region], 'Missing or not configured region: ' + region);
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		const options: RequestOptions = { customHeaders };
		if (!retry) {
			options.raxConfig = { retry: 0, noResponseRetries: 0 };
		}
		return this.clients[region]!.del(url, options);
	}
}
