import Vue from 'vue';
import { Module, Action, Mutation, RegisterOptions } from 'vuex-class-modules';
import { faroComponents, faroLocalization, faroNotify } from '@faroconnect/baseui';
import { AuthzConstant } from '@faroconnect/authz-client';
import { ProjectService, GetAllProjectsQuery, AssignProjectRolesUsersAndGroupsQuery } from '@/store/services/authz/AuthzProjectService';
import { DataHubService } from '@/store/services/DataHubService';
import { UtilsProjectService } from '@/store/services/UtilsProjectService';
import { IProject, Project } from '@/classes/authz/Project';
import { FilterByDateType } from '@/store/modules/BaseFilterModule';
import { PageModule } from '@/store/modules/PageModule';
import { InviteUsersAndGroupsToProjectAndWorkspaceBody, IProjectVisitString, IWebShareProject } from '@/definitions/interfaces';
import { InterfaceOf } from '@/classes';
import { ApplicationWebShare } from '@/classes/ApplicationWebShare';
import { ProjectBaseModule } from './ProjectBaseModule';
import { WebshareService } from '@/store/services/WebshareService';
import { webShareUrl } from '@/utils/route';
import { webShareLoginAndRedirect } from '@/utils/webshare';
import { demoProject } from '@/definitions-frontend/demo-workspace';

/**
 * Interceptor for projects.
 * This will be called after a getAll request before creating the instances to be added to the store.
 * If attributes inside the entity should be added to another store, this function will take care of it.
 * @param projects The projects response from the server.
 * @param lastVisits [Optional] Prefetched data from $tsStore.projects.getAllLastVisited().
 */
async function projectsInterceptor(
	projects: Array<InterfaceOf<Project>>,
	lastVisits?: IProjectVisitString[],
): Promise<void> {
	const $tsStore: $tsStore = Vue.prototype.$tsStore;

	// Projects are stored in AuthZ and the LastVisitDate in LP.
	// Fetch last visits if not provided.
	// This allows the caller to prefetch the last-visits data, making requests in parallel.
	if (!lastVisits) {
		try {
			lastVisits = await $tsStore.projects.getAllLastVisited();
		} catch (error) {
			console.error(error);
			lastVisits = [];
		}
	}

	// Create a Map out of the lastVisits array so we can get the items with O(1) complexity.
	const lastVisitsMap: Map<string, IProjectVisitString> = new Map();
	lastVisits.forEach((lastVisit) => lastVisitsMap.set(lastVisit.ProjectUuid, lastVisit));

	for (const project of projects) {
		const foundVisit = lastVisitsMap.get(project.UUID);

		project.LastVisitDate = foundVisit ? foundVisit.LastVisitDate : '';

		if ($tsStore.workspaces.ItemsMap.hasOwnProperty(project.Workspace)) {
			// We need to extract the DomainUrl from the workspace.
			const workspace = $tsStore.workspaces.ItemsMap[project.Workspace];

			// Project.Domain must not be changed to the WebshareAlias, since the WebshareAlias is not valid for DataHub API paths.
			project.Domain = workspace ? workspace.WebshareDomainName : '';

			// Unlike workspace.webShareUrl, this one doesn't have a trailing slash.
			project.DomainUrl = workspace ?
				webShareUrl(workspace.Region, workspace.WebshareAlias || workspace.WebshareDomainName) :
				'';
		}
	}
}


@Module
export class ProjectModule extends ProjectBaseModule<Project> {
	// ###################################### Properties ######################################

	// ###### Public ######

	public project: Project | null = null;

	// ###### Protected ######

	protected readonly service = new ProjectService({});
	protected readonly utilsProjectService = new UtilsProjectService({});
	protected readonly dataHubService = new DataHubService();
	protected readonly webshareService = new WebshareService();

	// ###### Private ######

	// ###################################### Getters ######################################

	// ###### Public ######

	public get filterByDateOptions(): Array<FilterByDateType<Project>> {
		return [
			{
				text: faroLocalization.i18n.tc('UI_CREATION_DATE'),
				value: 'CreationDate',
			},
			{
				text: faroLocalization.i18n.tc('LP_LAST_VISIT_DATE'),
				value: 'LastVisitDate',
			},
			{
				text: faroLocalization.i18n.tc('LP_SHARE_DATE'),
				value: 'ShareDate',
			},
		];
	}

	public get projectsForFilterList(): Project[] {
		return this.itemsList;
	}

	// ###### Protected ######

	protected get projectList(): Project[] {
		return this.itemsList;
	}

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions) {
		super(pages, options, Project);
	}

	// ###################################### Actions ######################################

	// ###### Public ######

	@Action
	public reset(): void {
		return super.reset();
	}

	/**
	 * Get's single project using workspace uuid & uuid
	 * @param workspaceUuid project workspace uuid
	 * @param uuid project uuid
	 * @author Shoaib Feda
	 */
	@Action
	public async getSingleWithWorkspaceUuid({workspaceUuid, uuid}: {workspaceUuid: string, uuid: string}) {
		const entity = await this.service.getSingleWithWorkspaceUuid(workspaceUuid, uuid);
		await projectsInterceptor([entity]);
		const newProject = Project.fromResponse(entity);
		this.updateProject({uuid, newProject});
	}

	@Action
	public async getAll() {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const workspaceUuid = $tsStore.workspaces.activeWorkspace?.UUID || '';
		if (!workspaceUuid) {
			this.setItems([]);
			return;
		}

		// Make requests in parallel, to speed things up.
		const entitiesPromise = this.utilsProjectService.getProjects<GetAllProjectsQuery>(workspaceUuid,
			{ withinviters: true, withsharedate: true });
		const lastVisitsPromise: IProjectVisitString[] = Vue.prototype.$tsStore.projects.getAllLastVisited().catch((e: any) => {
			return [];
		});

		const [entities, lastVisits] = await Promise.all([entitiesPromise, lastVisitsPromise]);

		await projectsInterceptor(entities, lastVisits);

		const projects = entities.map((entity) => this.classConstructor.fromResponse(entity));
		this.updateProjectsFullUrl(projects);
		this.setItems(projects);
	}

	@Action
	public async getLastVisited(projectUuid: string) {
		const lastVisit = await this.utilsProjectService.getLastVisited(projectUuid);
		this.setLastVisitDate(lastVisit);
		return lastVisit;
	}

	@Action
	public async getAllLastVisited(): Promise<IProjectVisitString[]> {
		const lastVisits: IProjectVisitString[] = await this.utilsProjectService.getAllLastVisited();
		lastVisits.forEach(this.setLastVisitDate);
		return lastVisits;
	}

	@Action
	public async updateLastVisited(projectUuid: string) {
		const lastVisit = await this.utilsProjectService.updateLastVisited(projectUuid);
		this.setLastVisitDate(lastVisit);
	}

	@Action
	public async removeProject(project: Project) {
		const result = await this.utilsProjectService.removeProject(project.Workspace, project);
		if (result.Success) {
			Vue.delete(this.ItemsMap, project.UUID);
		}
	}

	/**
	 * Sets the demo project as the one to be used. In particular the ItemsMap is set to have it as only member.
	 * @author OK
	 */
	@Action
	public setDemoProject(): void {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		if ($tsStore.workspaces.isDemoActive) {
			const demo = Project.forRequest(demoProject.project);
			this.updateProjectsFullUrl([demo]);
			this.setItems([demo]);
		}
	}

	/**
	 * Opens a Sphere website that shows the provided project depending on its type.
	 * Examples:
	 *  - WebShare project: Opens project details page in the WebShare UI.
	 *  - Stream project: Opens Stream folder in the DataHub UI.
	 * @param project Project that the user wants to open.
	 * @author OK
	 */
	@Action
	public async openPage(project: Project): Promise<void> {
		const url = this.getHref(project);
		if (!url) {
			faroComponents.$emit('show-error', { message: 'LP_ERR_PROJECT_TYPE' });
			return;
		}

		try {
			await this.updateLastVisited(project.UUID);
		} catch (error) {
			faroComponents.$emit('show-error', { message: 'LP_ERR_UPDATE_LAST_VISITED' });
		}

		// For WebShare and Datahub, we call the login route with the current
		// JWT and redirect to the url. This way we have the session faster.
		if (!project.IsDemo && project.Type === AuthzConstant.PROJECT_TYPE_WEBSHARE) {
			webShareLoginAndRedirect(project.DomainUrl, url);
		} else {
			window.location.href = url;
		}
	}

	/**
	 * Calls WebShare to put a job on the queue for the SCENE generator to create a SCENE project in the
	 * DataHub from the provided Stream project.
	 * For this, we first need to get the sync ID from the app key of the Stream project's main file.
	 * @param project Project Stream project for which the user wants to generate a SCENE project.
	 * @author OK
	 */
	@Action
	public async startSceneGenerator(project: Project): Promise<void> {
		let syncId: string;
		try {
			// throws DataHubError
			syncId = await this.dataHubService.getSyncId(project);
		} catch (error) {
			console.error(error);
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_SYNC_ID' });
			return;
		}

		try {
			// throws HttpError
			await this.webshareService.startSceneGenerator(project, syncId);
			faroNotify.showSnackbar('success', faroLocalization.i18n.tc('UI_SUCCESS'),
				faroLocalization.i18n.tc('LP_SCENE_GENERATOR_STARTED'));
		} catch (error) {
			console.error(error);
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_SCENE_GENERATOR' });
		}
	}

	/**
	 * Calls WebShare to update project, and in turn WebShare calls AuthZ to update it there as well.
	 * However, this requires a project with WebShare UrlId
	 * @param project Project to update
	 * @param data Data to update project with
	 * @throws {HttpError}
	 * @author Shoaib Feda
	 */
	@Action
	public async updateSingle({project, data}: {project: Project, data: object}): Promise<void> {
		try {
			// throws HttpError
			const webshareDetails = await this.webshareService.updateProject(project, data);

			// refresh project
			// throws HttpError
			await this.getSingleWithWorkspaceUuid({workspaceUuid: project.Workspace, uuid: project.UUID});

			// This is required so that we don't lose the properties from WebShare.
			this.updateProject({ uuid: project.UUID, newProject: { WebshareDetails: webshareDetails }});
		} catch (error) {
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_UPDATE_PROJECT' });
			throw error;
		}
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Mutations ######################################

	// ###### Public ######

	/**
	 * Adds a project to shared with me UUIDs list.
	 * If it didn't exist in the projects map, it will be added.
	 */
	@Mutation
	public setLastVisitDate(lastVisit: IProjectVisitString) {
		const uuid = lastVisit.ProjectUuid;
		if (this.ItemsMap[uuid]) {
			this.ItemsMap[uuid].LastVisitDate = lastVisit.LastVisitDate;
		}
	}

	@Mutation
	public setProject(project: Project) {
		this.project = project;
	}

	@Mutation
	public setProjectFullUrl(payload: {uuid: string, url: string | null}) {
		if (this.ItemsMap[payload.uuid]) {
			const attr: keyof Project = 'FullUrl';
			Vue.set(this.ItemsMap[payload.uuid], attr, payload.url);
		}
	}

	/**
	 * Replace and update project properties with new data;
	 * @param uuid The uuid of the project that should be updated.
	 * @param newProject The new project data
	 */
	@Mutation
	public updateProject({uuid, newProject}: {uuid: string, newProject: Partial<IProject>}): void {
		const project: Project = this.ItemsMap[uuid];

		if (project) {
			this.ItemsMap[uuid].updateProperties(newProject);
		} else {
			Vue.set(this.ItemsMap, uuid, newProject);
		}
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	public updateProjectsFullUrl(projects: Project[]) {
		for (const project of projects) {
			if (!project.FullUrl) {
				try {
					const fullUrl = this.getHref(project);
					this.setProjectFullUrl({uuid: project.UUID, url: fullUrl});
				} catch (error) {
					console.error(error);
				}
			}
		}
	}

	public async assignProjectRolesUsersAndGroups(workspaceUuid: string, query: AssignProjectRolesUsersAndGroupsQuery) {
		return await this.service.assignProjectRolesUsersAndGroups(workspaceUuid, query);
	}

	public async inviteUsersAndGroupsToProjectAndWorkspace(
		workspaceUuid: string,
		data: InviteUsersAndGroupsToProjectAndWorkspaceBody,
	) {
		return await this.utilsProjectService.inviteUsersAndGroupsToProjectAndWorkspace(workspaceUuid, data);
	}

	/**
	 * Get a project given its UUID.
	 * @param uuid Project UUID
	 * @returns Project object or null if there is no match
	 */
	public getProjectByUuid(uuid: string): Project | null {
		return this.projectList.find((project) => project.UUID === uuid) ?? null;
	}

	/**
	 * Get a project name given it's UUID
	 * @param uuid Project UUID
	 * @returns Project name. If no project object was found or there is no name value then return null
	 */
	public getProjectNameFromUuid(uuid: string): string | null {
		return this.projectList.find((project) => project.UUID === uuid)?.Name ?? null;
	}

	public getHref(project: Project): string {
		if (project.FullUrl) {
			// Optimization; especially avoid repeated API calls if the URL is already known.
			return project.FullUrl;
		} else if (project.Type === AuthzConstant.PROJECT_TYPE_WEBSHARE || project.Type === 'Unknown') {
			// "Unknown" project type was set in DEV environment during phase where other services didn't provide
			// the project type to AuthZ in their project creation requests.
			const url = project.DomainUrl + '/project/link/' + project.UUID + '?redirect=true';
			if (project.IsDemo) {
				// Demo projects are public, so we don't force SSO login.
				// Also we might be navigating e.g. from Sphere DEV to WebShare PROD, so the user is maybe not logged in.
				return url;
			}
			return ApplicationWebShare.makeSsoUrl(url, project.user);
		}
		// else: Don't show an error message here, since it would be shown on page load, without the user clicking on the project!
		return '';
	}

	@Action
	public async getWebshareDetails(project: Project): Promise<IWebShareProject | null> {
		// If details already setted this means we don't need to do this again.
		// Even if in future we need to set a different property from the same source,
		// This condition can still apply, means that we already fetched the info from WebShare.
		if (project.WebshareDetails) {
			// Optimization; especially avoid repeated API calls if the details are already known.
			return project.WebshareDetails;
		}
		if (project.Type !== AuthzConstant.PROJECT_TYPE_WEBSHARE) {
			return null;
		}
		try {
			const webshareDetails = await this.webshareService.getProjectDetails(project);
			if (this.ItemsMap[project.UUID]) {
				this.updateProject({ uuid: project.UUID, newProject: { WebshareDetails: webshareDetails }});
			}
			return webshareDetails;
		} catch (error) {
			// No need to show error IMO, at least for location, it's currently the only reason we call this.
			// faroComponents.$emit('show-error', { message: 'LP_ERR_UPDATE_LAST_VISITED' });
			return null;
		}
	}

	// ###### Protected ######

	// ###### Private ######
}
