import Vue from 'vue';
import { ProjzInterface, ProjzReqBody, ProjzType, ProjzValidate } from '@faroconnect/projz-client';
import { BaseEntity } from '@/classes/BaseEntity';
import { faroLocalization } from '@faroconnect/baseui';
import { AuthzInterface, AuthzConstant } from '@faroconnect/authz-client';
import { Project } from '@/classes/authz/Project';

/**
 * Project status application Id known aliases.
 * Required for matching them to project types
 * Aliases must be added to these arrays in lowercase, since the matching method compares them
 * to lowercase project types (E.g: 'webshare', 'scene', 'stream')
 */
const WEBSHARE_NAMES = ['ws', 'webshare', 'wsc', 'inc'];
const SCENE_NAMES = ['scene'];
const STREAM_NAMES = ['stream'];

export class ProjectStatus extends BaseEntity implements ProjzInterface.IProjectStatus {
	public static constructorName = ProjectStatus.name;

	public static fromResponse(json: ProjzInterface.IProjectStatus) {
		try {
			const projectStatus = ProjzValidate.validateProjectStatus(json);
			return new ProjectStatus(projectStatus);
		} catch (error) {
			console.error(error);
			return new ProjectStatus(json);
		}
	}

	public static forRequest(json: ProjzReqBody.ProjectStatusRouter.IProjectStatusReq) {
		return new ProjectStatus(json);
	}

	public readonly isProjectStatus: boolean = true;

	public Class: 'ProjectStatus' = 'ProjectStatus';
	public ProjectUUID: string;
	public WorkspaceUUID: string;
	public ApplicationId: string;
	public StatusId: string;
	public TranslationStringId?: string | null;
	public TranslationMeta?: ProjzType.JsonI<string | number> | null;
	public TranslationTable?: ProjzType.TranslationTable | null;
	public Meta?: ProjzType.JsonI | null;
	public IconName?: string | null;
	public Color?: ProjzType.StatusColor | null;
	/**
	 * Can be -1 for indefinite progress
	 * 0-100 for an specific progress
	 * null when the status is not running.
	 */
	public Progress?: number | null;

	// Inherited but unused
	public DefaultImgUrl: string = '';
	public ImgUrl: string = '';

	protected constructor(obj: Partial<ProjzInterface.IProjectStatus>) {
		super(obj);
		this.ProjectUUID = obj.ProjectUUID ?? '';
		this.WorkspaceUUID = obj.WorkspaceUUID ?? '';
		this.ApplicationId = this.setApplicationId(obj.ApplicationId ?? '');
		this.StatusId = obj.StatusId ?? '';
		this.TranslationStringId = obj.TranslationStringId ?? null;
		this.TranslationMeta = obj.TranslationMeta ?? null;
		this.TranslationTable = obj.TranslationTable ?? null;
		this.Meta = obj.Meta ?? null;
		this.IconName = obj.IconName ?? null;
		this.Color = obj.Color ?? null;
		this.Progress = obj.Progress ?? null;
	}

	/**
	 * Sets applicationId value to match project type values according to known aliases
	 * If the applicationId value is not known, just return the input parameter
	 * @param {string} applicationId E.g. 'webshare' or 'ws'
	 * @returns {string} matched project type. E.g. 'WebShare'
	 */
	public setApplicationId(applicationId: string): string {
		const applicationIdLowerCase = (applicationId ?? '').toLowerCase();
		if (WEBSHARE_NAMES.includes(applicationIdLowerCase)) {
			return AuthzConstant.PROJECT_TYPE_WEBSHARE;
		} else if (SCENE_NAMES.includes(applicationIdLowerCase)) {
			return AuthzConstant.PROJECT_TYPE_SCENE;
		} else if (STREAM_NAMES.includes(applicationIdLowerCase)) {
			return AuthzConstant.PROJECT_TYPE_STREAM;
		} else {
			return applicationId ?? '';
		}
	}

	/**
	 * Gets the text to be displayed for the status ID in the currently set language.
	 * For that, the following order is used:
	 *  1) Content of TranslationTable property in the currently set language.
	 *  2) Content of TranslationTable property in English.
	 *  3) Translated string of TranslationStringId property (also using TranslationMeta).
	 *  4) Translated string of well-known project status (also using TranslationMeta).
	 *  5) Content of StatusId property.
	 *  6) Empty string.
	 * @returns Either the translated string in the user's language, or an empty string if nothing was provided.
	 */
	public get displayText(): string {
		// First try to return the provided text
		const selectedLanguage = faroLocalization?.selectedLanguage?.id;
		if (this.TranslationTable && selectedLanguage) {
			const txt = this.TranslationTable[selectedLanguage];
			if (txt) {
				return txt;
			}
			const defaultTxt = this.TranslationTable.en_US;
			if (defaultTxt) {
				return defaultTxt;
			}
		}

		// If there was no provided text then try to translate the string ID.
		if (this.TranslationStringId) {
			return faroLocalization.i18n.tc(this.TranslationStringId, undefined, this.TranslationMeta ?? undefined);
		}

		const stringId = this.stringIdForWellKnownStatus();
		if (stringId) {
			return faroLocalization.i18n.tc(stringId, undefined, this.TranslationMeta ?? undefined);
		} else {
			// By default return an empty string.
			return this.StatusId ?? '';
		}
	}

	public get displayTextWithProgress() {
		if (typeof this.Progress === 'number' && this.Progress >= 0) {
			return `${this.displayText} ${this.Progress}%`;
		}
		return this.displayText;
	}

	public get vuetifyIcon(): string | null {
		if (!this.IconName) {
			return null;
		}

		let vuetifyIcon: string = this.IconName;
		if (!this.IconName.startsWith('$vuetify.icons')) {
			vuetifyIcon = `$vuetify.icons.${vuetifyIcon}`;
		}
		return vuetifyIcon.replace('_l.svg', '').replace('.svg', '');
	}

	/**
	 * Returns whether the status is running (progress is either between 0-100, or -1 for indefinite progress).
	 */
	public get isRunning(): boolean {
		return typeof this.Progress === 'number' && this.Progress < 100;
	}

	/**
	 * Gets a status Id with more information than the StatusId property. Used for the status filters.
	 */
	public get statusValueId(): string {
		return this.StatusId + '__FARO__' + this.displayText;
	}

	// Project Status Workspace

	public get workspace(): AuthzInterface.IWorkspace | null {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.workspaces.ItemsMap[this.WorkspaceUUID] ?? null;
	}

	// Project Status Project

	public get project(): AuthzInterface.IProject | null {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.projects.ItemsMap[this.ProjectUUID] ?? null;
	}

	public get projectName(): string {
		return this.project?.Name ?? '';
	}

	public get projectItem(): Project | null {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		return $tsStore.projects.itemsList.find((project) => project.UUID === this.ProjectUUID) ?? null;
	}

	/**
	 * Helper method for get displayText().
	 * @author OK
	 * @returns The associated translation string ID if the StatusId is a well-known one that the LandingPage
	 *          knows how to translate. E.g. WebShare_uploading -> LP_STATUS_WS_UPLOADING.
	 *          undefined if it's not a well-known project status.
	 */
	protected stringIdForWellKnownStatus(): string | undefined {
		switch (this.StatusId) {
			case 'WebShare_uploading':
				return 'LP_STATUS_WS_UPLOADING';
			case 'WebShare_draft':
				return 'LP_STATUS_WS_DRAFT';
			case 'WebShare_processing':
				return 'LP_STATUS_WS_PROCESSING';
			case 'WebShare_complete':
				return 'LP_STATUS_WS_COMPLETE';
			case 'WebShare_deleting':
				return 'LP_STATUS_WS_DELETING';
			case 'WebShare_downloading':
				return 'LP_STATUS_WS_DOWNLOADING';
			case 'WebShare_downloaded':
				return 'LP_STATUS_WS_DOWNLOADED';
			case 'WebShare_downloaderror':
				return 'LP_STATUS_WS_DOWNLOAD_ERROR';
			case 'WebShare_generating':
				return 'LP_STATUS_WS_GENERATING';
			case 'WebShare_generated':
				return 'LP_STATUS_WS_GENERATED';
			case 'WebShare_generateerror':
				return 'LP_STATUS_WS_GENERATE_ERROR';
			case 'SCENE_uploading':
				return 'LP_STATUS_SCENE_UPLOADING';
			case 'SCENE_uploaded':
				return 'LP_STATUS_SCENE_UPLOADED';
			case 'SCENE_uploaderror':
				return 'LP_STATUS_SCENE_UPLOAD_ERROR';
			case 'SCENE_downloading':
				return 'LP_STATUS_SCENE_DOWNLOADING';
			case 'SCENE_downloaded':
				return 'LP_STATUS_SCENE_DOWNLOADED';
			case 'SCENE_downloaderror':
				return 'LP_STATUS_SCENE_DOWNLOAD_ERROR';
			case 'SCENE_generating':
				return 'LP_STATUS_SCENE_GENERATING';
			case 'SCENE_generated':
				return 'LP_STATUS_SCENE_GENERATED';
			case 'SCENE_generateerror':
				return 'LP_STATUS_SCENE_GENERATE_ERROR';
			case 'Stream_uploading':
				return 'LP_STATUS_STREAM_UPLOADING';
			case 'Stream_uploaded':
				return 'LP_STATUS_STREAM_UPLOADED';
			case 'Stream_uploaderror':
				return 'LP_STATUS_STREAM_UPLOAD_ERROR';
			default:
				return undefined;
		}
	}
}
