
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';

@Component({
	components: {
		TopPageBase,
	},
})
export default class WelcomePage extends Vue {
	// Title of the header bar.
	@Prop(String) public readonly title!: string;
	// Text below the title of the header bar.
	@Prop(String) public readonly text!: string;
	// Flag whether the error should be shown
	@Prop(Boolean) public readonly error!: boolean;
	// Error title.
	@Prop(String) public readonly errorTitle!: string;
	// Error message.
	@Prop(String) public readonly errorMsg!: string;
}
