
import Vue from 'vue';
import Component from 'vue-class-component';
import { Project } from '@/classes/authz/Project';

@Component
export default class DeleteProjectConfirmation extends Vue {
	public isVisible: boolean = false;
	public project: Project | null = null;

	public onSuccess() {
		this.$faroComponents.$emit('project-delete-success', this.project?.UUID);
		this.project = null;
		this.$faroNotify.showSnackbar('success', this.$tc('UI_SUCCESS'), this.$tc('LP_SUCCESS_DELETE_PROJECT'));
	}

	public onError() {
		this.project = null;
		this.$faroComponents.$emit('show-error', { message: 'LP_UNABLE_DELETE_PROJECT' });
	}

	public async onRemoveProject() {
		if (this.project) {
			await this.$tsStore.projects.removeProject(this.project);
		} else {
			this.onError();
		}
	}

	public created() {
		this.$faroComponents.$on('show-delete-project-dialog', (project: Project) => {
			this.project = project;
			this.isVisible = true;
		});
	}
}
