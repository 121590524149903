
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Workspace } from '@/classes/authz/Workspace';

@Component
export default class InviterButton extends Vue {
	@Prop(Object) public readonly data!: Workspace;

	public get isCallingUserOwnerOfWorkspace(): boolean {
		return this.$tsStore.users.user?.UUID === this.data?.Owner;
	}

	public get email(): string {
		if (this.isCallingUserOwnerOfWorkspace && this.$tsStore.users.user?.Email) {
			return this.$tsStore.users.user?.Email;
		}
		return this.data?.Inviter?.Email || this.$tc('UI_UNKNOWN');
	}
}
