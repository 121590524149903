
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ApplicationMobile } from '@/classes/ApplicationMobile';

@Component
export default class InfoAppTask extends Vue {
	@Prop(Object) public readonly initData!: ApplicationMobile;

	protected get appName(): string {
		return this.initData.Name;
	}

	protected get appDescription(): string {
		return this.initData.Description;
	}

	protected get iosUrl(): string {
		return this.initData.IOSUrl ?? '';
	}

	protected get iosImgUrl(): string {
		return this.initData.IOSImgUrl ?? '';
	}

	protected get androidUrl(): string {
		return this.initData.AndroidUrl ?? '';
	}

	protected get androidImgUrl(): string {
		return this.initData.AndroidImgUrl ?? '';
	}

	protected get links() {
		return [
			{
				url: this.iosUrl,
				storeImg: require('@/assets/download-app-store.png'),
				qrUrl: this.iosImgUrl,
				dataCy: 'iapp_ios',
			},
			{
				url: this.androidUrl,
				storeImg: require('@/assets/download-google.png'),
				qrUrl: this.androidImgUrl,
				dataCy: 'iapp_andr',
			},
		];
	}

	protected ok() {
		this.$faroTaskService.closeTask();
	}
}
