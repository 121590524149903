import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-class-modules';
import { AuthzWorkspaceRoleService, GetPermissionsQuery } from '@/store/services/authz/AuthzWorkspaceRoleService';
import { WorkspaceRole } from '@/classes/authz/WorkspaceRole';

interface WorkspaceRoles {
	[key: string]: WorkspaceRole[];
}

@Module
export class WorkspaceRoleModule extends VuexModule {
	public workspaceRoles: WorkspaceRoles = {};

	protected readonly service = new AuthzWorkspaceRoleService({});

	@Action
	public async getAllFromWorkspace(payload: { workspaceUuid: string, query: GetPermissionsQuery }) {
		const iWorkspaceRoles = await this.service.getAllFromWorkspace(payload.workspaceUuid, payload.query);
		const workspaceRoles = iWorkspaceRoles.map((workspaceRole) => WorkspaceRole.fromResponse(workspaceRole));
		this.replaceWorkspaceRoles({ workspaceUuid: payload.workspaceUuid, workspaceRoles });
	}

	@Mutation
	public replaceWorkspaceRoles(payload: { workspaceUuid: string, workspaceRoles: WorkspaceRole[] }) {
		Vue.set(this.workspaceRoles, payload.workspaceUuid, payload.workspaceRoles);
	}
}
