
import Vue from 'vue';
import Component from 'vue-class-component';
import { Pie, Bar } from 'vue-chartjs';
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	PieController,
	Tooltip,
} from 'chart.js';
import { AuthzType } from '@faroconnect/authz-client';
import { $assert, DateUtils } from '@faroconnect/utils';
import { Workspace } from '@/classes/authz/Workspace';
import { Migration, MigrationStubI, ProjectContentState, TaskPerformanceMap } from '@/classes/migrator/Migration';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';
import { IWorkspaceSizes } from '@/definitions/interfaces';
import { getWeek } from '@/utils/date';
import { getErrorMessage } from '@/utils/errorhandler';

ChartJS.register(ArcElement, BarElement, CategoryScale, Legend, LinearScale, PieController, Tooltip);

interface StatsObj {
	Defined: boolean; // Flag if the values have been set.
	Workspaces: number;
	Total: number;
	Success: number;
	Aborted: number;
	Error: number;

	ProjectsTotal: number;
	ProjectsSuccess: number;
	ProjectsInstantSuccess: number;
	ProjectsError: number;
	TaskErrorTypes: {
		[key: string]: number;
	};

	// Estimated transferred storage size (heuristic factor).
	BytesTotal: number;
	BytesSuccess: number;
	BytesInstantSuccess: number;
	BytesAborted: number;
	BytesError: number;
	// Factor transferred bytes / WebShare bytes, only for those migrations where the storage is fully known.
	FactorTotal: number;
	FactorSuccess: number;
	FactorInstantSuccess: number;

	ScansTotal: number;
	ScansSuccess: number;
	ScansInstantSuccess: number;
	ScansAborted: number;
	ScansError: number;

	MSTotal: number;
	MSSuccess: number;
	MSInstantSuccess: number;
	MSAborted: number;
	MSError: number;

	MSTransferTotal: number;
	MSTransferSuccess: number;
	MSTransferInstantSuccess: number;
	MSTransferAborted: number;
	MSTransferError: number;

	MSProcTotal: number;
	MSProcSuccess: number;
	MSProcInstantSuccess: number;
	MSProcAborted: number;
	MSProcError: number;

	TaskPerformance: {
		[taskType: string]: {
			DurationInSecondsTotal: number;
			SucceededTotal: number;
			ExpectedTotal: number;
			DurationInSecondsSuccess: number;
			SucceededSuccess: number;
			ExpectedSuccess: number;
			DurationInSecondsInstantSuccess: number;
			SucceededInstantSuccess: number;
			ExpectedInstantSuccess: number;
			DurationInSecondsAborted: number;
			SucceededAborted: number;
			ExpectedAborted: number;
			DurationInSecondsError: number;
			SucceededError: number;
			ExpectedError: number;
		}
	};
}

interface BarAggregationResult {
	[key: string]: {
		Success: number;
		Aborted: number;
		Error: number;
		ProjectsSuccess: number;
		ProjectsError: number;
		TaskErrorTypes: {
			[key: string]: number;
		};
	}
}

interface BarChartData {
	label: string;
	data: number[];
	backgroundColor: string;
}

interface MigrationListItem {
	stub: MigrationStubI;
	workspace: Workspace;
}

// JSON object, when parsed from:
// - migration.Steps.Step7.ProjectContentResults[projectUuid].Errors
// - migrationStub.Results[projectUuid].Errors
// Possible formats:
// - { ProjectAPI: "Error message" }
// - { TaskType1: 42, TaskType2: 23, ... }
// - { TaskType1: [42, "failedJobId1", "failedJobId2" ...], TaskType2: [23, ...], ... }
type ErrorObj = Record<string, string | number | (string | number)[]>;

/**
 * The Migration Dashboard showing the migration status of all migratable workspaces in the environment.
 */
@Component({
	components: {
		TopPageBase,
		PieChart: Pie,
		BarChart: Bar,
	},
})
export default class MigrationStatsPage extends Vue {
	public error: boolean = false;
	public errorMsg: string | null = null;
	public loading: boolean = false;
	/**
	 * All migration stubs received in the getStubsInRange method which is called after selecting the date range.
	 */
	public stubs: MigrationStubI[] = [];
	/**
	 * All workspaces received from the store.
	 * Note when this.workspaces is used and when this.workspacesFiltered is used.
	 */
	public workspaces: Workspace[] = [];
	/**
	 * The workspaces filtered by user selection and date.
	 * Note when this.workspaces is used and when this.workspacesFiltered is used.
	 */
	public workspacesFiltered: Workspace[] = [];
	/**
	 * Array of workspace UUID for all selected workspaces.
	 */
	public selectedWorkspaces: string[] = [];
	/**
	 * Flag if the checkbox for "Only instantly successful Migrations" is checked.
	 */
	public onlyInstant: boolean = false;
	/**
	 * Flag if the checkbox for "Include aborted migrations" is checked.
	 */
	public inclAborted: boolean = true;
	/**
	 * Flag if the checkbox for "All Workspaces" is checked.
	 */
	public isAllWorkspacesChecked: boolean = true;
	/**
	 * The date range picker doesn't sort the two picked dates, so we have to care about that ourselves.
	 */
	public datesUnsorted: string[] = [];
	/**
	 * Search string for the workspaces list.
	 */
	public searchString: string|null = null;
	/**
	 * The content for the pie chart for migrations.
	 */
	public pieMigrations: any = { data: {} };
	/**
	 * The content for the pie chart for projects.
	 */
	public pieProjects: any = { data: {} };
	/**
	 * The content for the pie chart for project error types.
	 */
	public pieProjectErrors: any = { data: {} };
	/**
	 * The content for the stacked bar chart for migrations by week.
	 */
	public barWeeklyMigration: any = this.getEmptyBarChartObject();
	/**
	 * The content for the stacked bar chart for projects by week.
	 */
	public barWeeklyProjects: any = this.getEmptyBarChartObject();
	/**
	 * The content for the stacked bar chart for project error types by week.
	 */
	public barWeeklyProjectErrors: any = this.getEmptyBarChartObject();
	/**
	 * The content for the stacked bar chart for migrations by day.
	 */
	public barDailyMigration: any = this.getEmptyBarChartObject();
	/**
	 * The content for the stacked bar chart for projects by day.
	 */
	public barDailyProjects: any = this.getEmptyBarChartObject();
	/**
	 * The content for the stacked bar chart for project error types by day.
	 */
	public barDailyProjectErrors: any = this.getEmptyBarChartObject();
	/**
	 * Object that collects statistic data.
	 */
	public stats: StatsObj = this.getEmptyStatsObject();
	/**
	 * Values for the radio buttons of the workspace size range filter.
	 */
	public radioSize: 'size0'|'size1'|'size2'|'size3'|'size4' = 'size0';
	/**
	 * The migrations included in the result shown on the page.
	 */
	public migrationListItems: MigrationListItem[] = [];

	// ---------------------------------------------------------------------------

	/**
	 * Returns the picked ISO dates with the date range picker.
	 * Result array is either [first-date, second-date] or empty.
	 * @author OK
	 */
	public get dates(): string[] {
		if (this.datesUnsorted.length !== 2) {
			return [];
		}
		return this.datesUnsorted[0] <= this.datesUnsorted[1] ? [this.datesUnsorted[0], this.datesUnsorted[1]] :
			[this.datesUnsorted[1], this.datesUnsorted[0]];
	}

	public get todayStr(): string {
		return (new Date()).toISOString().substring(0, 10);
	}

	public get dateRangeStr(): string {
		return this.dates.length === 2 ? this.dates.join(' to ') : 'Please pick 2 dates!';
	}

	public get numberOfWorkspacesInRange(): number {
		const workspacesInRange = this.getWorkspacesOfStubs(this.stubs);
		return workspacesInRange.length;
	}

	public get allWorkspacesLabel(): string {
		return `All Workspaces (${this.numberOfWorkspacesInRange})`;
	}

	// ---------------------------------------------------------------------------

	/**
	 * @param bytes
	 * @return "0 GB" for 0 bytes. "x.x GB" for large values.
	 *         "x.xx GB" or "x.xxx GB" for small values, to avoid showing "0.0 GB" if the project has a few MB only.
	 */
	public getGB(bytes: number): string {
		if (!bytes) {
			return '0 GB';
		}
		const gb = bytes / 1073741824;
		const decimals = (gb < 0.005) ? 3 : ((gb < 0.05) ? 2 : 1);
		return gb.toFixed(decimals) + ' GB';
	}

	public getGBperMin(bytes: number, ms: number): string {
		return ((bytes / 1073741824) / (ms / 60000)).toFixed(3) + ' GB/min';
	}

	public getMBperSec(bytes: number, ms: number): string {
		return ((bytes / 1048576) / (ms / 1000)).toFixed(3) + ' MB/sec';
	}

	public getScans(scans: number): string {
		return scans + ' Scans';
	}
	public getScansPerMin(scans: number, ms: number): string {
		return (scans / (ms / 60000)).toFixed(2) + ' Scans/min';
	}

	/**
	 * Gets the duration in "hh:mm:ss" or "D days, hh:mm:ss" format.
	 * @author OK
	 */
	public getDuration(ms: number): string {
		if (typeof ms !== 'number' || !isFinite(ms)) {
			return 'n/a';
		}
		const days = Math.floor(ms / 86400000);
		const daysStr = (days > 0) ? (days > 1 ? `${days} days, ` : `${days} day, `) : '';
		const timeStr = new Date(ms).toISOString().substring(11, 19);
		return daysStr + timeStr + ' h';
	}

	public getPercent(value: number, total: number): string {
		if (!total) {
			return 'n/a';
		}
		return (100 * value / total).toFixed(1) + ' %';
	}

	/**
	 * Handler for the date range picker.
	 * @author OK
	 */
	public async onUpdateStubs(): Promise<void> {
		try {
			this.$faroLoading.start();
			await this.getStubsInRange();
			this.$faroLoading.stop();
		} catch (error) {
			console.error(error);
			this.error = true;
			this.errorMsg = getErrorMessage(error);
			this.$faroLoading.stop();
			return;
		}

		this.onUpdateFilteredWorkspaces(null);
	}

	/**
	 * Handler for the search box to update the list of shown workspaces.
	 * @author OK
	 * @param searchStringRaw If...
	 *         - string: Use the entered search term to filter the workspaces by setting this.searchString.
	 *         - null or empty string: Reset this.searchString to null since the search input field was cleared.
	 */
	public onUpdateFilteredWorkspaces(searchStringRaw: string | null, fromAllCheckbox?: boolean): void {
		const workspacesInRange = this.getWorkspacesOfStubs(this.stubs);

		if (searchStringRaw === null || searchStringRaw.trim() === '') {
			this.searchString = null;
			this.workspacesFiltered = workspacesInRange;
		} else {
			this.searchString = searchStringRaw.trim()?.toLocaleLowerCase();
			// Allow to filter by region, ignoring the name.
			if (searchStringRaw.trim() === 'EU' || searchStringRaw.trim() === 'US' || this.searchString === '[eu]' || this.searchString === '[us]') {
				this.workspacesFiltered = workspacesInRange.filter((w) => {
					return w.Region === this.searchString || `[${w.Region}]` === this.searchString;
				});
			} else {
				this.workspacesFiltered = workspacesInRange.filter((w) => {
					return ((w.Name || '') + ' ' + (w.WebshareDomainName || '') + ' ' + (w.Region || ''))
						.toLocaleLowerCase().includes(this.searchString + '');
				});
			}
		}

		if (fromAllCheckbox) {
			if (this.isAllWorkspacesChecked) {
				this.selectedWorkspaces = [];
				for (const workspace of workspacesInRange) {
					this.selectedWorkspaces.push(workspace.UUID);
				}
			} else {
				this.selectedWorkspaces = [];
			}
		} else {
			if (this.isAllWorkspacesChecked) {
				this.selectedWorkspaces = [];
				for (const workspace of workspacesInRange) {
					this.selectedWorkspaces.push(workspace.UUID);
				}
			} else {
				this.selectedWorkspaces = this.selectedWorkspaces.filter((uuid) => {
					return workspacesInRange.find((w) => w.UUID === uuid);
				});
			}
		}

		// It's confusing if the old result stays when the filters get changed.
		this.stats = this.getEmptyStatsObject();
	}

	/**
	 * Handler for the button to calculate the statistics based on the filter settings.
	 * @author OK
	 */
	public onGetStats(): void {
		// Already includes error handling.
		void this.recalculate();
	}

	/**
	 * Handler for the "All Workspaces" checkbox.
	 * @author OK
	 */
	public onSelectAllWorkspaces(): void {
		this.onUpdateFilteredWorkspaces(null, true);
	}

	/**
	 * Handler for the checkboxes of single workspaces.
	 * @author OK
	 */
	public onSelectSingleWorkspace(): void {
		this.onUpdateFilteredWorkspaces(null);
	}

	/**
	 * Handler for the button to check the automatic test workspace(s) in the checkbox list.
	 * @author OK
	 */
	public onCheckTest(): void {
		const names: string[] = ['migration-automatic-test', 'migration-automatic-test-eu', 'migration-automatic-test-us'];
		const workspacesInRange = this.getWorkspacesOfStubs(this.stubs);

		const prevNumSelected = this.selectedWorkspaces.length;
		for (const w of workspacesInRange) {
			if (!this.selectedWorkspaces.includes(w.UUID) && names.includes(w.Name)) {
				this.selectedWorkspaces.push(w.UUID);
			}
		}
		// If all matching workspaces were already selected, deselect them instead.
		if (this.selectedWorkspaces.length === prevNumSelected) {
			for (const w of workspacesInRange) {
				const idx = names.includes(w.Name) ? this.selectedWorkspaces.indexOf(w.UUID) : -1;
				if (idx >= 0) {
					// Remove the workspace from the list, in-place.
					this.selectedWorkspaces.splice(idx, 1);
				}
			}
		}
	}

	/**
	 * Handler for the button to check all EU/US workspaces in the checkbox list.
	 * @author MH
	 */
	public onCheckByRegion(region: AuthzType.WebshareRegion): void {
		const workspacesInRange = this.getWorkspacesOfStubs(this.stubs);

		const prevNumSelected = this.selectedWorkspaces.length;
		for (const w of workspacesInRange) {
			if (!this.selectedWorkspaces.includes(w.UUID) && w.Region === region) {
				this.selectedWorkspaces.push(w.UUID);
			}
		}
		// If all matching workspaces were already selected, deselect them instead.
		if (this.selectedWorkspaces.length === prevNumSelected) {
			for (const w of workspacesInRange) {
				const idx = w.Region === region ? this.selectedWorkspaces.indexOf(w.UUID) : -1;
				if (idx >= 0) {
					// Remove the workspace from the list, in-place.
					this.selectedWorkspaces.splice(idx, 1);
				}
			}
		}
	}

	public onCheckAllNone(): void {
		if (this.selectedWorkspaces.length > 0) {
			this.selectedWorkspaces.splice(0, this.selectedWorkspaces.length);
		} else {
			const workspacesInRange = this.getWorkspacesOfStubs(this.stubs);
			for (const w of workspacesInRange) {
				if (!this.selectedWorkspaces.includes(w.UUID)) {
					this.selectedWorkspaces.push(w.UUID);
				}
			}
		}
	}

	/**
	 * Main method of this page that retrieves the workspace size info and then calculates the statistic data.
	 * @author OK
	 */
	public async recalculate(): Promise<void> {
		try {
			this.$faroLoading.start();

			const stubs = this.stubs.filter((stub) => {
				return this.selectedWorkspaces.includes(stub.Workspace);
			});
			const workspacesInRange = this.getWorkspacesOfStubs(stubs);
			const workspaceSizes = await this.getWorkspaceSizes(workspacesInRange);

			this.aggregateTotal(stubs, workspacesInRange, workspaceSizes);
			this.aggregateByWeekOrDay(stubs, 'Week');
			this.aggregateByWeekOrDay(stubs, 'Day');
			this.constructInfoForMigrationList(stubs, workspacesInRange);

			this.$faroLoading.stop();
		} catch (error) {
			console.error(error);
			this.error = true;
			this.errorMsg = getErrorMessage(error);
			this.$faroLoading.stop();
			return;
		}
	}

	/**
	 * Calculates the necessary data for the pie charts for total aggregation and the performance data.
	 * @author OK
	 * @param stubs Migration stub objects as provided by the recalculate method.
	 * @param workspacesInRange Selected workspaces in the selected date range as provided by the recalculate method.
	 * @param workspaceSizes Workspace size info for the selected workspaces as provided by the recalculate method.
	 */
	protected aggregateTotal(stubs: MigrationStubI[], workspacesInRange: Workspace[], workspaceSizes: IWorkspaceSizes): void {
		// Calculate the statistics data.
		const stats: StatsObj = this.getEmptyStatsObject();
		stats.Workspaces = workspacesInRange.length;
		stats.Total = stubs.length;

		// "Ff" = "for factor". For calculating the factor, we only consider those migrations where both the WebShare storage
		// and the transferred storage are fully known.
		let bytesFfTotalWebShare = 0;
		let bytesFfTotalTransferred = 0;
		let bytesFfSuccessWebShare = 0;
		let bytesFfSuccessTransferred = 0;
		let bytesFfInstantSuccessWebShare = 0;
		let bytesFfInstantSuccessTransferred = 0;

		for (const stub of stubs) {
			stats.Success += (stub.State === 'unpublished' || stub.State === 'published') ? 1 : 0;
			stats.Aborted += stub.State === 'aborted' ? 1 : 0;
			stats.Error   += stub.State === 'error' ? 1 : 0;

			const ms = Migration.getDurationMs(stub.Start, stub.End);
			stats.MSTotal += ms;

			if (stub.State === 'unpublished' || stub.State === 'published') {
				stats.MSSuccess += ms;
				if (stub.Suc) {
					stats.MSInstantSuccess += Migration.getDurationMs(stub.Start, stub.Suc);
				}
			} else if (stub.State === 'aborted') {
				stats.MSAborted += ms;
			} else if (stub.State === 'error') {
				stats.MSError += ms;
			}

			if (stub.SizeWebShare && stub.SizeWebShareAllKnown && stub.TransferredFilesSize) {
				bytesFfTotalWebShare    += stub.SizeWebShare;
				bytesFfTotalTransferred += stub.TransferredFilesSize;
				if (stub.State === 'unpublished' || stub.State === 'published') {
					bytesFfSuccessWebShare    += stub.SizeWebShare;
					bytesFfSuccessTransferred += stub.TransferredFilesSize;
					if (stub.Suc) {
						bytesFfInstantSuccessWebShare    += stub.SizeWebShare;
						bytesFfInstantSuccessTransferred += stub.TransferredFilesSize;
					}
				}
			}

			for (const projectUuid in stub.Results) {
				const resultObj = stub.Results[projectUuid];
				const result = resultObj.Result;
				if (result === 'pending') {
					continue;
				}

				stats.MSTransferTotal += resultObj.TransDur;
				stats.MSProcTotal += resultObj.ProcDur;

				stats.ProjectsTotal   += 1;
				stats.ProjectsSuccess += result === 'complete' ? 1 : 0;
				if (stub.Suc) {
					stats.ProjectsInstantSuccess += result === 'complete' ? 1 : 0;
				}
				stats.ProjectsError   += result !== 'complete' ? 1 : 0;

				const workspaceSize = workspaceSizes[stub.Workspace];
				const projectSize = workspaceSize ? workspaceSize[projectUuid] : undefined;
				const bytes = projectSize?.Size ? projectSize.Size * Migration.SIZE_FACTOR : 0;
				const scans = projectSize?.Scans ? projectSize.Scans : 0;

				stats.BytesTotal += bytes;
				stats.ScansTotal += scans;

				if (stub.State === 'unpublished' || stub.State === 'published') {
					stats.MSTransferSuccess += resultObj.TransDur;
					stats.MSProcSuccess += resultObj.ProcDur;
					stats.BytesSuccess += bytes;
					stats.ScansSuccess += scans;
					if (stub.Suc) {
						stats.MSTransferInstantSuccess += resultObj.TransDur;
						stats.MSProcInstantSuccess += resultObj.ProcDur;
						stats.BytesInstantSuccess += bytes;
						stats.ScansInstantSuccess += scans;
					}
				} else if (stub.State === 'aborted') {
					stats.MSTransferAborted += resultObj.TransDur;
					stats.MSProcAborted += resultObj.ProcDur;
					stats.BytesAborted += bytes;
					stats.ScansAborted += scans;
				} else if (stub.State === 'error') {
					stats.MSTransferError += resultObj.TransDur;
					stats.MSProcError += resultObj.ProcDur;
					stats.BytesError += bytes;
					stats.ScansError += scans;
				}

				// START: Task performance data.
				const taskPerformance = resultObj.TaskPerf || {};
				for (const taskType in taskPerformance) {
					if (!stats.TaskPerformance[taskType]) {
						stats.TaskPerformance[taskType] = {
							SucceededTotal: 0,
							ExpectedTotal: 0,
							DurationInSecondsTotal: 0,
							DurationInSecondsSuccess: 0,
							SucceededSuccess: 0,
							ExpectedSuccess: 0,
							DurationInSecondsInstantSuccess: 0,
							SucceededInstantSuccess: 0,
							ExpectedInstantSuccess: 0,
							DurationInSecondsAborted: 0,
							SucceededAborted: 0,
							ExpectedAborted: 0,
							DurationInSecondsError: 0,
							SucceededError: 0,
							ExpectedError: 0,
						};
					}

					const duration = taskPerformance[taskType].TotalDurationInSeconds;
					// We must handle the case where the number of succeeded tasks exceeds the number of expected tasks.
					const expected = taskPerformance[taskType].Expected ?? 0;
					const succeeded = Math.min(taskPerformance[taskType].Succeeded ?? 0, expected);

					// All.
					stats.TaskPerformance[taskType].DurationInSecondsTotal += duration;
					stats.TaskPerformance[taskType].SucceededTotal += succeeded;
					stats.TaskPerformance[taskType].ExpectedTotal += expected;
					// By state.
					if (stub.State === 'unpublished' || stub.State === 'published') {
						stats.TaskPerformance[taskType].DurationInSecondsSuccess += duration;
						stats.TaskPerformance[taskType].SucceededSuccess += succeeded;
						stats.TaskPerformance[taskType].ExpectedSuccess += expected;
						if (stub.Suc) {
							stats.TaskPerformance[taskType].DurationInSecondsInstantSuccess += duration;
							stats.TaskPerformance[taskType].SucceededInstantSuccess += succeeded;
							stats.TaskPerformance[taskType].ExpectedInstantSuccess += expected;
						}
					} else if (stub.State === 'aborted') {
						stats.TaskPerformance[taskType].DurationInSecondsAborted += duration;
						stats.TaskPerformance[taskType].SucceededAborted += succeeded;
						stats.TaskPerformance[taskType].ExpectedAborted += expected;
					} else if (stub.State === 'error') {
						stats.TaskPerformance[taskType].DurationInSecondsError += duration;
						stats.TaskPerformance[taskType].SucceededError += succeeded;
						stats.TaskPerformance[taskType].ExpectedError += expected;
					}
				}
				// END: Task performance data.

				this.addTaskErrorTypes(stats.TaskErrorTypes, result, resultObj.TaskPerf, resultObj.Errors);
			}
		}

		stats.FactorTotal = bytesFfTotalWebShare > 0 ? bytesFfTotalTransferred / bytesFfTotalWebShare : 0;
		stats.FactorSuccess = bytesFfSuccessWebShare > 0 ? bytesFfSuccessTransferred / bytesFfSuccessWebShare : 0;
		stats.FactorInstantSuccess = bytesFfInstantSuccessWebShare > 0 ? bytesFfInstantSuccessTransferred / bytesFfInstantSuccessWebShare : 0;
		console.log('FactorTotal calculated from ' + this.getGB(bytesFfTotalWebShare));
		console.log('FactorSuccess calculated from ' + this.getGB(bytesFfSuccessWebShare));
		console.log('FactorInstantSuccess calculated from ' + this.getGB(bytesFfInstantSuccessWebShare));

		// Pie chart for the migration statistics.
		const labelsMigrations = (!this.onlyInstant && this.inclAborted) ?
			['Success', 'Aborted', 'Failed'] :
			['Success', 'Failed'];
		const dataMigrations = (!this.onlyInstant && this.inclAborted) ?
			[stats.Success, stats.Aborted, stats.Error] :
			[stats.Success, stats.Error];
		const colorsMigrations = (!this.onlyInstant && this.inclAborted) ?
			['rgb(92, 184, 92)', 'rgb(240, 173, 78)', 'rgb(217, 83, 79)'] :
			['rgb(92, 184, 92)', 'rgb(217, 83, 79)'];

		this.pieMigrations.data = {
			visible: !!stats.Total,
			labels: labelsMigrations,
			datasets: [{
				data: dataMigrations,
				backgroundColor: colorsMigrations,
				tooltip: {
					callbacks: {
						label: (context: any) => {
							// {chart: Chart, label: 'Success', parsed: 2714, raw: 2714, formattedValue: '2.714', ...}
							const value = context.raw;
							const percentage = (value * 100 / stats.Total).toFixed(1) + '%';
							return ` ${value} (${percentage})`;
						},
					},
				},
			}],
		};

		// Pie chart for the project statistics.
		const labelsProjects = ['Success', 'Failed'];
		const dataProjects = [stats.ProjectsSuccess, stats.ProjectsError];
		const colorsProjects = ['rgb(92, 184, 92)', 'rgb(217, 83, 79)'];

		this.pieProjects.data = {
			visible: !!stats.ProjectsTotal,
			labels: labelsProjects,
			datasets: [{
				data: dataProjects,
				backgroundColor: colorsProjects,
				tooltip: {
					callbacks: {
						label: (context: any) => {
							const value = context.raw;
							const percentage = (value * 100 / stats.ProjectsTotal).toFixed(1) + '%';
							return ` ${value} (${percentage})`;
						},
					},
				},
			}],
		};

		// Pie chart for the project error type statistics.
		const labelsProjectErrors: string[] = [];
		const dataProjectErrors: number[] = [];
		const colorsProjectErrors: string[] = this.getProjectErrorColors();

		for (const errorType in stats.TaskErrorTypes) {
			labelsProjectErrors.push(errorType);
			dataProjectErrors.push(stats.TaskErrorTypes[errorType]);
		}

		this.pieProjectErrors.data = {
			visible: !!dataProjectErrors.length,
			labels: labelsProjectErrors,
			datasets: [{
				data: dataProjectErrors,
				backgroundColor: colorsProjectErrors,
				tooltip: {
					callbacks: {
						label: (context: any) => {
							const value = context.raw;
							const percentage = (value * 100 / stats.ProjectsError).toFixed(1) + '%';
							return ` ${value} (${percentage})`;
						},
					},
				},
			}],
		};

		stats.Defined = true;
		this.stats = stats;
	}

	/**
	 * Calculates the necessary data for the stacked bar charts for weekly and daily aggregation.
	 * They differ in only a few code lines and can therefore be put into the same method.
	 * @author OK
	 * @param stubs Migration stub objects as provided by the recalculate method.
	 * @param type Aggregation type.
	 */
	protected aggregateByWeekOrDay(stubs: MigrationStubI[], type: 'Week'|'Day'): void {
		// Aggregate the migration info.
		const dateStart = new Date(this.dates[0]);
		const dateEnd = new Date(this.dates[1]);
		dateEnd.setUTCDate(dateEnd.getUTCDate() + 1); // May fail otherwise below since resultMap[periodStr] may be undefined.
		let date = new Date(dateStart);
		const labelsSet: Set<string> = new Set();
		const resultMap: BarAggregationResult = {};

		// Create the info map.
		// For the weekly aggregation it's the simplest to also iterate over the days.
		do {
			const periodStr = type === 'Week' ? getWeek(date) : date.toISOString().substring(0, 10);

			if (!resultMap[periodStr]) {
				labelsSet.add(periodStr);
				resultMap[periodStr] = {
					Success: 0,
					Aborted: 0,
					Error: 0,
					ProjectsSuccess: 0,
					ProjectsError: 0,
					TaskErrorTypes: {},
				};
			}

			date = DateUtils.addDays(1, date);
		} while (date <= dateEnd);

		const labels = Array.from(labelsSet);

		// Fill the info map with the results from the migration stubs.
		for (const stub of stubs) {
			const periodStr = type === 'Week' ? getWeek(new Date(stub.Start)) : stub.Start.substring(0, 10);

			resultMap[periodStr].Success += (stub.State === 'unpublished' || stub.State === 'published') ? 1 : 0;
			resultMap[periodStr].Aborted += stub.State === 'aborted' ? 1 : 0;
			resultMap[periodStr].Error   += stub.State === 'error' ? 1 : 0;

			for (const projectUuid in stub.Results) {
				const resultObj = stub.Results[projectUuid];
				const result = resultObj.Result;
				if (result === 'pending') {
					continue;
				}

				resultMap[periodStr].ProjectsSuccess += result === 'complete' ? 1 : 0;
				resultMap[periodStr].ProjectsError   += result !== 'complete' ? 1 : 0;

				this.addTaskErrorTypes(resultMap[periodStr].TaskErrorTypes, result, resultObj.TaskPerf, resultObj.Errors);
			}
		}

		// Bar chart for the migration statistics.
		const dataSuccess: number[] = [];
		const dataAborted: number[] = [];
		const dataError: number[] = [];

		for (const periodStr in resultMap) {
			dataSuccess.push(resultMap[periodStr].Success);
			dataAborted.push(resultMap[periodStr].Aborted);
			dataError.push(resultMap[periodStr].Error);
		}

		const datasetsMigrations: BarChartData[] = [];
		datasetsMigrations.push({
			label: 'Success',
			data: dataSuccess,
			backgroundColor: 'rgb(92, 184, 92)',
		});
		if (!this.onlyInstant && this.inclAborted) {
			datasetsMigrations.push({
				label: 'Aborted',
				data: dataAborted,
				backgroundColor: 'rgb(240, 173, 78)',
			});
		}
		datasetsMigrations.push({
			label: 'Error',
			data: dataError,
			backgroundColor: 'rgb(217, 83, 79)',
		});

		const barMigration = (type === 'Week') ? this.barWeeklyMigration : this.barDailyMigration;
		barMigration.data = {
			visible: true,
			labels,
			datasets: datasetsMigrations,
		};

		// Bar chart for the project statistics.
		const dataProjectsSuccess: number[] = [];
		const dataProjectsError: number[] = [];

		for (const periodStr in resultMap) {
			dataProjectsSuccess.push(resultMap[periodStr].ProjectsSuccess);
			dataProjectsError.push(resultMap[periodStr].ProjectsError);
		}

		const barProjects = (type === 'Week') ? this.barWeeklyProjects : this.barDailyProjects;
		barProjects.data = {
			visible: true,
			labels,
			datasets: [
				{
					label: 'Success',
					data: dataProjectsSuccess,
					backgroundColor: 'rgb(92, 184, 92)',
				},
				{
					label: 'Error',
					data: dataProjectsError,
					backgroundColor: 'rgb(217, 83, 79)',
				},
			],
		};

		// Bar chart for the project error type statistics.
		const colorsProjectErrors: string[] = this.getProjectErrorColors();
		// Index into the array of available background colors.
		let iColor = 0;

		// We need to collect the data by error type for the bar chart.
		const errorTypeSet: Set<string> = new Set();
		for (const periodStr in resultMap) {
			for (const errorType in resultMap[periodStr].TaskErrorTypes) {
				errorTypeSet.add(errorType);
			}
		}
		const errorTypes = Array.from(errorTypeSet);
		const dataByErrorType: { [key: string]: BarChartData } = {};

		for (const errorType of errorTypes) {
			if (!dataByErrorType[errorType]) {
				dataByErrorType[errorType] = {
					data: [],
					label: errorType,
					backgroundColor: colorsProjectErrors[iColor],
				};
				iColor++;
				// If we've reached the end of the color array, start with the first color again.
				if (iColor === colorsProjectErrors.length) {
					iColor = 0;
				}
			}

			for (const dateStr in resultMap) {
				dataByErrorType[errorType].data.push(resultMap[dateStr].TaskErrorTypes[errorType]);
			}
		}

		const datasetsProjectError: BarChartData[] = [];

		for (const errorType in dataByErrorType) {
			const labelProjectError = dataByErrorType[errorType].label;
			const dataProjectError = dataByErrorType[errorType].data;
			const colorProjectError = dataByErrorType[errorType].backgroundColor;

			datasetsProjectError.push({
				label: labelProjectError,
				data: dataProjectError,
				backgroundColor: colorProjectError,
			});
		}

		const barProjectErrors = (type === 'Week') ? this.barWeeklyProjectErrors : this.barDailyProjectErrors;
		barProjectErrors.data = {
			visible: !!datasetsProjectError.length,
			labels: labels,
			datasets: datasetsProjectError,
		};
	}

	/**
	 * Constructs the list that contains the migrations included in the displayed result and then stores it in the
	 * migrationListItems property.
	 * @author OK
	 * @param stubs Migration stub objects as provided by the recalculate method.
	 * @param workspaces Associated workspace objects.
	 */
	protected constructInfoForMigrationList(stubs: MigrationStubI[], workspaces: Workspace[]): void {
		const items: MigrationListItem[] = [];

		const workspaceMap: { [key: string]: Workspace } = {};
		for (const workspace of workspaces) {
			workspaceMap[workspace.UUID] = workspace;
		}

		for (const stub of stubs) {
			const workspace = workspaceMap[stub.Workspace];
			if (!workspace) {
				continue;
			}

			const item: MigrationListItem = {
				stub: stub,
				workspace: workspace,
			};
			items.push(item);
		}

		// 1. Sort by workspace name, ascending.
		// 2. Sort by start date, descending.
		items.sort((a, b) => {
			const aName = a.workspace.Name;
			const bName = b.workspace.Name;
			return (aName === bName) ? b.stub.Start.localeCompare(a.stub.Start) : aName.localeCompare(bName);
		});

		this.migrationListItems = items;
	}

	/**
	 * Returns the CSS class for the migration's state.
	 * @author OK
	 */
	public getCss(item: MigrationListItem): string {
		const state = item.stub.State;
		if (state === 'aborted') {
			return 'aborted';
		} else if (state === 'error') {
			return 'error2';
		} else if (state === 'unpublished' || state === 'published') {
			return 'complete';
		} else {
			return 'error2';
		}
	}

	/**
	 * Returns the caption for the migration's state.
	 * @author OK
	 */
	public getCaption(item: MigrationListItem): string {
		const state = item.stub.State;
		switch (state) {
			case 'aborted':
				return 'Aborted';
			case 'error':
				return 'Failed';
			case 'unpublished':
				return 'Unpublished';
			case 'published':
				return 'Published';
			default:
				return `${state}`;
		}
	}

	/**
	 * Returns the size in GB of the migration.
	 * @author OK
	 */
	public getSize(item: MigrationListItem): string {
		let result = '';
		if (typeof item.stub.TransferredFilesSize === 'number') {
			result = 'Transferred: ' + this.getGB(item.stub.TransferredFilesSize);
		}
		if (item.stub.SizeWebShare || item.stub.SizeWebShareAllKnown) {
			if (result) {
				result += ' | ';
			}
			result += item.stub.SizeWebShareAllKnown ? 'WebShare: ' : 'WebShare (incomplete): ';
			result += this.getGB(item.stub.SizeWebShare || 0);
		}
		if (typeof item.stub.TransferredFilesSize === 'number' && item.stub.SizeWebShare && item.stub.SizeWebShareAllKnown) {
			result += ' | Ratio: ' + (item.stub.TransferredFilesSize / item.stub.SizeWebShare).toFixed(2);
		}
		return result || 'Unknown size';
	}

	protected addErrorsFromTaskPerf(errorsMap: { [key: string]: number }, taskPerf: TaskPerformanceMap): boolean {
		let foundAny = false;
		for (const taskType in taskPerf) {
			const task = taskPerf[taskType];
			if (task.Succeeded >= task.Expected) {
				continue;
			}

			// For now we assume that all others are in "Failed" state; API only includes Expected + Succeeded so far.
			// It seems a reasonable assumption, since we only show stats for migrations with a FinishDate.
			errorsMap[taskType] = (errorsMap[taskType] || 0) + (task.Expected - task.Succeeded);
			foundAny = true;
		}
		return foundAny;
	}

	protected addErrorsFromString(errorsMap: { [key: string]: number }, errorStr: string): boolean {
		let foundAny = false;
		try {
			const errorObj = JSON.parse(errorStr) as ErrorObj;
			for (let taskType in errorObj) {
				if (taskType === 'ProjectAPI') {
					// Project migration failed before any tasks were started.
					if (typeof errorObj[taskType] === 'string') {
						taskType = errorObj[taskType] as string; // 'File copy or model tree creation'
					}
					errorsMap[taskType] = (errorsMap[taskType] || 0) + 1;
					foundAny = true;
				} else {
					// Task(s) failed.
					const errorInfo = errorObj[taskType];
					const errorCount = (Array.isArray(errorInfo) ? errorInfo[0] : errorInfo) as number;
					$assert.fatal.Assert(typeof errorCount === 'number' && errorCount >= 0, 'Invalid error count');
					if (errorCount > 0) {
						console.warn('Inaccurate error counting - retried tasks may appear as failed.');
						errorsMap[taskType] = (errorsMap[taskType] || 0) + errorCount;
						foundAny = true;
					}
				}
			}
		} catch (error) {
			errorsMap['FailedToParseErrors'] = (errorsMap['FailedToParseErrors'] || 0) + 1;
			foundAny = true;
		}
		return foundAny;
	}

	/**
	 * Gets the necessary data that needs to be retrieved only once after page load.
	 * Some data needs to be retrieved each time the recalculate method is called.
	 * @author OK
	 */
	protected async getData(): Promise<void> {
		this.workspaces = this.$tsStore.migrationWorkspaces.itemsList;
		// this.workspaces.length === 1 is checked because the user could have first accessed a deep link to
		// the Migration Stats page.
		if (!this.workspaces || !this.workspaces.length || this.workspaces.length === 1) {
			await this.$tsStore.migrationWorkspaces.getAll();
			this.workspaces = this.$tsStore.migrationWorkspaces.itemsList;
		}
		this.workspacesFiltered = this.workspaces.filter(() => true); // shallow clone

		this.onSelectAllWorkspaces();
	}

	/**
	 * Returns the subset of the provided migrations that fall into the selected date range.
	 * @author OK
	 */
	protected async getStubsInRange(): Promise<void> {
		if (this.dates.length !== 2) {
			this.stubs = [];
			return;
		}
		const dateStart = this.dates[0].substring(0, 10) + 'T00:00:00.000Z';
		const dateEnd = this.dates[1].substring(0, 10) + 'T23:59:59.999Z';

		let stubs = await this.$tsStore.migrations.getMigrationStubs({ dateStart, dateEnd });

		stubs = stubs.filter((stub) => {
			if (this.onlyInstant) {
				return (stub.State === 'published' || stub.State === 'unpublished') && !!stub.Suc;
			} else {
				return (this.inclAborted && stub.State === 'aborted') || stub.State === 'error' ||
					stub.State === 'published' || stub.State === 'unpublished';
			}
		});

		if (!stubs.length) {
			this.stubs = [];
			return;
		}

		const workspacesInRange = this.getWorkspacesOfStubs(stubs);
		if (!workspacesInRange.length) {
			this.stubs = [];
			return;
		}

		const workspaceSizes = await this.getWorkspaceSizes(workspacesInRange);
		const stubsToUse: MigrationStubI[] = [];
		const GB = 1073741824;

		for (const stub of stubs) {
			let sizeOfMigrationBytes = 0;

			let sizeAllKnown = true;
			for (const projectUuid in stub.Results) {
				const workspaceSize = workspaceSizes[stub.Workspace];
				const projectSize = workspaceSize ? workspaceSize[projectUuid] : undefined;
				if (typeof projectSize?.Size !== 'number') {
					console.log('Unknown WebShare size for project:', '/home/projects/' + stub.Workspace + '/' + projectUuid + '/info');
					sizeAllKnown = false;
				}
				const bytes = projectSize?.Size || 0;
				sizeOfMigrationBytes += bytes;
			}
			// Store the size to display it in the migration list.
			stub.SizeWebShare = sizeOfMigrationBytes;
			stub.SizeWebShareAllKnown = sizeAllKnown;

			// Filter by selected workspace size range.
			if (this.radioSize === 'size0') {
				stubsToUse.push(stub);
			} else if (this.radioSize === 'size1') {
				if (sizeOfMigrationBytes < 20 * GB) {
					stubsToUse.push(stub);
				}
			} else if (this.radioSize === 'size2') {
				if (20 * GB <= sizeOfMigrationBytes && sizeOfMigrationBytes < 200 * GB) {
					stubsToUse.push(stub);
				}
			} else if (this.radioSize === 'size3') {
				if (200 * GB <= sizeOfMigrationBytes && sizeOfMigrationBytes < 1024 * GB) {
					stubsToUse.push(stub);
				}
			} else if (this.radioSize === 'size4') {
				if (1024 * GB <= sizeOfMigrationBytes) {
					stubsToUse.push(stub);
				}
			}
		}

		this.stubs = stubsToUse;
	}

	/**
	 * Returns the workspaces that are part of at least one of the provided migrations.
	 * @author OK
	 * @param stubs Migration stub objects.
	 */
	protected getWorkspacesOfStubs(stubs: MigrationStubI[]): Workspace[] {
		const set = new Set<Workspace>();
		for (const stub of stubs) {
			const workspace = this.workspaces.find((workspace) => workspace.UUID === stub.Workspace);
			if (workspace) {
				set.add(workspace);
			}
		}
		const workspaces = Array.from(set);
		workspaces.sort((a: Workspace, b: Workspace) => {
			return a.Name.localeCompare(b.Name);
		});
		return workspaces;
	}

	/**
	 * Gets workspace size information for the workspaces of the provided migrations from WebShare.
	 * The backend route combines the results of both WebShare regions.
	 * @author OK
	 */
	protected async getWorkspaceSizes(workspaces: Workspace[]): Promise<IWorkspaceSizes> {
		const workspaceUuids = workspaces.map((workspace) => workspace.UUID);
		// We use the end date of the selected date range to get the storage sizes of the projects.
		// There could be differences, but it would not be feasible to use the individual dates of the migrations since
		// the storagebyresource table contains millions of entries and therefore using only one date is necessary for
		// a timely response.
		const date = new Date(this.dates[1]);

		return await this.$tsStore.workspaces.getWorkspaceSizes({ workspaceUuids, date });
	}

	/**
	 * Returns the basic statistics object.
	 * @author OK
	 */
	protected getEmptyStatsObject(): StatsObj {
		return {
			Defined: false,
			Workspaces: 0,
			Total: 0,
			Success: 0,
			Aborted: 0,
			Error: 0,

			ProjectsTotal: 0,
			ProjectsInstantSuccess: 0,
			ProjectsSuccess: 0,
			ProjectsError: 0,
			TaskErrorTypes: {},

			BytesTotal: 0,
			BytesInstantSuccess: 0,
			BytesSuccess: 0,
			BytesAborted: 0,
			BytesError: 0,
			FactorTotal: 0,
			FactorSuccess: 0,
			FactorInstantSuccess: 0,

			ScansTotal: 0,
			ScansInstantSuccess: 0,
			ScansSuccess: 0,
			ScansAborted: 0,
			ScansError: 0,

			MSTotal: 0,
			MSInstantSuccess: 0,
			MSSuccess: 0,
			MSAborted: 0,
			MSError: 0,

			MSTransferTotal: 0,
			MSTransferInstantSuccess: 0,
			MSTransferSuccess: 0,
			MSTransferAborted: 0,
			MSTransferError: 0,

			MSProcTotal: 0,
			MSProcInstantSuccess: 0,
			MSProcSuccess: 0,
			MSProcAborted: 0,
			MSProcError: 0,

			TaskPerformance: {},
		};
	}

	/**
	 * Returns the basic content object for the bar charts.
	 * @author OK
	 */
	protected getEmptyBarChartObject(): any {
		return {
			data: {},
			options: {
				responsive: true,
				scales: {
					x: { stacked: true },
					y: { stacked: true },
				},
			},
		};
	}

	/**
	 * Returns CSS background colors for the project error types.
	 * At least 10 colors are needed: 7 known error types + Unknown + dataProcessing + dataTransfer.
	 * @author OK
	 */
	protected getProjectErrorColors(): string[] {
		return [
			'rgb(91, 192, 222)',
			'rgb(243, 170, 0)',
			'rgb(1, 66, 106)',
			'rgb(0, 170, 140)',
			'rgb(141, 146, 149)',
			'rgb(94, 46, 134)',
			'rgb(255, 106, 19)',
			'rgb(226, 226, 226)',
			'rgb(160, 38, 34)',
			'rgb(85, 85, 85)',
			'rgb(180, 103, 0)',
			'rgb(34, 62, 19)',
			'rgb(177, 130, 97)',
			'rgb(92, 67, 50)',
			'rgb(145, 31, 100)',
			'rgb(223, 25, 145)',
		];
	}

	public countTaskErrors(errorTypeMap: { [key: string]: number }): number {
		let sum = 0;
		for (const taskType in errorTypeMap) {
			sum += errorTypeMap[taskType];
		}
		return sum;
	}

	/**
	 * Sets the error type info map for the aggregateTotal and aggregateByWeekOrDay methods.
	 * @author OK
	 * @param errorTypeMap Error type info map to construct.
	 * @param result The project content migration result string.
	 * @param errorStr The error string of the project content migration result object.
	 */
	protected addTaskErrorTypes(
		errorTypeMap: { [key: string]: number },
		result: ProjectContentState,
		taskPerf: TaskPerformanceMap | undefined,
		errorStr: string | null | undefined,
	): void {
		// For stalled project migrations, use the state as error type.
		if (result === 'dataProcessing' || result === 'dataTransfer') {
			const label = 'Stuck in ' + result;
			errorTypeMap[label] = (errorTypeMap[label] || 0) + 1;
		} else if (result === 'error') {
			let foundAny = taskPerf ? this.addErrorsFromTaskPerf(errorTypeMap, taskPerf) : false;
			if (!foundAny && errorStr) {
				foundAny = this.addErrorsFromString(errorTypeMap, errorStr);
			}
			if (!foundAny) {
				errorTypeMap.Unknown = (errorTypeMap.Unknown || 0) + 1;
			}
		}
	}

	public async mounted() {
		this.loading = true;
		try {
			this.$faroLoading.start();

			await this.getData();
		} catch (error) {
			console.error(error);
			this.error = true;
			this.errorMsg = getErrorMessage(error);
		} finally {
			this.$tsStore.pages.setFinishedPageLoading(true);
			if (this.$tsStore.pages.finishedMainLoading) {
				this.$faroLoading.stop();
			}
			this.loading = false;
		}
	}
}
