
import Vue from 'vue';
import Component from 'vue-class-component';
import { getSearchBarItems, SearchItem } from '@/components/AppBar/SearchBar';

@Component
export default class SearchBtn extends Vue {
	public get searchBarMobileVisible(): boolean {
		return this.$tsStore.pages.searchBarMobileVisible;
	}

	public toggleSearchBarMobileVisible() {
		this.$tsStore.pages.toggleSearchBarMobileVisible();
	}

	public get items(): SearchItem {
		return getSearchBarItems(this.$route, this.$tsStore);
	}
}
