import { render, staticRenderFns } from "./LpImg.vue?vue&type=template&id=ce0c8074&scoped=true"
import script from "./LpImg.vue?vue&type=script&lang=ts"
export * from "./LpImg.vue?vue&type=script&lang=ts"
import style0 from "./LpImg.vue?vue&type=style&index=0&id=ce0c8074&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce0c8074",
  null
  
)

export default component.exports