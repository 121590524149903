import { Module, VuexModule, Mutation, Action } from 'vuex-class-modules';
import { Resetable } from '@/store/modules/interfaces';
import HorizontalExpand from '@/components/AppBar/HorizontalExpand.vue';
import HelpBtn from '@/components/AppBar/HelpBtn.vue';
import ServicesBtn from '@/components/AppBar/ServicesBtn.vue';
import SearchBtn from '@/components/AppBar/SearchBtn.vue';
import Vue, { VueConstructor } from 'vue';
import SettingsBtn from '@/components/AppBar/SettingsBtn.vue';
import UserBtn from '@/components/AppBar/UserBtn.vue';
import LanguageDropdown from '@/components/AppBar/LanguagesDropdown.vue';

@Module
export class PageModule extends VuexModule implements Resetable {
	// ###################################### Properties ######################################

	// ###### Public ######

	public searchBarMobileVisible: boolean = true;
	public searchTxt: string = ''; // Text from the search bar that can be used to filter elements.
	// Stores the first page that was opened. It will be removed after we take action depending on the first page.
	public firstOpenedPage: string | null = null;
	public projectSourceTypes: string[] = [];
	// Stores whether the initial (main) loading of the services has finished.
	public finishedMainLoading = false;
	// Stores whether the loading that is relevant only for the opened page has finished.
	public finishedPageLoading = false;
	public isLeftNavigationDrawerMinified = false;
	// Same variable as $vuetify.breakpoint.mdAndUp. True if the screen size is considered medium or larger.
	public mdAndUp: boolean = false;

	public readonly leftNavigationDrawerUncollapsedWidth = 300;
	public readonly leftNavigationDrawerCollapsedWidth = 56;
	public readonly appbarHeight = 60;
	public readonly appbarExpandedHeight = 108;

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Getters ######################################

	// ###### Public ######

	public get appBarRightButtons(): Array<{ component: VueConstructor<Vue>, visible: boolean }> {
		return [
			{ component: HelpBtn, visible: true },
			{ component: ServicesBtn, visible: true },
			{ component: LanguageDropdown, visible: false },
			{ component: UserBtn, visible: true },
			{ component: SettingsBtn, visible: true },
		];
	}

	public get appBarRightButtonsMobile(): Array<{ component: VueConstructor<Vue>, visible: boolean }> {
		return [
			{ component: SearchBtn, visible: true },
			{ component: HorizontalExpand, visible: true },
		];
	}

	public get appbarHeightCurrent(): number {
		return this.searchBarMobileVisible ? this.appbarHeight : this.appbarExpandedHeight;
	}

	public get leftNavigationDrawerWidth(): number {
		// In smaller screens the left navigation drawer is hidden by default and even when it is shown,
		// it is overlapped on top of the screen so the content is not moved to the right.
		if (!this.mdAndUp) {
			return 0;
		}
		return this.isLeftNavigationDrawerMinified ? this.leftNavigationDrawerCollapsedWidth : this.leftNavigationDrawerUncollapsedWidth;
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Constructor ######################################

	// ###################################### Actions ######################################

	// ###### Public ######

	@Action
	/**
	 * Do something to reset this module
	 */
	public reset(): void {
		this.searchTxt = '';
	}

	/**
	 * Initializes this component by retrieving the values in the local storage.
	 */
	@Action
	public initialize(firstOpenedPage: string | null) {
		this.setFirstOpenedPage(firstOpenedPage);
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Mutations ######################################

	// ###### Public ######

	@Mutation
	public setFirstOpenedPage(firstOpenedPage: string | null) {
		this.firstOpenedPage = firstOpenedPage;
	}

	@Mutation
	public setSearchTxt(searchTxt?: string) {
		this.searchTxt = searchTxt ?? '';
	}

	@Mutation
	public setFinishedMainLoading(finishedMainLoading: boolean) {
		this.finishedMainLoading = finishedMainLoading;
	}

	@Mutation
	public setFinishedPageLoading(finishedPageLoading: boolean) {
		this.finishedPageLoading = finishedPageLoading;
	}

	@Mutation
	public toggleSearchBarMobileVisible(): void {
		this.searchBarMobileVisible = !this.searchBarMobileVisible;
	}

	@Mutation
	public setSearchBarMobileVisible(searchBarMobileVisible: boolean) {
		this.searchBarMobileVisible = searchBarMobileVisible;
	}

	@Mutation
	public setIsLeftNavigationDrawerMinified(isLeftNavigationDrawerMinified: boolean) {
		this.isLeftNavigationDrawerMinified = isLeftNavigationDrawerMinified;
	}

	@Mutation
	public setMdAndUp(mdAndUp: boolean) {
		this.mdAndUp = mdAndUp;
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	// ###### Private ######
}
