
import Vue from 'vue';
import Component from 'vue-class-component';
import { getSearchBarItems } from '@/components/AppBar/SearchBar';

@Component
export default class SearchBar extends Vue {
	public get txt() {
		return this.$tsStore.pages.searchTxt;
	}

	public set txt(txt: string) {
		this.$tsStore.pages.setSearchTxt(txt);
	}

	public get items(): { totalLength?: number, filteredLength?: number, placeholder?: string, show: boolean } {
		return getSearchBarItems(this.$route, this.$tsStore);
	}
}
