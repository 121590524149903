
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { isDateNewer } from '@/utils/date';

@Component
export default class DatePickerInput extends Vue {
	@Prop(Array) public readonly value!: [string, string?];
	@Prop(Boolean) public readonly disabled!: boolean;
	@Prop(Boolean) public readonly openOnDisabled!: boolean;

	public modalVisible: boolean = false;
	public internalValueInitial: [string, string?] = this.value;
	public internalValueStored: [string, string?] | null = null;

	public get internalValue(): [string, string?] {
		return this.internalValueStored ?? this.internalValueInitial;
	}

	public set internalValue(value: [string, string?]) {
		if (!value[1]) {
			this.internalValueStored = value;
			return;
		}
		const dateFrom = new Date(value[0]);
		const dateTo = new Date(value[1]);

		// Prevents that date range is choosed when picking an older date.
		if (isDateNewer(dateFrom, dateTo)) {
			this.internalValueStored = [value[1]];
			return;
		}

		if (value[0] === value[1]) {
			this.internalValueStored = [value[0]];
			return;
		}

		this.internalValueStored = value;
	}

	public get date(): [string, string?] {
		const date: [string, string?] = [this.value[0]];
		if (this.value[1]) {
			date.push(this.value[1]);
		}
		return date;
	}

	public get datePretty(): string {
		if (this.date[1]) {
			return `${this.date[0]} - ${this.date[1]}`;
		}
		return this.date[0];
	}

	public closeDialog() {
		this.modalVisible = false;
	}

	public onOk() {
		this.$emit('input', this.internalValue);
		this.$emit('ok');
		this.closeDialog();
	}

	public onClick() {
		if (this.disabled && this.openOnDisabled) {
			this.$emit('disabled-click');
		} else if (!this.disabled) {
			this.$emit('click');
		}
	}
}
