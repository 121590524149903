
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProjectStatusModule } from '@/store/modules/projz/ProjectStatusModule';

@Component
export default class FilterProjectMenu extends Vue {
	@Prop(Object) public readonly store!: ProjectStatusModule;

	public get filterByProjectOptions() {
		return this.store.filterByProjectOptions;
	}

	public get filterByAllProjects(): boolean {
		return this.store.filterByAllProjects;
	}

	public set filterByAllProjects(filterByAllProjects: boolean) {
		if (!filterByAllProjects) {
			this.store.setFilterByProjectUuids([]);
		}
		this.store.setFilterByAllProjects(filterByAllProjects);
	}

	public get filterByProjectUuids() {
		if (this.filterByAllProjects) {
			return this.store.projectsForFilterList.map((project) => project.UUID);
		}
		return this.store.filterByProjectUuids;
	}

	public set filterByProjectUuids(filterByProjectUuids: string[]) {
		if (filterByProjectUuids.length < this.store.projectsForFilterList.length) {
			this.filterByAllProjects = false;
		}
		if (filterByProjectUuids.length === this.filterByProjectOptions.length) {
			this.filterByAllProjects = true;
		}
		this.store.setFilterByProjectUuids(filterByProjectUuids);
	}
}
