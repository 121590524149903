
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ImageSize } from '@/utils/types';
import ComingSoonLabel from '@/components/Embeddable/ComingSoonLabel.vue';

@Component({
	components: {
		ComingSoonLabel,
	},
})
export default class GradientBackgroundImage extends Vue {
	@Prop(Boolean) public readonly listView!: boolean;
	@Prop(String) public readonly src!: string;
	@Prop(String) public readonly imageSize!: ImageSize;
	@Prop(Boolean) public readonly comingSoon!: boolean;
	@Prop(String) public readonly comingSoonSize!: 'x-small' | 'small' | 'large';
}
