import { InterfaceOf, LpEntity } from '@/classes';
import { BaseServiceAny } from './BaseServiceAny';
export { ClientOptions, ApiConfig } from './BaseServiceAny';

/**
 * Base service for CRUD operations on entities.
 */
export abstract class BaseService<EntityT extends LpEntity, InterfaceT extends InterfaceOf<EntityT> = InterfaceOf<EntityT>> extends BaseServiceAny {
	public abstract getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<InterfaceT>;
	public abstract getAll<QueryT extends object>(query?: QueryT): Promise<InterfaceT[]>;
	public abstract create<QueryT extends object>(body?: EntityT, query?: QueryT): Promise<InterfaceT>;
	public abstract updateSingle<QueryT extends object>(body?: EntityT, query?: QueryT): Promise<InterfaceT>;
	public abstract updateMulti<QueryT extends object>(body?: EntityT[], query?: QueryT): Promise<InterfaceT[]>;
	public abstract remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }>;
}
