import Vue from 'vue';
import { Constructable } from '@/classes';
import { Project, ProjectSource } from '@/classes/authz/Project';
import { BaseFilterModule, FilterByProjectSourceType, FilterByProjectStatusType } from '@/store/modules/BaseFilterModule';
import { faroLocalization } from '@faroconnect/baseui';
import { ArrayUtils } from '@faroconnect/utils';
import { RegisterOptions } from 'vuex-class-modules';
import { PageModule } from '../PageModule';
import { ProjectStatus } from '@/classes/projz/ProjectStatus';

export abstract class ProjectBaseModule<EntityT extends Project> extends BaseFilterModule<EntityT> {
	// ###################################### Properties ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Getters ######################################

	// ###### Public ######

	public get projectSources(): string[] {
		const projectSourceTypes: string[] = [];
		this.projectList.forEach((project) => ArrayUtils.pushIfMissing(projectSourceTypes, project.Type));
		return projectSourceTypes;
	}

	/**
	 * Not sure whether it is a bug in Vuex but if this method is a getter instead,
	 * it is undefined when used in this same file.
	 */
	public get filterByProjectSourceOptions(): FilterByProjectSourceType[] {
		return this.projectSources.map((projectSourceType) => ({
			text: Project.getProjectTypeName(projectSourceType),
			value: projectSourceType,
			icon: Project.getProjectTypeIcon(projectSourceType),
		})).sort((a, b) => a.text.localeCompare(b.text));
	}

	/**
	 * Gets an ordered array of project status type options for the current filtered project sources
	 * Adds only the first found element for each StatusId.
	 * If there no project status for a project, add the default "No status available" type.
	 */
	public get filterByProjectStatusOptions(): FilterByProjectStatusType[] {
		// Get pre-filtered projects (filtered by all filters except project Status)
		const filteredItemsList: Project[] = [];
		this.itemsList.forEach((project) => {
			if (this.filterItemWithoutStatus(project)) {
				filteredItemsList.push(project);
			}
		});
		const filteredProjectList: Project[] = filteredItemsList.filter((item) => item.Class === 'Project') as Project[];
		const filteredProjectUuids = filteredProjectList.map((project) => project.UUID);

		// Get array of statuses for the pre-filtered projects
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const projectStatusList: ProjectStatus[] = $tsStore.projectStatuses.itemsList;
		const projectStatusFilteredItems: ProjectStatus[] = [];
		projectStatusList.forEach((status) => {
			if (filteredProjectUuids.includes(status.ProjectUUID)) {
				projectStatusFilteredItems.push(status);
			}
		});

		// Get sorted array of status type options available in the array of statuses
		const projectStatusTypesFiltered: FilterByProjectStatusType[] = this.getProjectStatusTypes(projectStatusFilteredItems);
		const projectStatusTypesOptions: FilterByProjectStatusType[] = [];
		projectStatusTypesFiltered.forEach((statusType) => {
			if (!ArrayUtils.contains(projectStatusTypesOptions, statusType, 'value')) {
				projectStatusTypesOptions.push(statusType);
			}
		});
		let statusTypeOptions = projectStatusTypesOptions.sort((a, b) => a.text.localeCompare(b.text));

		// Remove default "No status available" option for projects without status(es).
		statusTypeOptions = statusTypeOptions.filter((statusType) => statusType.value !== this.noStatusAvailableId);

		// Add default "No status available" option if not all pre-filtered projects have at least one status
		if (filteredProjectUuids.length !== 0) {
			const filteredStatusProjectUuids = projectStatusFilteredItems.map((status) => status.ProjectUUID);
			let haveAllProjectsAStatus: boolean = false;
			haveAllProjectsAStatus = filteredProjectUuids.every((uuid) => filteredStatusProjectUuids.includes(uuid));
			const noStatusAvailableType = {
				text: this.noStatusAvailableId,
				value: this.noStatusAvailableId,
				icon: '$vuetify.icons.36_white_empty',
				color: 'noProjectStatusAvailableColor',
			};
			if (!haveAllProjectsAStatus && !ArrayUtils.contains(statusTypeOptions, noStatusAvailableType, 'value')) {
				statusTypeOptions.push(noStatusAvailableType);
			}
		}

		return statusTypeOptions;
	}

	// ###### Protected ######

	/**
	 * Override with the list of projects displayed on the page.
	 * It is useful to for example calculate dinamycally the project types.
	 */
	protected abstract get projectList(): Project[];

	protected get noStatusAvailableId(): string {
		return faroLocalization.i18n.tc('LP_NO_PROJECT_STATUS');
	}

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions, public classConstructor: Constructable<EntityT>) {
		super(pages, options, classConstructor);
	}

	// ###################################### Actions ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Mutations ######################################

	// ###### Public ######

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	// ###### Protected ######

	/**
	 * Filters a list of projects by keeping only the ones that have some attribute,
	 * that matches some search text.
	 * @param projects The original project list.
	 * @param searchTxt The search text.
	 * @returns A new filtered project list.
	 */
	protected filterByTextItems<SubEntityT extends EntityT>(entities: SubEntityT[], searchTxt: string): SubEntityT[] {
		searchTxt = searchTxt.toLowerCase();
		return entities.filter((entity) =>
			entity.Name.toLowerCase().includes(searchTxt) ||
			entity.Description.toLowerCase().includes(searchTxt),
		);
	}

	/**
	 * Compares whether the provided entity has the same project source as one of the selected ones.
	 * @param entity The entity to be evaluated.
	 * @param options If skipOtherEntities is true, it will return true when the provided entity is not a project. Default false.
	 * @returns True if the entity project source matches one of the selected project sources.
	 */
	protected compareFilterByProjectSource(entity: Project): boolean {
		if (!(entity instanceof Project)) {
			return true;
		}

		if (this.filterByAllProjectSources) {
			return true;
		}

		if (this.filterByProjectSource.length === 0) {
			return false;
		}

		return this.filterByProjectSource.includes(entity.Type as ProjectSource);
	}

	/**
	 * Compares whether the provided entity has the same project status as one of the selected ones.
	 * @param entity The entity to be evaluated.
	 * @param options If skipOtherEntities is true, it will return true when the provided entity is not a project. Default false.
	 * @returns True if the entity project source matches one of the selected project status(es).
	 */
	protected compareFilterByProjectStatus(entity: Project): boolean {
		if (!(entity instanceof Project)) {
			return true;
		}

		if (this.filterByAllProjectStatuses) {
			return true;
		}

		if (this.existingFilterByProjectStatusIds.length === 0) {
			return false;
		}

		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		const projectStatuses = $tsStore.projectStatuses.getProjectStatuses(entity);
		// If project has status(es), check if one of the status matches one of the selected options.
		// If project has no status(es), check if the default "No status available" option is selected.
		if (projectStatuses.length !== 0) {
			const projectStatusesStatusIds = projectStatuses.map((status) => status.statusValueId);
			let result: boolean = false;
			// If one of the checked StatusId options matches one of the Statuses of this project, return true
			this.existingFilterByProjectStatusIds.forEach((statusId) => {
				if (projectStatusesStatusIds.includes(statusId)) {
					result = true;
				}
			});
			return result;
		} else if (this.existingFilterByProjectStatusIds.includes(this.noStatusAvailableId)) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Maps an array of project status(es) to an an array of project status types
	 * @param statuses Array of project status(es)
	 * @returns Array of project status types
	 */
	public getProjectStatusTypes(statuses: ProjectStatus[]): FilterByProjectStatusType[] {
		return statuses.map((status) => ({
			text: status.displayText,
			value: status.statusValueId,
			icon: status.vuetifyIcon,
			color: status.Color,
		}));
	}

	/**
	 * Filters an entity by all filters except by Project Status. Use to dynamically get the status options
	 * @param entity to be filtered
	 * @returns true is the entity passes the filters
	 */
	public filterItemWithoutStatus(entity: EntityT): boolean {
		// First filter by entity type

		if (!this.filterByAllEntityTypes && !this.filterByEntityTypes.includes(entity.Class)) {
			return false;
		}

		// Project filters

		if (!this.compareFilterByProjectSource(entity)) {
			return false;
		}

		return true;
	}

	// ###### Private ######
}
