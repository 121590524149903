
import Vue, { VueConstructor } from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { FaroIconButtonI } from '@faroconnect/baseui';
import BackgroundImage from '@/components/Embeddable/BackgroundImage.vue';
import PageThumbnailView from '@/components/EntitiesPage/PageThumbnailView.vue';
import PageListView from '@/components/EntitiesPage/PageListView.vue';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';
import SortByMenu from '@/components/Embeddable/SortByMenu.vue';
import { LpEntity } from '@/classes';
import { PageLayout } from '@/definitions/types';
import { Header } from '@/utils/listview';
import { SortItem } from '@/utils/sortitems';
import { FilterByActiveIndicator, PageFilters, PAGE_FILTERS } from '@/store/modules/BaseFilterModule';
import { PAGE_LAYOUT_ENUM } from '@/definitions/constants';

/**
 * Base component to show a list of entities.
 * Includes the components to add a new tile, change the view, filter, sort and either thumbnail or list view.
 */
@Component({
	components: {
		SortByMenu,
		TopPageBase,
		BackgroundImage,
		PageThumbnailView,
		PageListView,
	},
})
export default class EntitiesPageBase<EntityT extends LpEntity> extends Vue {
	// Entities array. It will only show the filteredItems.
	@Prop(Array) public readonly items!: EntityT[];
	// Filtered entities array.
	@Prop(Array) public readonly filteredItems!: EntityT[];
	// Entities per page.
	@Prop(Array) public readonly entitiesOnPage!: EntityT[];
	// Index for the shown page (starts in 1).
	@Prop(Array) public readonly pageNumber!: number;
	// Title of the header bar.
	@Prop(String) public readonly title!: string;
	// Text below the title of the header bar.
	@Prop(String) public readonly text!: string;
	// Text to show in the add new tile button.
	@Prop(String) public readonly addNewTileText!: string;
	// Buttons array to show in the actions button for each entity.
	@Prop(Array) public readonly itemButtons!: FaroIconButtonI[];
	// Optional entity that has a operation that is loading. This will show the action buttons as loading.
	@Prop(Object) public readonly loadingEntity!: EntityT | null;
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: BaseFilterModule<EntityT>;
	// Flag whether the page is loading.
	@Prop(Boolean) public readonly loading!: boolean;
	// Flag whether there was an error.
	@Prop(Boolean) public readonly error!: boolean;
	// Error title.
	@Prop(String) public readonly errorTitle!: string;
	// Error message.
	@Prop(String) public readonly errorMsg!: string;
	// Callback whenever the add new tile is clicked.
	@Prop(Function) public readonly addNewTile!: () => Promise<void>;
	// Callback whenever the add new button is clicked.
	@Prop(Function) public readonly addNewBtn!: () => Promise<void>;
	// Array filled with the sorting items for the current page.
	@Prop(Array) public readonly sortItems!: SortItem<EntityT>;
	// Hides the thumbnail view
	@Prop(Boolean) public readonly hideThumbnails!: boolean;
	// Headers in the table
	@Prop({type: Array, required: true }) public readonly headers!: Array<Header<EntityT>>;

	@Prop(Function) public readonly filterMenu!: VueConstructor<Vue>;
	// Callback whenever an entity is clicked and should be opened.
	@Prop(Function) public readonly openEntity!: () => Promise<void>;

	@Prop(String) public pageName!: string;

	// Flag to hide secondary info on thumbnail-view as well
	@Prop({type: Boolean, default: false}) public readonly hideSecondaryInfo!: boolean;
	@Prop(Boolean) public readonly onlyThumbnailsView!: boolean;

	//If we are on the selectWorkspaceDasboardPage, we always show the thumbnail view only.
	public projectView: PageLayout = this.onlyThumbnailsView ? PAGE_LAYOUT_ENUM.thumbnail : this.$tsStore.settings.defaultView;
	// Default number of items to show when expanding a filter indicator
	public readonly indicatorItems = 5;
	public filterIndicatorItems = this.indicatorItems;

	/**
	 * Returns the Vuetify xsOnly breakpoint.
	 * If true, it meens that the page is being displayed in a XS device.
	 */
	public get xsOnly() {
		return this.$vuetify.breakpoint.xsOnly;
	}

	public get showFilterMenu(): boolean {
		return !!this.filterMenu;
	}

	public get filterByActiveIndicators(): FilterByActiveIndicator[] {
		return this.store.filterByActiveIndicators;
	}

	public get sortBy(): SortItem<EntityT> {
		return this.store.SortBy;
	}

	public get sortDesc(): boolean {
		return this.store.SortDesc;
	}

	public get filterIndicatorItemsToShow(): number {
		return this.filterIndicatorItems;
	}

	public set filterIndicatorItemsToShow(value: number) {
		this.filterIndicatorItems = value;
	}

	/**
	 * Gets the text to display as tootip for the filter indicators.
	 * @param filterIndicator.
	 * Returns "View more" if indicator can be expanded, a date range if indicator is of type date and has a range, otherwise undefined.
	 */
	public getFilterIndicatorTooltip(filterIndicator: FilterByActiveIndicator): string | undefined {
		if (filterIndicator.isExpandable && !filterIndicator.expanded) {
			return this.$tc('LP_VIEW_MORE');
		}
		if (filterIndicator.filter === PAGE_FILTERS.Date) {
			return filterIndicator.tooltip ?? undefined;
		}
		return undefined;
	}

	/**
	 * Add some classes to the appends buttons inside the sortByDropdown, to make it look like a normal icon button.
	 * Otherwise the style of the appended icons have no hover effect.
	 */
	public formatAppendedIcons() {
		const dropdownElement = this.$refs.sortByDropdown as Vue;
		if (!dropdownElement) {
			return;
		}

		const buttons = dropdownElement.$el.getElementsByTagName('button') ?? [];
		// buttons is not a normal array it is an HTMLCollectionOf<HTMLButtonElement> so it can't be looped normally.
		for (let i = 0; i < buttons.length; i++) {
			buttons.item(i)?.classList?.add('v-btn', 'v-btn--icon', 'v-btn--outlined', 'v-btn--round', 'v-size--default');
		}
	}

	public onEntityClicked(item: EntityT) {
		this.$emit('click-entity', item);
	}

	public resetAllFilters() {
		this.store.resetFilter();
	}

	/**
	 * Reset a single filter to it's default state.
	 * @param filter filter type to be reset. E.g: Date filter.
	 */
	public resetSingleFilter(filter: PageFilters) {
		switch (filter) {
			case PAGE_FILTERS.Date:
				this.store.updateFilterByDate(this.store.DEFAULT_FILTER_BY_DATE);
				break;
			case PAGE_FILTERS.Project:
				this.store.setFilterByAllProjects(true);
				break;
			case PAGE_FILTERS.EntityType:
				this.store.setFilterByAllEntityTypes(true);
				break;
			case PAGE_FILTERS.ProjectSource:
				this.store.setFilterByAllProjectSources(true);
				break;
			case PAGE_FILTERS.ProjectStatus:
				this.store.setFilterByAllProjectStatuses(true);
				break;
		}
	}

	/**
	 * "Unselects" an item from the user selection of an active filter.
	 * @param filter Active filter.
	 * @param item Id of the item to be unselected.
	 */
	public resetFilterItem(filter: PageFilters, item: string) {
		let updateList = [];
		switch (filter) {
			case PAGE_FILTERS.Project:
				updateList = this.store.filterByProjectUuids.filter((project) => project !== item);
				this.store.setFilterByProjectUuids(updateList);
				break;
			case PAGE_FILTERS.EntityType:
				updateList = this.store.filterByEntityTypes.filter((entityType) => entityType !== item);
				this.store.setFilterByEntityTypes(updateList);
				break;
			case PAGE_FILTERS.ProjectSource:
				updateList = this.store.filterByProjectSource.filter((projectSource) => projectSource !== item);
				this.store.setFilterByProjectSource(updateList);
				break;
			case PAGE_FILTERS.ProjectStatus:
				updateList = this.store.filterByProjectStatusIds.filter((projectStatus) => projectStatus !== item);
				this.store.setFilterByProjectStatusIds(updateList);
				break;
		}
	}

	/**
	 * Opens/Expands the items of an active filter indicator when a user click in the indicator.
	 */
	public showActiveFilterItems(filterIndicator: FilterByActiveIndicator) {
		if (filterIndicator.isExpandable) {
			// First hide the expanded items of any other filter.
			this.hideActiveFilterItems();
			switch (filterIndicator.filter) {
				case PAGE_FILTERS.Project:
					this.store.setExpandFilterByProjectIndicator(true);
					break;
				case PAGE_FILTERS.EntityType:
					this.store.setExpandFilterByEntityTypeIndicator(true);
					break;
				case PAGE_FILTERS.ProjectSource:
					this.store.setExpandFilterByProjectSourceIndicator(true);
					break;
				case PAGE_FILTERS.ProjectStatus:
					this.store.setExpandFilterByProjectStatusIndicator(true);
					break;
			}
		}
	}

	/**
	 * Closes/Hides the items of an active filter indicator when the user click outside the items list.
	 */
	public hideActiveFilterItems() {
		this.filterIndicatorItemsToShow = this.indicatorItems;
		this.store.setExpandFilterByProjectIndicator(false);
		this.store.setExpandFilterByEntityTypeIndicator(false);
		this.store.setExpandFilterByProjectSourceIndicator(false);
		this.store.setExpandFilterByProjectStatusIndicator(false);
	}

	/**
	 * Checks for click events. If an item list of an active filter is expanded an the user clicked outside the list
	 * then call for the close list method.
	 */
	public onClickEvent(event: Event) {
		const target = event.target as HTMLElement;
		const targetClass = target.className;
		const expandedElement = this.$refs.activeFilterExpandedItems;
		if (expandedElement && !targetClass.includes('activeFilterExpandedItem') && target.id !== 'activeFilterIndicatorText') {
			this.hideActiveFilterItems();
		}
	}

	public onSortDescChanged(sortDesc: boolean) {
		this.store.setSortDesc(sortDesc);
	}

	public mounted() {
		document.addEventListener('click', this.onClickEvent);
	}

	/**
	 * Before the page is destroyed it removes all filters.
	 * Remove even listeners.
	 */
	public beforeDestroy() {
		this.$tsStore.pages.setSearchTxt(undefined);
		document.removeEventListener('click', this.onClickEvent);
	}

	@Watch('loading')
	public async onLoadingChanged() {
		await Vue.nextTick();
		this.formatAppendedIcons();
	}
}
