import Vue from 'vue';
import { VuexModule, Mutation, Action, RegisterOptions } from 'vuex-class-modules';
import { Resetable } from '@/store/modules/interfaces';
import { PageModule } from '@/store/modules/PageModule';
import { BaseService } from '@/store/services/BaseService';
import { Constructable, InterfaceOf, LpEntity } from '@/classes';
import { EntitySortBy, SortItem } from '@/utils/sortitems';
import { $assert } from '@faroconnect/utils';
import { AuthZClient } from '@faroconnect/authz-client';
import { BaseServiceAny } from '../services/BaseServiceAny';
import { config } from '@/config';

/**
 * Compare function for two strings.
 * @param a First string to compare.
 * @param b Second string to compare.
 * @param sortDesc Flag whether the strings should be sorted in descending order.
 * @returns -1, 0 and 1 according to https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 */
export function compareStringAttr(a: string | undefined, b: string | undefined, sortDesc: boolean) {
	// Ignore upper and lower cases.
	const aUpper = (a ?? '').toUpperCase();
	const bUpper = (b ?? '').toUpperCase();
	if (!sortDesc) {
		return aUpper.localeCompare(bUpper);
	} else {
		return bUpper.localeCompare(aUpper);
	}
}

/**
 * Compare function for two numbers.
 * @param a First number to compare.
 * @param b Second number to compare.
 * @param sortDesc Flag whether the numbers should be sorted in descending order.
 * @returns -1, 0 and 1 according to https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 */
export function compareNumAttr(a: number | undefined, b: number | undefined, sortDesc: boolean) {
	if (!sortDesc) {
		return (a ?? -Infinity) > (b ?? -Infinity) ? -1 : 1;
	} else {
		return (b ?? -Infinity) < (a ?? -Infinity) ? -1 : 1;
	}
}

export function compareBoolAttr(a: boolean, b: boolean, sortDesc: boolean) {
	if (sortDesc) {
		return a > b ? 1 : -1;
	} else {
		return a < b ? 1 : -1;
	}
}

/**
 * Compare function for two attributes with any value type.
 * @param a First value to compare.
 * @param b Second value to compare.
 * @param sortDesc Flag whether the values should be sorted in descending order.
 * @returns -1, 0 and 1 according to https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 */
export function compareAttr(a: any, b: any, sortDesc: boolean) {
	if (
		(typeof a === 'string' && typeof b === 'string') ||
		(typeof a === 'string' && b === undefined) ||
		(a === undefined && typeof b === 'string')
	) {
		return compareStringAttr(a, b, sortDesc);
	}
	if (
		(typeof a === 'number' && typeof b === 'number') ||
		(typeof a === 'number' && b === undefined) ||
		(a === undefined && typeof b === 'number')
	) {
		return compareNumAttr(a, b, sortDesc);
	}
	// If at least one is a boolean and the other one is either null boolean or undefined, use the boolean comparation.
	if (
		(typeof a === 'boolean' && typeof b === 'boolean') ||
		(((typeof a === 'boolean') || a === undefined || a === null) &&  typeof b === 'boolean') ||
		((typeof a === 'boolean') &&  typeof(b === 'boolean' || b === undefined || b === null))
	) {
		return compareBoolAttr(a, b, sortDesc);
	}
	if ((a === undefined && b === undefined) || (a === null && b === null)) {
		return sortDesc ? 1 : -1;
	}

	throw new Error(`Sort function not implemented. A = ${typeof a}, B = ${typeof b}`);
}

export abstract class BaseModule<EntityT extends LpEntity> extends VuexModule implements Resetable {
	// ###################################### Properties ######################################

	// ###### Public ######

	public ItemsMap: { [key: string]: EntityT } = {};
	public defaultSort: SortItem<EntityT> = new EntitySortBy().name;
	public SortBy: SortItem<EntityT> = this.defaultSort;
	public SortDesc: boolean = true;
	// Index for the shown page (starts in 1).
	public pageNumber: number = 1;
	// The amount of items displayed in a single page in the table.
	public itemsPerPage: number = 24;

	// ###### Protected ######

	protected abstract readonly service: BaseService<EntityT> | undefined;
	protected storeName: string;

	// ###### Private ######

	// ###################################### Getters ######################################

	// ###### Public ######

	/**
	 * Returns post-processed entities:
	 *  - Filtered by text search.
	 *  - Filtered by filter menu.
	 *  - Sorted.
	 * @author OK
	 */
	public abstract get filteredItems(): EntityT[];

	/**
	 * Returns all entities.
	 */
	public get itemsList(): EntityT[] {
		return Object.values(this.ItemsMap);
	}

	/**
	 * Array of all the entities to be displayed in the current page.
	 */
	public get entitiesOnPage(): EntityT[] {
		const start = this.getStartPageItem();
		const end = this.getEndPageItem(start);
		return this.filteredItems.slice(start, end);
	}

	// ###### Protected ######

	protected async getAuthzClient(): Promise<AuthZClient> {
		const accessToken = await BaseServiceAny.getTokenSilentlyWithRedirect();
		return new AuthZClient(config.authzApiEndpoint, {
			refreshTokenCallback: BaseServiceAny.getTokenSilentlyWithRedirect,
			accessToken,
		});
	}

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions, public classConstructor: Constructable<EntityT>) {
		super(options);
		this.storeName = options.name;
	}

	// ###################################### Actions ######################################

	// ###### Public ######

	@Action
	public reset(): void {
		this.ItemsMap = {};
	}

	@Action
	public async getSingle(uuid: string, interceptor?: (entity: InterfaceOf<EntityT>) => Promise<void>) {
		$assert.Assert(this.service, 'this.service is undefined for Store ' + this.storeName);
		const entity = await this.service!.getSingle(uuid);
		if (interceptor) {
			await interceptor(entity);
		}
		this.addItem(this.classConstructor.fromResponse(entity));
	}

	@Action
	public async getAll(
		entityInterceptor?: (entity: InterfaceOf<EntityT>) => Promise<void>,
		entitiesInterceptor?: (entities: Array<InterfaceOf<EntityT>>) => Promise<void>,
	) {
		$assert.Assert(this.service, 'this.service is undefined for Store ' + this.storeName);
		const entities = await this.service!.getAll();
		if (entityInterceptor) {
			for (const entity of entities) {
				await entityInterceptor(entity);
			}
		} else if (entitiesInterceptor) {
			await entitiesInterceptor(entities);
		}
		this.addItems(entities.map((entity) => this.classConstructor.fromResponse(entity)));
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Mutations ######################################

	// ###### Public ######

	/**
	 * Adds an entity to the items map, using the UUID as key.
	 * @param item The entity to be added.
	 */
	@Mutation
	public addItem(item: EntityT) {
		Vue.set(this.ItemsMap, item.UUID, item);
	}

	/**
	 * Adds multiple items or replaces the ones in the store if they have the same UUID.
	 * @param items The items to be added.
	 */
	@Mutation
	public addItems(items: EntityT[]) {
		items.forEach((item) => Vue.set(this.ItemsMap, item.UUID, item));
	}

	/**
	 * Adds multiple items or replaces the ones in the store if they have the same UUID, and
	 * removes the items in the store that are not included in the items parameter.
	 * @param items The items to be added.
	 */
	@Mutation
	public setItems(items: EntityT[]) {
		const itemUuids = items.map((item) => item.UUID);
		// First delete all items that are not included in the parameter.
		for (const uuid in this.ItemsMap) {
			if (!itemUuids.includes(uuid)) {
				Vue.delete(this.ItemsMap, uuid);
			}
		}
		items.forEach((item) => Vue.set(this.ItemsMap, item.UUID, item));
	}

	@Mutation
	public removeItem(uuid: string) {
		if (this.ItemsMap[uuid]) {
			Vue.delete(this.ItemsMap, uuid);
		}
	}

	@Mutation
	public removeItems(uuids: string[]) {
		for (const uuid of uuids) {
			if (this.ItemsMap[uuid]) {
				Vue.delete(this.ItemsMap, uuid);
			}
		}
	}

	@Mutation
	public removeAllItems() {
		const atrrName: keyof BaseModule<any> = 'ItemsMap';
		Vue.set(this, atrrName, {});
	}

	@Mutation
	public setPageNumber(pageNumber: number) {
		this.pageNumber = pageNumber;
	}

	/**
	 * Sets the sort name and order.
	 */
	@Mutation
	public setSortBy(sortBy: SortItem<EntityT>) {
		$assert.Object(sortBy, 'sortBy must be provided, using the default sort by ' + (this.defaultSort.value as string));
		this.SortBy = sortBy ?? this.defaultSort;
	}

	/**
	 * Sets the sort name and order.
	 */
	@Mutation
	public setSortByDefault() {
		this.SortBy = this.defaultSort;
	}

	/**
	 * Sets the sort name and order.
	 */
	@Mutation
	public setDefaultSortBy(sortBy: SortItem<EntityT>) {
		$assert.Object(sortBy, 'sortBy must be provided, using the default sort by ' + (this.defaultSort.value as string));
		this.defaultSort = sortBy ?? this.defaultSort;
	}

	/**
	 * Sets the sort name and order.
	 */
	@Mutation
	public setSortDesc(SortDesc?: boolean) {
		this.SortDesc = SortDesc ?? false;
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	/**
	 * Calculates the amount of pages needed and updates the pageNumber to the last page with results.
	 */
	public getNumberOfPages(filteredItems: EntityT[] = this.filteredItems): number {
		const numberOfPages = Math.ceil(filteredItems.length / this.itemsPerPage) || 1;
		if (this.pageNumber > numberOfPages) {
			this.setPageNumber(numberOfPages);
		}
		return numberOfPages;
	}

	public getEndPageItem(start: number) {
		return start + this.itemsPerPage;
	}

	public getStartPageItem() {
		return (this.pageNumber - 1) * this.itemsPerPage;
	}

	// ###### Protected ######

	/**
	 * Sorts the entities according to the given sort criteria.
	 * @param a The first entity to be sorted.
	 * @param b The entity to be compared with a.
	 * @return Comparison result (-1, 1 or 0).
	 */
	protected sortItems(a: EntityT, b: EntityT) {
		const sortBy = this.SortBy;

		if (!sortBy) {
			return 0;
		}
		return compareAttr(a[sortBy.value], b[sortBy.value], this.SortDesc);
	}

	// ###### Private ######
}
