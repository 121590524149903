import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FaroComponentButton, FaroDividerButton, FaroDropdownIconButton, FaroIconButtonI, FaroSimpleIconButton } from '@faroconnect/baseui';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import { FaroIconButtonExtendedI } from '@/utils/types';
import { LpEntity } from '@/classes';
import { Workspace } from '@/classes/authz/Workspace';
import { Application } from '@/classes/Application';
import GradientBackgroundImage from '@/components/Embeddable/GradientBackgroundImage.vue';
import MeshBackgroundImage from '@/components/Embeddable/MeshBackgroundImage.vue';
import { HintMsg } from '@/utils/types';
import InviterButton from '@/components/buttons/InviterButton.vue';
import { SortItem } from '@/utils/sortitems';

/**
 * Base component to show the entities either with thumbnail or list view.
 */
@Component({
	components: {
		GradientBackgroundImage,
		MeshBackgroundImage,
	},
})
export default class ItemsViewBaseMixin<EntityT extends LpEntity> extends Vue {
	// ######################## Getters to Inherit ###################

	public get xNumberOfPages(): number {
		return this.store.getNumberOfPages(this.filteredItems);
	}

	public get xPageNumber(): number {
		return this.store.pageNumber;
	}

	public set xPageNumber(pageNumber: number) {
		this.store.setPageNumber(pageNumber);
	}

	// For props see ItemsViewBaseMixin

	// ######################## Data to Inherit ###################

	public readonly X_DESCRIPTION_PREVIEW_LENGTH = 130;
	// Limit the minimum length (in characters) of the title of the entities to preview as tooltip
	public readonly X_TITLE_PREVIEW_LENGTH = 10;

	// ######################## Props to Inherit ###################

	// Text to show in the add new tile button.
	@Prop(String) public readonly addNewTileText!: string;
	// Entities array. It will only show the filteredItems.
	@Prop(Array) public readonly items!: EntityT[];
	// Filtered entities array.
	@Prop(Array) public readonly filteredItems!: EntityT[];
	// Entities per page
	@Prop(Array) public readonly entitiesOnPage!: EntityT[];
	// Buttons array to show in the actions button for each entity.
	@Prop(Array) public readonly itemButtons!: FaroIconButtonI[];
	// Optional entity that has a operation that is loading. This will show the action buttons as loading.
	@Prop(Object) public readonly loadingEntity!: EntityT | null;
	// Callback whenever the add new tile button is clicked.
	@Prop(Function) public readonly addNewTile!: () => Promise<void>;
	// The Vuex store module for the entities that are displayed.
	@Prop(Object) public readonly store!: BaseFilterModule<EntityT>;
	// Array filled with the sorting items for the current page.
	@Prop(Array) public readonly sortItems!: Array<SortItem<EntityT>>;
	// Callback whenever an entity is clicked and should be opened.
	@Prop(Function) public readonly openEntity!: (entity: EntityT) => Promise<void>;

	// ######################## Methods to Override ##################

	/**
	 * Gets the actions buttons for the provided entity.
	 * The entity will be included in the first parameter for the click callback in each item button.
	 * @param item The selected entity.
	 * @returns An array filled with icon buttons.
	 */
	public xGetItemButtons(item: EntityT): Array<FaroIconButtonI | FaroComponentButton> {
		return this.itemButtons.map((btn) => (this.xMapButton(item, btn)));
		// Maybe needed later: Make meatballs menu disappear if all entries are hidden.
		// return this.itemButtons.map((btn) => (this.xMapButton(item, btn)));
		// 	.filter((btn) => !btn.hide);
	}

	/**
	 * Returns whether the provided entity has an operation that's been loaded. This will show the action buttons as loading.
	 * @param entity The entity to be checked.
	 * @returns True if the entity has an operation that's been loaded.
	 */
	public xisEntityLoading(entity: EntityT): boolean {
		if (!this.loadingEntity) {
			return false;
		}
		return entity.UUID === this.loadingEntity.UUID;
	}

	public xIsEntityClickable(entity: EntityT): boolean {
		if (entity instanceof Workspace && entity.WorkspaceUserState === 'pending') {
			return false;
		}
		if (this.xIsEntityDisabled(entity)) {
			return false;
		}
		return true;
	}

	public xIsEntityDisabled(entity: EntityT): boolean {
		if (entity instanceof Application) {
			return entity.ComingSoon;
		}
		return false;
	}

	public xOnEntityClicked(entity: EntityT) {
		if (this.xIsEntityClickable(entity) && !this.xIsEntityDisabled(entity)) {
			this.openEntity(entity);
		}
	}

	public xUseGradientBackground(entity: EntityT): boolean {
		return entity instanceof Application && !!entity.GradientBackground;
	}

	public xUseMeshBackground(entity: EntityT): boolean {
		return (entity instanceof Application && !!entity.MeshBackground) || (entity instanceof Workspace);
	}

	/**
	 * Gets the message hint to be shown on the top left corner of the image.
	 */
	public xGetHintMsg(entity: EntityT): HintMsg | null {
		if (!(entity instanceof Workspace)) {
			return null;
		}
		if (entity.isPendingUserInvitation) {
			return {
				color: 'warning',
				icon: '$vuetify.icons.36_white_generic-clock',
				text: this.$tc('UI_PENDING'),
				buttons: [
					{
						component: InviterButton,
						data: entity,
					},
					{
						icon: '$vuetify.icons.36_success_generic-check',
						caption: this.$tc('LP_ACCEPT_INVITATION'),
						textClass: 'ui_fontGreen',
						click: (async (workspace: Workspace) => {
							try {
								await this.$tsStore.workspaces.activateUser(workspace.UUID);
							} catch (error) {
								const message = this.$tc('LP_ERR_ACCEPT_INVITATION', undefined, { name: workspace.Name });
								this.$faroComponents.$emit('show-error', { error, message });
							}
						}).bind(null, entity),
					},
					{
						icon: '$vuetify.icons.36_error_generic-close',
						caption: this.$tc('LP_REJECT_INVITATION'),
						textClass: 'ui_fontRed',
						click: (async (workspace: Workspace) => {
							try {
								await this.$tsStore.workspaces.unassignCallingUser({workspaceUuid: workspace.UUID});
							} catch (error) {
								const message = this.$tc('LP_ERR_DECLINE_INVITATION', undefined, { name: workspace.Name });
								this.$faroComponents.$emit('show-error', { error, message });
							}
						}).bind(null, entity),
					},
				],
			};
		}
		return null;
	}

	// ######################## Class Methods ##################

	/**
	 * Maps a button to include the selected entity object as a parameter in the click callback.
	 * @param item The selected entity.
	 * @param btn The button to be mapped.
	 * @returns The mapped button.
	 */
	private xMapButton(item: EntityT, btn: FaroIconButtonExtendedI<EntityT> | FaroDropdownIconButton): FaroIconButtonI | FaroComponentButton {
		// ###### Modify FaroIconBase properties ######

		if (('getDisabled' in btn) && btn.getDisabled) {
			btn.disabled = btn.getDisabled(item);
		}

		if (('getDisabledTooltip' in btn) && btn.getDisabledTooltip) {
			btn.tooltipDisabled = btn.getDisabledTooltip(item);
		}

		if (('getHidden' in btn) && btn.getHidden) {
			btn.hide = btn.getHidden(item);
		}

		// ###### Return button ######

		if (('divider' in btn) && (btn as FaroDividerButton).divider) {
			const dividerBtn: FaroDividerButton = btn as FaroDividerButton;
			const returnBtn: FaroDividerButton = {
				hide: dividerBtn.hide,
				divider: dividerBtn.divider,
			};
			return returnBtn;
		}

		// Returns FaroComponentButton

		if (('getCustomComponent' in btn && btn.getCustomComponent)) {
			const returnBtn: FaroComponentButton = {
				hide: btn.hide,
				...btn.getCustomComponent(item),
			};
			return returnBtn;
		}

		// Returns FaroSimpleIconButton

		if ('click' in btn) {
			const returnBtn: FaroSimpleIconButton = {
				...btn,
				click: btn.click?.bind(btn, item),
			};
			return returnBtn;
		}

		// Returns FaroDropdownIconButton

		if ('buttons' in btn) {
			const returnBtn: FaroDropdownIconButton = {
				...btn,
				// If it contains an array of buttons, calls this function recursively.
				buttons: btn.buttons.map((btn2) => this.xMapButton(item, btn2 as FaroIconButtonI)),
			};
			return returnBtn;
		}
		return btn;
	}
}
