
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PageBaseMixin from '@/mixins/PageBaseMixin';
import { Workspace } from '@/classes/authz/Workspace';
import { ModuleNameOf } from '@/store/types';
import { EntitiesFetchResult } from '@/utils/types';
import { Header } from '@/utils/listview';
import UpdateOrCreateWorkspaceTask from '@/components/Tasks/UpdateOrCreateWorkspaceTask.vue';
import { InitData } from '@/components/Tasks/UpdateOrCreateWorkspaceTask';
import { config } from '@/config';

function workspaceFilter(w: Workspace, activeUuid: string, includeActive: boolean): boolean {
	// Don't show the active workspace.
	//
	// We should not restrict the workspaces shown here by permission, to make sure that workspaces-memberships
	// that were auto-provisioned by SSO are shown.
	// In this case, the user may be without any permissions initially.
	//
	// w.State === null:      Workspace exists only in AuthZ (legacy WebShare domains), or SubSvc is N/A.
	// w.State === 'pending': Workspace not created yet; exists only in SubSvc.
	// w.State === 'active':  Workspace exists in SubSvc and should also exist in AuthZ.
	//                        If WebshareDomainName is empty, the workspace does not exist in AuthZ.
	// The POs decided that deactivated workspaces should not be shown on the Landing Page.
	// If this should ever be changed, !w.isPending could be used instead of w.isActive.
	//
	// Workspaces with w.isPendingUserInvitation === true are also included, so the user can accept or decline.
	return (includeActive || w.UUID !== activeUuid) && (w.IsDemo || (w.isActive && !!w.WebshareDomainName && !!w.webShareUrl));
}

const prevPendingUserInvitations = new Set<string>();

/**
 * Workspace comparator.
 * Primary sort order: Pending invitations first (very useful if there are several pages of workspaces!).
 *                     To avoid that workspaces "jump" when the user accepts the invitation, we remember the previous value
 *                     of the `isPendingUserInvitation` property in `prevPendingUserInvitations`.
 * Secondary sort order: Alphabetical by name.
 * @param a
 * @param b
 * @returns Number according to array.sort() convention.
 */
function workspaceCompare(a: Workspace, b: Workspace): number {
	const aPending = (a.isPendingUserInvitation || prevPendingUserInvitations.has(a.UUID)) ? 1 : 0;
	const bPending = (b.isPendingUserInvitation || prevPendingUserInvitations.has(b.UUID)) ? 1 : 0;
	if (a.isPendingUserInvitation) {
		prevPendingUserInvitations.add(a.UUID);
	}
	if (b.isPendingUserInvitation) {
		prevPendingUserInvitations.add(b.UUID);
	}
	return (aPending !== bPending) ? (bPending - aPending) : a.Name.localeCompare(b.Name);
}

@Component
export default class SelectWorkspacePage extends PageBaseMixin<Workspace> {
	@Prop(Object) public readonly workspacesFetchResult!: EntitiesFetchResult;

	public showCreateWorkspaceButton: boolean = false;
	// To avoid that the workspace tiles immedately change after clicking on a workspace,
	// we store the workspace that was active when mount() was called.
	// This doesn't make a practical difference, and avoids this flickering.
	public activeWorkspaceOnMount: Workspace | null = null;

	// ######################## Inherited Getters ####################

	public get xGetAllErrString(): string {
		return this.$tc('LP_ERR_GET_WORKSPACES');
	}

	public get xStoreName(): ModuleNameOf<Workspace> {
		return 'workspaces';
	}

	public get xPageName(): string {
		return 'SelectWorkspacePage';
	}

	public get xItems() {
		return this.$tsStore.workspaces.itemsList.filter((w: Workspace): boolean => {
			return workspaceFilter(w, this.activeWorkspace?.UUID || '', this.includeActive);
		}).sort(workspaceCompare);
	}

	public get xFilteredItems() {
		return this.$tsStore.workspaces.filteredItems.filter((w: Workspace): boolean => {
			return workspaceFilter(w, this.activeWorkspace?.UUID || '', this.includeActive);
		}).sort(workspaceCompare);
	}

	public get xEntitiesOnPage() {
		// Same algorithm as in BaseModule, get entitiesOnPage().
		const start = this.$tsStore.workspaces.getStartPageItem();
		const end = this.$tsStore.workspaces.getEndPageItem(start);
		return this.xFilteredItems.slice(start, end);
	}

	// ######################## Class Getters ####################

	/**
	 * Returns if the currently active workspace should also be shown in the selection.
	 * This can be useful in some circumstances to not confuse the user.
	 */
	public get includeActive(): boolean {
		return this.$route.params?.includeActive === 'true';
	}

	public get hasWorkspacesFetchResultError(): boolean {
		return typeof this.workspacesFetchResult.errorMsg === 'string';
	}

	public get pageLoading(): boolean {
		if (this.hasWorkspacesFetchResultError) {
			return false;
		}
		return this.workspacesFetchResult.loading || this.xLoading;
	}

	public get pageError(): boolean {
		if (this.hasWorkspacesFetchResultError) {
			return true;
		}
		return this.xError;
	}

	public get pageErrorTitle(): string | null {
		if (this.hasWorkspacesFetchResultError) {
			return this.xGetAllErrString;
		}
		return this.xErrorTitle;
	}

	public get pageErrorMsg(): string | null {
		return this.workspacesFetchResult.errorMsg || this.xErrorMsg;
	}

	public get xHeaders(): Array<Header<Workspace>> {
		return [
			this.xEntityHeader.imgUrl,
			this.xEntityHeader.name,
			this.xEntityHeader.description,
		];
	}

	/**
	 * Get unfiltered list of user workspaces.
	 */
	public get userWorkspaces() {
		return this.$tsStore.workspaces.itemsList;
	}

	public get activeWorkspace(): Workspace | null {
		return this.activeWorkspaceOnMount || this.$tsStore.workspaces.activeWorkspace;
	}

	// ######################## Inherited Methods ####################

	/**
	 * Handler for the Create workspace button.
	 * @author OK
	 */
	public async xAddNewTile(): Promise<void> {
		this.$faroTaskService.showTask<InitData>(UpdateOrCreateWorkspaceTask, { isFullscreen: false });
	}

	public async xOpenEntity(workspace: Workspace) {
		await this.$tsStore.workspaces.updateActiveWorkspaceWithSettings(workspace);
		const workspaceUuid = this.$tsStore.workspaces.activeWorkspace?.UUID || '';
		this.$router.push({ name: 'ProjectsPage', params: { workspace: workspaceUuid } });
	}

	/**
	 * Override xInitialize so that it does not fetch workspaces when workspaces page is opened.
	 */
	public async xInitialize() {
		this.xLoading = true;
		try {
			this.$faroLoading.start();
		} catch (error) {
			this.handleError(error);
		} finally {
			this.$tsStore.pages.setFinishedPageLoading(true);
			if (this.$tsStore.pages.finishedMainLoading) {
				this.$faroLoading.stop();
			}
			this.xLoading = false;
		}
	}

	/**
	 * Sets if the workspace creation button is visible.
	 * @author OK
	 */
	public async determineCreateWorkspaceButton(): Promise<void> {
		// List of environments that always show the create workspace button.
		const ADD_WORKSPACE_BUTTON_ENV_VISIBLE = [ 'local', 'dev' ];
		this.showCreateWorkspaceButton = ADD_WORKSPACE_BUTTON_ENV_VISIBLE.includes(config.env);
	}

	public async mounted() {
		const initialized = await this.xInitializeAfterFirstPage();
		if (initialized) {
			this.$emit('reset-workspaces-fetch-result');
		}
		this.xStore.resetFilter(); // Avoid that filter is applied, since it's not shown in the UI.
		// Don't await. Update tile visibility once response is received.
		this.determineCreateWorkspaceButton();
		this.xStore.updateFiltersState();
		this.activeWorkspaceOnMount = this.$tsStore.workspaces.activeWorkspace;
	}

	/**
	 * Check if user can create a workspace after list of workspaces changes.
	 */
	@Watch('userWorkspaces')
	public async onUserWorkspacesChange() {
		await this.determineCreateWorkspaceButton();
	}
}
