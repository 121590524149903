import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';
import en_US from '@/assets/converted-lang/en_US.json'; // eslint-disable-line camelcase
import { BaseUI, getVuetify, faroLocalization, LanguageCode } from '@faroconnect/baseui';
import { baseIcons } from '@faroconnect/icons';
import '@/assets/styles/global.less';
import { Auth0PluginInstallOptions, Auth0PluginInstall } from '@faroconnect/auth0-frontend';
import { config } from './config';
import LpImg from '@/components/Embeddable/LpImg.vue';
const vuetify = getVuetify(baseIcons);

Vue.use(BaseUI, {
	appId: 'LANDINGPAGE',
});

// Add the component globally so it doesn't have to be imported.
Vue.component('LpImg', LpImg);

const auth0Options: Auth0PluginInstallOptions = {
	oidc: config.oidc,
	defaultTargetPath: window.location.pathname,
	// Override default from auth.ts.
	redirectUri: location.origin + '/home/',
	router,
};

Vue.use(Auth0PluginInstall, auth0Options);

Vue.config.productionTip = false;

faroLocalization.loadLanguage(LanguageCode.en_US, en_US);

faroLocalization.registerAsyncLanguage(LanguageCode.de_DE, () => import('@/assets/converted-lang/de_DE.json'));
faroLocalization.registerAsyncLanguage(LanguageCode.es_MX, () => import('@/assets/converted-lang/es_MX.json'));
faroLocalization.registerAsyncLanguage(LanguageCode.fr_FR, () => import('@/assets/converted-lang/fr_FR.json'));
faroLocalization.registerAsyncLanguage(LanguageCode.it_IT, () => import('@/assets/converted-lang/it_IT.json'));
faroLocalization.registerAsyncLanguage(LanguageCode.ja_JP, () => import('@/assets/converted-lang/ja_JP.json'));
faroLocalization.registerAsyncLanguage(LanguageCode.ko_KR, () => import('@/assets/converted-lang/ko_KR.json'));
faroLocalization.registerAsyncLanguage(LanguageCode.pt_BR, () => import('@/assets/converted-lang/pt_BR.json'));
faroLocalization.registerAsyncLanguage(LanguageCode.zh_CN, () => import('@/assets/converted-lang/zh_CN.json'));

/**
 * Convenience developer helper to set the language in the UI via the DevTools console.
 * E.g. sphereSetLanguage('fr_FR')
 */
(window as any).sphereSetLanguage = (lang: string) => {
	faroLocalization.setLanguage(lang as LanguageCode);
};

new Vue({
	router,
	store,
	i18n: faroLocalization.i18n,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
