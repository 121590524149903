import { HttpClient } from '@faroconnect/clientbase';
import { BaseServiceAny } from './BaseServiceAny';
import { config } from '@/config';

export class AnalyticsService {
	/**
	 * Returns a map from workspace UUID to the date of the last project update in yyyy-mm-dd format.
	 * @author OK
	 * @throws {HttpError}
	 */
	public async getLastUpdates(): Promise<{[key: string]: string}> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		const client = new HttpClient();
		const url = `${config.sphereAnalyticsApiEndpoint}/sphere-analytics/v1/workspace/last-update?as_map=true`;
		// throws HttpError
		const obj = await client.get(url, { customHeaders });
		return obj.Success ? obj.data : {};
	}
}
