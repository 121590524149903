import { AuthzBaseInterface, AuthzInterface, AuthzReq, Generated } from '@faroconnect/authz-client';
import { BaseService, ClientOptions } from '@/store/services/BaseService';
import { BASE_AUTHZ_API_URL, config } from '@/config';
import { User } from '@/classes/authz/User';
import { SendEmailVerificationBody, WithUserRegistrationToken, DeleteUserResponse } from '@/utils/types';
import { WebUtils } from '@faroconnect/utils';

interface GetPermissionsQuery {
	workspaces?: 'true' | true | 'all' | string;
	projects?: 'true' | true | 'all' | string;
	user?: 'true' | true | 'full';
}

export interface AssignProjectRolesQuery {
	projectRolesUuids: string[];
	projectUuid: string;
}

export interface AssignProjectRolesUsersQuery {
	userUuids: string[];
	projectRolesUuids: string[];
	projectUuid: string;
}

/**
 * This service is in charge of CRUD operations to manipulate the Sphere users in the Authz backend.
 */
export class AuthzUserService extends BaseService<User> {
	public constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'user',
			apiEndpoint: config.authzApiEndpoint,
			baseApiUrl: BASE_AUTHZ_API_URL,
		}, clientConfig);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<AuthzInterface.IUser> {
		throw new Error('Function not yet implemented');
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<AuthzInterface.IUser[]> {
		throw new Error('Function not yet implemented');
	}

	public async create<QueryT extends object>(body?: User, query?: QueryT): Promise<AuthzInterface.IUser> {
		throw new Error('Function not yet implemented');
	}

	public async updateSingle<QueryT extends object>(body?: Partial<AuthzInterface.IUser>, query?: QueryT): Promise<AuthzInterface.IUser> {
		const url = this.makeUrl('', query);
		return this.put(url, body);
	}

	public async updateMulti<QueryT extends object>(body?: User[], query?: QueryT): Promise<AuthzInterface.IUser[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	/**
	 * Reads the user object from AuthZ.
	 * @param workspaceUuid Workspace UUID.
	 * @param uuid User UUID.
	 * @param withWorkspaceRoles URL parameter to also get the WorkspaceRoles and WorkspaceRolesFromGroups attributes.
	 */
	public async readUser(workspaceUuid: string, uuid: string, withWorkspaceRoles?: boolean): Promise<AuthzInterface.IUser> {
		const query = withWorkspaceRoles ? {
			workspaceroles: 'true',
			workspacerolesfromgroups: 'true',
		} : undefined;
		const url = this.makeUrl(`${workspaceUuid}/${uuid}`, query);
		return this.get(url);
	}

	public async readCallingUser(query?: { compliancecheck: boolean }): Promise<AuthzInterface.IUser> {
		const endpoint = query ? WebUtils.getParamString(query) : '';
		const url = this.makeUrl(endpoint);
		return this.get(url);
	}

	public async readPermissionsCurrentUser(workspaceUuid: string, query: GetPermissionsQuery): Promise<AuthzBaseInterface.PermissionResponseI> {
		const url = this.makeUrl(`${workspaceUuid}/permissions`, query);
		return this.post(url);
	}

	public async sendMailForChangePassword(): Promise<{ Success: true }> {
		const url = this.makeUrl('reset-password');
		return this.post(url);
	}

	public async readAllPermissionsCurrentUser(query: GetPermissionsQuery): Promise<AuthzBaseInterface.AllPermissionResponseI> {
		const url = this.makeUrl('permissions', query);
		return this.post(url);
	}

	public async getAllFromWorkspace<QueryT extends object>(workspaceUuid: string, query?: QueryT): Promise<AuthzInterface.IUser[]> {
		const users: AuthzInterface.IUser[] = await this.get(this.makeUrl(`${workspaceUuid}`, query));
		return users.map((user) => User.fromResponse(user));
	}

	public async assignProjectRoles(workspaceUuid: string, userUuid: string, query: AssignProjectRolesQuery): Promise<void> {
		const body = {
			ProjectRoleUuids: query.projectRolesUuids,
			CollectionOrProjectUuid: query.projectUuid,
			Target: 'Project',
		};
		await this.put(this.makeUrl(`${workspaceUuid}/${userUuid}/assign-projectroles`), body);
	}

	public async assignProjectRolesUsers(workspaceUuid: string, query: AssignProjectRolesUsersQuery): Promise<void> {
		const body = {
			UserOrGroupUuids: query.userUuids,
			ProjectRoleUuids: query.projectRolesUuids,
			CollectionOrProjectUuid: query.projectUuid,
			Target: 'Project',
		};
		await this.put(this.makeUrl(`${workspaceUuid}/assign-projectroles/users`), body);
	}

	public async registerUser(user: AuthzReq.RegisterUserReq & WithUserRegistrationToken): Promise<AuthzInterface.IUser> {
		const url = this.makeUrl('register');
		// Use empty headers to prevent from using authentication, otherwise it would try to get the JWT token which is not needed for this route.
		return this.post(url, user, { headers: {} });
	}

	public async sendEmailVerification(sendEmailVerificationBody: SendEmailVerificationBody): Promise<void> {
		const url = this.makeUrl('send-email-verification');
		// Use empty headers to prevent from using authentication, otherwise it would try to get the JWT token which is not needed for this route.
		return this.post(url, sendEmailVerificationBody, { headers: {} });
	}

	public async deleteCallingUser(): Promise<DeleteUserResponse> {
		const url = this.makeUrl('delete-calling-user');
		return this.del(url);
	}

	public async deleteCallingUserIgnoreBaseWorkspaces(): Promise<DeleteUserResponse> {
		const url = this.makeUrl('delete-calling-user', { ignoreBaseOwner: true });
		return this.del(url);
	}

	public async getByEmail(workspaceUuid: string, email: string, params?: any): Promise<AuthzInterface.IUser> {
		const url = this.makeUrl(`${workspaceUuid}/email`);
		return this.get(url, params, {'sphere-user-email': email});
	}

	public async validateWorkspaceCreation(): Promise<{ CreateWorkspaceCheck: boolean, PendingWorkspaces: Generated.SubSvcPendingWorkspaces }> {
		const url = this.makeUrl('create-workspace-check');
		return this.get(url);
	}

	public async readTokenInfo(): Promise<Generated.TokenInfo> {
		const url = this.makeUrl('token-info');
		return await this.get(url);
	}

	/**
	 * Calls AuthZ to init the process to change the user's email address.
	 * @author OK
	 * @param newEmail The new email address of the user.
	 * @throws {HttpError}
	 */
	public async initEmailChange(newEmail: string): Promise<void> {
		const url = this.makeUrl('init-email-change');
		await this.post(url, { Email: newEmail });
	}

	/**
	 * Calls AuthZ to finish the process to change the user's email address.
	 * @author OK
	 * @param tokenUuid UUID of the email token in AuthZ's database.
	 * @returns The user object with the updated email attribute.
	 * @throws {HttpError}
	 */
	public async finishEmailChange(tokenUuid: string): Promise<User> {
		const url = this.makeUrl(`finish-email-change/${tokenUuid}`);
		const user: AuthzInterface.IUser = await this.post(url, {}, {
			// Use an empty object to prevent using the default headers,
			// since they include the bearer token by default and for that the user has to be logged in,
			// which should not be required in this case.
			headers: {},
		});
		return User.fromResponse(user);
	}
}
