import { Workspace } from '@/classes/authz/Workspace';
import { config } from '@/config';
import { ProjectOrWorkspace } from '@/definitions-frontend/types-client-only';
import PageBaseMixin from '@/mixins/PageBaseMixin';
import { $assert } from '@faroconnect/utils';
import Vue from 'vue';

/**
 * Calculates whether the user hasn't accepted the invitation to a workspace (yet).
 * @param item The workspace to be checked.
 * @return True if the user has a pending invitation. False if the user is an active workspace member.
 */
export function missingAuthzAcceptation(item: Workspace): boolean {
	if (!hasType('Workspace', item)) {
		return true;
	}
	if (!item.WorkspaceUserState) {
		return false;
	}
	switch (item.WorkspaceUserState) {
		case 'pending':
			return true;
		case 'active':
			return false;
		default:
			$assert.Assert(false, 'Invalid state ' + item.WorkspaceUserState);
			return false;
	}
}

export function hasType(entityType: 'Workspace' | 'Project', entity: ProjectOrWorkspace): boolean {
	return entity.Class === entityType;
}

/**
 * Calculates whether the user doesn't have permissions to open AuthZ.
 * @param item The workspace to be checked.
 * @return True if the user doesn't have permissions. False otherwise.
 */
export function missingOpenAuthzPermission(item: Workspace, vue: Vue): boolean {
	const openAuthz = vue.$tsStore.users.getWorkspaceAccessControl(item.UUID).openAuthz;
	return missingAuthzAcceptation(item) || !openAuthz.hasPermission;
}

/**
 * Calculates whether the user doesn't have permissions to open the workspace dashboard.
 * @param item The workspace to be checked.
 * @return True if the user doesn't have permissions. False otherwise.
 */
export function missingOpenDashboardPermission(item: Workspace, vue: Vue): boolean {
	// See WebShare frontend, permissionsToSeeDashboard.
	// Even users without the 'view-billing-data' permission may have a "Dashboard" entry in the WebShare menu,
	// but won't see any content on the page.
	const viewDashboard = vue.$tsStore.users.getWorkspaceAccessControl(item.UUID).viewWorkspaceInsightsDashboard;
	return missingAuthzAcceptation(item) || !viewDashboard.hasPermission;
}

/**
 * Open the AuthZ UI for the given workspace.
 * @param workspace
 */
export function xOpenWorkspaceAccessControl(workspace: Workspace): void {
	// Since AuthZ API v1.2.0 it is possible to open the UI using workspace UUID instead of workspace name.
	window.open(config.authzUI + '/' + workspace.UUID, '_blank');
}

/**
 * Triggered whenever the user accepts an invitation to a particular workspace.
 * It will set the status of that workspace to 'active' and it will grant the user access.
 */
export async function acceptInvitation(item: Workspace, vue: PageBaseMixin<Workspace>): Promise<void> {
	vue.xLoadingEntity = item;
	try {
		await vue.$tsStore.workspaces.activateUser(item.UUID);
	} catch (error) {
		const message = vue.$tc('LP_ERR_ACCEPT_INVITATION', undefined, { name: item.Name });
		vue.$faroComponents.$emit('show-error', { error, message });
	} finally {
		vue.xLoadingEntity = null;
	}
}

/**
 * Triggered whenever the user rejects an invitation to a particular workspace.
 * It will remove the invitation and will be removed from the workspaces list.
 */
export async function rejectInvitation(item: Workspace, vue: PageBaseMixin<Workspace>): Promise<void> {
	vue.xLoadingEntity = item;
	try {
		await vue.$tsStore.workspaces.unassignCallingUser({workspaceUuid: item.UUID});
	} catch (error) {
		const message = vue.$tc('LP_ERR_DECLINE_INVITATION', undefined, { name: item.Name });
		vue.$faroComponents.$emit('show-error', { error, message });
	} finally {
		vue.xLoadingEntity = null;
	}
}

/**
 * Triggered whenever the user leaves the workspace.
 * The user will be removed from the workspace and the workspace will be removed from the workspaces list.
 */
export async function leaveWorkspace(item: Workspace, vue: PageBaseMixin<Workspace>): Promise<void> {
	vue.xLoadingEntity = item;
	try {
		await vue.$tsStore.workspaces.unassignCallingUser({workspaceUuid: item.UUID, action: 'leave'});
		// Automatically determine new active workspace & show projects page.
		const workspaceUuid = vue.$tsStore.workspaces.activeWorkspace?.UUID || '';
		void vue.$router.push({ name: 'ProjectsPage', params: { workspace: workspaceUuid } });
	} catch (error) {
		const message = vue.$tc('LP_ERR_LEAVE_WORKSPACE', undefined, { name: item.Name });
		vue.$faroComponents.$emit('show-error', { error, message });
	} finally {
		vue.xLoadingEntity = null;
	}
}
